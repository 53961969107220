import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import PaginationV2 from './PaginationV2';
import style from './PaginationV3.module.css';

function PaginationV3({ className, items, itemsPerPage = 6, render }) {
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);

  useEffect(() => setSelectedPageNumber(1), [items]);

  const visibleItems = useMemo(() => {
    const startIndex = itemsPerPage * (selectedPageNumber - 1);
    return items.slice(startIndex, startIndex + itemsPerPage);
  }, [selectedPageNumber, items, itemsPerPage]);

  const totalPages = useMemo(() => Math.ceil(items.length / itemsPerPage), [
    items,
    itemsPerPage
  ]);

  return (
    <div className={classNames(style.container, className)}>
      {render(visibleItems)}
      {totalPages > 1 && (
        <div className={style.pagination}>
          <PaginationV2
            totalPages={totalPages}
            onPageChanged={setSelectedPageNumber}
            selectedPageNumber={selectedPageNumber}
          />
        </div>
      )}
    </div>
  );
}

export default PaginationV3;

import config from '../configs/config';
import httpRequest from './httpRequest';

const {
  schema,
  hostName,
  headers,
  app,
  services
} = config.apiSettings.appCenterPlatform;

const getDefaultHeaders = () => ({
  [headers.authorization.key]: [headers.authorization.value],
  Accept: headers.accept.value,
  [headers.contentTypeJSON.key]: headers.contentTypeJSON.value
});

const appCenterRequest = async ({
  urlSuffix,
  httpMethod,
  headers,
  basePath = config.apiSettings.appCenterPlatform.basePath,
  appPlatform,
  body,
  isStatusRequest
}) => {
  let url = '';
  if (isStatusRequest) {
    url = `${schema}://${services.status}${hostName}${basePath.status}${urlSuffix}`;
  } else {
    url = `${schema}://${hostName}${basePath.tmoBps}${app[appPlatform]}${services.analytics}${urlSuffix}`;
  }

  return await httpRequest({
    url,
    method: httpMethod,
    headers: {
      ...getDefaultHeaders(),
      ...headers
    }
  });
};

export default appCenterRequest;

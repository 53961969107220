import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  TmoH2,
  TmoButtons as TmoButton,
  withModal,
  ModalV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import useManualFetcher from '../../hooks/useManualFetcher';
import { FETCH_STATE } from '../../utils/app_constants';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import addIcon from '../../static/assets/icons/add.svg';

import style from './AddDeviceForThing.module.css';

function AddDeviceForThing({
  className,
  hardwareId,
  onAddClicked,
  availableThings,
  thingType,
  openModal,
  closeModal
}) {
  const [thingId, setThingId] = useState('');

  const [fetchState, actionTrigger] = useManualFetcher({
    action: () => {
      trackCustomEvents(`Add Device To ${thingType}`, { thingId, hardwareId });
      onAddClicked({ thingId, hardwareId });
    }
  });

  return (
    <>
      <TmoButton
        type="magenta_link"
        icon={<ReactSVG src={addIcon} />}
        onClick={openModal}
      >
        Add
      </TmoButton>
      <ModalV2
        portal={false}
        title={`Add device to ${thingType}`}
        modalType="slide"
      >
        <div className={classNames(style.container, className)}>
          {availableThings.length > 0 ? (
            <>
              <div className={style.form_content}>
                <TmoH2>
                  Which {thingType} would you like to add this device to?
                </TmoH2>
                <input
                  className={style.input}
                  list={thingType}
                  name={thingType}
                  autoComplete="off"
                  onChange={event => setThingId(event.target.value)}
                />
                <datalist id={thingType}>
                  {availableThings.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}: {id}
                    </option>
                  ))}
                </datalist>
              </div>

              <div className={style.button_wrapper}>
                <TmoButton onClick={closeModal} type="magenta_secondary">
                  Cancel
                </TmoButton>
                <TmoButton
                  className={style.button}
                  onClick={actionTrigger}
                  type="magenta_primary"
                  disabled={fetchState === FETCH_STATE.LOADING}
                >
                  Add To {thingType}
                </TmoButton>
              </div>
            </>
          ) : (
            <div className={style.row}>
              All {thingType} have an active device. Add an additional{' '}
              {thingType} to add a device.
            </div>
          )}
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(AddDeviceForThing);

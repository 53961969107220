import React, { useState } from 'react';

import DynamicList from '../../uiHelpers/DynamicList';
import { SORT_DIRECTION } from '../../../utils/app_constants';
import { sortList } from '../../../utils/helper_functions';
import TmoSvg from '../../uiHelpers/TmoSvg';
import TmoH2 from '../../controlWrappers/TmoH2';
import { FaEllipsisV } from 'react-icons/all';
import { commercialAccessPoints } from '../../../utils/pocDummyData';
import AccessPointsImg from '../../../static/assets/commercial/images/access-point.png';
import TmoCard from '../../uiHelpers/TmoCard';

import style from './AccessPoints.module.css';

const LIST_HEADER_OBJECT_MAPPING = [
  {
    listItemKey: 'name',
    headerDisplayName: 'ASSET NAME',
    columnClass: 'Heading'
  },
  {
    listItemKey: 'batteryLevel',
    headerDisplayName: 'POWER',
    columnClass: 'Heading',
    render: () => (
      <div className={style.battery}>
        <TmoSvg svgId="icon-ac-power" className={style.battery_icon} />
        <span className={style.battery_type}>AC</span>
      </div>
    )
  },
  {
    listItemKey: 'status',
    headerDisplayName: 'STATUS',
    columnClass: 'Heading'
  },
  {
    listItemKey: 'lastUpdate',
    headerDisplayName: 'LAST UPDATE',
    columnClass: 'Heading'
  }
];

function AccessPoints() {
  const [accessPoint, setAccessPoint] = useState(commercialAccessPoints);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);

  const handleOnListSort = ({ listItemKey }) => {
    const sortedAccessPoint = accessPoint;
    sortList(sortedAccessPoint, { listItemKey }, sortDirection);
    sortDirection === SORT_DIRECTION.ASC
      ? setSortDirection(SORT_DIRECTION.DESC)
      : setSortDirection(SORT_DIRECTION.ASC);
    setAccessPoint(sortedAccessPoint);
  };

  return (
    <TmoCard className={style.container}>
      <div className={style.header}>
        <div className={style.image}>
          <img alt="AccessPoints" src={AccessPointsImg} />
        </div>
        <TmoH2 className={style.title}>Access Points</TmoH2>
        <div className={style.count}>
          {commercialAccessPoints.length} devices
        </div>
        <FaEllipsisV className={style.title_icon} />
      </div>
      <DynamicList
        className="border-lite"
        headerNameListItemMap={LIST_HEADER_OBJECT_MAPPING}
        listItems={commercialAccessPoints}
        onSort={handleOnListSort}
        sortDirection={sortDirection}
      />
    </TmoCard>
  );
}

export default AccessPoints;

import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import {
  TmoH2,
  TmoInput,
  TmoDropDownSelect,
  TmoTextarea,
  TmoButtons as TmoButton,
  FormModal,
  TmoCard,
  withModal
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import { getPetsFormValue } from '../../utils/simulatorConsts';
import simulatorService from '../../services/simulatorService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './SimulatorCard.module.css';
import { FaEdit, FaTrash } from 'react-icons/all';

function PetsNewSimulator({ data, onRemove, openModal, forceRefresh }) {
  const [editForm, setEditForm] = useState(getPetsFormValue());
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const handleRemove = () => {
    try {
      onRemove(data);
      addSuccessMessage({ message: 'Device deleted successfully' });
    } catch (error) {
      addErrorMessage({ error });
    }
  };
  const openEditSimulatorModal = async () => {
    let jsonString = JSON.stringify(editForm);
    let tempObj = JSON.parse(jsonString);
    tempObj.forEach(item => {
      Object.entries(data).forEach(([k, v]) => {
        if (item.fieldName === k) {
          item.formComponent =
            item.formInputType === 'TmoInput'
              ? TmoInput
              : item.formInputType === 'TmoTextarea'
              ? TmoTextarea
              : item.formInputType === 'TmoDropDownSelect'
              ? TmoDropDownSelect
              : null;
          item.defaultValue = v;
        }
      });
    });
    setEditForm(tempObj);
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      let formData = { id: data.id, ...formValues };
      trackCustomEvents(`Update Simulator`, {
        id: data.id
      });
      let result = await simulatorService.saveSimulator(formData);
      if (result) {
        addSuccessMessage({ message: 'Successfully updated simulated device' });
        forceRefresh();
      } else {
        addErrorMessage({ message: 'Failed to update simulated device' });
      }
    }
  };
  return (
    <TmoCard className={style.container}>
      <TmoH2 className={style.heading}>{data.hardwareId}</TmoH2>
      <div className={style.body}>
        <div className={style.row}>
          <div className={style.items}>
            <label className={style.label}> Power:</label>
            <span> {data?.isPoweredOn?.toString()}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Security Key:</label>
            <span> {data.securityKey}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Owned By:</label>
            <span> {data.ownedBy}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Temperature:</label>
            <span> {data.temperature}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Buzzer Enabled:</label>
            <span> {data?.isBuzzerOn?.toString()}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Activity Mode:</label>
            <span> {data.activityMode}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Location Mode:</label>
            <span> {data.locationMode}</span>
          </div>
        </div>
        <div className={classNames(style.row, style.grid4)}>
          <div className={style.items}>
            <label className={style.label}> Latitude:</label>
            <span> {data.latitude}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Longitude:</label>
            <span> {data.longitude}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Altitude:</label>
            <span> {data.altitude}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> Radius:</label>
            <span> {data.radius}</span>
          </div>
        </div>
        <div className={style.row}>
          <div className={style.items}>
            <label className={style.label}> battery level mode:</label>
            <span> {data.batteryLevelMode}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> battery level:</label>
            <span> {data.batteryLevel}</span>
          </div>
          <div className={style.items}>
            <label className={style.label}> battery status:</label>
            <span> {data.batteryStatus}</span>
          </div>
        </div>
      </div>
      <div className={style.footer}>
        <div className={style.action}>
          <TmoButton
            type="magenta_secondary"
            icon={<FaTrash />}
            onClick={handleRemove}
            onSubmit={handleRemove}
            className={style.delete}
          >
            Delete
          </TmoButton>
        </div>
        <div className={style.action}>
          <TmoButton
            type="magenta_primary"
            icon={<FaEdit />}
            className={style.save}
            buttonType="submit"
            onClick={openEditSimulatorModal}
          >
            Edit Simulator
          </TmoButton>
          <FormModal
            title={`Edit Simulator`}
            fieldSpecs={editForm}
            modalType={'slide'}
          />
        </div>
      </div>
    </TmoCard>
  );
}

export default withModal(PetsNewSimulator);

import React, { useContext } from 'react';
import { FaTrash } from 'react-icons/all';
import {
  ConfirmationModalV2,
  withModal,
  TmoButtons,
  TmoH3
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import firmwareService from '../../services/firmwareService';
import StatusContext from '../../context/StatusContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './GroupDelete.module.css';

function GroupDelete({ onRemove, group, openModal }) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const handleRemoveGroupClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Firmware Group Removal`, {
        groupId: group.id
      });
      try {
        onRemove(await firmwareService.deleteFirmwareGroup(group.id));
        addSuccessMessage({
          message: `Package id: ${group.id} removed successfully`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  const removeModalMessage = (
    <>
      <TmoH3 flush className={style.remove_message_heading}>
        Are you sure you want to remove <u>{group.name}</u>?
      </TmoH3>
      {group.imeis && (
        <div className={style.row}>
          <label>{group.imeis.length} IMEI's in Group: </label>{' '}
          <ol className={style.imei}>
            {group.imeis.map((i, index) => (
              <li key={index}>{i}</li>
            ))}
          </ol>
        </div>
      )}
    </>
  );

  return (
    <>
      <TmoButtons
        onClick={handleRemoveGroupClicked}
        icon={<FaTrash />}
        type="danger"
      >
        Delete
      </TmoButtons>
      <ConfirmationModalV2
        message={removeModalMessage}
        title="Confirm Firmware Group Removal"
        acceptButtonText="Remove Group"
      />
    </>
  );
}

export default withModal(GroupDelete);

import React from 'react';

import TmoSvg from '../../uiHelpers/TmoSvg';

import style from './DeviceBatteryLevel.module.css';

function DeviceBatteryLevel({ battery }) {
  return (
    <div className={style.battery}>
      <TmoSvg
        svgId={
          battery > 80
            ? 'icon-battery-100'
            : battery >= 61 && battery <= 80
            ? 'icon-battery-75'
            : battery >= 31 && battery <= 60
            ? 'icon-battery-50'
            : battery >= 10 && battery <= 30
            ? 'icon-battery-25'
            : battery < 10
            ? 'icon-battery-0'
            : null
        }
        className={style.battery_icon}
      />
      <span className={style.battery_text}>{battery}%</span>
    </div>
  );
}

export default DeviceBatteryLevel;

import React from 'react';
import classNames from 'classnames';

import TmoCard from '../uiHelpers/TmoCard';

import style from './StatusCard.module.css';

function StatusCard({ className, status, children }) {
  return (
    <TmoCard
      shadow={false}
      className={classNames(
        style.container,
        style[status.toLowerCase()],
        className
      )}
    >
      {children}
    </TmoCard>
  );
}

export default StatusCard;

import config from '../configs/config';
import httpRequest from './httpRequest';
import { authClient } from '../auth/authClient';
import { HubConnectionBuilder } from '@microsoft/signalr';

const { headers } = config.apiSettings.syncUpPlatform;

const getDefaultHeaders = async () => ({
  [headers.partnerId.key]: headers.partnerId.value,
  [headers.subscriptionKey.key]: headers.subscriptionKey.value,
  [headers.authorization.key]: await authClient.getIdToken()
});

const pocRepository = {
  getDeviceHistory: async ({ productId, deviceId }) => {
    const url =
      config.apiSettings.pocPlatform.basePath.replace(
        '{product-id}',
        productId
      ) +
      config.apiSettings.pocPlatform.getDeviceHistory.replace(
        '{device-id}',
        deviceId
      );

    return await httpRequest({
      url,
      headers: { ...(await getDefaultHeaders()) }
    });
  },
  getDevices: async ({ productId }) => {
    const url =
      config.apiSettings.pocPlatform.basePath.replace(
        '{product-id}',
        productId
      ) + config.apiSettings.pocPlatform.getDevices;

    return await httpRequest({
      url,
      headers: { ...(await getDefaultHeaders()) }
    });
  },
  sendSms: async ({ productId, deviceId }) => {
    const url =
      config.apiSettings.pocPlatform.basePath.replace(
        '{product-id}',
        productId
      ) +
      config.apiSettings.pocPlatform.sendSms.replace('{device-id}', deviceId);

    return await httpRequest({
      url,
      headers: { ...(await getDefaultHeaders()) }
    });
  },
  setDesiredState: async ({ productId, deviceId, desiredState }) => {
    const url =
      config.apiSettings.pocPlatform.basePath.replace(
        '{product-id}',
        productId
      ) +
      config.apiSettings.pocPlatform.setDesiredState.replace(
        '{device-id}',
        deviceId
      );

    return await httpRequest({
      url,
      body: desiredState,
      method: 'POST',
      headers: {
        ...(await getDefaultHeaders()),
        [config.apiSettings.syncUpPlatform.headers.contentTypeJSON.key]:
          config.apiSettings.syncUpPlatform.headers.contentTypeJSON.value
      }
    });
  },
  connectToDeviceUpdates: async ({ productId, methodHandler }) => {
    const connection = new HubConnectionBuilder()
      .withUrl(
        config.apiSettings.pocPlatform.basePath.replace(
          '{product-id}',
          productId
        )
      )
      .withAutomaticReconnect()
      .build();
    connection.on(`newDeviceState:${productId}`, methodHandler);
    await connection.start();
    return connection;
  }
};

export default pocRepository;

import React, { useContext } from 'react';

import SyncUpProductContext from '../../context/SyncUpProductContext';
import ActiveUserTabContext from 'context/ActiveUserTabContext';

function Feature({ Products = [], children, isUserDetails = false }) {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const activeUserTabContext = useContext(ActiveUserTabContext);

  return Products.includes(
    isUserDetails
      ? activeUserTabContext?.selectedTab?.product
      : syncUpProductContext.syncUpAppName
  ) ? (
    <>{children}</>
  ) : null;
}

export default Feature;

import React, { useState } from 'react';
import { TmoCard, TmoH1, TmoButtons } from '@tmobile/tmo-bps-syncup-web-component-lib';

import { objectToQueryString } from '../../../utils/queryStringUtils';
import StatusSearchForm from './StatusSearchForm';
import StatusSearchResults from './StatusSearchResults';

import style from './StatusSearch.module.css';

function StatusSearch({ history, location }) {
  const [filter, setFilter] = useState({});

  const handleFilterChange = (value, filterName) => {
    setFilter({ ...filter, [filterName]: value });
  };

  const handleOnSearch = () => {
    let query = objectToQueryString(filter);
    query && history.push({ pathname: '/firmware/status', search: query });
  };

  return (
    <>
      <TmoCard>
        <div className={style.heading_section}>
          <TmoH1 flush>Search Firmware Download Status</TmoH1>
        </div>
        <StatusSearchForm onFilter={handleFilterChange} filter={filter} />
        <div className={style.action}>
          <TmoButtons
            className={style.action_reset}
            type="secondary"
            onClick={() => setFilter({})}
          >
            Reset Filters
          </TmoButtons>
          <TmoButtons
            disabled={!(Object.keys(filter).length >= 1)}
            onClick={handleOnSearch}
          >
            Search
          </TmoButtons>
        </div>
      </TmoCard>
      <StatusSearchResults location={location} />
    </>
  );
}

export default StatusSearch;

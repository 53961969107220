import React from 'react';
import classNames from 'classnames';
import { withModal } from '@tmobile/tmo-bps-syncup-web-component-lib';

import ProductFAQVotes from './ProductFAQRating';

import style from './Details.module.css';

function Details({
  details,
  isAdmin,
  userId,
  onDataChange,
  tabIndex,
  isOpen,
  faqContent,
  category,
  isPortalFAQ = false,
  className,
  language
}) {
  const { description } = details;
  return (
    <div className={isAdmin && style.container}>
      {details && (
        <>
          <div className={style.details_wrapper}>
            <p
              className={classNames(
                style.description,
                className && className,
                isAdmin && style.faq_description
              )}
              aria-label={description}
              tabIndex={tabIndex && (isOpen ? tabIndex + 1 : -1)}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
          {!isPortalFAQ && (
            <ProductFAQVotes
              isAdmin={isAdmin}
              details={details}
              userId={userId}
              onDataChange={onDataChange}
              tabIndex={tabIndex && (isOpen ? tabIndex + 1 : -1)}
              faqContent={faqContent}
              category={category}
              language={language}
            />
          )}
        </>
      )}
    </div>
  );
}

export default withModal(Details);

import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { Tooltip } from '@tmobile/tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import ExpandableText from '../expandableText/ExpandableText';
import { SUA_PAGES } from '../../utils/app_constants';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import config from '../../configs/config';

import style from './LeftPanenNavigation.module.css';
import { FaCaretDown } from 'react-icons/all';

const leftNavPages = Object.values(SUA_PAGES)
  .filter(p => p.leftNavName && p.leftNavPriority)
  .map(page => {
    if (page.subPages) {
      const subPages = Object.values(page.subPages)
        .filter(sp => sp.subPageName && sp.subPagePriority)
        .sort((a, b) => a.subPagePriority - b.subPagePriority);

      return {
        ...page,
        subPages
      };
    }
    return page;
  })
  .sort((a, b) => a.leftNavPriority - b.leftNavPriority);

function LeftPanelNavigation({ className, showMenuItems }) {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const env = config.apiSettings.environment;

  const getNavLink = (path, navLinkName, icon) => (
    <NavLink
      key={path}
      className={style.nav_link}
      activeClassName={style.nav_link_active}
      to={path}
    >
      {showMenuItems ? (
        <>
          <div className={style.menu_icon}>
            <ReactSVG src={icon ? icon : ''} className={style.no_data_img} />
          </div>
          <div className={style.link_name}>{navLinkName}</div>
        </>
      ) : (
        <Tooltip tooltip={navLinkName} position="bottom">
          <div className={style.menu_icon}>
            <ReactSVG src={icon ? icon : ''} className={style.no_data_img} />
          </div>
        </Tooltip>
      )}
    </NavLink>
  );

  const authRoutes = (authEnv, authProduct) => {
    return (
      authEnv.includes(env) &&
      authProduct.includes(syncUpProductContext.syncUpAppName)
    );
  };

  return (
    <div className={classNames(style.container, className)}>
      {leftNavPages.map(
        ({
          path,
          authorizedRoles,
          authorizedProducts = [],
          authorizedEnvironments = [],
          leftNavName,
          subPages,
          icon,
          icons
        }) =>
          authRoutes(authorizedEnvironments, authorizedProducts) && (
            <Authorized key={path} roles={authorizedRoles}>
              {subPages ? (
                <ExpandableText
                  triggerText={leftNavName}
                  className={style.expandable_menu}
                  triggerIcon={<FaCaretDown />}
                  theme="Navigation-dropdown"
                  menuIcon={
                    icons && icons.length
                      ? icons.filter(
                          x => x.product === syncUpProductContext.syncUpAppName
                        )[0].icon
                      : icon
                  }
                  showMenuItems={showMenuItems}
                  isNavCollapsed={!showMenuItems}
                >
                  {subPages.map(
                    ({
                      path,
                      subPageName,
                      authorizedRoles: subPagesAuthorizedRoles,
                      authorizedProducts = [],
                      subMenuIcon
                    }) =>
                      authorizedProducts.includes(
                        syncUpProductContext.syncUpAppName
                      ) && (
                        <Authorized
                          key={path}
                          roles={
                            subPagesAuthorizedRoles
                              ? subPagesAuthorizedRoles
                              : authorizedRoles
                          }
                        >
                          {getNavLink(path, subPageName, subMenuIcon)}
                        </Authorized>
                      )
                  )}
                </ExpandableText>
              ) : (
                getNavLink(path, leftNavName, icon)
              )}
            </Authorized>
          )
      )}
    </div>
  );
}

export default withRouter(LeftPanelNavigation);

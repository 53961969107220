import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoButtons,
  withModal,
  ConfirmationModalV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import userService from '../../services/userService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import StatusContext from '../../context/StatusContext';

import style from './LocationGroupsMemberSwap.module.css';
import swap_icon from '../../static/assets/icons/swap_member.svg';

function LocationGroupsMemberSwap({ user, openModal, forceRefresh }) {
  const { id, role } = user;
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);

  const handleMemberSwap = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Swap Group Owner`, {
        groupId: id
      });
      try {
        await userService.swapPhoneLocationGroupMember({
          groupId: id,
          userId: userId,
          targetUserId: user.userId,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Member is swapped successfully`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };
  return (
    <>
      {role === 'MEMBER' && (
        <>
          <TmoButtons
            className={style.swap_button}
            onClick={handleMemberSwap}
            type="magenta_icon_btn"
            tooltip="Swap Group Owner"
            icon={<ReactSVG src={swap_icon} />}
          />
          <ConfirmationModalV2
            modalType="delete"
            message={<>Are you sure you want to swap group owner?</>}
            title="Swap Group Owner"
            acceptButtonText="Yes,Accept"
            type="danger"
            usePortal={false}
            confirmInput
            confirmText="Accept"
            buttonIcon={<ReactSVG src={swap_icon} />}
          />
        </>
      )}
    </>
  );
}

export default withModal(LocationGroupsMemberSwap);

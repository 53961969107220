import {
  deleteDevice,
  thingLinkDevice,
  getDeviceHealthCheckStatus
} from '../services/syncup_platform';
import { getUserThings } from './user_things';
import { getUserDevices } from './devices';
import {
  ADD_DEVICE_REQUEST,
  ADD_DEVICE_SUCCESS,
  ADD_DEVICE_ERROR,
  REMOVE_DEVICE_REQUEST,
  REMOVE_DEVICE_SUCCESS,
  REMOVE_DEVICE_ERROR,
  HEALTH_CHECK_DEVICE_REQUEST,
  HEALTH_CHECK_DEVICE_SUCCESS,
  HEALTH_CHECK_DEVICE_ERROR
} from '../utils/app_constants';

const removeDeviceRequest = () => ({
  type: REMOVE_DEVICE_REQUEST
});

const removeDeviceSuccess = () => ({
  type: REMOVE_DEVICE_SUCCESS
});

const removeDeviceError = error => ({
  type: REMOVE_DEVICE_ERROR,
  payload: error
});

const addDeviceRequest = () => ({
  type: ADD_DEVICE_REQUEST
});

const addDeviceSuccess = () => ({
  type: ADD_DEVICE_SUCCESS
});

const addDeviceError = error => ({
  type: ADD_DEVICE_ERROR,
  payload: error
});

const healthCheckRequest = () => ({
  type: HEALTH_CHECK_DEVICE_REQUEST
});

const healthCheckSuccess = data => ({
  type: HEALTH_CHECK_DEVICE_SUCCESS,
  payload: data
});

const healthCheckError = error => ({
  type: HEALTH_CHECK_DEVICE_ERROR,
  payload: error
});

export function removeDevice(userId, deviceId) {
  return async dispatch => {
    dispatch(removeDeviceRequest());
    try {
      await deleteDevice(userId, deviceId);

      dispatch(removeDeviceSuccess());
      dispatch(getUserThings(userId));
      dispatch(getUserDevices(userId));
    } catch (error) {
      dispatch(removeDeviceError({ errorCode: error.message }));
    }
  };
}

export function addDevice(thingId, hardwareId, userId) {
  return async dispatch => {
    dispatch(addDeviceRequest());
    try {
      await thingLinkDevice(thingId, hardwareId, userId);

      dispatch(addDeviceSuccess());
      dispatch(getUserDevices(userId));
      dispatch(getUserThings(userId));
    } catch (error) {
      dispatch(addDeviceError({ errorCode: error.message }));
    }
  };
}

export function getHealthCheck(userId, deviceId) {
  return async dispatch => {
    dispatch(healthCheckRequest());
    try {
      const payload = await getDeviceHealthCheckStatus(userId, deviceId);
      dispatch(healthCheckSuccess(payload));
    } catch (error) {
      dispatch(healthCheckError({ errorCode: error.message }));
    }
  };
}

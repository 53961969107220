import React, { useCallback, useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import {
  ContentContainer,
  TmoButtons,
  withModal,
  ModalV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import PortalSearch from './PortalSearch';
import PortalFaqContent from './PortalFaqContent';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import Fetcher from '../../components/Fetcher';
import { PORTAL_FAQ_DATA } from '../../utils/portalFAQ_data';

import style from './PortalFaq.module.css';
import helpIcon from '../../static/assets/icons/leftpanel/help.svg';

function PortalFaq({ openModal }) {
  const [faqContent, setFaqContent] = useState();
  const syncUpProductContext = useContext(SyncUpProductContext);

  const actionCallback = useCallback(() => {
    return PORTAL_FAQ_DATA;
  }, []);

  return (
    <ContentContainer className={style.container}>
      <TmoButtons
        buttonType="magenta_link"
        onClick={openModal}
        className={style.help_btn}
        icon={<ReactSVG src={helpIcon} />}
      >
        <div className={style.help_text}>Help</div>
      </TmoButtons>
      <ModalV2 title={`Portal FAQ manager`} modalType="slide" portal={true}>
        <Fetcher
          action={actionCallback}
          onLoad={setFaqContent}
          render={() =>
            faqContent && faqContent.length > 0 ? (
              <>
                <PortalSearch
                  list={faqContent}
                  renderItem={(item, index) => (
                    <PortalFaqContent
                      key={`portal-faq-fragment-${index}`}
                      item={item}
                    />
                  )}
                  isAdmin
                />
              </>
            ) : (
              <div className={style.faq_not_found}>
                There are no FAQ's for {syncUpProductContext.syncUpAppName}. Add
                some now!
              </div>
            )
          }
        />
      </ModalV2>
    </ContentContainer>
  );
}

export default withModal(PortalFaq);

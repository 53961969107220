import React, { useState } from 'react';

import UesrSearchResults from './UerSearchResult';
import UserSearchBar from './UserSearchBar';
import { TmoRadioGroup } from '@tmobile/tmo-bps-syncup-web-component-lib';
import style from './UserSearchPanel.module.css';
import { STORAGE_KEYS, STORAGE_TYPES } from '../../../utils/app_constants';

import {
  storageWrite,
  storageRead
} from '../../../utils/storageUtils';


function SearchPanel() {
  const [searchTerm, setSearchTerm] = useState();
  const [searchType, setSearchType] = useState('LEGACY');
  const onSerachTerm = text => {
    setSearchTerm(text);
  };
  const sessionSearchType = storageRead({
    key: STORAGE_KEYS.SEARCH_TYPE,
    storageType: STORAGE_TYPES.LOCAL
  });
  const handleSearchChange = value => {
    setSearchTerm('');
    setSearchType(value);
    storageWrite({
      key: STORAGE_KEYS.SEARCH_TYPE,
      value: value,
      storageType: STORAGE_TYPES.LOCAL
    });
  };
  return (
    <>
      <h1 className={style.panel_title}> User Search </h1>
      {/* for tlife search */}
      <div className={style.radio_style}>
        <TmoRadioGroup
          name="tripType"
          data={{
            radioGrp: 'iconPosition-radio',
            defaultValue: sessionSearchType,
            options: ['LEGACY', 'TLIFE']
          }}
          onChange={handleSearchChange}
        />
      </div>
      <UserSearchBar onSerachTerm={[onSerachTerm, searchType]} />
      <UesrSearchResults userSearchTerm={searchTerm} />
    </>
  );
}
export default SearchPanel;

import React, { useState } from 'react';
import classNames from 'classnames';
import {
  TmoH3,
  DynamicList,
  ExpandableText,
  TmoCard,
  ContentContainer
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import { HashLink as Link } from 'react-router-hash-link';
import Fetcher from '../components/Fetcher';
import deviceService from '../services/deviceService';
import { FaCaretDown } from 'react-icons/all';
import { DATE_IN_TIME_ZONE, SORT_DIRECTION } from '../utils/app_constants';
import { getTimestampList, minutesAgo } from '../utils/dateUtils';
import { sortList } from '../utils/helper_functions';

import style from './RecentDevicesPage.module.css';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

const onboarded = 'ONBOARDED';
const onboarding = 'ONBOARDING';
const failed = 'FAILED';

function RecentDevicesPage({ className }) {
  const [devices, setDevices] = useState([]);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);

  const headerNameListItemMap = [
    {
      listItemKey: 'id',
      headerDisplayName: 'ID'
    },
    {
      listItemKey: 'userId',
      headerDisplayName: 'User ID',
      render: ({ userId }) => (
        <Link to={`users/${userId}/things`}>{userId}</Link>
      )
    },
    {
      listItemKey: 'hardwareId',
      headerDisplayName: 'IMEI'
    },
    {
      listItemKey: 'msisdn',
      headerDisplayName: 'MSISDN'
    },
    {
      listItemKey: 'onboardingModifiedDateTime',
      headerDisplayName: 'Last Modified',
      render: ({ onboardingModifiedDateTime, onboardingStatus }) => (
        <div className={style.created}>
          <p>
            {getTimestampList(
              onboardingModifiedDateTime,
              null,
              DATE_IN_TIME_ZONE.SYSTEM
            )}
          </p>
          <span
            className={classNames(
              style.created_date,
              onboardingStatus === onboarding &&
                onboardingModifiedDateTime <= minutesAgo(119) &&
                'warning'
            )}
          >
            (
            {getTimestampList(
              onboardingModifiedDateTime,
              null,
              DATE_IN_TIME_ZONE.SYSTEM
            ).fromNow()}
            )
          </span>
        </div>
      )
    },
    {
      listItemKey: 'onboardingStatus',
      headerDisplayName: 'Status',
      render: ({ onboardingStatus }) => (
        <div className={style[onboardingStatus]}>{onboardingStatus}</div>
      )
    },
    {
      listItemKey: 'onboardingFailureReason',
      headerDisplayName: 'Failure Reason',
      render: ({ onboardingFailureReason }) =>
        onboardingFailureReason && (
          <ExpandableText
            triggerText="View"
            closeText="Close"
            triggerIcon={<FaCaretDown />}
          >
            {onboardingFailureReason}
          </ExpandableText>
        )
    }
  ];

  const handleOnListSort = ({ listItemKey }) => {
    const copyDevices = [...devices];
    sortList(copyDevices, { listItemKey }, sortDirection);
    sortDirection === SORT_DIRECTION.ASC
      ? setSortDirection(SORT_DIRECTION.DESC)
      : setSortDirection(SORT_DIRECTION.ASC);
    setDevices(copyDevices);
  };

  const deviceCounts = status => {
    return devices.filter(s => s.onboardingStatus === status).length;
  };

  return (
    <ContentContainer className={classNames(style.container, className)}>
      <PageHeader title={'Recent Devices'} />
      <TmoCard>
        <Fetcher
          action={deviceService.getRecentDevices}
          onLoad={setDevices}
          data={devices}
          render={() => (
            <>
              <TmoH3 className={style.device_count}>
                Total: <span>{devices.length}</span>
              </TmoH3>
              <TmoH3 className={style.device_count}>
                Onboarded: <span>{deviceCounts(onboarded)}</span>
              </TmoH3>
              <TmoH3 className={style.device_count}>
                Onboarding: <span>{deviceCounts(onboarding)}</span>
              </TmoH3>
              <TmoH3 className={style.device_count}>
                Failed: <span>{deviceCounts(failed)}</span>
              </TmoH3>
              <DynamicList
                headerNameListItemMap={headerNameListItemMap}
                listItems={devices}
                onSort={handleOnListSort}
                sortDirection={sortDirection}
              />
            </>
          )}
        />
      </TmoCard>
    </ContentContainer>
  );
}

export default RecentDevicesPage;

import config from 'configs/config';
import deviceRepository from 'repositories/deviceRepository';
import faqContentRepository from 'repositories/faqContentRepository';
import firmwareRepository from 'repositories/firmwareRepository';
import iotFirmwareRepository from 'repositories/iotFirmwareRepository';
import logoutRepository from 'repositories/logoutRepository';
import mediaRepository from 'repositories/mediaRepository';
import mobileAppRepository from 'repositories/mobileAppRepository';
import platformRepository from 'repositories/platformRepository';
import simulatorRepository from 'repositories/simulatorRepository';
import thingRepository from 'repositories/thingRepository';
import userRepository from 'repositories/userRepository';
import { APP_MOBILE, IOT_PRODUCTS } from './app_constants';
import { getTimestampRangeForLastHours } from './dateUtils';
import { generateQueryString } from './helper_functions';
import {
  END_DATE_TIME_KEY,
  START_DATE_TIME_KEY,
  getRandomString
} from './stringUtils';
export const apiStatusData = product => {
  const {
    basePath,
    resources,
    schema,
    httpMethods,
    hostName
  } = config.apiSettings.syncUpPlatform;

  const getTimeRange = (startTimeKey, endTimeKey) => {
    const timeRange = getTimestampRangeForLastHours(6);
    const queryParams = [
      {
        key: startTimeKey ? startTimeKey : START_DATE_TIME_KEY,
        value: timeRange?.startTime
      },
      {
        key: endTimeKey ? endTimeKey : END_DATE_TIME_KEY,
        value: timeRange?.endTime
      }
    ];
    return generateQueryString(queryParams);
  };

  const getRoute = (basePath, resource) => {
    let match = resource.match(/:\w+/g);
    let apiPath = resource;
    match &&
      match.forEach(item => {
        apiPath = apiPath.replace(item, `{${item.replace(/:/g, '')}}`);
      });
    return `${schema}://${hostName}${basePath}${apiPath}`;
  };
  const getVersion = (baseVal) =>{
    let match = baseVal.toLowerCase().indexOf('/v');
    let v = baseVal.slice(match+1,match+3);
    return v;
  };
  const APIList = [
    {
      name: 'Admin Portal',
      categories: [
        {
          name: 'User',
          apis: [
            {
              name: 'Users Search',
              description: 'To search users based on entered keyword(Legacy).',
              APIRoute: resources.search,
              method: userRepository.getUsersBySearch,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.search
                )}q={query}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.search)}[q]`,
                requestParameters: [
                  {
                    name: 'q',
                    required: true,
                    type: 'string',
                    displayName: 'Search Query'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    UserProfile: {
                      UserId: '{userId}',
                      FirstName: 'UpdatedFirstName',
                      LastName: 'UpdatedLastName',
                      Email: 'test@test.com',
                      Msisdns: [],
                      DemoUser: false,
                      CreatedDateTime: '2021-08-04T07:42:53.210419',
                      ModifiedDateTime: '2021-08-04T07:46:45.932648'
                    },
                    ApplicationProfile: {
                      UserId: '',
                      CreatedDateTime: '0001-01-01T00:00:00',
                      ModifiedDateTime: '0001-01-01T00:00:00',
                      Product: 'DRIVE',
                      Preferences: null,
                      Terms: null,
                      Icn: null,
                      Locale: null,
                      Location: null
                    }
                  }
                ]
              }
            },
            {
              name: 'Users Search',
              description: 'To search users based on entered keyword(TLife).',
              APIRoute: resources.search,
              method: userRepository.getUsersBySearch,
              methodType: httpMethods.GET,
              version: getVersion(basePath.adminTlifeSearch),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.adminTlifeSearch,
                  resources.search
                )}q={query}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.adminTlifeSearch, resources.search)}[q]`,
                requestParameters: [
                  {
                    name: 'q',
                    required: true,
                    type: 'string',
                    displayName: 'Search Query'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    UserProfile: {
                      UserId: '{userId}',
                      FirstName: 'UpdatedFirstName',
                      LastName: 'UpdatedLastName',
                      Email: 'test@test.com',
                      Msisdns: [],
                      DemoUser: false,
                      CreatedDateTime: '2021-08-04T07:42:53.210419',
                      ModifiedDateTime: '2021-08-04T07:46:45.932648'
                    },
                    ApplicationProfile: {
                      UserId: '',
                      CreatedDateTime: '0001-01-01T00:00:00',
                      ModifiedDateTime: '0001-01-01T00:00:00',
                      Product: 'DRIVE',
                      Preferences: null,
                      Terms: null,
                      Icn: null,
                      Locale: null,
                      Location: null
                    }
                  }
                ]
              }
            },
            {
              name: 'User - Details',
              description: 'Selected user details based on user id.',
              APIRoute:
                product === APP_MOBILE.DRIVE
                  ? resources.driveUser
                  : resources.user,
              method: userRepository.getUserById,
              methodType: httpMethods.GET,
              version:
              product === APP_MOBILE.DRIVE
              ? getVersion(basePath.userManagement)
              : getVersion(basePath.adminTlife), 
              apiDetails:
                product === APP_MOBILE.DRIVE
                  ? {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.userManagement,
                        resources.driveUser
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(
                        basePath.userManagement,
                        resources.driveUser
                      )}`,
                      requestParameters: [
                        {
                          name: 'id',
                          required: true,
                          type: 'string',
                          displayName: 'User Impersonation (User Id)'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          id: '{id}',
                          userId: '{userId}',
                          firstName: 'firstName',
                          lastName: 'lastName',
                          email: 'test@test.com',
                          createdBy: '{createdBy}',
                          createdDateTime: 1621589504027,
                          lastModifiedBy: '{lastModifiedBy}',
                          modifiedDateTime: 1621589504027,
                          demoUser: false,
                          status: 'ACTIVE'
                        }
                      ]
                    }
                  : {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.adminTlife,
                        resources.user
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(
                        basePath.adminTlife,
                        resources.user
                      )}`,
                      requestParameters: [
                        {
                          name: 'id',
                          required: true,
                          type: 'string',
                          displayName: 'User Id'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          userProfile: {
                            userId: '{userId}',
                            firstName: '{firstName}',
                            lastName: '{lastName}',
                            email: '{test@test.com}',
                            msisdns: [],
                            demoUser: false,
                            createdDateTime: '2020-03-24T20:51:14.049Z',
                            modifiedDateTime: '2020-09-03T19:17:35.603Z'
                          },
                          applicationProfile: {
                            userId: '{userId}',
                            pushNotificationTokens: [
                              {
                                token: '{token}',
                                platform: 'IOS',
                                version: '1.2.0',
                                modifiedDateTime: 1590782383076,
                                bundleId: '{bundleId}',
                                installationId: '{installationId}',
                                apnsEnvironment: 'PRODUCTION'
                              }
                            ],
                            preferences: {
                              pushNotificationsEnabled: true,
                              smsNotificationsEnabled: true,
                              emailNotificationsEnabled: true,
                              safeZonePushNotificationsEnabled: true,
                              batteryLevelPushNotificationsEnabled: true,
                              deviceOfflinePushNotificationsEnabled: true,
                              activityGoalWarningPushNotificationsEnabled: true,
                              activityGoalCompletedPushNotificationsEnabled: true,
                              lostModeChangePushNotificationsEnabled: true,
                              petBirthdayPushNotificationsEnabled: true
                            },
                            notificationSnooze: {
                              firmware: null,
                              wifiSetup: null
                            },
                            terms: {
                              version: 1,
                              type: 'TERMS'
                            },
                            icn: {
                              version: 1,
                              type: 'ICN'
                            },
                            locationPermissions: null,
                            locale: {
                              language: 'en',
                              country: 'US',
                              unitPreference: 'US',
                              timeZone: 'America/New_York'
                            },
                            location: null,
                            geolocations: [
                              {
                                coordinates: {
                                  longitude: -122.2290627,
                                  latitude: 47.7746612
                                },
                                state: 'WA',
                                postalCode: '98028',
                                createdDateTime: '2020-05-07T22:55:52.081Z'
                              }
                            ],
                            createdDateTime: '2020-03-24T20:51:14.049Z',
                            modifiedDateTime: '2020-07-01T01:29:23.574Z',
                            product: 'PETS'
                          }
                        }
                      ]
                    }
            },
            {
              name: 'Emergency Contacts - Details',
              description: "Selected user's emergency contact details.",
              APIRoute: resources.emergencyContacts,
              method: userRepository.getContacts,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platform),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.emergencyContacts
                )}" -H "Authorization: {token}" -H "Content-Type: application/json" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.platform,
                  resources.emergencyContacts
                )}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    msisdn: '{msisdn}',
                    countryCode: '1',
                    firstName: 'Mark',
                    lastName: 'Jones',
                    type: 'ADMIN',
                    emergency: true,
                    iconUrl: '{iconUrl}',
                    email: 'test@test.com',
                    accessInvites: [
                      {
                        accessInviteId: '{accessInviteId}',
                        thingIds: ['{thingId}'],
                        accessInviteStatus: 'UNCLAIMED'
                      }
                    ],
                    things: {
                      '{thingId}': 'watchDisplayName1'
                    },
                    entityDisplayNames: {
                      '{thingId}': 'entityDisplayName1'
                    }
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'School Mode - Details',
              description: "Selected user's school mode details.",
              APIRoute: resources.schoolMode,
              method: userRepository.getSchoolModeDetails,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.schoolMode
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache"  -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.schoolMode)}`,

                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    primaryAccountHolderId: '{primaryAccountHolderId}',
                    scheduleType: 'OneTimeDuration',
                    functionToCall: 'ScheduleSchoolMode',
                    enabled: true,
                    attributes: {
                      thingId: '{thingId}',
                      primaryAccountHolderId: '{primaryAccountHolderId}',
                      product: 'KIDSWATCH'
                    },
                    displayName: 'This is new one-time schedule task.',
                    schedule: {
                      weekdays: [],
                      localStartTime: '2022-11-08T11:07:59',
                      olsenTimeZone: 'America/Los_Angeles',
                      durationInMinutes: 5
                    }
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Phone Location - Things',
              description: "Selected user's list of phone locations.",
              APIRoute: resources.phoneLocation,
              method: userRepository.getPhoneLocationDetails,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platformPhoneLocation),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformPhoneLocation,
                  resources.phoneLocation
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache"  -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.platformPhoneLocation,
                  resources.phoneLocation
                )}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    userId: '{userId}',
                    deviceId: '{deviceId}',
                    name: 'User Name',
                    latitude: 47.981182,
                    longitude: -122.20844,
                    batteryLevel: 95,
                    isOnline: false,
                    isSharingLocation: true,
                    thingType: 'PHONE_LOCATION'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Phone Location - Groups',
              description: "Selected user's phone location groups.",
              APIRoute: resources.phoneLocationGroups,
              method: userRepository.getPhoneLocationGroups,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platformCoreV1),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformCoreV1,
                  resources.phoneLocationGroups
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache"  -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.platformCoreV1,
                  resources.phoneLocationGroups
                )}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    name: 'Family Group',
                    thingsCount: 3,
                    avatar: null,
                    avatarExpiration: null
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Phone Location - Group Members',
              description: "Selected user's phone location group's members.",
              APIRoute: resources.phoneLocationGroupUsers,
              method: userRepository.getPhoneLocationGroupUsers,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platformCoreV1),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformCoreV1,
                  resources.phoneLocationGroupUsers
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache"  -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.platformCoreV1,
                  resources.phoneLocationGroupUsers
                )}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'groupId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    userId: '{userId}',
                    role: 'MEMBER',
                    msisdn: '{msisdn}',
                    emergencyContact: true,
                    firstName: 'User 1',
                    lastName: 'LastNmae',
                    mob: null,
                    id: '{id}',
                    name: 'Family Group',
                    thingsCount: 2,
                    avatar: 'avatar-url',
                    avatarExpiration: null
                  },
                  {
                    userId: '{userId}',
                    role: 'OWNER',
                    msisdn: '{msisdn}',
                    emergencyContact: true,
                    firstName: 'User 2',
                    lastName: 'LastNmae',
                    mob: null,
                    id: '{id}',
                    name: 'Family Group',
                    thingsCount: 2,
                    avatar: 'avatar-url',
                    avatarExpiration: null
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Notification Tokens',
              description: "Selected user's app versions for push notification",
              APIRoute:
                product === APP_MOBILE.DRIVE
                  ? resources.driveUserNotificationTokens
                  : resources.userNotificationTokens,
              method: userRepository.getUserNotificationTokens,
              methodType: httpMethods.GET,
              version: getVersion(basePath.userManagement),
              apiDetails:
                product === APP_MOBILE.DRIVE
                  ? {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.userManagement,
                        resources.driveUserNotificationTokens
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(
                        basePath.userManagement,
                        resources.driveUserNotificationTokens
                      )}`,
                      requestParameters: [
                        {
                          name: 'userId',
                          required: true,
                          type: 'string'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          id: 2,
                          userId: '{userId}',
                          deviceToken: '{deviceToken}',
                          platform: 'ANDROID',
                          applicationVersion: '1.2.0',
                          installationId: '{installationId}',
                          advertisingIdentifier: '{advertisingIdentifier}',
                          deviceIdentifierForVendor:
                            '{deviceIdentifierForVendor}',
                          bundleId: '{bundleId}',
                          isSandbox: false,
                          createdAt: '{createdAt}',
                          lastModifiedBy: '{lastModifiedBy}',
                          lastModifiedAt: '2021-07-14T18:20:23.6281'
                        }
                      ]
                    }
                  : {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.userManagement,
                        resources.userNotificationTokens
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(
                        basePath.userManagement,
                        resources.userNotificationTokens
                      )}`,
                      requestParameters: [
                        {
                          name: 'userId',
                          required: true,
                          type: 'string'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          token: '{token}',
                          platform: 'ANDROID',
                          version: null,
                          modifiedDateTime: 1590782383076
                        },
                        {
                          token: '{token}',
                          platform: 'IOS',
                          version: '1.2.0',
                          modifiedDateTime: 1602002444514,
                          bundleId: '{bundleId}',
                          installationId: '{installationId}',
                          advertisingIdentifier:
                            'so we can track conversions maybe, but this is optional',
                          apnsEnvironment: 'DEVELOPMENT'
                        }
                      ]
                    }
            },
            {
              name: 'Notifications - List',
              description: "Selected user's notification list details.",
              APIRoute: resources.notification,
              method: userRepository.getUserNotificationsList,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.notification
                )}start-date-time={startDateTime}&end-date-time={endDateTime}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.admin,
                  resources.notification
                )}start-date-time={startDateTime}&end-date-time={endDateTime}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'startDateTime',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange(),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    createdDateTime: '2020-08-06T17:17:32.922Z',
                    deleted: true,
                    id: 'string',
                    notificationData: {
                      notificationContent: {
                        body: 'string',
                        customData: {
                          additionalProp1: {},
                          additionalProp2: {},
                          additionalProp3: {}
                        },
                        name: 'string',
                        title: 'string'
                      },
                      notificationTargets: [
                        {
                          devices: ['string'],
                          notificationIds: [
                            {
                              env: 'string',
                              id: 'string',
                              url: 'string'
                            }
                          ],
                          tokenPlatform: 'IOS',
                          tokenPlatformEnvironment: 'DEV',
                          type: 'string'
                        }
                      ]
                    },
                    notificationType: 'GENERIC_PUSH',
                    parentId: 'string',
                    read: true,
                    remindAt: '2020-08-06T17:17:32.922Z',
                    remindProcessed: true
                  }
                ]
              },

              authorizedProducts: [
                APP_MOBILE.PETS,
                APP_MOBILE.TRACKER,
                APP_MOBILE.WATCH
              ]
            },
            {
              name: 'Notifications - List',
              description: "Selected user's notification list details.",
              APIRoute: resources.driveNotification,
              method: userRepository.getDriveUserNotificationsList,
              methodType: httpMethods.GET,
              version: getVersion(basePath.userManagement),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.userManagement,
                  resources.driveNotification
                )}startDate={startdate}&endDate={enddate}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.userManagement,
                  resources.driveNotification
                )}startDate={startdate}&endDate={enddate}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'startDate',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'endDate',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange('startDate', 'endDate'),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [{}]
              },
              authorizedProducts: [APP_MOBILE.DRIVE]
            },
            {
              name: 'Auth Users - List',
              description: 'To fetch authorized users list.',
              APIRoute: resources.authUsers,
              method: userRepository.getAuthUsers,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.authUsers
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H  "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.authUsers)}`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    username: '{username}',
                    subject: '{subject}',
                    createdBy: 'scottie',
                    lastModifiedBy: 'functions',
                    allowedScopes: ['*'],
                    userId: '{userId}',
                    firstName: 'scottie',
                    lastName: 'tien',
                    email: '{test@test.com}',
                    geolocations: [
                      {
                        coordinates: {
                          longitude: -87.7323,
                          latitude: 42.108
                        },
                        state: 'IL',
                        postalCode: '60093',
                        createdDateTime: '2020-06-08T18:19:36.392Z'
                      }
                    ],
                    msisdns: ['{msisdn1}', '{msisdn2}'],
                    msisdnsNormalized: ['16305000005', '16305000006'],
                    countryCode: '1',
                    createdDateTime: '2020-06-08T18:19:35.875Z',
                    modifiedDateTime: '2020-06-08T18:19:35.956Z',
                    pushNotificationTokens: [
                      {
                        token: '{token}',
                        platform: 'ANDROID',
                        version: null,
                        modifiedDateTime: 1591640375500
                      }
                    ],
                    preferences: {
                      pushNotificationsEnabled: true,
                      smsNotificationsEnabled: true,
                      emailNotificationsEnabled: true,
                      safeZonePushNotificationsEnabled: false,
                      batteryLevelPushNotificationsEnabled: false,
                      activityGoalWarningPushNotificationsEnabled: false,
                      activityGoalCompletedPushNotificationsEnabled: false,
                      deviceOfflinePushNotificationsEnabled: true,
                      lostModeChangePushNotificationsEnabled: false,
                      petBirthdayPushNotificationsEnabled: false
                    },
                    notificationSnooze: {
                      firmware: null,
                      wifiSetup: null
                    },
                    terms: {
                      type: 'TERMS',
                      version: 4
                    },
                    icn: {
                      type: 'ICN',
                      version: 1
                    },
                    locationPermissions: null,
                    locale: {
                      language: 'en',
                      country: 'US',
                      unitPreference: 'US',
                      timeZone: 'America/Los_Angeles'
                    },
                    demoUser: false
                  }
                ]
              },
              authorizedProducts: [
                APP_MOBILE.PETS,
                APP_MOBILE.TRACKER,
                APP_MOBILE.WATCH
              ]
            },
            {
              name: 'Safe Zones - Details',
              description: "Location details of user's selected thing.",
              APIRoute:
                product === APP_MOBILE.DRIVE
                  ? resources.driveGeofences
                  : resources.geofences,
              method: userRepository.getUserSafeZones,
              methodType: httpMethods.GET,
              version:
                product === APP_MOBILE.DRIVE
                ? getVersion(basePath.platformDrive)
                : getVersion(basePath.platform),
              apiDetails:
                product === APP_MOBILE.DRIVE
                  ? {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.platformDrive,
                        resources.driveGeofences
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(
                        basePath.platformDrive,
                        resources.driveGeofences
                      )}`,
                      requestParameters: [
                        {
                          name: 'id',
                          required: true,
                          type: 'string',
                          displayName: 'User Id'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          id: 1,
                          name: 'Test',
                          latitude: 47.57851,
                          longitude: -122.16532,
                          radius: 1000.0,
                          address: '',
                          isEnabled: true,
                          createdBy: '{createdBy}',
                          createdAt: '2021-12-22T15:52:02.358486',
                          lastModifiedBy: '{lastModifiedBy}',
                          lastModifiedAt: '2021-12-22T15:52:14.579928',
                          thingIds: [4]
                        }
                      ]
                    }
                  : {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.platform,
                        resources.geofences
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(
                        basePath.platform,
                        resources.geofences
                      )}`,
                      requestParameters: [
                        {
                          name: 'id',
                          required: true,
                          type: 'string',
                          displayName: 'User Id'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          allThings: true,
                          deviceIds: '{deviceIds}',
                          geofence: {
                            shape: 'CIRCLE',
                            point: {
                              latitude: 47.600395,
                              longitude: -122.1529
                            },
                            radius: 0,
                            geometry: {
                              coordinates:
                                'coordinates: [[[-34.99999,20.000888311174993],[-35.00022773944855,20.000860089077552]]]'
                            }
                          },
                          name: '{name}',
                          status: 'ACTIVE',
                          thingIds: '{thingIds}'
                        }
                      ]
                    }
            },
            {
              name: 'User - Delete',
              description: 'Delete user By id.',
              APIRoute: resources.deleteUser,
              method: userRepository.deleteUserById,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.platform),
              apiDetails: {
                url: getRoute(basePath.platform, resources.deleteUser),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.deleteUser
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Drive User - Delete',
              description: 'Delete drive User By id.',
              APIRoute: resources.user,
              method: userRepository.deleteDriveUserById,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.adminDrive),
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.adminDrive, resources.user),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.adminDrive,
                  resources.user
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'User Task - Delete',
              description: 'Delete user task by task id.',
              APIRoute: resources.deleteUserTask,
              method: userRepository.deleteUserTask,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.platform),
              apiDetails: {
                url: getRoute(basePath.platform, resources.deleteUserTask),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.deleteUserTask
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'userTaskId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Bulk Notifications - Create',
              description: 'Create bulk notifications.',
              APIRoute: resources.bulkNotification,
              method: userRepository.postBulkNotification,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: getRoute(basePath.admin, resources.bulkNotification),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.bulkNotification
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{userIds:'{userId}',
                title:'{title}',
                body:'{body}'
                }"`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  userIds: ['{userId}'],
                  title: '{title}',
                  body: '{body}'
                }
              }
            },
            {
              name: 'Auth User - Delete',
              description: 'Delete auth user by user id.',
              APIRoute: resources.deleteAuthUser,
              method: userRepository.deleteAuthUser,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: getRoute(basePath.admin, resources.deleteAuthUser),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.deleteAuthUser
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Auth User - Create',
              description: 'Create auth user.',
              APIRoute: resources.authUsers,
              method: userRepository.createAuthUser,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: getRoute(basePath.admin, resources.authUsers),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.authUsers
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "
                {"allowedScopes":["*"],"coordinates":{"latitude":41.889512,"longitude":-87.617595},
                "email":"test@test.com","firstName":"{firstName}","icn":{"type":"ICN","version":1},
                "lastName":"{lastName}","msisdns":["{msisnds}"],"password":"********",
                "preferences":{"pushNotificationsEnabled":false,
                "safeZonePushNotificationsEnabled":true},
                "pushNotificationToken":{"platform":"ANDROID","token":"{token}"},
                "terms":{"type":"TERMS","version":1},"username":"{usrname}"}"`,
                requestParameters: [],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  allowedScopes: ['*'],
                  coordinates: { latitude: 41.889512, longitude: -87.617595 },
                  email: 'test@test.com',
                  firstName: '{firstName}',
                  icn: { type: 'ICN', version: 1 },
                  lastName: '{lastName}',
                  msisdns: ['{msisdns}'],
                  password: '********',
                  preferences: {
                    pushNotificationsEnabled: false,
                    safeZonePushNotificationsEnabled: true
                  },
                  pushNotificationToken: {
                    platform: 'ANDROID',
                    token: '{token}'
                  },
                  terms: { type: 'TERMS', version: 1 },
                  username: '{username}'
                }
              }
            }
          ]
        },
        {
          name: 'Things',
          apis: [
            {
              name: 'Thing - Details',
              description: "Thing details of selected user's.",
              APIRoute:
                product === APP_MOBILE.DRIVE
                  ? resources.userThings
                  : resources.things,
              method:
                product === APP_MOBILE.DRIVE
                  ? thingRepository.getThingsDrive
                  : thingRepository.getThingsByUserId,
              methodType: httpMethods.GET,
              version:
                product === APP_MOBILE.DRIVE
                ? getVersion(basePath.adminDrive)
                : getVersion(basePath.platform), 
              apiDetails:
                product === APP_MOBILE.DRIVE
                  ? {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.adminDrive,
                        resources.userThings
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(
                        basePath.adminDrive,
                        resources.userThings
                      )}`,
                      requestParameters: [
                        {
                          name: 'userId',
                          required: true,
                          type: 'string'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          id: 11,
                          name: '{name}',
                          createdBy: '{createdBy}',
                          createdAt: '2021-07-02T22:50:50.061678',
                          lastModifiedBy: '{lastModifiedBy}',
                          lastModifiedAt: '2021-07-02T22:50:50.061678',
                          deletedBy: null,
                          deletedAt: null,
                          fetchedAt: '2022-11-11T07:14:36.334329',
                          product: 'DRIVE',
                          onboardStatus:
                            'NEEDS_TELEMETRY|NEEDS_PERSONALIZATION|ONBOARDED',
                          onboardAt: '2021-08-03T14:45:22.200783',
                          mergedInto: null,
                          lastKnownLatitude: 26.1978092,
                          lastKnownLongitude: 74.1485552,
                          lastKnownRadius: 0.0,
                          lastKnownAddress:
                            'Unnamed Road,Boontiwas,RJ,India,306102',
                          lastKnownAt: '2021-08-03T14:46:55.020476',
                          lastKnownAtText: '3 hours ago',
                          device: {
                            id: '{id}',
                            model: 'SD 7000T',
                            manufacturer: 'SyncUP',
                            mcuFirmwareVersion: '{mcuFirmwareVersion}',
                            modemFirmwareVersion: '{modemFirmwareVersion}',
                            configuration: 'UNKNOWN',
                            name: '{name}',
                            createdBy: '{createdBy}',
                            createdAt: '2022-08-23T11:07:36.779433',
                            lastModifiedBy: '{lastModifiedBy}',
                            lastModifiedAt: '2022-11-11T07:14:36.237648',
                            decommissionedBy: null,
                            decommissionedAt: null,
                            claimedBy: '{claimedBy}',
                            claimedAt: '2021-07-02T22:20:55.962271',
                            lastConnectedAt: '2021-08-03T14:45:22.200783',
                            status: 'ACTIVE',
                            statusAt: '2021-08-03T14:45:22.200783',
                            fetchedAt: '0001-01-01T00:00:00',
                            isWhitelisted: true,
                            lineLookupSource: '{lineLookupSource}',
                            ratePlan: '{ratePlan}',
                            wifiSsid: '**********',
                            wifiPassword: '********',
                            wifiDisabled: false,
                            wifiDisabledAt: null,
                            wifiStatus: 'ONLINE',
                            wifiStatusAt: '2022-08-23T11:19:03.006194',
                            wifiDeviceUpdated: true,
                            wifiDeviceRequestId: null,
                            wifiStatusRequestId: null,
                            wifiStatusUpdated: false,
                            wifiAdminAt: null,
                            roadsideStatus: 'ENROLLED',
                            roadsideStatusAt: '2022-08-23T11:07:41.060801',
                            simulatorType: 'MANUAL'
                          },
                          vehicle: {
                            vehicleId: 97,
                            vehicleEngineId: 111,
                            vehicleColorId: 111,
                            year: 2021,
                            make: 'Honda',
                            model: 'Accord',
                            engine: '2.0L I4 Turbo 252hp',
                            horsepower: '252hp',
                            fuelGrade: 'UNKNOWN',
                            colorName: 'Crystal Black Pearl',
                            colorHex: '#020202',
                            vin: 'ABCDEFGHIJKLMNOPQ',
                            vinReceivedAt: '2021-08-24T16:17:08.603123',
                            licensePlateState: 'TX',
                            licensePlateNumber: '{licensePlateNumber}',
                            frontImageUrl: '{frontImageUrl}',
                            rearImageUrl: '{rearImageUrl}',
                            sideImageUrl: 'sideImageUrl',
                            personalizationAt: '2021-08-24T16:17:25.410127',
                            vehicleStatus: '{vehicleStatus}',
                            vehicleStatusAt: '2021-08-24T16:19:23.307296',
                            odometerReading: 25000,
                            odometerReadingAt: '2021-08-24T16:19:23.307296',
                            fuelLevel: 12.5,
                            fuelLevelPercent: 12.5,
                            fuelLevelLiter: null,
                            fuelStatus: 'UNKNOWN|FULL|LOW|VERY_LOW',
                            fuelStatusAt: '2021-08-24T16:20:42.120094',
                            fuelLevelGauge: 0.125,
                            batteryLevel: 10000.0,
                            batteryStatus:
                              'UNKNOWN|HEALTHY|LOW_VOLTAGE|DRAINING|UNHEALTHY|EXTREMELY_UNHEALTHY',
                            batteryStatusAt: '2021-08-24T16:19:27.604556',
                            engineHealthDtc: 'P-205,P-206',
                            engineHealthStatus:
                              'UNKNOWN|NO_PROBLEMS|LOW_SEVERITY_PROBLEMS|MEDIUM_HIGH_SEVERITY_PROBLEMS',
                            engineHealthStatusAt: '2021-08-24T16:17:05.077206',
                            speedThreshold: 105,
                            currentTrip: {
                              id: 1,
                              thingId: 11,
                              journeyId: 111,
                              lastModifiedBy: '{lastModifiedBy}',
                              lastModifiedAt: '2022-11-11T07:14:36.303622',
                              lastReadAt: null,
                              fetchedAt: '2022-11-11T07:14:36.349953',
                              isBusiness: false,
                              deletedBy: null,
                              deletedAt: null,
                              startedLatitude: 19.8762,
                              startedLongitude: 75.3433,
                              startedAddress:
                                '123 Placeholder St\nCity, State 00001',
                              startedAt: '2022-11-11T07:14:35',
                              endedLatitude: null,
                              endedLongitude: null,
                              endedAddress: null,
                              endedAt: null,
                              polylineEncoded: null,
                              duration: null,
                              drivingDuration: null,
                              idlingDuration: null,
                              distance: null,
                              speed: null,
                              avgSpeed: null,
                              maxSpeed: null,
                              fuelEfficiency: null,
                              lowSpeedSinceEvent: null,
                              lastSpeedEvent: null,
                              seatbeltStatusPassenger: false,
                              seatbeltStatusDriver: true,
                              seatbeltPassengerWeight: false,
                              vehicleMovement: true,
                              locations: [
                                {
                                  latitude: 19.8762,
                                  longitude: 75.3433,
                                  locationAt: '2022-11-11T07:14:35'
                                }
                              ],
                              tripEvents: [
                                {
                                  id: 1,
                                  thingId: 11,
                                  tripId: 1,
                                  eventType: '{eventType}',
                                  eventLatitude: 19.8762,
                                  eventLongitude: 75.3433,
                                  eventAddress:
                                    '123 Placeholder St\nCity, State 00001',
                                  eventAt: '2022-11-11T07:14:35',
                                  eventCode: null,
                                  eventTitle: null,
                                  eventDescription: null,
                                  eventNextSteps: null,
                                  eventActions: null,
                                  lastReadAt: null
                                }
                              ]
                            }
                          },
                          isBusinessTrip: null
                        }
                      ]
                    }
                  : {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.platform,
                        resources.things
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(basePath.platform, resources.things)}`,
                      requestParameters: [
                        {
                          name: 'userId',
                          required: true,
                          type: 'string'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          id: '{id}',
                          thingType: 'PET',
                          name: 'Max',
                          status: 'ACTIVE',
                          safezone: false,
                          notes: '16',
                          deviceHistory: [],
                          createdDateTime: '2020-04-28T19:27:02.516Z',
                          modifiedDateTime: '2020-04-28T19:27:02.516Z',
                          petType: 'CAT',
                          breed: 'Shetland Sheepdog',
                          birthdate: '2010-05-23',
                          gender: 'MALE',
                          identifyingMarks: 'Striped tail',
                          goals: {
                            dailyActivityGoal: 0,
                            dailyActivityGoalRecommendation: null
                          },
                          geofences: []
                        }
                      ]
                    }
            },
            {
              name: 'Breeds - List',
              description: 'To fetch  all the breeds list.',
              APIRoute: resources.breeds,
              method: thingRepository.getBreeds,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platform),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.breeds
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.platform, resources.breeds)}`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    name: '{name}',
                    petType: 'DOG'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.PETS]
            },
            {
              name: 'Thing Events - List',
              description: 'Triggered events list of selected vehicle.',
              APIRoute: resources.driveEvents,
              method: thingRepository.getThingEvents,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platformDrive),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveEvents
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.platformDrive,
                  resources.driveEvents
                )}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'thingId',
                    required: true,
                    type: 'int'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: 9,
                    thingId: 1,
                    tripId: 1,
                    eventType: '{eventType}',
                    eventLatitude: 38.897663,
                    eventLongitude: -77.0387679,
                    eventAddress:
                      '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                    eventAt: '2021-09-03T16:59:56.456205',
                    eventCode: null,
                    eventTitle: null,
                    eventDescription: null,
                    eventNextSteps: null,
                    eventActions: null,
                    lastReadAt: null
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.DRIVE]
            },
            {
              name: 'Thing Trips - Details',
              description: 'To fetch selected vehicle trip details. ',
              APIRoute: resources.driveTrips,
              method: thingRepository.getThingTrips,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platformDrive),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveTrips
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.platformDrive,
                  resources.driveTrips
                )}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'thingId',
                    required: true,
                    type: 'int'
                  }
                ],
                responseStructure: [
                  {
                    id: 11,
                    thingId: 2,
                    startedLatitude: 38.897663,
                    startedLongitude: -77.0387679,
                    startedAddress:
                      '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                    startedAt: '2021-08-31T22:02:36.813311',
                    endedLatitude: null,
                    endedLongitude: null,
                    endedAddress: null,
                    endedAt: null,
                    polylineEncoded: null,
                    duration: 0.0,
                    drivingDuration: 0.0,
                    idlingDuration: 0.0,
                    distance: 0.0,
                    speed: 0,
                    avgSpeed: 0.0,
                    maxSpeed: 0.0,
                    lowSpeedSinceEvent: 0.0,
                    lastSpeedEvent: null,
                    fuelEfficiency: 0.0,
                    seatbeltStatusPassenger: false,
                    seatbeltStatusDriver: false,
                    seatbeltPassengerWeight: false,
                    isBusiness: false,
                    lastReadAt: null,
                    lastModifiedBy: '{lastModifiedBy}',
                    lastModifiedAt: '2021-08-31T22:02:36.813311',
                    locations: [
                      {
                        latitude: 38.897663,
                        longitude: -77.0387679,
                        locationAt: '2021-08-31T22:02:47.711182'
                      }
                    ],
                    tripEvents: [
                      {
                        id: 11,
                        thingId: 2,
                        tripId: 21,
                        eventType: '{eventType}',
                        eventLatitude: 38.897663,
                        eventLongitude: -77.0387679,
                        eventAt: '2021-08-31T22:02:36.953209',
                        eventAddress:
                          '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                        lastReadAt: null
                      },
                      {
                        id: 11,
                        thingId: 2,
                        tripId: 21,
                        eventType: '{eventType}',
                        eventLatitude: 38.897663,
                        eventLongitude: -77.0387679,
                        eventAt: '2021-08-31T22:02:47.900011',
                        eventAddress:
                          '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                        lastReadAt: null
                      }
                    ]
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.DRIVE]
            },
            {
              name: 'Trips Roadside Services - Details',
              description:
                'To fetch available road side services for selected vehicle.',
              APIRoute: resources.driveRoadsideServices,
              method: thingRepository.getRoadsideServices,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platformDrive),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServices
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServices
                )}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'thingId',
                    required: true,
                    type: 'int'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: 1,
                    thingId: 1,
                    serviceType: 'TOW|BATTERY|FUEL|TIRE|LOCKOUT',
                    status:
                      'PENDING|MATCHING|MATCHED|LIMIT_EXCEEDED|ENROUTE|ARRIVED|COMPLETED|CANCELLED',
                    statusAt: '2021-08-17T19:46:20.876743',
                    latitude: -71.09102,
                    longitude: 42.35042,
                    notes: 'test',
                    matchingAt: null,
                    matchedAt: null,
                    enrouteAt: null,
                    arrivedAt: null,
                    completedAt: null,
                    serviceProviderName: null,
                    serviceProviderPhone: null,
                    serviceProviderEmail: null,
                    serviceProviderWeb: null,
                    serviceProviderTechnicianName: null,
                    serviceProviderCost: 0.0,
                    serviceProviderStatus: -1,
                    serviceProviderStatusAt: null,
                    serviceProviderEta: null,
                    serviceProviderLatitude: 0.0,
                    serviceProviderLongitude: 0.0,
                    serviceProviderLocationAt: null,
                    createdBy: '{createdBy}',
                    createdAt: '2021-08-17T19:46:20.876743',
                    lastModifiedBy: '{lastModifiedBy}',
                    lastModifiedAt: '2021-08-17T19:46:20.876743',
                    deletedBy: null,
                    deletedAt: null
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.DRIVE]
            },
            {
              name: 'Breeds - Create',
              description: 'Create breeds.',
              APIRoute: resources.breeds,
              method: thingRepository.postBreed,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.PETS],
              apiDetails: {
                url: getRoute(basePath.admin, resources.breeds),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.breeds
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  {
                    id: '{id}',
                    name: '{name}',
                    petType: 'DOG'
                  }
                }"`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: [
                  {
                    id: '{id}',
                    name: '{name}',
                    petType: 'DOG'
                  }
                ]
              }
            },
            {
              name: 'Thing - Add',
              description: 'Create a thing.',
              APIRoute: resources.things,
              method: thingRepository.addThing,
              methodType: httpMethods.POST,
              version: getVersion(basePath.platform),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.things),
                curlRequest:
                  product === APP_MOBILE.TRACKER
                    ? `curl -v -X POST "${getRoute(
                        basePath.platform,
                        resources.things
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "  {
                  name: '{name}',
                  thingType: 'TRACKER',
                  trackerType: 'TAG',
                  locationRateProfile: 'CUSTOM',
                  locationRateValueInSeconds: 10,
                  deviceLinkOperation: {
                    kind: 'REGISTER',
                    identifier: '{imei}',
                    product: 'TRACKER',
                    model: 'TAG1'
                  }
                } "`
                    : `curl -v -X POST "${getRoute(
                        basePath.platform,
                        resources.things
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "  {
                  name: '{name}',
          notes: '{notes}',
          thingType: 'WATCH',
          watchType: 'CHILD',
          locationRateProfile: 'CUSTOM',
          locationRateValueInSeconds: 10,
          relation: '{relation}',
          gender: '{gender}',
          coppa:{
           version: 3,
            modifiedDateTime: "{dateTime}"
            },
          birthdate: '{birthdate}',
          deviceLinkOperation: {
            kind: 'REGISTER',
            identifier: '{identifier}',
            product: 'KIDSWATCH',
            model: 'WATCH1'
          }
                } "`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody:
                  product === APP_MOBILE.TRACKER
                    ? {
                        name: '{name}',
                        thingType: 'TRACKER',
                        trackerType: 'TAG',
                        locationRateProfile: 'CUSTOM',
                        locationRateValueInSeconds: 10,
                        deviceLinkOperation: {
                          kind: 'REGISTER',
                          identifier: '{imei}',
                          product: 'TRACKER',
                          model: 'TAG1'
                        }
                      }
                    : {
                        name: '{name}',
                        notes: '{notes}',
                        thingType: 'WATCH',
                        watchType: 'CHILD',
                        locationRateProfile: 'CUSTOM',
                        locationRateValueInSeconds: 10,
                        relation: '{relation}',
                        gender: '{gender}',
                        coppa: {
                          version: 3,
                          modifiedDateTime: '{dateTime}'
                        },
                        birthdate: '{birthdate}',
                        deviceLinkOperation: {
                          kind: 'REGISTER',
                          identifier: '{identifier}',
                          product: 'KIDSWATCH',
                          model: 'WATCH1'
                        }
                      }
              }
            },
            {
              name: 'Thing - Delete',
              description: 'Delete a thing',
              APIRoute: resources.things,
              method: thingRepository.deleteThing,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.platform),
              apiDetails: {
                url: getRoute(basePath.platform, resources.things),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.things
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            },
            {
              name: 'Breed - Delete',
              description: 'Delete breed by breed id.',
              APIRoute: resources.breed,
              method: thingRepository.deleteBreed,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.PETS],
              apiDetails: {
                url: getRoute(basePath.admin, resources.breed),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.breed
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'breedId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'Firmware',
          apis: [
            {
              name: 'Packages - List',
              description: 'To fetch firmware packages configuration list.',
              APIRoute: resources.firmwarePackage,
              method: firmwareRepository.getFirmwarePackages,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.firmwarePackage
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.firmwarePackage)}`,
                requestParameters: [
                  {
                    name: 'page',
                    required: false,
                    type: 'int',
                    value: 1
                  },
                  {
                    name: 'results',
                    required: false,
                    type: 'int',
                    results: 18
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    data: [
                      {
                        createdBy: '{createdBy}',
                        createdDateTime: '2020-08-06T16:43:38.246Z',
                        failureReason: '',
                        firmwareId: '{firmwareId}',
                        firmwareSize: 614400,
                        firmwareSizeTransmitted: 614400,
                        hardwareId: '{hardwareId}',
                        id: '{id}',
                        lastModifiedBy: '{lastModifiedBy}',
                        modifiedDateTime: '2020-08-06T16:43:38.246Z',
                        packageName: '{packageName}',
                        status: 'SUCCEEDED',
                        version: '{version}'
                      }
                    ],
                    page: {
                      currentPage: 0,
                      from: 0,
                      lastPage: 0,
                      pageSize: 0,
                      to: 25,
                      totalItems: 1
                    }
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.PETS]
            },
            {
              name: 'Firmware - Details',
              description: 'Get firmware details by selected imeival.',
              APIRoute: resources.firmwarePackageImei,
              method: firmwareRepository.getFirmwarePackageByImei,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.firmwarePackageImei
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.admin,
                  resources.firmwarePackageImei
                )}`,
                requestParameters: [
                  {
                    name: 'imei',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    checkSumSHAValue: null,
                    checkSumValue: null,
                    createdBy: null,
                    createdDateTime: '2020-03-25T19:36:02.181Z',
                    expirationDateTime: null,
                    hardwareRevision: '{hardwareRevision}',
                    id: '{id}',
                    lastModifiedBy: null,
                    modifiedDateTime: '2020-03-25T19:36:02.181Z',
                    packageName: '{packageName}',
                    packageUrl: 'presigned url for firmware package file',
                    previousVersion: '{previousVersion}',
                    product: 'PETS',
                    status: 'ACTIVE',
                    version: '{version}'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.PETS]
            },
            {
              name: 'Download Status',
              description: 'Search firmware download status by filters.',
              APIRoute: resources.firmwareDownloadStatus,
              method: firmwareRepository.getFirmwareDownloadStatus,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.firmwareDownloadStatus
                )}?hardware-id={hardwareid}&firmware-version={firmwareversion}&status={status}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.admin,
                  resources.firmwareDownloadStatus
                )}?hardware-id={hardwareid}&firmware-version={firmwareversion}&status={status}`,
                requestParameters: [
                  {
                    name: 'hardwareId',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'firmwareVersion',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'status',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'page',
                    required: false,
                    type: 'string',
                    value: 1
                  },
                  {
                    name: 'resultsSize',
                    required: false,
                    type: 'string',
                    displayName: 'size',
                    value: 18
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value:
                      'hardware-id={hardwareid}&firmware-version={firmwareversion}&status=SUCCEEDED',
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    data: [
                      {
                        createdBy: '{createdBy}',
                        createdDateTime: '2020-08-06T18:27:12.513Z',
                        failureReason: '',
                        firmwareId: '{firmwareId}',
                        firmwareSize: 614400,
                        firmwareSizeTransmitted: 614400,
                        hardwareId: '{hardwareId}',
                        id: '{id}',
                        lastModifiedBy: '{lastModifiedBy}',
                        modifiedDateTime: '2020-08-06T18:27:12.513Z',
                        packageName: '{packageName}',
                        status: 'SUCCEEDED',
                        version: '{version}'
                      }
                    ],
                    page: {
                      currentPage: 0,
                      from: 0,
                      lastPage: 0,
                      pageSize: 0,
                      to: 25,
                      totalItems: 1
                    }
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.PETS]
            },
            {
              name: 'Groups',
              description: 'To fetch firmware groups list.',
              APIRoute: resources.firmwareGroups,
              method: firmwareRepository.getFirmwareGroups,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.firmwareGroups
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.firmwareGroups)}`,

                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    data: [
                      {
                        createdBy: '{createdBy}',
                        createdDateTime: '2020-07-22T18:52:40.664Z',
                        firmwarePackageId: '{firmwarePackageId}',
                        id: '{id}',
                        imeis: ['{imeis1}', '{imeis2}'],
                        lastModifiedBy: '{lastModifiedBy}',
                        modifiedDateTime: '2020-07-28T17:10:53.843Z',
                        name: '{name}',
                        status: 'ACTIVE'
                      }
                    ],
                    page: {
                      currentPage: 0,
                      from: 0,
                      lastPage: 0,
                      pageSize: 0,
                      to: 25,
                      totalItems: 1
                    }
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.PETS]
            },
            {
              name: 'Firmware Package - Delete',
              description: 'Delete a firmware package.',
              APIRoute: resources.firmwarePackage,
              method: firmwareRepository.deleteFirmwarePackage,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.PETS],
              apiDetails: {
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.firmwarePackage
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.firmwarePackage)}`,
                requestParameters: [
                  {
                    name: 'id',
                    required: true,
                    type: 'string',
                    displayName: 'Package Id'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            },
            {
              name: ' Firmware Package - Update',
              description: 'Update a firmware package.',
              APIRoute: resources.firmwareGroupById,
              method: firmwareRepository.patchFirmwareGroup,
              methodType: httpMethods.PATCH,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.PETS],
              apiDetails: {
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.admin,
                  resources.firmwareGroupById
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.firmwareGroupById)}`,
                requestParameters: [
                  {
                    name: 'groupId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: [
                  {
                    op: 'replace',
                    path: '/name',
                    value: '{name}'
                  },
                  {
                    op: 'replace',
                    path: '/imeis',
                    value: '[imeis]'
                  },
                  {
                    op: 'replace',
                    path: '/status',
                    value: '{status}'
                  }
                ]
              }
            },
            {
              name: 'Firmware Group - Add',
              description: 'Add new firmware group.',
              APIRoute: resources.firmwareGroups,
              method: firmwareRepository.addFirmwareGroup,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.PETS],
              apiDetails: {
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.firmwareGroups
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.firmwareGroups)}`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  name: '{groupName}',
                  firmwarePackageId: '{firmwarePackageId}',
                  imeis: '[imeis]',
                  status: '{status}'
                }
              }
            },
            {
              name: 'Firmware Group - Delete',
              description: 'Firmware config removal.',
              APIRoute: resources.firmwareGroupById,
              method: firmwareRepository.deleteFirmwareGroup,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.PETS],
              apiDetails: {
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.firmwareGroupById
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.firmwareGroupById)}`,
                requestParameters: [
                  {
                    name: 'groupId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            }
          ]
        },
        {
          name: 'Iot Firmware',
          apis: [
            {
              name: 'Firmware - List',
              description: 'To fetch Iot firmware configuration list.',
              APIRoute: resources.iotFirmware,
              method: iotFirmwareRepository.getAllIotFirmware,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.iotFirmware
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.iotFirmware)}`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    targetVersion: '0.0.2',
                    product: 'TRACKER',
                    appliesTo: '0.0.1',
                    url: '{url}',
                    hash: '{hash}',
                    metrics: {
                      Total: 1,
                      Queued: 1
                    }
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.IOT_PRODUCTS]
            },
            {
              name: 'Groups - List',
              description: 'To fetch Iot firmware group list.',
              APIRoute: resources.iotFirmwareGroups,
              method: iotFirmwareRepository.getAllIotFirmwareGroups,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.iotFirmwareGroups
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.iotFirmwareGroups)}`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    data: [
                      {
                        firmwareId: '{firmwareId}',
                        groupName: 'testGroup',
                        id: '{id}',
                        updateBy: 'testUser'
                      }
                    ]
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.IOT_PRODUCTS]
            },
            {
              name: 'Firmware Devices In Group',
              description: 'Available devices list in selected firmware group.',
              APIRoute: resources.iotFirmwareDevicesInGroup,
              method: iotFirmwareRepository.getIotFirmwareDevicesInGroup,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.iotFirmwareDevicesInGroup
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.admin,
                  resources.iotFirmwareDevicesInGroup
                )}`,
                requestParameters: [
                  {
                    name: 'groupId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.IOT_PRODUCTS]
            },
            {
              name: 'Firmware Configuration - Create',
              description: 'Add new firmware configuration.',
              APIRoute: resources.iotFirmware,
              method: iotFirmwareRepository.createIotFirmware,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.iotFirmware
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.iotFirmware)}`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  targetVersion: '{version}',
                  appliesTo: '{appliesTo}',
                  isActive: false,
                  priority: null
                }
              }
            },
            {
              name: 'Firmware Configuration - Update',
              description: 'Update firmware configuration.',
              APIRoute: resources.iotFirmware,
              method: iotFirmwareRepository.updateIotFirmware,
              methodType: httpMethods.PATCH,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.admin,
                  resources.iotFirmware
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.iotFirmware)}`,
                requestParameters: [
                  {
                    name: 'firmwareId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  targetVersion: '{version}',
                  appliesTo: '{appliesTo}',
                  isActive: false,
                  priority: null
                }
              }
            },
            {
              name: 'Firmware Configuration - Delete',
              description: 'Configuration removal of firmware. ',
              APIRoute: resources.iotFirmware,
              method: iotFirmwareRepository.deleteIotFirmware,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.iotFirmware
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.iotFirmware)}`,
                requestParameters: [
                  {
                    name: 'firmwareId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            },
            {
              name: 'Firmware Group - Create',
              description: 'Add new firmware group.',
              APIRoute: resources.iotFirmwareGroups,
              method: iotFirmwareRepository.createIotFirmwareGroup,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.iotFirmwareGroups
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.iotFirmwareGroups)}`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  groupName: '{groupName}'
                }
              }
            },
            {
              name: 'Firmware Group - Update',
              description: 'Assign FW configuration to group.',
              APIRoute: resources.iotFirmwareGroups,
              method: iotFirmwareRepository.updateIotFirmwareGroup,
              methodType: httpMethods.PUT,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                curlRequest: `curl -v -X PUT "${getRoute(
                  basePath.admin,
                  resources.iotFirmwareGroups
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.iotFirmwareGroups)}`,
                requestParameters: [
                  {
                    name: 'groupId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  groupName: '{groupName}',
                  firmwareId: '{firmwareId}'
                }
              }
            },
            {
              name: 'Firmware Group - Delete',
              description: 'Firware group configuration removal. ',
              APIRoute: resources.iotFirmwareGroups,
              method: iotFirmwareRepository.deleteIotFirmwareGroup,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.iotFirmwareGroups
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.iotFirmwareGroups)}`,
                requestParameters: [
                  {
                    name: 'groupId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            },
            {
              name: 'Firmware Group - Add',
              description: 'Add devices to firmware group.',
              APIRoute: resources.iotFirmwareDevicesInGroup,
              method: iotFirmwareRepository.addDevicesToFirmwareGroup,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.iotFirmwareDevicesInGroup
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.admin,
                  resources.iotFirmwareDevicesInGroup
                )}`,
                requestParameters: [
                  {
                    name: 'groupId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  hardwareIds: '[{hardwareIds}]'
                }
              }
            },
            {
              name: 'Firmware Group - Delete',
              description: 'Delete devices from firmware group.',
              APIRoute: resources.iotFirmwareDevicesInGroup,
              method: iotFirmwareRepository.deleteDeviceFromFirmwareGroup,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.iotFirmwareDevicesInGroup
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.admin,
                  resources.iotFirmwareDevicesInGroup
                )}`,
                requestParameters: [
                  {
                    name: 'groupId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            }
          ]
        },
        {
          name: 'Simulators',
          apis: [
            {
              name: 'Device Simulators - List',
              description: 'To fetch simulated devices list.',
              APIRoute:
                product === APP_MOBILE.DRIVE
                  ? resources.driveSimulators
                  : APP_MOBILE.PETS
                  ? resources.simulator
                  : resources.simulator,
              method: simulatorRepository.getIotSimulators,
              methodType: httpMethods.GET,
              version:
                product === APP_MOBILE.DRIVE
                ? getVersion(basePath.adminDrive)
                : getVersion(basePath.admin),
              apiDetails:
                product === APP_MOBILE.DRIVE
                  ? {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.adminDrive,
                        resources.driveSimulators
                      )}?pageNumber={pagenumber}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(
                        basePath.adminDrive,
                        resources.driveSimulators
                      )}[?pageNumber]`,
                      requestParameters: [
                        {
                          name: 'pageNumber',
                          required: false,
                          type: 'int',
                          value: 1
                        },
                        {
                          name: 'pageSize',
                          required: false,
                          type: 'int',
                          value: 18
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          Id: '{id}',
                          Model: 'SD 7000T',
                          FirmwareVersion: '1.0',
                          Name: 'Default',
                          DecommissionedBy: null,
                          DecommissionedAt: null,
                          ClaimedBy: '{ClaimedBy}',
                          ClaimedAt: '2021-08-04T11:07:59.519969',
                          LastConnectedAt: '2021-08-04T11:08:03.172903',
                          Status: 4,
                          StatusAt: '2021-08-04T11:08:03.172903'
                        }
                      ]
                    }
                  : {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.admin,
                        resources.simulator
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(basePath.admin, resources.simulator)}`,
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          _etag: 'string',
                          activityMode: 'SENSOR_DISABLED',
                          additionalModifierInfo: ['string'],
                          altitude: 0,
                          batteryLevel: 0,
                          batteryLevelMode: 'FIXED',
                          batteryStatus: 'NORMAL',
                          createdBy: 'string',
                          createdDateTime: 0,
                          firmwareVersion: 'string',
                          hardwareId: 'string',
                          id: 'string',
                          isBuzzerOn: true,
                          isPoweredOn: true,
                          lastModifiedBy: 'string',
                          latitude: 0,
                          locationMode: 'STATIONARY',
                          longitude: 0,
                          manufacturer: 'string',
                          modifiedDateTime: 0,
                          ownedBy: 'string',
                          radius: 0,
                          securityKey: 'string',
                          temperature: 0
                        }
                      ]
                    }
            },
            {
              name: 'Simulators - List',
              description: 'Get list of simulators.',
              APIRoute: resources.simulator,
              method: simulatorRepository.getSimulators,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.simulator
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.simulator)}`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [{}]
              }
            },
            {
              name: 'Simulators - Add',
              description: 'Add a simulator.',
              APIRoute: resources.simulator,
              method: simulatorRepository.addSimulator,
              authorizedProducts: [APP_MOBILE.PETS],
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: getRoute(basePath.admin, resources.simulator),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.simulator
                )}"   -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d " 
                {
                  mapKey: '{mapKey}',
                  securityKey: '123456',
                  temperature: 98.6,
                  latitude: 47.578651,
                  longitude: -122.165176,
                  batteryLevel: 98,
                  altitude: 55,
                  locationMode: 'HUB_AND_SPOKE_WANDERING',
                  batteryLevelMode: 'FIXED',
                  activityMode: 'CIRCADIAN',
                  isPoweredOn: true,
                  manufacturer: 'Simulator',
                  firmwareVersion: '1.0.0',
                  isBuzzerOn: true,
                  radius: 50,
                  batteryStatus: 1,
                  hardwareId: '{hardwareId}',
                  ownedBy: '{ownedBy}'
                }
                  "`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  mapKey: '{mapKey}',
                  securityKey: '123456',
                  temperature: 98.6,
                  latitude: 47.578651,
                  longitude: -122.165176,
                  batteryLevel: 98,
                  altitude: 55,
                  locationMode: 'HUB_AND_SPOKE_WANDERING',
                  batteryLevelMode: 'FIXED',
                  activityMode: 'CIRCADIAN',
                  isPoweredOn: true,
                  manufacturer: 'Simulator',
                  firmwareVersion: '1.0.0',
                  isBuzzerOn: true,
                  radius: 50,
                  batteryStatus: 1,
                  hardwareId: '{hardwareId}',
                  ownedBy: '{ownedBy}'
                }
              }
            },
            {
              name: 'IOT Simulators - Add',
              description: 'Add IOT simulator.',
              APIRoute: resources.iotSimulator,
              method: simulatorRepository.addIotSimulator,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.admin, resources.iotSimulator),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.iotSimulator
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  mapKey: '{mapKey}',
                  securityKey: '123456',
                  temperature: 98.6,
                  latitude: 47.578651,
                  longitude: -122.165176,
                  batteryLevel: 98,
                  altitude: 55,
                  locationMode: 'HUB_AND_SPOKE_WANDERING',
                  batteryLevelMode: 'FIXED',
                  activityMode: 'CIRCADIAN',
                  isPoweredOn: true,
                  manufacturer: 'Simulator',
                  firmwareVersion: '1.0.0',
                  isBuzzerOn: true,
                  radius: 50,
                  batteryStatus: 1,
                  tags: { productId: 'KIDSWATCH', simulatorType: 'AUTO' },
                  simulatorType: 'AUTO',
                  productId: 'KIDSWATCH',
                  ownedBy: 'System',
                  UpdateDeviceDataRequest: '1800',
                  deviceId: '{deviceId}',
                  hardwareId: '{hardwareId}'
                }"`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  mapKey: '{mapKey}',
                  securityKey: '123456',
                  temperature: 98.6,
                  latitude: 47.578651,
                  longitude: -122.165176,
                  batteryLevel: 98,
                  altitude: 55,
                  locationMode: 'HUB_AND_SPOKE_WANDERING',
                  batteryLevelMode: 'FIXED',
                  activityMode: 'CIRCADIAN',
                  isPoweredOn: true,
                  manufacturer: 'Simulator',
                  firmwareVersion: '1.0.0',
                  isBuzzerOn: true,
                  radius: 50,
                  batteryStatus: 1,
                  tags: { productId: 'KIDSWATCH', simulatorType: 'AUTO' },
                  simulatorType: 'AUTO',
                  productId: 'KIDSWATCH',
                  ownedBy: 'System',
                  UpdateDeviceDataRequest: '1800',
                  deviceId: '{deviceId}',
                  hardwareId: '{hardwareId}'
                }
              }
            },
            {
              name: 'Simulators - Save',
              description: 'Save simulator.',
              APIRoute: resources.simulator,
              method: simulatorRepository.saveSimulator,
              methodType: httpMethods.PUT,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: getRoute(basePath.admin, resources.simulator),
                curlRequest: `curl -v -X PUT "${getRoute(
                  basePath.admin,
                  resources.simulator
                )}"   -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d " 
                {
                  id: '{id}',
                  hardwareId: '{hardwareId}',
                  securityKey: '00000',
                  manufacturer: 'Simulator',
                  firmwareVersion: '1.0.0',
                  temperature: 97.6,
                  isBuzzerOn: true,
                  isPoweredOn: true,
                  batteryLevel: 98,
                  longitude: -122.165176,
                  latitude: 47.578651,
                  altitude: 55,
                  radius: 50,
                  ownedBy: '{ownedBy}',
                  batteryStatus: 'CHARGING',
                  batteryLevelMode: 'FIXED',
                  locationMode: 'HUB_AND_SPOKE_WANDERING',
                  activityMode: 'CIRCADIAN',
                  createdDateTime: 1676625620692,
                  modifiedDateTime: 1676625620776,
                  createdBy: '{createdBy}',
                  lastModifiedBy: '{lastModifiedBy}',
                  additionalModifierInfo: ['test@test.com'],
                  _etag: null
                }"`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  id: '{id}',
                  hardwareId: '{hardwareId}',
                  securityKey: '00000',
                  manufacturer: 'Simulator',
                  firmwareVersion: '1.0.0',
                  temperature: 97.6,
                  isBuzzerOn: true,
                  isPoweredOn: true,
                  batteryLevel: 98,
                  longitude: -122.165176,
                  latitude: 47.578651,
                  altitude: 55,
                  radius: 50,
                  ownedBy: '{ownedBy}',
                  batteryStatus: 'CHARGING',
                  batteryLevelMode: 'FIXED',
                  locationMode: 'HUB_AND_SPOKE_WANDERING',
                  activityMode: 'CIRCADIAN',
                  createdDateTime: 1676625620692,
                  modifiedDateTime: 1676625620776,
                  createdBy: '{createdBy}',
                  lastModifiedBy: '{lastModifiedBy}',
                  additionalModifierInfo: ['test@test.com'],
                  _etag: null
                }
              }
            },
            {
              name: 'Simulator - Delete',
              description: 'Delete a simulator.',
              APIRoute: resources.simulatorId,
              method: simulatorRepository.deleteSimulator,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: getRoute(basePath.admin, resources.simulatorId),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.simulatorId
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'id',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            },
            {
              name: 'IOT Simulator - Delete',
              description: 'Delete IOT simulator by id.',
              APIRoute: resources.iotSimulator,
              method: simulatorRepository.deleteIotSimulator,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.admin, resources.iotSimulator),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.admin,
                  resources.iotSimulator
                )}"   -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'id',
                    type: 'string',
                    required: true,
                    displayName: 'query'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            },
            {
              name: 'Watch Simulator - Update',
              description: 'Update the step count of watch simulator.',
              APIRoute: resources.simulatorMessage,
              method: simulatorRepository.updateWatchSimulatorStepCount,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.admin,
                  resources.simulatorMessage
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.simulatorMessage)}`,
                requestParameters: [
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {}
              }
            }
          ]
        },
        {
          name: 'FAQ',
          apis: [
            {
              name: 'FAQ - List',
              description: 'All the FAQ list.',
              APIRoute: resources.productFaq,
              method: faqContentRepository.getAllFAQ,
              methodType: httpMethods.GET,
              version: getVersion(basePath.coreConsumer),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.coreConsumer,
                  resources.productFaq
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.coreConsumer, resources.productFaq)}`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'FAQ Categories - List',
              description: 'Categories wise FAQ list.',
              APIRoute: resources.faqCategories,
              method: faqContentRepository.getFAQbyCategories,
              methodType: httpMethods.GET,
              version: getVersion(basePath.coreConsumer),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.coreConsumer,
                  resources.faqCategories
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.coreConsumer,
                  resources.faqCategories
                )}`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: 2,
                    product: '',
                    name: 'general',
                    categoryOrder: 1.0,
                    faqList: [
                      {
                        id: 1111,
                        product: '',
                        title: 'demo title',
                        description: 'demo description',
                        category: '',
                        upvotes: 0,
                        downvotes: 0,
                        faqOrder: 0.0
                      },
                      {
                        id: 1111,
                        product: '',
                        title: 'demo title',
                        description: 'demo description',
                        category: '',
                        upvotes: 0,
                        downvotes: 0,
                        faqOrder: 0.0
                      }
                    ]
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'FAQ - Delete',
              description: 'Delete FAQ.',
              APIRoute: resources.adminSingleFAQ,
              method: faqContentRepository.deleteFAQ,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                url: `${getRoute(
                  basePath.coreAdminV1,
                  resources.adminSingleFAQ
                )}`,
                curlRequest: `curl -v -x DELETE "${getRoute(
                  basePath.coreAdminV1,
                  resources.adminSingleFAQ
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'faqId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Bulk FAQ - Delete',
              description: 'Delete Bulk FAQ.',
              APIRoute: resources.adminBulkFAQ,
              method: faqContentRepository.deleteBulkFAQ,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                url: `${getRoute(
                  basePath.coreAdminV1,
                  resources.adminBulkFAQ
                )}`,
                curlRequest: `curl -v -x DELETE "${getRoute(
                  basePath.coreAdminV1,
                  resources.adminBulkFAQ
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'faqIds',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'FAQ - Create',
              description: 'Create FAQ.',
              APIRoute: resources.adminFAQ,
              method: faqContentRepository.createFAQ,
              methodType: httpMethods.POST,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                url: `${getRoute(basePath.coreAdminV1, resources.adminFAQ)}`,
                curlRequest: `curl -v -x POST "${getRoute(
                  basePath.coreAdminV1,
                  resources.adminFAQ
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  category: "{faq_category}",
                  title: "{faq_title}",
                  description: "{faq_description}",
                  faqOrder: 100
                }"`,
                requestBody: [
                  {
                    category: '{faq_category}',
                    title: '{faq_title}',
                    description: '{faq_description}',
                    faqOrder: 100
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'FAQ - Update',
              description: 'Update FAQ.',
              APIRoute: resources.adminFAQ,
              method: faqContentRepository.updateFAQ,
              methodType: httpMethods.PUT,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                url: `${getRoute(basePath.coreAdminV1, resources.adminFAQ)}`,
                curlRequest: `curl -v -x PUT "${getRoute(
                  basePath.coreAdminV1,
                  resources.adminFAQ
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  id:"{id}",
                  title: '{faq_title}',
                  description: '{faq_description}',
                  category: '{faq_category}'
                }"`,
                requestBody: [
                  {
                    id: '{id}',
                    title: '{faq_title}',
                    description: '{faq_description}',
                    category: '{faq_category}'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'FAQ Category - Update',
              description: 'Update FAQ Category.',
              APIRoute: resources.adminCategory,
              method: faqContentRepository.updateFAQCategory,
              methodType: httpMethods.PUT,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                url: `${getRoute(
                  basePath.coreAdminV1,
                  resources.adminCategory
                )}`,
                curlRequest: `curl -v -x PUT "${getRoute(
                  basePath.coreAdminV1,
                  resources.adminCategory
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  id: "{id}", 
                    categoryOrder: "{categoryOrder}"
                }"`,
                requestBody: {
                  id: '{id}',
                  categoryOrder: '{categoryOrder}'
                },
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'FAQ Vote',
              description: 'FAQ vote.',
              APIRoute: resources.faqVote,
              method: faqContentRepository.faqVote,
              methodType: httpMethods.POST,
              version: getVersion(basePath.coreConsumer),
              apiDetails: {
                url: `${getRoute(basePath.coreConsumer, resources.faqVote)}`,
                curlRequest: `curl -v -x POST "${getRoute(
                  basePath.coreConsumer,
                  resources.faqVote
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d " {
                  id: "{id}",
                  upvotes: "{upvote}",
                  downvotes: "{downvote}"
                }"`,
                requestBody: [
                  {
                    id: '{id}',
                    upvotes: '{upvote}',
                    downvotes: '{downvote}'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            }
          ]
        },
        {
          name: 'Media ',
          apis: [
            {
              name: 'Media - All',
              description: 'Current configuration of all  media.',
              APIRoute: resources.allMedia,
              method: mediaRepository.getAllMedia,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.allMedia
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.allMedia)}`,
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    assetName: '{assetName}',
                    assetId: '{assetId}',
                    assetHash: '{assetHash}',
                    type: 'BACKGROUND',
                    fileType: 'JPG',
                    mimeType: 'image/jpeg',
                    tagName: 'deviceId',
                    tagValue: '{tagValue}',
                    action: 'UPDATE',
                    productId: '{productId}',
                    createdBy: 'user1',
                    assetUrl: '{assetUrl}',
                    blobName: '{blobName}',
                    version: '0.0.2'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Media Meta - Add',
              description: 'Add media meta.',
              APIRoute: resources.media,
              method: mediaRepository.addMediaMeta,
              methodType: httpMethods.POST,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: `${getRoute(basePath.admin, resources.media)}`,
                curlRequest: `curl -v -x POST "${getRoute(
                  basePath.admin,
                  resources.media
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "
                { configType: 'MEDIA_DEFAULT',
                action:'UPDATE',
                configName: '{configName}',
                productId: 'KIDSWATCH',
                deviceId: '{deviceId}',
                fileType: 'JPG',
                createdBy: '{createdBy}',
                type: 'THEME',
              }
                "`,
                requestParameters: [
                  {
                    name: 'assetId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'assetName',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'productId',
                    type: 'string',
                    required: true,
                    hide: true
                  }
                ],
                requestBody: {
                  configType: 'MEDIA_DEFAULT',
                  action: 'UPDATE',
                  configName: '{configName}',
                  productId: 'KIDSWATCH',
                  deviceId: '{deviceId}',
                  fileType: 'JPG',
                  createdBy: '{createdBy}',
                  type: 'THEME'
                },
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Media Meta - Details',
              description: 'Get media meta details.',
              APIRoute: resources.media,
              method: mediaRepository.getMediaMeta,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: `${getRoute(basePath.admin, resources.media)}`,
                curlRequest: `curl -v -x GET "${getRoute(
                  basePath.admin,
                  resources.media
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"
                "`,
                requestParameters: [
                  {
                    name: 'assetId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'assetName',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'productId',
                    type: 'string',
                    required: true,
                    hide: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Media Meta - Update',
              description: 'Update media meta.',
              APIRoute: resources.media,
              method: mediaRepository.updateMediaMeta,
              methodType: httpMethods.PUT,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: `${getRoute(basePath.admin, resources.media)}`,
                curlRequest: `curl -v -x PUT "${getRoute(
                  basePath.admin,
                  resources.media
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "   { configType: 'MEDIA_DEFAULT',
                action:'UPDATE',
                configName: '{configName}',
                productId: 'KIDSWATCH',
                deviceId: '{deviceId}',
                fileType: 'JPG',
                createdBy: '{createdBy}',
                type: 'THEME',
              }" 
                "`,
                requestParameters: [
                  {
                    name: 'assetId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'assetName',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'productId',
                    type: 'string',
                    required: true,
                    hide: true
                  }
                ],
                requestBody: {
                  configType: 'MEDIA_DEFAULT',
                  action: 'UPDATE',
                  configName: '{configName}',
                  productId: 'KIDSWATCH',
                  deviceId: '{deviceId}',
                  fileType: 'JPG',
                  createdBy: '{createdBy}',
                  type: 'THEME'
                },
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Media Meta - Delete',
              description: 'Delete media meta.',
              APIRoute: resources.media,
              method: mediaRepository.deleteMediaMeta,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: `${getRoute(basePath.admin, resources.media)}`,
                curlRequest: `curl -v -x DELETE "${getRoute(
                  basePath.admin,
                  resources.media
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"
                  "`,
                requestParameters: [
                  {
                    name: 'assetId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'assetName',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'productId',
                    type: 'string',
                    required: true,
                    hide: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            },
            {
              name: 'Media Asset - Details',
              description: 'Get media asset.',
              APIRoute: resources.media,
              method: mediaRepository.getMediaAsset,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                url: `${getRoute(basePath.admin, resources.media)}`,
                curlRequest: `curl -v -x GET "${getRoute(
                  basePath.admin,
                  resources.media
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"
                "`,
                requestParameters: [
                  {
                    name: 'assetId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'assetName',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'productId',
                    type: 'string',
                    required: true,
                    hide: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.WATCH]
            }
          ]
        },
        {
          name: 'Platform Support ',
          apis: [
            {
              name: 'On-Call Support',
              description: 'Platform on-call support details.',
              APIRoute: resources.oncallPlatformSupport,
              method: platformRepository.getOnCallSupportGroup,
              methodType: httpMethods.GET,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.coreAdminV1,
                  resources.oncallPlatformSupport
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.coreAdminV1,
                  resources.oncallPlatformSupport
                )}`
              }
            },
            {
              name: 'Support Groups',
              description: 'Platform support members groups. ',
              APIRoute: resources.platformSupportGroups,
              method: platformRepository.getSupportGroupsList,
              methodType: httpMethods.GET,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.coreAdminV1,
                  resources.platformSupportGroups
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.coreAdminV1,
                  resources.platformSupportGroups
                )}`
              }
            }
          ]
        },
        {
          name: 'Device',
          apis: [
            {
              name: "User's Devices - List",
              description: "Get selected user's devices list.",
              APIRoute:
                product === APP_MOBILE.DRIVE
                  ? resources.driveDevice
                  : resources.userDevices,
              method: deviceRepository.getDevicesByUserId,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails:
                product === APP_MOBILE.DRIVE
                  ? {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.admin,
                        resources.driveDevice
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(basePath.admin, resources.driveDevice)}`,
                      requestParameters: [
                        {
                          name: 'userId',
                          required: true,
                          type: 'string'
                        }
                      ],

                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          id: '{id}',
                          hardwareId: '{hardwareId}',
                          product: 'PETS',
                          model: 'PETS1',
                          status: 'DELETED',
                          deleteReason: null,
                          networkSsids: [],
                          ratePlan: null,
                          onboardingStatus: 'ONBOARDED',
                          onboardingCreatedDateTime: '2020-01-21T21:56:17.810Z',
                          onboardingUpdateDateTime: '2020-01-21T21:57:33.192Z',
                          userId: '{userId}',
                          msisdn: null,
                          lineStatus: null,
                          onboardingFailureReason: null,
                          onboardingMethod: null,
                          lastCommunicationDateTime: '2020-03-13T20:39:34.000Z',
                          offline: true,
                          softwareWifiVersion: '1.2.0.2',
                          softwareWifiVersionUpdatedDateTime:
                            '2020-03-13T20:40:21.437Z'
                        }
                      ]
                    }
                  : {
                      curlRequest: `curl -v -X GET "${getRoute(
                        basePath.admin,
                        resources.userDevices
                      )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                      url: `${getRoute(basePath.admin, resources.userDevices)}`,
                      requestParameters: [
                        {
                          name: 'userId',
                          required: true,
                          type: 'string'
                        }
                      ],
                      requestHeaders: [
                        {
                          name: 'Authorization',
                          required: true,
                          type: 'string'
                        },
                        {
                          name: 'x-subscription-key',
                          required: true,
                          type: 'string'
                        }
                      ],
                      responseStructure: [
                        {
                          id: '{id}',
                          hardwareId: '{hardwareId}',
                          product: 'PETS',
                          model: 'PETS1',
                          status: 'DELETED',
                          deleteReason: null,
                          networkSsids: [],
                          ratePlan: null,
                          onboardingStatus: 'ONBOARDED',
                          onboardingCreatedDateTime: '2020-01-21T21:56:17.810Z',
                          onboardingUpdateDateTime: '2020-01-21T21:57:33.192Z',
                          userId: '{userId}',
                          msisdn: null,
                          lineStatus: null,
                          onboardingFailureReason: null,
                          onboardingMethod: null,
                          lastCommunicationDateTime: '2020-03-13T20:39:34.000Z',
                          offline: true,
                          softwareWifiVersion: '1.2.0.2',
                          softwareWifiVersionUpdatedDateTime:
                            '2020-03-13T20:40:21.437Z'
                        }
                      ]
                    }
            },
            {
              name: 'Device Twin',
              description: 'Device twin details of selected hardware id.',
              APIRoute: resources.deviceTwin,
              method: deviceRepository.getDeviceTwin,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.deviceTwin
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.deviceTwin)}`,
                requestParameters: [
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              authorizedProducts: IOT_PRODUCTS
            },
            {
              name: 'Sensor History - Details',
              description:
                'Sensor history of selected thing based on selected time range.',
              APIRoute: resources.sensorHistory,
              method: deviceRepository.getSensorHistory,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.sensorHistory
                )}start-date-time={startDateTime}&end-date-time={endDateTime}" -H "Authorization: {token}" -H "Cache-Control: no-cache"  -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.admin,
                  resources.sensorHistory
                )}start-date-time={startDateTime}&end-date-time={endDateTime}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'sensorType',
                    required: true,
                    type: 'string',
                    value: 'LOCATION'
                  },
                  {
                    name: 'startDateTime',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange(),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    deviceId: '{deviceId}',
                    hardwareId: '{hardwareId}',
                    type: 'LOCATION',
                    timestamp: '2020-08-06T19:56:06.000Z',
                    updateDate: '2020-08-06T19:56:18.145Z',
                    dataSource: 'NSEP',
                    latitude: 48.63063,
                    longitude: -98.4711,
                    altitude: 55,
                    radius: 66,
                    speed: 4.5e-5,
                    source: 'GPS'
                  }
                ]
              },
              authorizedProducts: [
                APP_MOBILE.PETS,
                APP_MOBILE.TRACKER,
                APP_MOBILE.WATCH
              ]
            },
            {
              name: 'Health Check',
              description:
                'To fetch sensor latest timestamp & history timestamp of selected device.',
              APIRoute: resources.healthCheck,
              method: deviceRepository.healthCheck,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platform),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.healthCheck
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.platform, resources.healthCheck)}`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    observationsHealth: {
                      trackerId: '{trackerId}',
                      hardwareId: '{hardwareId}',
                      nextRefreshDateTime: '2030-08-04T18:25:52.040Z',
                      lastRefreshAttempt: '2020-08-06T19:41:39.181Z',
                      refreshAttempts: 0,
                      status: 'ACTIVE',
                      device: {
                        id: '{id}',
                        status: 'EXECUTING',
                        timeStamp: 1596738438440,
                        lastUpdatedTime: 1596738438457,
                        faultCode: null,
                        faultString: null,
                        endpoint: '{endpoint}',
                        type: 'OBSERVATION',
                        expiredTime: 1912098438440,
                        remainingTime: 315355539,
                        targets: ['{targets}'],
                        sensorType: 'DEVICE'
                      },
                      location: {
                        id: '{id}',
                        status: 'EXECUTING',
                        timeStamp: 1596738438443,
                        lastUpdatedTime: 1596738438463,
                        faultCode: null,
                        faultString: null,
                        endpoint: '{endpoint}',
                        type: 'OBSERVATION',
                        expiredTime: 1912098438443,
                        remainingTime: 315355539,
                        targets: ['{targets}'],
                        sensorType: 'LOCATION'
                      },
                      temperature: {
                        id: '{id}',
                        status: 'EXECUTING',
                        timeStamp: 1596738438440,
                        lastUpdatedTime: 1596738438492,
                        faultCode: null,
                        faultString: null,
                        endpoint: '{endpoint}',
                        type: 'OBSERVATION',
                        expiredTime: 1912098438440,
                        remainingTime: 315355539,
                        targets: ['{targets}'],
                        sensorType: 'TEMPERATURE'
                      },
                      activity: {
                        id: '{id}',
                        status: 'EXECUTING',
                        timeStamp: 1596738438441,
                        lastUpdatedTime: 1596738438457,
                        faultCode: null,
                        faultString: null,
                        endpoint: '{endpoint}',
                        type: 'OBSERVATION',
                        expiredTime: 1912098438441,
                        remainingTime: 315355539,
                        targets: ['{targets}'],
                        sensorType: 'ACTIVITY'
                      },
                      wlan: {
                        id: '{id}',
                        status: 'EXECUTING',
                        timeStamp: 1596738438443,
                        lastUpdatedTime: 1596738438501,
                        faultCode: null,
                        faultString: null,
                        endpoint: '{endpoint}',
                        type: 'OBSERVATION',
                        expiredTime: 1912098438443,
                        remainingTime: 315355539,
                        targets: ['{targets}'],
                        sensorType: 'WLAN'
                      },
                      lightControl: null,
                      buzzerControl: null,
                      lostMode: null,
                      nbiot: null,
                      errorCode: null
                    },
                    latestSensorTimestamps: {
                      device: '{device}',
                      location: '2020-08-06T22:06:06.000Z',
                      temperature: '2020-08-06T22:06:10.025Z',
                      activity: '2020-08-06T21:10:55.000Z',
                      wlan: '2020-08-06T20:00:45.522Z',
                      lightControl: '2020-08-06T20:00:43.876Z',
                      buzzerControl: '2020-08-06T20:00:43.876Z',
                      lostMode: '2020-08-06T20:00:45.242Z',
                      nbiot: '2020-08-06T20:00:43.876Z',
                      errorCode: '2020-08-06T20:00:43.876Z'
                    },
                    sensorHistoryTimestamps: {
                      device: ['{device1}', '{device2}', '{device3}'],
                      location: [
                        '2020-08-06T19:46:08.356Z',
                        '2020-08-06T19:51:08.076Z',
                        '2020-08-06T19:56:08.128Z'
                      ],
                      temperature: [
                        '2020-08-06T19:46:10.221Z',
                        '2020-08-06T19:51:10.090Z',
                        '2020-08-06T19:56:10.076Z'
                      ],
                      activity: [
                        '2020-08-06T20:00:44.047Z',
                        '2020-08-06T20:10:56.343Z',
                        '2020-08-06T20:40:56.064Z'
                      ],
                      wlan: ['2020-08-06T20:00:45.573Z'],
                      lightControl: ['2020-08-06T20:00:45.265Z'],
                      buzzerControl: ['2020-08-06T20:00:44.079Z'],
                      lostMode: ['2020-08-06T20:00:45.405Z']
                    }
                  }
                ]
              },

              authorizedProducts: [
                APP_MOBILE.PETS,
                APP_MOBILE.TRACKER,
                APP_MOBILE.WATCH,
                APP_MOBILE.FAMILYLOCATION
              ]
            },
            {
              name: 'Hardware - Details',
              description: 'Hardware details of selected hardware id.',
              APIRoute: resources.hardwareDetails,
              method: deviceRepository.getHardwareDetails,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.hardwareDetails
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.hardwareDetails)}`,
                requestParameters: [
                  {
                    name: 'imei',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    iccid: '{iccid}',
                    imeival: '{imeival}',
                    blemac: '{blemac}',
                    psk: '{psk}',
                    manufacturingGroup: 'DV2',
                    wifimac: '{wifimac}',
                    imsi: '{imsi}',
                    msisdn: '{msisdn}',
                    ssid: '{ssid}',
                    hardwareRevision: 'DV2-0001',
                    product: 'PETS',
                    status: 'ACTIVE'
                  }
                ]
              },
              authorizedProducts: [APP_MOBILE.PETS]
            },
            {
              name: 'Log - Details',
              description: 'Get logs by IMEI.',
              APIRoute: resources.logs,
              method: deviceRepository.getLogsByIMEI,
              methodType: httpMethods.GET,
              version: getVersion(basePath.admin),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.admin,
                  resources.logs
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.admin, resources.logs)}`,
                requestParameters: [
                  {
                    name: 'hardwareId',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'startDateTime',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'EndDateTime',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'queryString',
                    isHidden: true,
                    value:
                      'hardware-id={hardwareid}&start-date-time={startDateTime}&end-date-time={endDateTime}',
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                responseStructure: [{}]
              }
            },
            {
              name: 'Drive Device - Delete',
              description: 'Delete real drive device By device id.',
              APIRoute: resources.driveClaimDevice,
              method: deviceRepository.removeRealDriveDevice,
              methodType: httpMethods.DELETE,
              version: getVersion(basePath.adminDrive),
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.adminDrive, resources.driveClaimDevice),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.adminDrive,
                  resources.driveClaimDevice
                )}"  -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            },
            {
              name: 'Device - Bulk Recommission',
              description: 'Recommission bulk device.',
              APIRoute: resources.recommission,
              method: deviceRepository.bulkRecommissionrDevice,
              methodType: httpMethods.POST,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                url: getRoute(basePath.coreAdminV1, resources.recommission),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.coreAdminV1,
                  resources.recommission
                )}"  -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{     hardwareId:'{hardwareId}',
                status: 'Staged',
                message: 'Click recommission to start the process'
                }"`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  hardwareId: '{hardwareId}',
                  status: 'Staged',
                  message: 'Click recommission to start the process'
                }
              }
            },
            {
              name: 'Device Existance - Validate',
              description: 'Validate device existence.',
              APIRoute: resources.validateDeviceExistance,
              method: deviceRepository.bulkvalDeviceExistancerDevice,
              methodType: httpMethods.POST,
              version: getVersion(basePath.coreAdminV1),
              apiDetails: {
                url: getRoute(
                  basePath.coreAdminV1,
                  resources.validateDeviceExistance
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.coreAdminV1,
                  resources.validateDeviceExistance
                )}"   -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  
                }"`,
                requestParameters: [],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {}
              }
            },
            {
              name: 'Device Lost Mode - Update',
              description: 'Update a device lost mode.',
              APIRoute: resources.updateLostModeSensor,
              method: deviceRepository.updateDeviceLostMode,
              methodType: httpMethods.PUT,
              version: getVersion(basePath.platform),
              apiDetails: {
                curlRequest: `curl -v -X PUT "${getRoute(
                  basePath.platform,
                  resources.updateLostModeSensor
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(
                  basePath.platform,
                  resources.updateLostModeSensor
                )}`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ],
                requestBody: {
                  onOff: false
                }
              }
            }
          ]
        },
        {
          name: 'Mobile App',
          apis: [
            {
              name: 'App Versions',
              description: 'App versions list of selected product & platform.',
              APIRoute: resources.appVersions,
              method: mobileAppRepository.getAppVersions,
              methodType: httpMethods.GET,
              version: getVersion(basePath.platform),
              apiDetails: {
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.appVersions
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                url: `${getRoute(basePath.platform, resources.appVersions)}`,
                requestParameters: [
                  {
                    name: 'osVersion',
                    required: true,
                    type: 'string'
                  }
                ],

                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'x-subscription-key',
                    required: true,
                    type: 'string'
                  }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      name: 'Mobile Application',
      categories: [
        {
          name: 'Alarms',
          apis: [
            {
              name: 'Alarms - List',
              description: 'Get a list of alarms for current user.',
              APIRoute: resources.alarms,
              method: thingRepository.getAlarms,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.alarms),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.alarms
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Alarm - Create',
              description: 'Create an alarm for current user.',
              APIRoute: resources.alarms,
              method: thingRepository.createAlarm,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.POST,
              apiDetails: {
                url: getRoute(basePath.platform, resources.alarms),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platform,
                  resources.alarms
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  title: 'Time to catch school bus!',
                  thingId: '{thingId}',
                  startDate: '2021-01-27',
                  startTime: '10:10:10',
                  duration: 1000,
                  repeatingSchedule: {
                    sunday: false,
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false
                  },
                  snooze: false,
                  inSchoolMode: false,
                  active: true,
                  status: 'ACTIVE'
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  title: 'Time to catch school bus!',
                  thingId: '{thingId}',
                  startDate: '2021-01-27',
                  startTime: '10:10:10',
                  duration: 1000,
                  repeatingSchedule: {
                    sunday: false,
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false
                  },
                  snooze: false,
                  inSchoolMode: false,
                  active: true,
                  status: 'ACTIVE'
                }
              }
            },
            {
              name: 'Alarm - Update',
              description: 'Update an alarm by alarm id for current user.',
              APIRoute: resources.alarmsById,
              method: thingRepository.updateAlarm,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.PATCH,
              apiDetails: {
                url: getRoute(basePath.platform, resources.alarmsById),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platform,
                  resources.alarmsById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "[
                  {
                    op: 'replace',
                    path: '/title',
                    value: 'Time to catch school bus!'
                  },
                  {
                    op: 'replace',
                    path: '/startDate',
                    value: '2021-01-27'
                  },
                  {
                    op: 'replace',
                    path: '/startTime',
                    value: '2023-02-16'
                  },
                  {
                    op: 'replace',
                    path: '/repeatingSchedule',
                    value: {
                      sunday: false,
                      monday: true,
                      tuesday: true,
                      wednesday: true,
                      thursday: true,
                      friday: true,
                      saturday: false
                    }
                  }
                ]"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'alarmId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: [
                  {
                    op: 'replace',
                    path: '/title',
                    value: 'Time to catch school bus!'
                  },
                  {
                    op: 'replace',
                    path: '/startDate',
                    value: '2021-01-27'
                  },
                  {
                    op: 'replace',
                    path: '/startTime',
                    value: '2023-02-16'
                  },
                  {
                    op: 'replace',
                    path: '/repeatingSchedule',
                    value: {
                      sunday: false,
                      monday: true,
                      tuesday: true,
                      wednesday: true,
                      thursday: true,
                      friday: true,
                      saturday: false
                    }
                  }
                ]
              }
            },
            {
              name: 'Alarm - Delete',
              description: 'Delete an alarm by alarm id for current user.',
              APIRoute: resources.alarmsById,
              method: thingRepository.deleteAlarm,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.DELETE,
              apiDetails: {
                url: getRoute(basePath.platform, resources.alarmsById),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.alarmsById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'alarmId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Alarm - Details',
              description: 'Get an alarm by alarm id.',
              APIRoute: resources.alarmsById,
              method: thingRepository.getAlarmsById,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.alarmsById),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.alarmsById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'alarmId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'User Task',
          apis: [
            {
              name: 'User Tasks - List',
              description: 'Get a list of tasks for current user.',
              APIRoute: resources.userTasks,
              method: userRepository.getUserTasksDetails,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.userTasks),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.userTasks
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'User Tasks - Thing Id',
              description: 'Get a user tasks by thing id.',
              APIRoute: resources.userTaskByThing,
              method: userRepository.getUserTasksDetailsByThing,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.userTaskByThing),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.userTaskByThing
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'User Task - Create',
              description: 'Create a task for current user.',
              APIRoute: resources.userTasks,
              method: userRepository.createUserTasksDetails,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.POST,
              apiDetails: {
                url: getRoute(basePath.platform, resources.userTasks),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platform,
                  resources.userTasks
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
  thingId: 'thing-id',
  title: 'user task title',
  message: 'task for you!',
  startDateTime: '2020-10-29T00:39:00.9790000+00:00',
  endDateTime: null,
  rewardId: 'reward-id',
  days: {
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false
  },
  silentMode: false,
  reminders: false,
  active: true,
  status: 'ACTIVE'
}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  thingId: 'thing-id',
                  title: 'user task title',
                  message: 'task for you!',
                  startDateTime: '2020-10-29T00:39:00.9790000+00:00',
                  endDateTime: null,
                  rewardId: 'reward-id',
                  days: {
                    sunday: false,
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false
                  },
                  silentMode: false,
                  reminders: false,
                  active: true,
                  status: 'ACTIVE'
                }
              }
            },
            {
              name: 'User Task - Details',
              description: 'Get a task by task id.',
              APIRoute: resources.userTaskByTaskId,
              method: userRepository.getUserTasksDetailsByTaskId,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.userTaskByTaskId),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.userTaskByTaskId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'userTaskId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'User Task - Update',
              description: 'Update task by task Id for current user.',
              APIRoute: resources.userTaskByTaskId,
              method: userRepository.updateUserTask,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.PATCH,
              apiDetails: {
                url: getRoute(basePath.platform, resources.userTaskByTaskId),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platform,
                  resources.userTaskByTaskId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
  op: 'replace',
  path: '/title',
  value: 'updated title'
}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'userTaskId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: [
                  {
                    op: 'replace',
                    path: '/title',
                    value: 'updated title'
                  }
                ]
              }
            },
            {
              name: 'User Task - Delete',
              description: 'Delete task by task Id for current user.',
              APIRoute: resources.userTaskByTaskId,
              method: userRepository.deleteTaskById,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.DELETE,
              apiDetails: {
                url: getRoute(basePath.platform, resources.userTaskByTaskId),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.userTaskByTaskId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'userTaskId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'Contact',
          apis: [
            {
              name: 'Contacts - List',
              description: 'Get a list of contacts for current user.',
              APIRoute: resources.emergencyContacts,
              method: userRepository.getContacts,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.emergencyContacts),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.emergencyContacts
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Contact - Create',
              description: ' Create contact for current user.',
              APIRoute: resources.emergencyContacts,
              method: userRepository.createUserContact,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.POST,
              apiDetails: {
                url: getRoute(basePath.platform, resources.emergencyContacts),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platform,
                  resources.emergencyContacts
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  msisdn: '{msisdn}',
                  countryCode: '1',
                  firstName: 'Mark',
                  lastName: 'Jones',
                  type: 'GUARDIAN',
                  emergency: true,
                  email: 'test@test.com',
                  things: {
                    '{thingId}': 'watchDisplayName1'
                  },
                  entityDisplayNames: {
                    '{thingId}': 'entityDisplayName1'
                  }
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  msisdn: '{msisdn}',
                  countryCode: '1',
                  firstName: 'Mark',
                  lastName: 'Jones',
                  type: 'GUARDIAN',
                  emergency: true,
                  email: 'test@test.com',
                  things: {
                    '{thingId}': 'watchDisplayName1'
                  },
                  entityDisplayNames: {
                    '{thingId}': 'entityDisplayName1'
                  }
                }
              }
            },
            {
              name: 'Contact - Details',
              description: 'Get a contact by contact id.',
              APIRoute: resources.contactByContactId,
              method: userRepository.userContactDetails,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.contactByContactId),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.contactByContactId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'contactId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Contact - Update',
              description: 'Update contact by contact id for current user.',
              APIRoute: resources.contactByContactId,
              method: userRepository.updateContactDetails,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.PUT,
              apiDetails: {
                url: getRoute(basePath.platform, resources.contactByContactId),
                curlRequest: `curl -v -X PUT "${getRoute(
                  basePath.platform,
                  resources.contactByContactId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  msisdn: '{msisdn}',
                  countryCode: '1',
                  firstName: 'Mark',
                  lastName: 'Jones',
                  type: 'GUARDIAN',
                  emergency: true,
                  email: 'test@test.com',
                  things: {
                    '{thingId}': 'watchDisplayName1', 
                  },
                  entityDisplayNames: {
                    '{thingId}':
                      'entityDisplayName1', 
                  }
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'contactId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  msisdn: '{msisdn}',
                  countryCode: '1',
                  firstName: 'Mark',
                  lastName: 'Jones',
                  type: 'GUARDIAN',
                  emergency: true,
                  email: 'test@test.com',
                  things: {
                    '{thingId}': 'watchDisplayName1'
                  },
                  entityDisplayNames: {
                    '{thingId}': 'entityDisplayName1'
                  }
                }
              }
            },
            {
              name: 'Contact - Delete',
              description: 'Delete contact by contact id for current user.',
              APIRoute: resources.contactByContactId,
              method: userRepository.deleteContactDetails,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.DELETE,
              apiDetails: {
                url: getRoute(basePath.platform, resources.contactByContactId),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.contactByContactId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'contactId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Contact - Icon Upload',
              description: 'Upload icon for a contact.',
              APIRoute: resources.iconByContactId,
              method: userRepository.uploadContactIcon,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.POST,
              apiDetails: {
                url: getRoute(basePath.platform, resources.contactByContactId),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platform,
                  resources.contactByContactId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'contactId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'Task Scheduler',
          apis: [
            {
              name: 'Scheduled Task - Create',
              description: 'Create scheduled tasks  for current user.',
              APIRoute: resources.createSchoolMode,
              method: userRepository.postSchoolModeDetails,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.POST,
              apiDetails: {
                url: getRoute(basePath.platform, resources.createSchoolMode),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platform,
                  resources.createSchoolMode
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  scheduleType: 'weekday',
                  schedule: {
                    weekdays: {
                      monday: [''],
                      friday: ['', '']
                    }
                  },
                  functionToCall: 'functionNameToCall',
                  enabled: true,
                  attributes: {
                    primaryAccountHolderId: 'validPrimaryAccountHolderId',
                    thingId: 'validThingId',
                    lineId: 'validLineId',
                    targetName: 'validTargetName',
                    deviceId: 'validDeviceId',
                    placeId: 'validPlaceId',
                    email: 'email'
                  }
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  scheduleType: 'weekday',
                  schedule: {
                    weekdays: {
                      monday: [''],
                      friday: ['', '']
                    }
                  },
                  functionToCall: 'functionNameToCall',
                  enabled: true,
                  attributes: {
                    primaryAccountHolderId: 'validPrimaryAccountHolderId',
                    thingId: 'validThingId',
                    lineId: 'validLineId',
                    targetName: 'validTargetName',
                    deviceId: 'validDeviceId',
                    placeId: 'validPlaceId',
                    email: 'email'
                  }
                }
              }
            },
            {
              name: 'Scheduled Task - List',
              description: 'Get scheduled tasks for current user.',
              APIRoute: resources.createSchoolMode,
              method: userRepository.getUserSchoolModeDetails,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.createSchoolMode),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.createSchoolMode
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Scheduled Task - Details',
              description:
                'Get scheduled tasks for current user by scheduled task id.',
              APIRoute: resources.deleteSchoolMode,
              method: userRepository.getSchoolModeDetailsById,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.deleteSchoolMode),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.deleteSchoolMode
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'scheduledTaskId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Scheduled Task - Update',
              description:
                'Update scheduled tasks for current user by scheduled task id.',
              APIRoute: resources.deleteSchoolMode,
              method: userRepository.updateSchoolModeDetails,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.PUT,
              apiDetails: {
                url: getRoute(basePath.platform, resources.deleteSchoolMode),
                curlRequest: `curl -v -X PUT "${getRoute(
                  basePath.platform,
                  resources.deleteSchoolMode
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  id: 'validScheduledTaskId',
                  scheduleType: 'weekday',
                  schedule: {
                    weekdays: {
                      monday: [''],
                      friday: ['', '']
                    }
                  },
                  functionToCall: 'functionNameToCall',
                  enabled: true,
                  attributes: {
                    primaryAccountHolderId: 'validPrimaryAccountHolderId',
                    thingId: 'validThingId',
                    lineId: 'validLineId',
                    targetName: 'validTargetName',
                    deviceId: 'validDeviceId',
                    placeId: 'validPlaceId',
                    email: 'email'
                  }
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'scheduledTaskId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  id: 'validScheduledTaskId',
                  scheduleType: 'weekday',
                  schedule: {
                    weekdays: {
                      monday: [''],
                      friday: ['', '']
                    }
                  },
                  functionToCall: 'functionNameToCall',
                  enabled: true,
                  attributes: {
                    primaryAccountHolderId: 'validPrimaryAccountHolderId',
                    thingId: 'validThingId',
                    lineId: 'validLineId',
                    targetName: 'validTargetName',
                    deviceId: 'validDeviceId',
                    placeId: 'validPlaceId',
                    email: 'email'
                  }
                }
              }
            },
            {
              name: 'Scheduled Task - Delete',
              description:
                'Delete scheduled tasks for current user by scheduled task id.',
              APIRoute: resources.deleteSchoolMode,
              method: userRepository.deleteSchoolModeDetails,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.DELETE,
              apiDetails: {
                url: getRoute(basePath.platform, resources.deleteSchoolMode),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.deleteSchoolMode
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'scheduledTaskId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'Applications',
          apis: [
            {
              name: 'Versions Available - Check',
              description:
                'Check for version information based on distribution channel and platform.',
              APIRoute: resources.versionsAvailable,
              method: mobileAppRepository.getAvailableVersions,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.versionsAvailable
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.versionsAvailable
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'distributionChannel',
                    type: 'string',
                    required: true
                  },
                  { name: 'platform', type: 'string', required: true }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  distributionChannel: 'APP_CENTER',
                  platform: 'IOS',
                  minVersion: '1.0.0',
                  minBuildNumber: '1',
                  mostUpToDateVersion: '1.0.0',
                  mostUpToDateBuildNumber: '5'
                }
              }
            },
            {
              name: 'Version - Update',
              description: 'Update a applications version.',
              APIRoute: resources.appVersionUpdate,
              method: mobileAppRepository.updateAppVersion,
              methodType: httpMethods.PUT,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.appVersionUpdate
                ),
                curlRequest: `curl -v -X PUT " ${getRoute(
                  basePath.platformDrive,
                  resources.appVersionUpdate
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{"minVersion": "1.0.0",
                "mostUpToDateVersion":"1.0.3",
                "minBuildNumber": "1",
                "mostUpToDateBuildNumber": "5" }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'distributionChannel',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'platform',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  minVersion: '1.0.0',
                  mostUpToDateVersion: '1.0.3',
                  minBuildNumber: '1',
                  mostUpToDateBuildNumber: '5'
                }
              }
            },
            {
              name: 'Application Versions - List',
              description: 'Get a list of application versions.',
              APIRoute: resources.appVersions,
              method: mobileAppRepository.getAppVersions,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.appVersions),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.appVersions
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'osVersion',
                    type: 'string',
                    required: true,
                    displayName: 'Platform Name'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Application Version - Check',
              description:
                'Check available upgrade for current application version.',
              APIRoute: resources.appVersionCheck,
              method: thingRepository.createAppVersionCheck,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.appVersionCheck),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.appVersionCheck
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                            -d "{
                              "buildNumber": "1338",
                              "distributionChannel": "APP_CENTER",
                              "platform": "iOS",
                              "version": "1.0.1"
                               }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  buildNumber: '1338',
                  distributionChannel: 'APP_CENTER',
                  platform: 'iOS',
                  version: '1.0.1'
                }
              }
            },
            {
              name: 'Configuration - Mobile',
              description: 'Read mobile device configurations.',
              APIRoute: resources.mobileDeviceConfig,
              method: thingRepository.getMobileDeviceConfig,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.mobileDeviceConfig
                )}version={version}`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.mobileDeviceConfig
                )}version={version}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                            `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'configFile',
                    type: 'string',
                    required: true,
                    displayName: 'config-file'
                  },
                  {
                    name: 'version',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'Devices',
          apis: [
            {
              name: 'Devices - List',
              description: 'Get a list of all devices for the current user.',
              APIRoute: resources.driveDevice,
              method: deviceRepository.getDevicesByUserId,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveDevice),
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveDevice
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    id: '{id}',
                    model: 'SD 7000T',
                    manufacturer: 'MDI',
                    mcuFirmwareVersion: '1.0',
                    modemFirmwareVersion: '1.0',
                    iccId: 'SAMPLE_ICCID',
                    msisdn: 'SAMPLE_MSISDN',
                    configuration: 'UNKNOWN',
                    name: 'New Car',
                    decommissionedBy: null,
                    decommissionedAt: null,
                    claimedBy: 'claimedBy',
                    claimedAt: '2022-02-10T17:19:38.712863',
                    lastConnectedAt: '2022-02-10T21:50:28.511504',
                    status: 'UNCLAIMED|CLAIMED|ACTIVE|UNPLUGGED|OFFLINE|ACTIVE',
                    statusAt: '2022-02-10T21:56:45.875938',
                    roadsideStatus: 'ENROLLED|NOT_ENROLLED',
                    roadsideStatusAt: '2022-02-10T16:13:31.569018',
                    wifiSsid: '**********',
                    wifiPassword: '**********',
                    wifiDisabled: false,
                    wifiDisabledAt: null,
                    wifiStatus: 'OFFLINE|ONLINE',
                    wifiStatusAt: '2022-02-10T21:56:45.875938',
                    wifiDeviceUpdated: true,
                    wifiDeviceRequestId: null,
                    wifiAdminAt: null,
                    simulatorType: 'NONE|MANUAL|AUTO'
                  }
                ]
              }
            },
            {
              name: 'Device - Details',
              description: 'Get a device details by device id.',
              APIRoute: resources.device,
              method: deviceRepository.getDeviceById,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.device),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.device
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'deviceId', type: 'string', required: true }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  id: '{id}',
                  model: 'SD 7000T',
                  manufacturer: 'MDI',
                  mcuFirmwareVersion: '1.0',
                  modemFirmwareVersion: '1.0',
                  iccId: 'SAMPLE_ICCID',
                  msisdn: 'SAMPLE_MSISDN',
                  configuration: 'UNKNOWN',
                  name: 'New Car',
                  decommissionedBy: null,
                  decommissionedAt: null,
                  claimedBy: '{claimedBy}',
                  claimedAt: '2022-02-10T17:19:38.712863',
                  lastConnectedAt: '2022-02-10T21:50:28.511504',
                  status: 'UNCLAIMED|CLAIMED|ACTIVE|UNPLUGGED|OFFLINE|ACTIVE',
                  statusAt: '2022-02-10T21:56:45.875938',
                  roadsideStatus: 'ENROLLED|NOT_ENROLLED',
                  roadsideStatusAt: '2022-02-10T16:13:31.569018',
                  wifiSsid: '**********',
                  wifiPassword: '***********',
                  wifiDisabled: false,
                  wifiDisabledAt: null,
                  wifiStatus: 'OFFLINE|ONLINE',
                  wifiStatusAt: '2022-02-10T21:56:45.875938',
                  wifiDeviceUpdated: true,
                  wifiDeviceRequestId: null,
                  wifiAdminAt: null,
                  simulatorType: 'NONE|MANUAL|AUTO',
                  wifiStatusUpdated: false,
                  WifiStatusRequestId: ''
                }
              }
            },
            {
              name: 'Device - Claim',
              description: 'Claim a device for the current user.',
              APIRoute: resources.driveClaimDevice,
              method: deviceRepository.claimDriveDevice,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveClaimDevice
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveClaimDevice
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Simulator - Plug/Unplug',
              description: 'Plug/unplug simulator by device id.',
              APIRoute: resources.drivePlugInSimulator,
              method: simulatorRepository.plugInDriveSimulator,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.drivePlugInSimulator
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.drivePlugInSimulator
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{eventType: '{eventType}',
                latitude: 38.897663,
                longitude: -77.0387679 }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  eventType: '{eventType}',
                  latitude: 38.897663,
                  longitude: -77.0387679
                }
              }
            },
            {
              name: 'Device - Patch',
              description: 'Patch a device with name and wifi information.',
              APIRoute: resources.device,
              method: deviceRepository.updateDeviceById,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.device),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platformDrive,
                  resources.device
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{name: '{name}'}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  name: '{name}'
                },
                responseStructure: {
                  id: '{id}',
                  model: 'SIM',
                  manufacturer: '{manufacturer}',
                  mcuFirmwareVersion: 'SIM_MCU_FW_VERSION',
                  modemFirmwareVersion: 'SIM_MODEM_FW_VERSION',
                  configuration: 'UNKNOWN',
                  name: '{name}',
                  createdBy: '{createdBy}',
                  createdAt: '2022-07-13T13:25:40.105627',
                  lastModifiedBy: '{lastModifiedBy}',
                  lastModifiedAt: '2023-02-16T06:12:35.7141',
                  decommissionedBy: null,
                  decommissionedAt: null,
                  claimedBy: '{claimedBy}',
                  claimedAt: '2022-07-13T13:27:44.716466',
                  lastConnectedAt: '2023-02-15T11:23:08.848377',
                  lastActivityAt: '2023-02-15T11:23:08.884152',
                  status: 'ACTIVE',
                  statusAt: '2023-02-15T11:23:08.848286',
                  fetchedAt: '2023-02-16T06:12:35.725581',
                  isWhitelisted: true,
                  lineLookupSource: 'SIM_LINE_LOOKUP_SOURCE',
                  ratePlan: 'SIM_RATE_PLAN',
                  wifiSsid: '**********',
                  wifiPassword: '***********',
                  wifiDisabled: false,
                  wifiDisabledAt: null,
                  wifiStatus: 'ONLINE',
                  wifiStatusAt: '2023-02-15T11:23:08.848286',
                  wifiDeviceUpdated: false,
                  wifiDeviceRequestId: '778726526369628161',
                  wifiStatusRequestId: null,
                  wifiStatusUpdated: false,
                  wifiAdminAt: null,
                  roadsideStatus: 'ENROLLED',
                  roadsideStatusAt: '2023-02-15T10:48:09.136437',
                  offlineHeartbeatOrchestrationId: null,
                  noVinOrchestrationId: null,
                  disturbanceOrchestrationId: null,
                  tripEndOrchestrationId: '{tripEndOrchestrationId}',
                  offlineTripOrchestrationId: '{offlineTripOrchestrationId}',
                  simulatorType: 'MANUAL',
                  locationPrivacyOptin: true
                }
              }
            },
            {
              name: 'Device - Unclaim',
              description: 'Unclaim a device for the current user.',
              APIRoute: resources.driveClaimDevice,
              method: deviceRepository.unregisterDevice,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveClaimDevice
                ),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platformDrive,
                  resources.driveClaimDevice
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Sensors',
              description: 'Get latest sensor data by device id.',
              APIRoute: resources.sensorDataByDeviceId,
              method: deviceRepository.getDeviceSensorData,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.sensorDataByDeviceId
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.sensorDataByDeviceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Sensor History',
              description:
                'Return paginated data of sensor history for a given device and sensor.',
              APIRoute: resources.sensorHistoryBySensorId,
              method: deviceRepository.getDeviceSensorHistory,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.sensorHistoryBySensorId
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.sensorHistoryBySensorId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'sensorId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device Location - Ping',
              description: 'Get latest device location by device id.',
              APIRoute: resources.locationByDeviceId,
              method: deviceRepository.deviceLocation,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.locationByDeviceId),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platform,
                  resources.locationByDeviceId
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "deviceTriggerType": "PASSIVE"
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  deviceTriggerType: 'PASSIVE'
                }
              }
            },
            {
              name: 'Device - Lost Mode',
              description: 'Update lost mode sensor data by device id.',
              APIRoute: resources.updateLostModeSensor,
              method: deviceRepository.updateLostModeSensor,
              methodType: httpMethods.PUT,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.updateLostModeSensor
                ),
                curlRequest: `curl -v -X PUT " ${getRoute(
                  basePath.platform,
                  resources.updateLostModeSensor
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "onOff": true
                } "`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  onOff: true
                }
              }
            },
            {
              name: 'Device - Sensor By Type',
              description: 'Get the most recent sensor value by sensor type.',
              APIRoute: resources.sensorBySensorType,
              method: deviceRepository.getDeviceSensorByType,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.sensorBySensorType),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.sensorBySensorType
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'sensorType',
                    required: true,
                    type: 'string',
                    value: 'Device'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Light',
              description: 'Get light sensor data by device id.',
              APIRoute: resources.updateLightSensor,
              method: deviceRepository.updateDeviceLightSensor,
              methodType: httpMethods.PUT,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.updateLightSensor),
                curlRequest: `curl -v -X PUT " ${getRoute(
                  basePath.platform,
                  resources.updateLightSensor
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "onOff": true
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  onOff: true
                }
              }
            },
            {
              name: 'Device - Buzzer',
              description: 'Update buzzer sensor data by device id.',
              APIRoute: resources.updateBuzzerSensor,
              method: deviceRepository.updateDeviceBuzzerSensor,
              methodType: httpMethods.PUT,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.updateBuzzerSensor),
                curlRequest: `curl -v -X PUT " ${getRoute(
                  basePath.platform,
                  resources.updateBuzzerSensor
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "duration": 10,
                  "onOff": true
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  duration: 10,
                  onOff: true
                }
              }
            },
            {
              name: 'Device - Hardware Enabled',
              description: 'Get the enabled hardware for a device.',
              APIRoute: resources.enabledHardwareByDeviceId,
              method: deviceRepository.getDeviceEnabledHardware,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.enabledHardwareByDeviceId
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.enabledHardwareByDeviceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Hardware Enabled Change',
              description: 'Change the enabled hardware for a device.',
              APIRoute: resources.changeEnabledHardwareByDevice,
              method: deviceRepository.updateDeviceHardwarEnabled,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.changeEnabledHardwareByDevice
                ),
                curlRequest: `curl -v -X PATCH " ${getRoute(
                  basePath.platform,
                  resources.changeEnabledHardwareByDevice
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
      "cameraEnabled": true,
      "forceSilentMode": true
    }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  cameraEnabled: true,
                  forceSilentMode: true
                }
              }
            },
            {
              name: 'Device - Tracking Rate Update',
              description: 'Update the tracking rate for a device.',
              APIRoute: resources.updateDeviceTrackingRate,
              method: deviceRepository.updateDeviceTrackingRate,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.updateDeviceTrackingRate
                ),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.updateDeviceTrackingRate
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "trackingMode": "LIVE",
                  "duration": 1000
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  trackingMode: 'LIVE',
                  duration: 1000
                }
              }
            },
            {
              name: 'Device - Tracking Rate',
              description: 'Get the tracking rate for a device.',
              APIRoute: resources.updateDeviceTrackingRate,
              method: deviceRepository.getDeviceTrackingRate,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.updateDeviceTrackingRate
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.updateDeviceTrackingRate
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Delete',
              description: 'Delete device by device id.',
              APIRoute: resources.device,
              method: deviceRepository.unregisterDevice,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.device),
                curlRequest: `curl -v -X DELETE " ${getRoute(
                  basePath.platform,
                  resources.device
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {}
              }
            },
            {
              name: 'Device - Update',
              description: 'Update device by device id.',
              APIRoute: resources.device,
              method: deviceRepository.updateDevice,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.device),
                curlRequest: `curl -v -X PATCH " ${getRoute(
                  basePath.platform,
                  resources.device
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "[
                  {
                    "op": "replace",
                    "path": "/networkSsids",
                    "value": [
                      {
                        "createdDateTime": "2020-07-25T18:00:00.0000000+00:00",
                        "macAddress": null,
                        "name": "{name}"
                      }
                    ]
                  }
                ]"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: [
                  {
                    op: 'replace',
                    path: '/networkSsids',
                    value: [
                      {
                        createdDateTime: '2020-07-25T18:00:00.0000000+00:00',
                        macAddress: null,
                        name: '{name}'
                      }
                    ]
                  }
                ]
              }
            },
            {
              name: 'Device - Health Check',
              description:
                'Get the observation health check and history data around the device.',
              APIRoute: resources.healthCheck,
              method: deviceRepository.healthCheck,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.healthCheck),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.healthCheck
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Temperature',
              description:
                'Get the air temperature around the device in celsius.',
              APIRoute: resources.temperatureByDeviceId,
              method: deviceRepository.getDeviceTemperature,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.temperatureByDeviceId
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.temperatureByDeviceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Activity',
              description: 'Get the activity logged for a given device.',
              APIRoute: resources.activityByDeviceId,
              method: deviceRepository.getDeviceActivity,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.activityByDeviceId
                )}start-date-time{startDateTime}&end-date-time={endDateTime}`,
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.activityByDeviceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}start-date-time{startDateTime}&end-date-time={endDateTime}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'startDateTime',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    value: getTimeRange('start-date-time', 'end-date-time'),
                    isHidden: true,
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Onboarding Grant',
              description: 'Device side verification for device pairing.',
              APIRoute: resources.deviceOnboardingGrant,
              method: deviceRepository.deviceOnboardingGrant,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.deviceOnboardingGrant
                ),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.deviceOnboardingGrant
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "deviceModelType": "WATCH1",
                  "hardwareId": "{hardwareId}",
                  "productType": "KIDSWATCH",
                  "registrationSourceName": "testdevice"
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  deviceModelType: 'WATCH1',
                  hardwareId: '{hardwareId}',
                  productType: 'KIDSWATCH',
                  registrationSourceName: 'testdevice'
                }
              }
            },
            {
              name: 'Device - Onboarding Precheck',
              description:
                'Validate data contract, checking registration information device provisioning information and rate plan information.',
              APIRoute: resources.deviceOnboardingPrecheck,
              method: deviceRepository.deviceOnboardingPreCheck,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.deviceOnboardingPrecheck
                ),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.deviceOnboardingPrecheck
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "deviceModelType": "WATCH1",
                  "hardwareId": "{hardwareId}",
                  "productType": "KIDSWATCH"
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  deviceModelType: 'WATCH1',
                  hardwareId: '{hardwareId}',
                  productType: 'KIDSWATCH'
                }
              }
            },
            {
              name: 'Device - Rate Plan Check',
              description: 'Check whether rate plan for a PET device is valid.',
              APIRoute: resources.checkRatePlanById,
              method: deviceRepository.getDeviceRatePlan,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.PETS],
              apiDetails: {
                url: getRoute(basePath.platform, resources.checkRatePlanById),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.checkRatePlanById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'imei',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Lost Mode Status',
              description:
                'Returns latest lost mode status for a given device.',
              APIRoute: resources.lostModeHistory,
              method: deviceRepository.getLostModeHistory,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.lostModeHistory),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.lostModeHistory
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Rate Plan Check',
              description: 'Check whether rate plan for a device is valid.',
              APIRoute: resources.checkRatePlanbyHardwareId,
              method: deviceRepository.deviceRatePlan,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.checkRatePlanbyHardwareId
                ),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.checkRatePlanbyHardwareId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "deviceModelType": "PETS1",
                  "hardwareId": "{hardwareId}",
                  "productType": "PETS"
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'hardwareId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  deviceModelType: 'PETS1',
                  hardwareId: '{hardwareId}',
                  productType: 'PETS'
                }
              }
            },
            {
              name: 'Motion Sensor - Motion Lock',
              description:
                'Check whether motion lock of motion sensor is on or off.',
              APIRoute: resources.motionLock,
              method: deviceRepository.deviceMotionLock,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.motionLock),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.motionLock
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "deviceId": "{deviceId}",
                  "productType": "TRACKER",
                  "motionLock": "ON"
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  deviceId: '{deviceId}',
                  productType: 'TRACKER',
                  motionLock: 'ON'
                }
              }
            },
            {
              name: 'Motion Sensor - Motion Lock Details',
              description: 'Get motion lock details of motion sensor.',
              APIRoute: resources.motionLockByDeviceId,
              method: deviceRepository.getDeviceMotionLock,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.motionLockByDeviceId
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.motionLockByDeviceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Location History - Create',
              description:
                'Retrieve a list of location histories for a given device id.',
              APIRoute: resources.locationHistoryListByDeviceId,
              method: thingRepository.getLocationHistoryList,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.locationHistoryListByDeviceId
                ),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.locationHistoryListByDeviceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                            `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'IotHub Device Hardware Info',
              description: 'Get hardware information from iothub by device id.',
              APIRoute: resources.hardwareInfoByDeviceId,
              method: thingRepository.getHardwareInfo,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.hardwareInfoByDeviceId
                ),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.hardwareInfoByDeviceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                            `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'Feature Flags',
          apis: [
            {
              name: 'Feature Flag Platform - Details',
              description: 'Get a feature flags for specified platform.',
              APIRoute: resources.featureFlag,
              method: userRepository.getFeatureFlag,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.featureFlag),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.featureFlag
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'platform', type: 'string', required: true }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    Id: 1,
                    Name: '{Name}',
                    Platform: 'IOS',
                    UserId: '{UserId}',
                    Value: 50,
                    Version: 'Beta'
                  }
                ]
              }
            },
            {
              name: 'Feature Flag - Details',
              description: 'Gets feature flag by feature flag name.',
              APIRoute: resources.featureFlagByName,
              method: userRepository.getFeatureFlagByName,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.featureFlagByName
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.featureFlagByName
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'featureflagName',
                    type: 'string',
                    required: true,
                    displayName: 'Feature Flag Name'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  id: 3,
                  name: '{name}',
                  value: 0,
                  platform: '',
                  version: '',
                  lastModifiedBy: 'system',
                  lastModifiedAt: '2022-03-04T02:50:04.956738'
                }
              }
            }
          ]
        },
        {
          name: 'Geofences',
          apis: [
            {
              name: 'Geofences - List',
              description: 'Get all geofences for current user.',
              APIRoute: resources.driveGeofences,
              method: userRepository.getUserSafeZones,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveGeofences),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveGeofences
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'id',
                    type: 'string',
                    required: true,
                    displayName: 'User Id'
                  }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    id: 1,
                    name: 'Test No Address',
                    latitude: 47.57851,
                    longitude: -122.16532,
                    radius: 1000.0,
                    address: '',
                    isEnabled: true,
                    createdBy: '{createdBy}',
                    createdAt: '2021-12-22T15:52:02.358486',
                    lastModifiedBy: '{lastModifiedBy}',
                    lastModifiedAt: '2021-12-22T15:52:14.579928',
                    thingIds: [4]
                  }
                ]
              }
            },
            {
              name: 'Geofences - Create',
              description: 'Create a geofence.',
              APIRoute: resources.driveGeofences,
              method: userRepository.createUserSafeZones,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveGeofences),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveGeofences
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{  Name: '{name}',
                Address: '3625 132nd Ave SE, Bellevue, WA 98006',
                Latitude: 47.57851,
                Longitude: -122.16532,
                Radius: 1000}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  Name: '{name}',
                  Address: '3625 132nd Ave SE, Bellevue, WA 98006',
                  Latitude: 47.57851,
                  Longitude: -122.16532,
                  Radius: 1000
                }
              }
            },
            {
              name: 'Geofences - Things',
              description:
                'Add associations for a set of things to a geofence.',
              APIRoute: resources.driveThingsGeofenceById,
              method: userRepository.setSafeZoneThings,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveThingsGeofenceById
                ),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platformDrive,
                  resources.driveThingsGeofenceById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ [
      {
          "geofenceId": {{geofenceId}},
          "thingId": {{thingId}},
          "allowPushOnEntry": true,
          "allowPushOnExit": true
      }
  ]}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'geofenceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: [
                  {
                    geofenceId: '{{geofenceId}}',
                    thingId: '{{thingId}}',
                    allowPushOnEntry: true,
                    allowPushOnExit: true
                  }
                ]
              }
            },
            {
              name: 'Geofence - Delete Thing',
              description: 'Remove association to a thing from a geofence.',
              APIRoute: resources.driveGeofenceByThingId,
              method: userRepository.deleteSafeZoneThings,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveGeofenceByThingId
                ),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platformDrive,
                  resources.driveGeofenceByThingId
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'geofenceId',
                    type: 'int',
                    required: true
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Geofence - Delete',
              description: 'Delete a geofence.',
              APIRoute:
                product === APP_MOBILE.DRIVE
                  ? resources.driveGeofenceById
                  : resources.geofencesByGeofenceId,
              method: userRepository.deleteSafeZone,
              methodType: httpMethods.DELETE,
              authorizedProducts: [
                APP_MOBILE.DRIVE,
                APP_MOBILE.WATCH,
                APP_MOBILE.TRACKER
              ],
              apiDetails: {
                url:
                  product === APP_MOBILE.DRIVE
                    ? getRoute(
                        basePath.platformDrive,
                        resources.driveGeofenceById
                      )
                    : getRoute(
                        basePath.platform,
                        resources.geofencesByGeofenceId
                      ),
                curlRequest:
                  product === APP_MOBILE.DRIVE
                    ? `curl -v -X DELETE "${getRoute(
                        basePath.platformDrive,
                        resources.driveGeofenceById
                      )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`
                    : `curl -v -X DELETE "${getRoute(
                        basePath.platform,
                        resources.geofencesByGeofenceId
                      )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'geofenceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Geofence - List By Device Id',
              description: 'Retrieve a list of geofence by device id.',
              APIRoute: resources.geofencesByDeviceId,
              method: userRepository.getSafeZonesByDeviceId,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.geofencesByDeviceId),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.geofencesByDeviceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Geofence - Create',
              description: 'Create a geofence.',
              APIRoute: resources.geofences,
              method: userRepository.createUserSafeZones,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.geofences),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platform,
                  resources.geofences
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "status": "ACTIVE",
                  "name": "test notification",
                  "product": "{product_Name}}",
                  "deviceIds": [
                    "string"
                  ],
                  "thingIds": [
                    "string"
                  ],
                  "geofence": {
                    "shape": "CIRCLE",
                    "point": {
                      "longitude": 30,
                      "latitude": 30
                    },
                    "radius": 100
                  },
                  "edgeGeofenceDetectionConfig": {
                    "enabled": true,
                    "insideGeofenceEnablesSilentMode": true,
                    "recurringSchedule": {
                      "sunday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "monday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "tuesday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "wednesday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "thursday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "friday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      }
                    }
                  },
                  "timeZone": "Africa/Cairo",
                  "placeDisplayZoomLevel": 12.3,
                  "placeName": "school",
                  "placeDescription": "school description",
                  "active": true
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  status: 'ACTIVE',
                  name: 'test notification',
                  product: '{product_Name}}',
                  deviceIds: ['string'],
                  thingIds: ['string'],
                  geofence: {
                    shape: 'CIRCLE',
                    point: {
                      longitude: 30,
                      latitude: 30
                    },
                    radius: 100
                  },
                  edgeGeofenceDetectionConfig: {
                    enabled: true,
                    insideGeofenceEnablesSilentMode: true,
                    recurringSchedule: {
                      sunday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      monday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      tuesday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      wednesday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      thursday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      friday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      }
                    }
                  },
                  timeZone: 'Africa/Cairo',
                  placeDisplayZoomLevel: 12.3,
                  placeName: 'school',
                  placeDescription: 'school description',
                  active: true
                }
              }
            },
            {
              name: 'Geofence - Update',
              description: 'Update geofence by geofence id.',
              APIRoute: resources.geofencesByGeofenceId,
              method: userRepository.updateSafeZone,
              methodType: httpMethods.PUT,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.geofencesByGeofenceId
                ),
                curlRequest: `curl -v -X PUT "${getRoute(
                  basePath.platform,
                  resources.geofencesByGeofenceId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
                  "status": "ACTIVE",
                  "name": "test notification",
                  "product": "{product_Name}}",
                  "deviceIds": [
                    "string"
                  ],
                  "thingIds": [
                    "string"
                  ],
                  "geofence": {
                    "shape": "CIRCLE",
                    "point": {
                      "longitude": 30,
                      "latitude": 30
                    },
                    "radius": 100
                  },
                  "edgeGeofenceDetectionConfig": {
                    "enabled": true,
                    "insideGeofenceEnablesSilentMode": true,
                    "recurringSchedule": {
                      "sunday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "monday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "tuesday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "wednesday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "thursday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      },
                      "friday": {
                        "startTime": "2023-02-16",
                        "duration": 3600
                      }
                    }
                  },
                  "timeZone": "Africa/Cairo",
                  "placeDisplayZoomLevel": 12.3,
                  "placeName": "school",
                  "placeDescription": "school description",
                  "active": true
                }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'geofenceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  status: 'ACTIVE',
                  name: 'test notification',
                  product: '{product_Name}}',
                  deviceIds: ['string'],
                  thingIds: ['string'],
                  geofence: {
                    shape: 'CIRCLE',
                    point: {
                      longitude: 30,
                      latitude: 30
                    },
                    radius: 100
                  },
                  edgeGeofenceDetectionConfig: {
                    enabled: true,
                    insideGeofenceEnablesSilentMode: true,
                    recurringSchedule: {
                      sunday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      monday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      tuesday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      wednesday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      thursday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      },
                      friday: {
                        startTime: '2023-02-16',
                        duration: 3600
                      }
                    }
                  },
                  timeZone: 'Africa/Cairo',
                  placeDisplayZoomLevel: 12.3,
                  placeName: 'school',
                  placeDescription: 'school description',
                  active: true
                }
              }
            }
          ]
        },
        {
          name: 'Items',
          apis: [
            {
              name: 'Current User - Items',
              description:
                'Get all claimed devices and things for current user.',
              APIRoute: resources.driveItems,
              method: thingRepository.getItems,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveItems),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveItems
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  devices: [
                    {
                      id: '{id}',
                      model: 'SD 7000T',
                      manufacturer: 'MDI',
                      mcuFirmwareVersion: '1.0',
                      modemFirmwareVersion: '1.0',
                      iccId: 'SAMPLE_ICCID',
                      msisdn: 'SAMPLE_MSISDN',
                      configuration: 'UNKNOWN',
                      name: 'New Car',
                      decommissionedBy: null,
                      decommissionedAt: null,
                      claimedBy: '{claimedBy}',
                      claimedAt: '2022-02-10T17:19:38.712863',
                      lastConnectedAt: '2022-02-10T21:50:28.511504',
                      status:
                        'UNCLAIMED|CLAIMED|ACTIVE|UNPLUGGED|OFFLINE|ACTIVE',
                      statusAt: '2022-02-10T21:56:45.875938',
                      roadsideStatus: 'ENROLLED|NOT_ENROLLED',
                      roadsideStatusAt: '2022-02-10T16:13:31.569018',
                      wifiSsid: '**********',
                      wifiPassword: '********',
                      wifiDisabled: false,
                      wifiDisabledAt: null,
                      wifiStatus: 'OFFLINE|ONLINE',
                      wifiStatusAt: '2022-02-10T21:56:45.875938',
                      wifiDeviceUpdated: true,
                      wifiDeviceRequestId: null,
                      wifiAdminAt: null,
                      simulatorType: 'NONE|MANUAL|AUTO'
                    }
                  ],
                  things: [
                    {
                      id: 11,
                      name: 'Temporary',
                      createdBy: 'guy.saenger',
                      createdAt: '2021-07-02T22:50:50.061678',
                      lastModifiedBy: 'system',
                      lastModifiedAt: '2021-07-02T22:50:50.061678',
                      onboardStatus:
                        'NEEDS_TELEMETRY|NEEDS_PERSONALIZATION|ONBOARDED',
                      onboardAt: '2021-08-03T14:45:22.200783',
                      lastKnownLatitude: 26.1978092,
                      lastKnownLongitude: 74.1485552,
                      lastKnownAddress:
                        'Unnamed Road,Boontiwas,RJ,India,306102',
                      lastKnownAt: '2021-08-03T14:46:55.020476',
                      lastKnownAtText: '3 hours ago',
                      device: {
                        id: '357881103808947',
                        model: 'SD 7000T',
                        manufacturer: 'MDI',
                        mcuFirmwareVersion: '1.0',
                        modemFirmwareVersion: '1.0',
                        iccId: 'SAMPLE_ICCID',
                        msisdn: 'SAMPLE_MSISDN',
                        configuration: 'UNKNOWN',
                        name: 'New Car',
                        decommissionedBy: null,
                        decommissionedAt: null,
                        claimedBy:
                          'U:test:47b4f6a9-079f-4134-96a1-389ab5190036',
                        claimedAt: '2022-02-10T17:19:38.712863',
                        lastConnectedAt: '2022-02-10T21:50:28.511504',
                        status:
                          'UNCLAIMED|CLAIMED|ACTIVE|UNPLUGGED|OFFLINE|ACTIVE',
                        statusAt: '2022-02-10T21:56:45.875938',
                        roadsideStatus: 'ENROLLED|NOT_ENROLLED',
                        roadsideStatusAt: '2022-02-10T16:13:31.569018',
                        wifiSsid: '**********',
                        wifiPassword: '*********',
                        wifiDisabled: false,
                        wifiDisabledAt: null,
                        wifiStatus: 'OFFLINE|ONLINE',
                        wifiStatusAt: '2022-02-10T21:56:45.875938',
                        wifiDeviceUpdated: true,
                        wifiDeviceRequestId: null,
                        wifiAdminAt: null,
                        simulatorType: 'NONE|MANUAL|AUTO'
                      },
                      vehicle: {
                        year: 2021,
                        make: 'Honda',
                        model: 'Accord',
                        engine: '2.0L I4 Turbo 252hp',
                        horsepower: '252hp',
                        fuelGrade: 0,
                        colorName: 'white',
                        colorHex: '#FFFFFF',
                        vin: '{vin}',
                        vinReceivedAt: '2021-08-24T16:17:08.603123',
                        licensePlateState: 'TX',
                        licensePlateNumber: 'abceasyas1',
                        imageUrl: 'http://www.images.com',
                        personalizationAt: '2021-08-24T16:17:25.410127',
                        vehicleStatus:
                          'UNPLUGGED|OFFLINE|PARKED|TRIP_IN_PROGRESS|TOW|CRASH',
                        vehicleStatusAt: '2021-08-24T16:19:23.307296',
                        odometerReading: 25000,
                        odometerReadingAt: '2021-08-24T16:19:23.307296',
                        fuelLevel: 12.5,
                        fuelStatus: 'UNKNOWN|FULL|LOW|VERY_LOW',
                        fuelStatusAt: '2021-08-24T16:20:42.120094',
                        batteryLevel: 10000.0,
                        batteryStatus:
                          'UNKNOWN|HEALTHY|LOW_VOLTAGE|DRAINING|UNHEALTHY|EXTREMELY_UNHEALTHY',
                        batteryStatusAt: '2021-08-24T16:19:27.604556',
                        engineHealthDtc: 'P-205,P-209',
                        engineHealthDtcInfo: [
                          {
                            code: 'P-205',
                            severity: 'UNKNOWN',
                            title: 'DTC Title - Code P-205',
                            description: null,
                            nextSteps: null,
                            actions: null
                          },
                          {
                            code: 'P-209',
                            severity: 'UNKNOWN',
                            title: 'DTC Title - Code P-209',
                            description: null,
                            nextSteps: null,
                            actions: null
                          }
                        ],
                        engineHealthStatus:
                          'UNKNOWN|NO_PROBLEMS|LOW_SEVERITY_PROBLEMS|MEDIUM_HIGH_SEVERITY_PROBLEMS|UNKNOWN_SEVERITY_PROBLEMS',
                        engineHealthStatusAt: '2021-08-24T16:17:05.077206',
                        currentTrip: {
                          id: 1,
                          thingId: 11,
                          startedLocationLatitude: 26.1978092,
                          startedLocationLongitude: 74.1485552,
                          startedAddress:
                            ' Unnamed Road ,Boontiwas,RJ,India,306102',
                          endedLocationLatitude: null,
                          endedLocationLongitude: null,
                          endedAddress: null,
                          startedAt: '2021-07-07T18:50:06.8255',
                          endedAt: null,
                          distance: 2,
                          speed: 0,
                          avgSpeed: 60,
                          maxSpeed: 123,
                          fuelEfficiency: 22.2,
                          seatbeltStatusPassenger: false,
                          seatbeltStatusDriver: false,
                          seatbeltPassengerWeight: false,
                          isBusiness: false,
                          isRead: false,
                          lastModifiedBy: 'system',
                          lastModifiedAt: '2021-08-03T16:35:25.719665',
                          polylineEncoded: '',
                          tripEvents: [
                            {
                              id: 1,
                              thingId: 11,
                              tripId: 1,
                              eventType: 'trip_start',
                              eventLocationLatitude: 26.1978092,
                              eventLocationLongitude: 74.1485552,
                              eventAddress:
                                ' Unnamed Road ,Boontiwas,RJ,India,306102',
                              eventAt: '2021-08-03T14:46:55.020476',
                              isRead: false
                            }
                          ]
                        }
                      }
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          name: 'Localization',
          apis: [
            {
              name: 'Languages - List',
              description: 'Get localization file for all languages.',
              APIRoute: resources.localization,
              method: userRepository.getLocalization,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.localization),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.localization
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },

                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    main_title: 'SyncUP Drive',
                    introduction: 'Welcome!',
                    terms:
                      'Please read the terms and conditions and then choose accept'
                  }
                ]
              }
            },
            {
              name: 'Language - Details',
              description: 'Get localization file for specified language.',
              APIRoute: resources.specificLocalization,
              method: userRepository.getLocalizationByLng,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.specificLocalization
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.specificLocalization
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'language', type: 'string', required: true }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },

                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    main_title: 'SyncUP Drive',
                    introduction: 'Welcome!',
                    terms:
                      'Please read the terms and conditions and then choose accept'
                  }
                ]
              }
            }
          ]
        },
        {
          name: 'Notification',
          apis: [
            {
              name: 'Notification Settings - Details',
              description: 'Get user notification settings for a thing.',
              APIRoute: resources.notificationSettings,
              method: userRepository.getNotificationSetting,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.notificationSettings
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.notificationSettings
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'thingId', type: 'int', required: true }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },

                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    userId: '{userId}',
                    thingId: '{thingId}',
                    notificationTypeKey: '{notificationTypeKey}',
                    allowPush: false,
                    allowEmail: false,
                    allowSMS: false,
                    lastModifiedBy: 'system',
                    lastModifiedAt: '2021-07-13T13:55:05.227246'
                  }
                ]
              }
            },
            {
              name: 'Push Notification - Register ',
              description: 'Register user device token for push notifications.',
              APIRoute: resources.drivePushNotificaiton,
              method: userRepository.postDrivePushNotificaiton,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDriveV2,
                  resources.drivePushNotificaiton
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDriveV2,
                  resources.drivePushNotificaiton
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{  product: 'DRIVE',
            platform: 'ANDROID',
            bundleId: '{bundleId}',
            applicationVersion: '1.2.0',
            installationId: '{installationId}',
            deviceToken:
              '{deviceToken}',
            advertisingIdentifier: '00000000-0000-0000-0000-000000000000',
            deviceIdentifierForVendor:
              '00000000-0000-0000-0000-000000000000',
            apnsEnvironment: 'PRODUCTION'}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  product: 'DRIVE',
                  platform: 'ANDROID',
                  bundleId: '{bundleId}',
                  applicationVersion: '1.2.0',
                  installationId: '{installationId}',
                  deviceToken: '{deviceToken}',
                  advertisingIdentifier: '00000000-0000-0000-0000-000000000000',
                  deviceIdentifierForVendor:
                    '00000000-0000-0000-0000-000000000000',
                  apnsEnvironment: 'PRODUCTION'
                }
              }
            }
          ]
        },
        {
          name: 'Roadside',
          apis: [
            {
              name: 'Device - Eligibility',
              description: 'Get roadside device eligibility details.',
              APIRoute: resources.driveRoadsideEligibility,
              method: thingRepository.getRoadsideEligibility,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideEligibility
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideEligibility
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'deviceId', type: 'string', required: true }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },

                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  deviceId: '{deviceId}',
                  userId: '{userId}',
                  isEligible: false,
                  claimLimit: null,
                  claimRemaining: null,
                  status: 0
                }
              }
            },
            {
              name: 'Device - Enrollment',
              description: 'Get roadside device enrollment details.',
              APIRoute: resources.driveRoadsideEnrollment,
              method: thingRepository.getRoadsideEnrollment,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideEnrollment
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideEnrollment
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'deviceId', type: 'string', required: true }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },

                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  id: '{id}',
                  model: 'SD 7000T',
                  mcuFirmwareVersion: '1.0',
                  modemFirmwareVersion: '1.0',
                  iccId: '{iccId}',
                  name: 'F-150',
                  decommissionedBy: null,
                  decommissionedAt: null,
                  claimedBy: '{claimedBy}',
                  claimedAt: '2022-01-08T20:14:44.985441',
                  lastConnectedAt: null,
                  status: 'CLAIMED',
                  statusAt: '2022-01-08T20:14:44.985441',
                  roadsideStatus: 1,
                  roadsideStatusAt: '2022-01-14T18:49:04.273247',
                  simulatorType: 'NONE'
                }
              }
            },
            {
              name: 'Service - List',
              description: 'Get all roadside service details for a thing.',
              APIRoute: resources.driveRoadsideServices,
              method: thingRepository.getRoadsideServices,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServices
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServices
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'thingId', type: 'int', required: true }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },

                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    id: 0,
                    thingId: 0,
                    serviceType: 'TOW|BATTERY|FUEL|TIRE|LOCKOUT',
                    status:
                      'PENDING|MATCHING|MATCHED|LIMIT_EXCEEDED|ENROUTE|ARRIVED|COMPLETED|CANCELLED',
                    statusAt: '2021-08-17T19:46:20.876743',
                    latitude: -71.09102,
                    longitude: 42.35042,
                    notes: 'test',
                    matchingAt: null,
                    matchedAt: null,
                    enrouteAt: null,
                    arrivedAt: null,
                    completedAt: null,
                    serviceProviderName: null,
                    serviceProviderPhone: null,
                    serviceProviderEmail: null,
                    serviceProviderWeb: null,
                    serviceProviderTechnicianName: null,
                    serviceProviderCost: 0.0,
                    serviceProviderStatus: -1,
                    serviceProviderStatusAt: null,
                    serviceProviderEta: null,
                    serviceProviderLatitude: 0.0,
                    serviceProviderLongitude: 0.0,
                    serviceProviderLocationAt: null,
                    createdBy: '{createdBy}',
                    createdAt: '2021-08-17T19:46:20.876743',
                    lastModifiedBy: '{lastModifiedBy}',
                    lastModifiedAt: '2021-08-17T19:46:20.876743',
                    deletedBy: null,
                    deletedAt: null
                  }
                ]
              }
            },
            {
              name: 'Service - Details',
              description: 'Get roadside service details.',
              APIRoute: resources.driveRoadsideServiceById,
              method: thingRepository.getRoadsideServiceById,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServiceById
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServiceById
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'thingId', type: 'int', required: true },
                  { name: 'serviceId', type: 'int', required: true }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },

                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  id: 0,
                  thingId: 0,
                  serviceType: 'TOW|BATTERY|FUEL|TIRE|LOCKOUT',
                  firstName: 'John',
                  lastName: 'Doe',
                  primaryPhoneNumber: '{primaryPhoneNumber}',
                  status:
                    'PENDING|MATCHING|MATCHED|LIMIT_EXCEEDED|ENROUTE|ARRIVED|COMPLETED|CANCELLED',
                  statusAt: '2021-08-17T19:46:20.876743',
                  latitude: -71.09102,
                  longitude: 42.35042,
                  notes: 'test',
                  matchingAt: null,
                  matchedAt: null,
                  enrouteAt: null,
                  arrivedAt: null,
                  completedAt: null,
                  serviceProviderName: null,
                  serviceProviderPhone: null,
                  serviceProviderEmail: null,
                  serviceProviderWeb: null,
                  serviceProviderTechnicianName: null,
                  serviceProviderCost: 0.0,
                  serviceProviderStatus: -1,
                  serviceProviderStatusAt: null,
                  serviceProviderEta: null,
                  serviceProviderLatitude: 0.0,
                  serviceProviderLongitude: 0.0,
                  serviceProviderLocationAt: null,
                  createdBy: '{createdBy}',
                  createdAt: '2021-08-17T19:46:20.876743',
                  lastModifiedBy: '{lastModifiedBy}',
                  lastModifiedAt: '2021-08-17T19:46:20.876743',
                  deletedBy: null,
                  deletedAt: null
                }
              }
            },
            {
              name: 'Service - Opt In',
              description: 'Opt in to roadside services.',
              APIRoute: resources.driveRoadsideOptIn,
              method: thingRepository.optInRoadsideService,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideOptIn
                ),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideOptIn
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key} "`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  userProfile: {
                    userId: '{userId}',
                    firstName: 'arpan',
                    lastName: 'chauhan',
                    email: 'test@test.com',
                    msisdns: ['{msisdns}'],
                    demoUser: false,
                    createdDateTime: '2022-07-13T13:05:43.117047',
                    modifiedDateTime: '2022-07-13T13:30:24.107707'
                  },
                  applicationProfile: {
                    product: 'DRIVE',
                    userId: '{userId}',
                    createdDateTime: '2022-07-13T13:05:43.445101',
                    modifiedDateTime: '2023-02-15T10:48:05.964541',
                    preferences: {
                      pushNotificationsEnabled: true,
                      emailNotificationsEnabled: true,
                      smsNotificationsEnabled: true
                    },
                    terms: {
                      version: 5,
                      type: 'TERMS',
                      timestamp: '2022-07-13T13:30:24.138973'
                    },
                    privacy: null,
                    data: null,
                    locationPrivacy: null,
                    roadsideEnrollment: {
                      version: 1,
                      type: 'ROADSIDE',
                      timestamp: '2023-02-15T10:48:05.964541'
                    },
                    icn: null,
                    locale: {
                      language: 'en',
                      country: 'US',
                      unitPreference: 'US',
                      timeZone: 'America/Los_Angeles'
                    },
                    location: null,
                    isReopenedAccount: false,
                    platform: null,
                    applicationVersion: null
                  }
                }
              }
            },
            {
              name: 'Device - Enroll',
              description: 'Enroll device for roadside assistance.',
              APIRoute: resources.driveRoadsideEnrollment,
              method: thingRepository.addRoadsideEnrollment,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideEnrollment
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideEnrollment
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Device - Unenroll',
              description: 'Unenroll device for roadside assistance.',
              APIRoute: resources.driveRoadsideEnrollment,
              method: thingRepository.deleteRoadsideEnrollment,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideEnrollment
                ),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideEnrollment
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
            "`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'deviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Service - Create',
              description: 'Create new roadside service request.',
              APIRoute: resources.driveRoadsideServices,
              method: thingRepository.addRoadsideServices,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServices
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServices
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{FirstName: 'John',
            LastName: 'Doe',
            PrimaryPhoneNumber: '{PrimaryPhoneNumber}',
            ServiceType: 'TOW',
            Notes: 'test',
            TroubleCode: 'T6',
            TroubleCodeDescription: 'TTTTT',
            Latitude: 0.0,
            Longitude: 0.0,
            DestinationLatitude: 0.0,
            DestinationLongitude: 0.0,
            TowDestinationName: 'Lakemary Shell Repair Center',
            BackupPhoneNumber: '{BackupPhoneNumber}',
            AllowTextMessageUpdates: true },
              product: 'DRIVE'
            } }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  FirstName: 'John',
                  LastName: 'Doe',
                  PrimaryPhoneNumber: '{PrimaryPhoneNumber}',
                  ServiceType: 'TOW',
                  Notes: 'test',
                  TroubleCode: 'T6',
                  TroubleCodeDescription: 'TTTTT',
                  Latitude: 38.897863,
                  Longitude: -77.0387679,
                  DestinationLatitude: 38.897163,
                  DestinationLongitude: -77.0387579,
                  TowDestinationName: 'Lakemary Shell Repair Center',
                  BackupPhoneNumber: '{BackupPhoneNumber}',
                  AllowTextMessageUpdates: true
                }
              }
            },
            {
              name: 'Service - Delete',
              description: 'Cancel a roadside service request.',
              APIRoute: resources.driveRoadsideServiceById,
              method: thingRepository.deleteRoadsideServices,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServiceById
                ),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServiceById
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'serviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Service - Update',
              description: 'Update roadside service request.',
              APIRoute: resources.driveRoadsideServiceById,
              method: thingRepository.updateRoadsideServices,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServiceById
                ),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServiceById
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ notes: 'some notes' }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'serviceId',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  notes: 'some notes'
                },
                responseStructure: {
                  id: 0,
                  thingId: 0,
                  serviceType: 'TOW',
                  status: 'MATCHING',
                  statuses: null,
                  statusAt: '0001-01-01T00:00:00',
                  updateCode: '',
                  firstName: 'John',
                  lastName: 'Doe',
                  primaryPhoneNumber: '{primaryPhoneNumber}',
                  callKey: '{callKey}',
                  latitude: 47.86421,
                  longitude: -122.28846,
                  destinationLatitude: 38.897163,
                  destinationLongitude: -77.0387579,
                  notes: 'some notes',
                  createdBy: '{createdBy}',
                  createdAt: '2023-02-15T10:49:05.402679',
                  lastModifiedBy: '{lastModifiedBy}',
                  lastModifiedAt: '2023-02-16T11:12:39.647488',
                  deletedBy: null,
                  deletedAt: null,
                  matchingAt: null,
                  matchedAt: null,
                  enrouteAt: null,
                  arrivedAt: null,
                  completedAt: null,
                  cancelledAt: null,
                  serviceProviderName: null,
                  serviceProviderPhone: null,
                  serviceProviderEmail: null,
                  serviceProviderWeb: null,
                  serviceProviderToken: null,
                  serviceProviderTechnicianName: null,
                  serviceProviderCost: null,
                  serviceProviderEta: '2023-02-15T11:49:05.262',
                  serviceProviderPta: '2023-02-15T11:49:05.262',
                  serviceProviderLatitude: null,
                  serviceProviderLongitude: null,
                  serviceProviderLocationAt: null
                }
              }
            },
            {
              name: 'Service - Feedback',
              description: 'Add feedback for roadside service.',
              APIRoute: resources.driveRoadsideFeedback,
              method: thingRepository.postRoadsideServiceFeedback,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideFeedback
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideFeedback
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ feedback: 'some feedback' }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'serviceId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  feedback: 'some feedback'
                }
              }
            },
            {
              name: 'Service - Feedback Update',
              description: 'Update feedback for roadside service.',
              APIRoute: resources.driveRoadsideFeedbackById,
              method: thingRepository.updateRoadSideServiceFeedback,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideFeedbackById
                ),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideFeedbackById
                )}  " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ feedback: 'some feedback revised' }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'serviceId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'feedbackId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  feedback: 'some feedback revised'
                }
              }
            },
            {
              name: 'Service - Search Stations',
              description: 'Search roadside service stations.',
              APIRoute: resources.driveRoadsideStations,
              method: thingRepository.searchDriveServiceStation,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideStations
                ),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideStations
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ latitude:  28.763333,
              longitude: -81.372245,
              radius: 10 }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  latitude: 28.763333,
                  longitude: -81.372245,
                  radius: 10
                }
              }
            },
            {
              name: 'Service - Query PTA',
              description:
                'Get roadside service planned time of arrival details.',
              APIRoute: resources.driveRoadsideServicesPta,
              method: thingRepository.serviceQueryPTA,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServicesPta
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideServicesPta
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ latitude: 38.997666,
              longitude: -77.0387679,
              troubleCodes: [
                {
                  troubleCodeType: 'PROBLEM',
                  code: 'T6',
                  description: 'Tow'
                }
              ] }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  latitude: 38.997666,
                  longitude: -77.0387679,
                  troubleCodes: [
                    {
                      troubleCodeType: 'PROBLEM',
                      code: 'T6',
                      description: 'Tow'
                    }
                  ]
                }
              }
            },
            {
              name: 'Service - Opt Out',
              description: 'Opt out of roadside services.',
              APIRoute: resources.driveRoadsideOptOut,
              method: thingRepository.optOutRoadsideService,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideOptOut
                ),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platformDrive,
                  resources.driveRoadsideOptOut
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: ' Terms',
          apis: [
            {
              name: 'Terms - List',
              description: 'Get all terms available.',
              APIRoute: resources.terms,
              method: userRepository.getTerms,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.terms),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.terms
                )}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },

                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    htmlUrl: '{htmlUrl}',
                    product: 'DRIVE',
                    type: 'TERMS',
                    version: 1,
                    versionDate: '2021-10-11T17:00:04.791402'
                  }
                ]
              }
            }
          ]
        },
        {
          name: 'Things',
          apis: [
            {
              name: 'Things - List',
              description: 'Get all things for the current user.',
              APIRoute: resources.things,
              method: thingRepository.getThingsDrive,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.things),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.things
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'thingId', type: 'int', required: true }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    id: 0,
                    name: 'Temporary',
                    createdBy: '{createdBy}',
                    createdAt: '2021-07-02T22:50:50.061678',
                    lastModifiedBy: '{lastModifiedBy}',
                    lastModifiedAt: '2021-07-02T22:50:50.061678',
                    deletedBy: null,
                    deletedAt: null,
                    onboardStatus:
                      'NEEDS_TELEMETRY|NEEDS_PERSONALIZATION|ONBOARDED',
                    onboardAt: '2021-08-03T14:45:22.200783',
                    mergedInto: null,
                    lastKnownLatitude: 26.1978092,
                    lastKnownLongitude: 74.1485552,
                    lastKnownAddress: 'Unnamed Road,Boontiwas,RJ,India,306102',
                    lastKnownAt: '2021-08-03T14:46:55.020476',
                    lastKnownAtText: '3 hours ago',
                    isBusinessTrip: null,
                    device: {
                      id: '{id}',
                      model: 'SD 7000T',
                      manufacturer: 'MDI',
                      mcuFirmwareVersion: '1.0',
                      modemFirmwareVersion: '1.0',
                      iccId: 'SAMPLE_ICCID',
                      msisdn: 'SAMPLE_MSISDN',
                      configuration: 'UNKNOWN',
                      name: 'New Car',
                      decommissionedBy: null,
                      decommissionedAt: null,
                      claimedBy: '{claimedBy}',
                      claimedAt: '2022-02-10T17:19:38.712863',
                      lastConnectedAt: '2022-02-10T21:50:28.511504',
                      status:
                        'UNCLAIMED|CLAIMED|ACTIVE|UNPLUGGED|OFFLINE|ACTIVE',
                      statusAt: '2022-02-10T21:56:45.875938',
                      roadsideStatus: 'ENROLLED|NOT_ENROLLED',
                      roadsideStatusAt: '2022-02-10T16:13:31.569018',
                      wifiSsid: '**********',
                      wifiPassword: '*********',
                      wifiDisabled: false,
                      wifiDisabledAt: null,
                      wifiStatus: 'OFFLINE|ONLINE',
                      wifiStatusAt: '2022-02-10T21:56:45.875938',
                      wifiDeviceUpdated: true,
                      wifiDeviceRequestId: null,
                      wifiAdminAt: null,
                      simulatorType: 'NONE|MANUAL|AUTO'
                    },
                    vehicle: {
                      year: 2021,
                      make: 'Honda',
                      model: 'Accord',
                      engine: '2.0L I4 Turbo 252hp',
                      horsepower: '252hp',
                      fuelGrade: 0,
                      colorName: 'white',
                      colorHex: '#FFFFFF',
                      vin: 'ABCDEFGHIJKLMNOPQ',
                      vinReceivedAt: '2021-08-24T16:17:08.603123',
                      licensePlateState: 'TX',
                      licensePlateNumber: '{licensePlateNumber}',
                      imageUrl: '{imageUrl}',
                      personalizationAt: '2021-08-24T16:17:25.410127',
                      vehicleStatus:
                        'UNPLUGGED|OFFLINE|PARKED|TRIP_IN_PROGRESS|TOW|CRASH',
                      vehicleStatusAt: '2021-08-24T16:19:23.307296',
                      odometerReading: 25000,
                      odometerReadingAt: '2021-08-24T16:19:23.307296',
                      fuelLevel: 12.5,
                      fuelStatus: 'UNKNOWN|FULL|LOW|VERY_LOW',
                      fuelStatusAt: '2021-08-24T16:20:42.120094',
                      batteryLevel: 10000.0,
                      batteryStatus:
                        'UNKNOWN|HEALTHY|LOW_VOLTAGE|DRAINING|UNHEALTHY|EXTREMELY_UNHEALTHY',
                      batteryStatusAt: '2021-08-24T16:19:27.604556',
                      engineHealthDtc: 'P-205,P-209',
                      engineHealthDtcInfo: [
                        {
                          code: 'P-205',
                          severity: 'UNKNOWN',
                          title: 'DTC Title - Code P-205',
                          description: null,
                          nextSteps: null,
                          actions: null
                        }
                      ],
                      engineHealthStatus:
                        'UNKNOWN|NO_PROBLEMS|LOW_SEVERITY_PROBLEMS|MEDIUM_HIGH_SEVERITY_PROBLEMS|UNKNOWN_SEVERITY_PROBLEMS',
                      engineHealthStatusAt: '2021-08-24T16:17:05.077206',
                      currentTrip: {
                        id: 0,
                        thingId: 0,
                        startedLocationLatitude: 26.1978092,
                        startedLocationLongitude: 74.1485552,
                        startedAddress:
                          ' Unnamed Road ,Boontiwas,RJ,India,306102',
                        endedLocationLatitude: null,
                        endedLocationLongitude: null,
                        endedAddress: null,
                        startedAt: '2021-07-07T18:50:06.8255',
                        endedAt: null,
                        distance: 2,
                        speed: 0,
                        avgSpeed: 60,
                        maxSpeed: 123,
                        fuelEfficiency: 22.2,
                        seatbeltStatusPassenger: false,
                        seatbeltStatusDriver: false,
                        seatbeltPassengerWeight: false,
                        isBusiness: false,
                        isRead: false,
                        lastModifiedBy: '{lastModifiedBy}',
                        lastModifiedAt: '2021-08-03T16:35:25.719665',
                        polylineEncoded: 'iw{~CocqcM',
                        tripEvents: [
                          {
                            id: 0,
                            thingId: 0,
                            tripId: 0,
                            eventType: 'trip_start',
                            eventLocationLatitude: 26.1978092,
                            eventLocationLongitude: 74.1485552,
                            eventAddress:
                              ' Unnamed Road ,Boontiwas,RJ,India,306102',
                            eventAt: '2021-08-03T14:46:55.020476',
                            isRead: false
                          }
                        ]
                      }
                    }
                  }
                ]
              }
            },
            {
              name: 'Things - Details',
              description: 'Get a details by thing id.',
              APIRoute: resources.thingById,
              method: thingRepository.getThingById,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.thingById),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.thingById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'thingId', type: 'int', required: true }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  id: 0,
                  name: 'Temporary',
                  createdBy: '{createdBy}',
                  createdAt: '2021-07-02T22:50:50.061678',
                  lastModifiedBy: '{lastModifiedBy}',
                  lastModifiedAt: '2021-07-02T22:50:50.061678',
                  deletedBy: null,
                  deletedAt: null,
                  onboardStatus:
                    'NEEDS_TELEMETRY|NEEDS_PERSONALIZATION|ONBOARDED',
                  onboardAt: '2021-08-03T14:45:22.200783',
                  mergedInto: null,
                  lastKnownLatitude: 26.1978092,
                  lastKnownLongitude: 74.1485552,
                  lastKnownAddress: 'Unnamed Road,Boontiwas,RJ,India,306102',
                  lastKnownAt: '2021-08-03T14:46:55.020476',
                  lastKnownAtText: '3 hours ago',
                  isBusinessTrip: null,
                  device: {
                    id: '{deviceId}',
                    model: 'SD 7000T',
                    manufacturer: 'MDI',
                    mcuFirmwareVersion: '1.0',
                    modemFirmwareVersion: '1.0',
                    iccId: 'SAMPLE_ICCID',
                    msisdn: 'SAMPLE_MSISDN',
                    configuration: 'UNKNOWN',
                    name: 'New Car',
                    decommissionedBy: null,
                    decommissionedAt: null,
                    claimedBy: '{claimedBy}',
                    claimedAt: '2022-02-10T17:19:38.712863',
                    lastConnectedAt: '2022-02-10T21:50:28.511504',
                    status: 'UNCLAIMED|CLAIMED|ACTIVE|UNPLUGGED|OFFLINE|ACTIVE',
                    statusAt: '2022-02-10T21:56:45.875938',
                    roadsideStatus: 'ENROLLED|NOT_ENROLLED',
                    roadsideStatusAt: '2022-02-10T16:13:31.569018',
                    wifiSsid: '**********',
                    wifiPassword: '********',
                    wifiDisabled: false,
                    wifiDisabledAt: null,
                    wifiStatus: 'OFFLINE|ONLINE',
                    wifiStatusAt: '2022-02-10T21:56:45.875938',
                    wifiDeviceUpdated: true,
                    wifiDeviceRequestId: null,
                    wifiAdminAt: null,
                    simulatorType: 'NONE|MANUAL|AUTO'
                  },
                  vehicle: {
                    year: 2021,
                    make: 'Honda',
                    model: 'Accord',
                    engine: '2.0L I4 Turbo 252hp',
                    horsepower: '252hp',
                    fuelGrade: 0,
                    colorName: 'white',
                    colorHex: '#FFFFFF',
                    vin: '{vin}',
                    vinReceivedAt: '2021-08-24T16:17:08.603123',
                    licensePlateState: 'TX',
                    licensePlateNumber: '{licensePlateNumber}',
                    imageUrl: '{imageUrl}',
                    personalizationAt: '2021-08-24T16:17:25.410127',
                    vehicleStatus:
                      'UNPLUGGED|OFFLINE|PARKED|TRIP_IN_PROGRESS|TOW|CRASH',
                    vehicleStatusAt: '2021-08-24T16:19:23.307296',
                    odometerReading: 25000,
                    odometerReadingAt: '2021-08-24T16:19:23.307296',
                    fuelLevel: 12.5,
                    fuelStatus: 'UNKNOWN|FULL|LOW|VERY_LOW',
                    fuelStatusAt: '2021-08-24T16:20:42.120094',
                    batteryLevel: 10000.0,
                    batteryStatus:
                      'UNKNOWN|HEALTHY|LOW_VOLTAGE|DRAINING|UNHEALTHY|EXTREMELY_UNHEALTHY',
                    batteryStatusAt: '2021-08-24T16:19:27.604556',
                    engineHealthDtc: 'P-205,P-209',
                    engineHealthDtcInfo: [
                      {
                        code: 'P-205',
                        severity: 'UNKNOWN',
                        title: 'DTC Title - Code P-205',
                        description: null,
                        nextSteps: null,
                        actions: null
                      },
                      {
                        code: 'P-209',
                        severity: 'UNKNOWN',
                        title: 'DTC Title - Code P-209',
                        description: null,
                        nextSteps: null,
                        actions: null
                      }
                    ],
                    engineHealthStatus:
                      'UNKNOWN|NO_PROBLEMS|LOW_SEVERITY_PROBLEMS|MEDIUM_HIGH_SEVERITY_PROBLEMS|UNKNOWN_SEVERITY_PROBLEMS',
                    engineHealthStatusAt: '2021-08-24T16:17:05.077206',
                    currentTrip: {
                      id: 1,
                      thingId: 11,
                      startedLocationLatitude: 26.1978092,
                      startedLocationLongitude: 74.1485552,
                      startedAddress:
                        ' Unnamed Road ,Boontiwas,RJ,India,306102',
                      endedLocationLatitude: null,
                      endedLocationLongitude: null,
                      endedAddress: null,
                      startedAt: '2021-07-07T18:50:06.8255',
                      endedAt: null,
                      distance: 2,
                      speed: 0,
                      avgSpeed: 60,
                      maxSpeed: 123,
                      fuelEfficiency: 22.2,
                      seatbeltStatusPassenger: false,
                      seatbeltStatusDriver: false,
                      seatbeltPassengerWeight: false,
                      isBusiness: false,
                      isRead: false,
                      lastModifiedBy: '{lastModifiedBy}',
                      lastModifiedAt: '2021-08-03T16:35:25.719665',
                      polylineEncoded: 'iw{~CocqcM',
                      tripEvents: [
                        {
                          id: 0,
                          thingId: 0,
                          tripId: 0,
                          eventType: 'trip_start',
                          eventLocationLatitude: 26.1978092,
                          eventLocationLongitude: 74.1485552,
                          eventAddress:
                            ' Unnamed Road ,Boontiwas,RJ,India,306102',
                          eventAt: '2021-08-03T14:46:55.020476',
                          isRead: false
                        }
                      ]
                    }
                  }
                }
              }
            },
            {
              name: 'Thing - Update',
              description: 'Update a thing.',
              APIRoute: resources.thingById,
              method: thingRepository.updateDriveThing,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.thingById),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platformDrive,
                  resources.thingById
                )}  " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ name: '{{thingName}}',
            licensePlateNumber: '{{licensePlateNumber}}',
            licensePlateState: 'TX',
            vehicleId: ' {{vehicleId}}',
            vehicleEngineId: '{{vehicleEngineId}}',
            vehicleColorId: '{{vehicleColorId}}',
            odometerReading: 25999,
            isbusinesstrip: true }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  name: '{{thingName}}',
                  licensePlateNumber: '{{licensePlateNumber}}',
                  licensePlateState: 'TX',
                  vehicleId: ' {{vehicleId}}',
                  vehicleEngineId: '{{vehicleEngineId}}',
                  vehicleColorId: '{{vehicleColorId}}',
                  odometerReading: 25999,
                  isbusinesstrip: true
                }
              }
            },
            {
              name: 'Thing - WIFI Update ',
              description: 'Thing update with wifi details.',
              APIRoute: resources.thingById,
              method: thingRepository.updateDriveWifiDetails,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.thingById),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platformDrive,
                  resources.thingById
                )}  " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{wifissid: '**********',
            wifipassword: '********' }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  wifissid: '*********',
                  wifipassword: '*********'
                }
              }
            },
            {
              name: 'Thing - Delete',
              description: 'Soft delete a thing and clear associated device.',
              APIRoute: resources.thingById,
              method: thingRepository.deleteDriveThing,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.thingById),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platformDrive,
                  resources.thingById
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - Merge',
              description: 'Merge things.',
              APIRoute: resources.driveMergeThing,
              method: deviceRepository.mergeThing,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveMergeThing
                ),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platformDrive,
                  resources.driveMergeThing
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" "`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingIdFrom',
                    type: 'int',
                    required: true
                  },
                  {
                    name: 'thingIdInto',
                    type: 'int',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - Sensor History',
              description:
                'Get specific type sensor history for all devices of thing.',
              APIRoute: resources.sensorHistoryByThingId,
              method: thingRepository.getSensorHistoryByThingId,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.sensorHistoryByThingId
                ),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.sensorHistoryByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'sensorType',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - Activity',
              description: 'Retrieve thing activity.',
              APIRoute: resources.activityByThingId,
              method: thingRepository.getActivityByThingId,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: ` ${getRoute(
                  basePath.platform,
                  resources.activityByThingId
                )}start-date-time={startDateTime}&end-date-time={endDateTime}`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.activityByThingId
                )}start-date-time={startDateTime}&end-date-time={endDateTime}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'startDateTime',
                    type: 'string',
                    required: true,
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    type: 'string',
                    required: true,
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange('start-date-time', 'end-date-time'),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - Details',
              description: 'Get a thing by thing id.',
              APIRoute: resources.thingById,
              method: thingRepository.getThingById,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.thingById),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.thingById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Silent Schedules - Thing Id',
              description: 'Get silent schedules for a given thing id.',
              APIRoute: resources.silentSchedulesByThingId,
              method: thingRepository.getSilentSchedulesByThingId,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.silentSchedulesByThingId
                ),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.silentSchedulesByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - List',
              description: 'Get all things.',
              APIRoute: resources.things,
              method: thingRepository.getSharedThingsByUserId,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.things),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.things
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - Twin State',
              description: 'Sync twin state by thing id.',
              APIRoute: resources.syncTwinStateByThingId,
              method: thingRepository.createSyncTwinStateByThingId,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.syncTwinStateByThingId
                ),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.syncTwinStateByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - Link Device',
              description: 'Associate a device to a thing.',
              APIRoute: resources.thingLinkDevice,
              method: thingRepository.linkDevice,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.thingLinkDevice),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.thingLinkDevice
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        -d "{
                          "identifier": "hardwareId",
                          "kind": "REGISTER"
                           }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  identifier: 'hardwareId',
                  kind: 'REGISTER'
                }
              }
            },
            {
              name: 'Thing - Avatar Upload',
              description: 'Upload avatar for a thing.',
              APIRoute: resources.avatarByThingId,
              method: thingRepository.createAvatarByThingId,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.avatarByThingId),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.avatarByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                       `,

                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - Delete',
              description: 'Delete a thing by thing id.',
              APIRoute: resources.thingById,
              method: thingRepository.deleteThingById,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.thingById),
                curlRequest: `curl -v -X DELETE " ${getRoute(
                  basePath.platform,
                  resources.thingById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Thing - Avatar Delete',
              description: 'Delete avatar for a thing.',
              APIRoute: resources.avatarByThingId,
              method: thingRepository.deleteAvatarByThingId,
              methodType: httpMethods.DELETE,
              authorizedProducts: [
                APP_MOBILE.WATCH,
                APP_MOBILE.TRACKER,
                APP_MOBILE.DRIVE
              ],
              apiDetails: {
                url: getRoute(basePath.platform, resources.avatarByThingId),
                curlRequest: `curl -v -X DELETE " ${getRoute(
                  basePath.platform,
                  resources.avatarByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Communication History - List',
              description: 'Get a list of communications count by thing id.',
              APIRoute: resources.communicationCountByThingId,
              method: thingRepository.getCommunicationCount,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.communicationCountByThingId
                )}start-date-time={startDateTime}&end-date-time={endDateTime}`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.communicationCountByThingId
                )}start-date-time={startDateTime}&end-date-time={endDateTime}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'startDateTime',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange('start-date-time', 'end-date-time'),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'Parental Controls',
          apis: [
            {
              name: 'Parental Control - Details',
              description: 'Get a parental control by thing id.',
              APIRoute: resources.parentalControlById,
              method: thingRepository.getThingParentalControl,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.parentalControlById),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.parentalControlById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {}
              }
            },
            {
              name: 'Parental Control - Update',
              description: 'Update parental control by thing id.',
              APIRoute: resources.parentalControlById,
              method: thingRepository.updateParentalControl,
              methodType: httpMethods.PUT,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.parentalControlById),
                curlRequest: `curl -v -X PUT " ${getRoute(
                  basePath.platform,
                  resources.parentalControlById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
    "sos": false,
    "entertainment": false,
    "airplaneMode": false,
    "camera": false,
    "calculator": false,
    "wifi": false
  }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  sos: false,
                  entertainment: false,
                  airplaneMode: false,
                  camera: false,
                  calculator: false,
                  wifi: false
                }
              }
            },
            {
              name: 'Parental Control - Delete',
              description: 'Delete parental control by thing id.',
              APIRoute: resources.parentalControlById,
              method: thingRepository.deleteParentalControl,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.parentalControlById),
                curlRequest: `curl -v -X DELETE " ${getRoute(
                  basePath.platform,
                  resources.parentalControlById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Parental Controls - List',
              description: 'Get a list of parental controls for current user.',
              APIRoute: resources.parentalControl,
              method: thingRepository.getParentalControlList,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.parentalControl),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.parentalControl
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Parental Control - Create',
              description: 'Create parental control for current user.',
              APIRoute: resources.parentalControl,
              method: userRepository.userParentalControl,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.parentalControl),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.parentalControl
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
    "thingId": "{thingId}",
    "sos": true,
    "entertainment": true,
    "airplaneMode": true,
    "camera": true,
    "calculator": true,
    "wifi": true
  }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  thingId: '{thingId}',
                  sos: true,
                  entertainment: true,
                  airplaneMode: true,
                  camera: true,
                  calculator: true,
                  wifi: true
                }
              }
            }
          ]
        },
        {
          name: 'Vehicles',
          apis: [
            {
              name: 'Years - List',
              description: 'Get all vehicle list of years.',
              APIRoute: resources.driveVehiclesYears,
              method: thingRepository.getVehiclesYears,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesYears
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesYears
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [2018, 2019, 2020, 2021]
              }
            },
            {
              name: 'Makes - List',
              description: 'Get all vehicle makes based on year.',
              APIRoute: resources.driveVehiclesMakes,
              method: thingRepository.getVehiclesMakes,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesMakes
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesMakes
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'year',
                    required: true,
                    type: 'int'
                  }
                ],
                responseStructure: ['ACURA', 'AUDI', 'BMW']
              }
            },
            {
              name: 'Models - List',
              description: 'Get all vehicle models based on year and make.',
              APIRoute: resources.driveVehiclesModels,
              method: thingRepository.getVehiclesModels,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesModels
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesModels
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'year',
                    type: 'int',
                    required: true
                  },
                  {
                    name: 'make',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: ['K5', 'SEDONA', 'SELTOS']
              }
            },
            {
              name: 'Vehicles List - Year ',
              description: 'Get all vehicles list by year.',
              APIRoute: resources.driveVehiclesByYear,
              method: thingRepository.getVehiclesByYear,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesByYear
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesByYear
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'year',
                    required: true,
                    type: 'int'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    vehicleId: 0,
                    year: 2021,
                    make: 'Volvo',
                    model: 'XC90'
                  }
                ]
              }
            },
            {
              name: 'Engines - List',
              description:
                'Get all vehicle engines list based on year, make and model.',
              APIRoute: resources.driveVehiclesEngines,
              method: thingRepository.getEngines,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesEngines
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesEngines
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'year',
                    required: true,
                    type: 'int'
                  },
                  {
                    name: 'make',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'model',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    vehicleId: 0,
                    vehicleEngineId: 0,
                    engine: '2.0L I4 Turbo 250hp',
                    horsePower: '250hp',
                    fuelGrade: 0
                  }
                ]
              }
            },
            {
              name: 'Engines - Details',
              description: 'Get all vehicle engines by vehicle id.',
              APIRoute: resources.driveVehiclesEnginesbyVehicleId,
              method: thingRepository.getEnginesByVehicleId,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesEnginesbyVehicleId
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesEnginesbyVehicleId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'vehicleId',
                    required: true,
                    type: 'int'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    vehicleId: 0,
                    vehicleEngineId: 0,
                    engine: '2.0L I4 Turbo 250hp',
                    horsePower: '250hp',
                    fuelGrade: 0
                  }
                ]
              }
            },
            {
              name: 'Compatibility - Details',
              description:
                'Get vehicle compatibility based on year, make and model.',
              APIRoute: resources.driveVehiclesCompatibility,
              method: thingRepository.getCompatibility,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesCompatibility
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesCompatibility
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'year',
                    required: true,
                    type: 'int'
                  },
                  {
                    name: 'make',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'model',
                    required: true,
                    type: 'string'
                  }
                ]
              },
              requestHeaders: [
                { name: 'x-subscription-key', type: 'string', required: true }
              ],
              responseStructure: {
                year: 2011,
                make: 'HONDA',
                model: 'Insight',
                engine: 'Any',
                obdLocationCode: '3',
                obdLocationStringCode: '3',
                compatibilityLevelSyncUpGeneration1: 'B',
                compatibilityLevelSyncUpGeneration2: 'E',
                compatibilityFeaturesGeneration2: {
                  emissionRelatedData: true,
                  diagnosticTroubleCodes: true,
                  driverSeatbeltWarning: false,
                  passengerSeatbeltWarning: false,
                  lowTirePressureWarning: false,
                  oilPressureWarning: false,
                  oilTemperatureWarning: false,
                  lowBrakeFluidWarning: false,
                  odometerValue: true,
                  fuelLevel: false,
                  smartMaintenanceIntervals: false,
                  batteryLevel: false,
                  vehicleSpeed: false
                }
              }
            },
            {
              name: 'Vehicles Colors - List',
              description:
                'Get all vehicle colors and images based on year, make and model.',
              APIRoute: resources.driveVehiclesColors,
              method: thingRepository.getVehiclesColors,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesColors
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesColors
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'year',
                    required: true,
                    type: 'int'
                  },
                  {
                    name: 'make',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'model',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    id: 0,
                    vehicleId: 0,
                    name: 'Birch Light Metallic',
                    hex: '#B0ADAA',
                    frontImageUrl: '{frontImageUrl}',
                    rearImageUrl: '{rearImageUrl}',
                    topImageUrl: '{topImageUrl}',
                    sideDriverImageUrl: '{sideDriverImageUrl}',
                    sidePassengerImageUrl: '',
                    frontDriverImageUrl: '',
                    frontPassengerImageUrl: '',
                    rearDriverImageUrl: '',
                    rearPassengerImageUrl: '',
                    aerialImageUrl: '{aerialImageUrl}',
                    iconImageUrl: '',
                    mapImageUrl: ''
                  }
                ]
              }
            },
            {
              name: 'Color- Details',
              description: 'Get all vehicle colors by vehicle id.',
              APIRoute: resources.driveVehiclesColorsbyVehicleId,
              method: thingRepository.getColorsByVehicleId,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesColorsbyVehicleId
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveVehiclesColorsbyVehicleId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'vehicleId',
                    required: true,
                    type: 'int'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    id: 0,
                    vehicleId: 0,
                    name: 'Birch Light Metallic',
                    hex: '#B0ADAA',
                    frontImageUrl: '{frontImageUrl}',
                    rearImageUrl: '{rearImageUrl}',
                    topImageUrl: '{topImageUrl}',
                    sideDriverImageUrl: '{sideDriverImageUrl}',
                    sidePassengerImageUrl: '',
                    frontDriverImageUrl: '',
                    frontPassengerImageUrl: '',
                    rearDriverImageUrl: '',
                    rearPassengerImageUrl: '',
                    aerialImageUrl: '{aerialImageUrl}',
                    iconImageUrl: '',
                    mapImageUrl: ''
                  }
                ]
              }
            }
          ]
        },
        {
          name: 'Timeline',
          apis: [
            {
              name: 'Events - Details',
              description: 'Get an event for a thing.',
              APIRoute: resources.driveEventById,
              method: thingRepository.getEventById,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveEventById),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveEventById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'thingId', type: 'int', required: true },
                  { name: 'eventId', type: 'int', required: true }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  id: 0,
                  thingId: 0,
                  tripId: 0,
                  eventType: 'dtc_minor',
                  eventLatitude: 38.897663,
                  eventLongitude: -77.0387679,
                  eventAddress:
                    '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                  eventAt: '2021-09-03T16:59:56.456205',
                  eventCode: null,
                  eventTitle: null,
                  eventDescription: null,
                  eventNextSteps: null,
                  eventActions: null,
                  lastReadAt: null
                }
              }
            },
            {
              name: 'Events - Paged List',
              description: 'Get a paged list of events for a thing.',
              APIRoute: resources.driveEvents,
              method: thingRepository.getThingEvents,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platformDrive,
                  resources.driveEvents
                )}?limit={limit}&after={after}`,
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveEvents
                )}?limit={limit}&after={after}" -H "Authorization: {token}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -H "Authorization: {token}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  { name: 'thingId', type: 'int', required: true },
                  {
                    name: 'limit',
                    type: 'int',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'after',
                    type: 'double',
                    required: false,
                    hiddenInput: true
                  }
                ],
                requestHeaders: [
                  { name: 'Authorization', type: 'string', required: true },
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    id: 0,
                    thingId: 0,
                    tripId: 0,
                    eventType: 'dtc_minor',
                    eventLatitude: 38.897663,
                    eventLongitude: -77.0387679,
                    eventAddress:
                      '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                    eventAt: '2021-09-03T16:51:10.178163',
                    eventCode: null,
                    eventTitle: null,
                    eventDescription: null,
                    eventNextSteps: null,
                    eventActions: null,
                    lastReadAt: null
                  }
                ]
              }
            },
            {
              name: 'Trips - Details',
              description: 'Get a trip for a thing.',
              APIRoute: resources.driveTripsbyId,
              method: thingRepository.getTripsById,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveTripsbyId),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveTripsbyId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    required: true,
                    type: 'int'
                  },
                  {
                    name: 'tripId',
                    required: true,
                    type: 'int'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  id: 0,
                  thingId: 0,
                  startedLatitude: 38.897663,
                  startedLongitude: -77.0387679,
                  startedAddress:
                    '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                  startedAt: '2021-08-31T22:02:36.813311',
                  endedLatitude: null,
                  endedLongitude: null,
                  endedAddress: null,
                  endedAt: null,
                  polylineEncoded: null,
                  duration: 0.0,
                  drivingDuration: 0.0,
                  idlingDuration: 0.0,
                  distance: 0.0,
                  speed: 0,
                  avgSpeed: 0.0,
                  maxSpeed: 0.0,
                  lowSpeedSinceEvent: 0.0,
                  lastSpeedEvent: null,
                  fuelEfficiency: 0.0,
                  seatbeltStatusPassenger: false,
                  seatbeltStatusDriver: false,
                  seatbeltPassengerWeight: false,
                  isBusiness: false,
                  lastReadAt: null,
                  lastModifiedBy: '{lastModifiedBy}',
                  lastModifiedAt: '2021-08-31T22:02:36.813311',
                  locations: [
                    {
                      latitude: 38.897663,
                      longitude: -77.0387679,
                      locationAt: '2021-08-31T22:02:47.711182'
                    }
                  ],
                  tripEvents: [
                    {
                      id: 0,
                      thingId: 0,
                      tripId: 0,
                      eventType: 'trip_start',
                      eventLatitude: 38.897663,
                      eventLongitude: -77.0387679,
                      eventAt: '2021-08-31T22:02:36.953209',
                      eventAddress:
                        '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                      lastReadAt: null
                    }
                  ]
                }
              }
            },
            {
              name: 'Trips - List',
              description: 'Get all trips for a thing.',
              APIRoute: resources.driveTrips,
              method: thingRepository.getThingTrips,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveTrips),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveTrips
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    required: true,
                    type: 'int'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    id: 0,
                    thingId: 0,
                    startedLatitude: 38.897663,
                    startedLongitude: -77.0387679,
                    startedAddress:
                      '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                    startedAt: '2021-08-31T22:02:36.813311',
                    endedLatitude: null,
                    endedLongitude: null,
                    endedAddress: null,
                    endedAt: null,
                    polylineEncoded: null,
                    duration: 0.0,
                    drivingDuration: 0.0,
                    idlingDuration: 0.0,
                    distance: 0.0,
                    speed: 0,
                    avgSpeed: 0.0,
                    maxSpeed: 0.0,
                    lowSpeedSinceEvent: 0.0,
                    lastSpeedEvent: null,
                    fuelEfficiency: 0.0,
                    seatbeltStatusPassenger: false,
                    seatbeltStatusDriver: false,
                    seatbeltPassengerWeight: false,
                    isBusiness: false,
                    lastReadAt: null,
                    lastModifiedBy: '{lastModifiedBy}',
                    lastModifiedAt: '2021-08-31T22:02:36.813311',
                    locations: [
                      {
                        latitude: 38.897663,
                        longitude: -77.0387679,
                        locationAt: '2021-08-31T22:02:47.711182'
                      }
                    ],
                    tripEvents: [
                      {
                        id: 0,
                        thingId: 0,
                        tripId: 0,
                        eventType: 'trip_start',
                        eventLatitude: 38.897663,
                        eventLongitude: -77.0387679,
                        eventAt: '2021-08-31T22:02:36.953209',
                        eventAddress:
                          '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                        lastReadAt: null
                      },
                      {
                        id: 0,
                        thingId: 0,
                        tripId: 0,
                        eventType: 'very_low_fuel',
                        eventLatitude: 38.897663,
                        eventLongitude: -77.0387679,
                        eventAt: '2021-08-31T22:02:47.900011',
                        eventAddress:
                          '1703 W Executive Ave NW ,Washington,DC,United States,20500',
                        lastReadAt: null
                      }
                    ]
                  }
                ]
              }
            },
            {
              name: 'Paged - List',
              description: 'Get a paged list of trips and events for a thing',
              authorizedProducts: [APP_MOBILE.DRIVE],
              APIRoute: resources.driveTimelinePaged,
              method: thingRepository.getTimelinePaged,
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platformDrive,
                  resources.driveTimelinePaged
                )}?limit={limit}&after={after}&filter={filter}`,
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveTimelinePaged
                )}?limit={limit}&after={after}&filter={filter}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    required: true,
                    type: 'int'
                  },
                  {
                    name: 'limit',
                    required: false,
                    type: 'int',
                    hiddenInput: true
                  },
                  {
                    name: 'after',
                    required: false,
                    type: 'double',
                    hiddenInput: true
                  },
                  {
                    name: 'filter',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: [
                  {
                    rowEpoch: 0,
                    itemType: 'EVENT',
                    itemInfo: 'LOW_TIRE_PRESSURE',
                    itemAt: '2021-07-27T14:57:30.895422',
                    lastReadAt: null,
                    id: 0,
                    thingId: 0,
                    tripId: 0,
                    latitude: 38.897663,
                    longitude: -77.0387679,
                    startedLatitude: null,
                    startedLongitude: null,
                    startedAt: '2022-01-08T20:15:04.985441',
                    endedLatitude: null,
                    endedLongitude: null,
                    endedAt: null,
                    duration: null,
                    drivingDuration: null,
                    idlingDuration: null,
                    distance: null,
                    avgSpeed: null,
                    maxSpeed: null,
                    isBusiness: null,
                    polylineEncoded: null,
                    countSpeeding: null,
                    countSeatbelt: null,
                    countBattery: null,
                    countFuel: null,
                    countDtc: null,
                    countOilLow: null,
                    countOilTemp: null,
                    countGeofenceEntry: null,
                    countGeofenceExit: null,
                    countEngineLightOn: null,
                    countTire: null,
                    countDriveBehaviorSharpCornering: null,
                    countDriveBehaviorHarshBreaking: null,
                    countDriveBehaviorRapidAccelaration: null,
                    countIdling: null,
                    eventDescription: null
                  }
                ]
              }
            },
            {
              name: 'Export - Summary',
              description:
                ' Get timeline summary of trips that will be emailed within the specified period.',
              authorizedProducts: [APP_MOBILE.DRIVE],
              APIRoute: resources.driveTimelineSummary,
              method: thingRepository.getTimelineSummary,
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platformDrive,
                  resources.driveTimelineSummary
                )}?startDate={startDate}&endDate={endDate}`,
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDrive,
                  resources.driveTimelineSummary
                )}?startDate={startDate}&endDate={endDate}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    required: true,
                    type: 'int'
                  },
                  {
                    name: 'startDate',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'endDate',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  }
                ],

                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  'Scenario-1': {
                    startDate: '2022-11-07T00:00:00-08:00',
                    endDate: '2022-11-19T16:00:00-08:00',
                    businessSummary: {
                      tripType: 'Business',
                      milesDriven: 29,
                      driveDuration: 42,
                      count: 1
                    },
                    personalSummary: {
                      tripType: 'Personal',
                      milesDriven: 89,
                      driveDuration: 126,
                      count: 3
                    }
                  }
                }
              }
            },
            {
              name: 'Timeline - Details',
              description:
                'Set when the timeline trip and events were last viewed by the user.',
              APIRoute: resources.thingViewed,
              method: thingRepository.updateViewsTimeline,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.thingViewed),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platformDrive,
                  resources.thingViewed
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Timeline - Export',
              description:
                'Export timeline records to PDF or CSV and send to email for provided period.',
              APIRoute: resources.driveTimelineExport,
              method: thingRepository.postExportTimeline,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveTimelineExport
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveTimelineExport
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'startDate',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'endDate',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Business Trip - Update',
              description: 'Update to business trip.',
              APIRoute: resources.driveTripsbyId,
              method: thingRepository.updateTripsById,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveTripsbyId),
                curlRequest: `curl -v -X PATCH " ${getRoute(
                  basePath.platformDrive,
                  resources.driveTripsbyId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{isbusiness: true }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'tripId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  isbusiness: true
                }
              }
            },

            {
              name: 'Timeline - Delete',
              description: 'Delete timeline by timeline id.',
              APIRoute: resources.timelinesById,
              method: thingRepository.deleteTimeline,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.timelinesById),
                curlRequest: `curl -v -X DELETE " ${getRoute(
                  basePath.platform,
                  resources.timelinesById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'timelineId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Timeline - Details',
              description: 'Retrieve a timeline by timeline id.',
              APIRoute: resources.timelinesById,
              method: thingRepository.getTimeline,
              methodType: httpMethods.GET,
              authorizedProducts: [APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.timelinesById),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.timelinesById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'timelineId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Timeline - Update',
              description: 'Update a timeline.',
              APIRoute: resources.timelinesById,
              method: thingRepository.updateTimeline,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.timelinesById),
                curlRequest: `curl -v -X PATCH " ${getRoute(
                  basePath.platform,
                  resources.timelinesById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "[
              {
                op: 'replace',
                path: '/name',
                value: 'Updated Timeline Name'
              }
            ]"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'timelineId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: [
                  {
                    op: 'replace',
                    path: '/name',
                    value: 'Updated Timeline Name'
                  }
                ]
              }
            },
            {
              name: 'Timeline - Create',
              description: 'Create a timeline for a thing.',
              APIRoute: resources.timelines,
              method: thingRepository.createTimeline,
              authorizedProducts: [APP_MOBILE.TRACKER],
              methodType: httpMethods.POST,
              apiDetails: {
                url: getRoute(basePath.platform, resources.timelines),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.timelines
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
              endDateTime: null,
              name: 'Timeline Name',
              startDateTime: '2020-07-25T18:00:00.0000000+00:00',
              thingId: '{thingId}'
            }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  endDateTime: null,
                  name: 'Timeline Name',
                  startDateTime: '2020-07-25T18:00:00.0000000+00:00',
                  thingId: '{thingId}'
                }
              }
            },
            {
              name: 'Timelines - List',
              description: 'Get all timelines for a thing.',
              APIRoute: resources.timelines,
              method: thingRepository.getTimelines,
              authorizedProducts: [APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.timelines),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.timelines
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'User',
          apis: [
            {
              name: 'Current User - Details',
              description: 'Get current user details.',
              APIRoute: resources.driveCurrentUser,
              method: userRepository.getCurrentUser,
              authorizedProducts: [APP_MOBILE.DRIVE],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platformDriveV2,
                  resources.driveCurrentUser
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platformDriveV2,
                  resources.driveCurrentUser
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  userProfile: {
                    userId: '{userId}',
                    firstName: 'User Name',
                    lastName: 'Last name',
                    email: 'test@test.com',
                    msisdns: ['{msisdns}'],
                    demoUser: false,
                    createdDateTime: '2022-07-13T13:05:43.117047',
                    modifiedDateTime: '2022-07-13T13:30:24.107707'
                  },
                  applicationProfile: {
                    product: 'DRIVE',
                    userId: '{userId}',
                    createdDateTime: '2022-07-13T13:05:43.445101',
                    modifiedDateTime: '2022-07-13T13:30:24.138973',
                    preferences: {
                      pushNotificationsEnabled: true,
                      emailNotificationsEnabled: true,
                      smsNotificationsEnabled: true
                    },
                    terms: {
                      version: 5,
                      type: 'TERMS',
                      timestamp: '2022-07-13T13:30:24.138973'
                    },
                    privacy: null,
                    data: null,
                    locationPrivacy: null,
                    roadsideEnrollment: null,
                    icn: null,
                    locale: {
                      language: 'en',
                      country: 'US',
                      unitPreference: 'US',
                      timeZone: 'America/Los_Angeles'
                    },
                    location: null,
                    isReopenedAccount: false,
                    platform: null,
                    applicationVersion: null
                  }
                }
              }
            },
            {
              name: 'Register - Details',
              description: 'Register a user.',
              APIRoute: resources.registerUser,
              method: userRepository.registerUser,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDriveV2, resources.registerUser),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDriveV2,
                  resources.registerUser
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ userProfile: {
              firstName: 'Test',
              lastName: 'User',
              email: 'test@test.com',
              msisdns: ['{msisdns}'],
              demoUser: true
            },
            applicationProfile: {
              preferences: {
                pushNotificationsEnabled: true,
                smsNotificationsEnabled: true,
                emailNotificationsEnabled: true
              },
              locale: {
                language: 'en',
                country: 'US',
                unitPreference: 'US',
                timeZone: 'America/Chicago'
              },
              product: 'DRIVE'
            } }"`,
                requestParameters: [],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  userProfile: {
                    firstName: 'Test',
                    lastName: 'User',
                    email: 'test@test.com',
                    msisdns: ['{msisdns}'],
                    demoUser: true
                  },
                  applicationProfile: {
                    preferences: {
                      pushNotificationsEnabled: true,
                      smsNotificationsEnabled: true,
                      emailNotificationsEnabled: true
                    },
                    locale: {
                      language: 'en',
                      country: 'US',
                      unitPreference: 'US',
                      timeZone: 'America/Chicago'
                    },
                    product: 'DRIVE'
                  }
                },
                responseStructure: {
                  userProfile: {
                    userId: '{userId}',
                    firstName: 'PK',
                    lastName: 'User',
                    email: 'test@test.com',
                    msisdns: ['{msisdns}'],
                    demoUser: false,
                    createdDateTime: '2022-07-13T13:05:43.117047',
                    modifiedDateTime: '2023-02-16T08:32:24.10357'
                  },
                  applicationProfile: {
                    product: 'DRIVE',
                    userId: '{userId}',
                    createdDateTime: '2022-07-13T13:05:43.445101',
                    modifiedDateTime: '2023-02-16T08:32:24.150448',
                    preferences: {
                      pushNotificationsEnabled: true,
                      emailNotificationsEnabled: true,
                      smsNotificationsEnabled: true
                    },
                    terms: {
                      version: 5,
                      type: 'TERMS',
                      timestamp: '2023-02-16T06:20:29.953666'
                    },
                    privacy: null,
                    data: null,
                    locationPrivacy: null,
                    roadsideEnrollment: {
                      version: 1,
                      type: 'ROADSIDE',
                      timestamp: '2023-02-15T10:48:05.964541'
                    },
                    icn: null,
                    locale: {
                      language: 'en',
                      country: 'US',
                      unitPreference: 'US',
                      timeZone: 'America/Chicago'
                    },
                    location: null,
                    isReopenedAccount: false,
                    platform: null,
                    applicationVersion: null
                  }
                }
              }
            },
            {
              name: 'Accept Terms - Update',
              description: 'Update user terms.',
              APIRoute: resources.driveCurrentUser,
              method: userRepository.updateUserTerms,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDriveV2,
                  resources.driveCurrentUser
                ),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platformDriveV2,
                  resources.driveCurrentUser
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ applicationProfile: {
              terms: {
                version: '5',
                type: 'TERMS'
              },
              trip: {
                isbusiness: true // false/ empty
              }
            }}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  applicationProfile: {
                    terms: {
                      version: '5',
                      type: 'TERMS'
                    },
                    trip: {
                      isbusiness: true // false/ empty
                    }
                  }
                }
              }
            },
            {
              name: 'Current User - Delete',
              description: 'Delete current user.',
              APIRoute: resources.driveCurrentUser,
              method: userRepository.deleteDriveUser,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDriveV2,
                  resources.driveCurrentUser
                ),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platformDriveV2,
                  resources.driveCurrentUser
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    value: null,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Notification - Continuation Tokens',
              description:
                'Get list of notifications for user utilizing continuation tokens.',
              APIRoute: resources.userNotificationList,
              method: userRepository.getUserNotificationList,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.userNotificationList
                )}pageSize={pageSize}&start-date-time={startDateTime}&continuationToken={continuationToken}"`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.userNotificationList
                )}pageSize={pageSize}&start-date-time={startDateTime}&continuationToken={continuationToken}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'pageSize',
                    type: 'int',
                    required: true,
                    value: 1
                  },
                  {
                    name: 'startDateTime',
                    type: 'string',
                    required: false
                  },
                  {
                    name: 'continuationToken',
                    type: 'string',
                    required: false
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'User Highlights - List',
              description: 'Get list of user lights.',
              APIRoute: resources.userLights,
              method: userRepository.getUserLights,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.userLights),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.userLights
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Notification - List',
              description: 'Get list of notifications for user.',
              APIRoute: resources.userNotificationsList,
              method: userRepository.getCurrentUserNotification,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.userNotificationsList
                )}start-date-time=[startDateTime]`,
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.userNotificationsList
                )}start-date-time=[startDateTime]" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'startDateTime',
                    type: 'string',
                    required: false
                  }
                ],
                requestHeaders: [
                  {
                    name: 'x-subscription-key',
                    type: 'string',
                    required: true
                  }
                ]
              }
            },
            {
              name: 'Notification - Bulk Read',
              description:
                'When a bulk read notification call is made, mark all the selected notification ids as read.',
              APIRoute: resources.notificationBulkRead,
              method: userRepository.postBulkReadNotification,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.POST,
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.notificationBulkRead
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platform,
                  resources.notificationBulkRead
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "[
              "{notificationId}"
            ]"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'x-subscription-key',
                    type: 'string',
                    required: true
                  }
                ],
                requestBody: ['{notificationId}']
              }
            },
            {
              name: 'Notification - Bulk Delete',
              description: 'Mark all the selected notification ids as deleted.',
              APIRoute: resources.notificationBulkRead,
              method: userRepository.deleteBulkNotification,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.DELETE,
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.notificationBulkDelete
                ),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.notificationBulkDelete
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "[
              "{notificationId}"
            ]"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'x-subscription-key',
                    type: 'string',
                    required: true
                  }
                ],
                requestBody: ['{notificationId}']
              }
            },
            {
              name: 'User - Details',
              description: 'Get current user.',
              APIRoute: resources.currentUser,
              method: userRepository.getCurrentUser,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.currentUser),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.currentUser
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'x-subscription-key',
                    type: 'string',
                    required: true
                  }
                ]
              }
            },
            {
              name: 'User - Update',
              description: 'Update user details.',
              APIRoute: resources.currentUser,
              method: userRepository.updateUserDetails,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.currentUser),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platform,
                  resources.currentUser
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'x-subscription-key',
                    type: 'string',
                    required: true
                  }
                ],
                requestBody: {}
              }
            },
            {
              name: 'User - Onboard',
              description: 'Create/Update the current user.',
              APIRoute: resources.currentUser,
              method: userRepository.postUserDetails,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.currentUser),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.currentUser
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
              "coordinates": {
                "latitude": 47.600395,
                "longitude": -122.1529
              },
              "demoUser": true,
              "email": "string",
              "firstName": "string",
              "icn": {
                "type": "TERMS",
                "version": 0
              },
              "lastName": "string",
              "locale": {
                "country": "string",
                "language": "string",
                "timeZone": "string",
                "unitPreference": "US"
              },
              "locationPermissions": {
                "acceptedDateTime": 0
              },
              "msisdns": [
                "string"
              ],
              "notificationSnooze": {
                "firmware": {
                  "snoozeUntil": "2020-07-28T21:07:04.5670000+00:00"
                },
                "wifiSetup": {
                  "snoozeUntil": "2020-07-28T21:07:04.5670000+00:00"
                }
              },
              "preferences": {
                "activityGoalCompletedPushNotificationsEnabled": true,
                "activityGoalWarningPushNotificationsEnabled": true,
                "batteryLevelPushNotificationsEnabled": true,
                "deviceOfflinePushNotificationsEnabled": true,
                "emailNotificationsEnabled": true,
                "lostModeChangePushNotificationsEnabled": true,
                "petBirthdayPushNotificationsEnabled": true,
                "pushNotificationsEnabled": true,
                "safeZonePushNotificationsEnabled": true,
                "smsNotificationsEnabled": true
              },
              "pushNotificationTokens": [
                {
                  "modifiedDateTime": 0,
                  "platform": "IOS",
                  "token": "string",
                  "version": "string"
                }
              ],
              "terms": {
                "type": "TERMS",
                "version": 0
              }
            }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  {
                    name: 'x-subscription-key',
                    type: 'string',
                    required: true
                  }
                ],
                requestBody: {
                  coordinates: {
                    latitude: 47.600395,
                    longitude: -122.1529
                  },
                  demoUser: true,
                  email: 'string',
                  firstName: 'string',
                  icn: {
                    type: 'TERMS',
                    version: 0
                  },
                  lastName: 'string',
                  locale: {
                    country: 'string',
                    language: 'string',
                    timeZone: 'string',
                    unitPreference: 'US'
                  },
                  locationPermissions: {
                    acceptedDateTime: 0
                  },
                  msisdns: ['string'],
                  notificationSnooze: {
                    firmware: {
                      snoozeUntil: '2020-07-28T21:07:04.5670000+00:00'
                    },
                    wifiSetup: {
                      snoozeUntil: '2020-07-28T21:07:04.5670000+00:00'
                    }
                  },
                  preferences: {
                    activityGoalCompletedPushNotificationsEnabled: true,
                    activityGoalWarningPushNotificationsEnabled: true,
                    batteryLevelPushNotificationsEnabled: true,
                    deviceOfflinePushNotificationsEnabled: true,
                    emailNotificationsEnabled: true,
                    lostModeChangePushNotificationsEnabled: true,
                    petBirthdayPushNotificationsEnabled: true,
                    pushNotificationsEnabled: true,
                    safeZonePushNotificationsEnabled: true,
                    smsNotificationsEnabled: true
                  },
                  pushNotificationTokens: [
                    {
                      modifiedDateTime: 0,
                      platform: 'IOS',
                      token: 'string',
                      version: 'string'
                    }
                  ],
                  terms: {
                    type: 'TERMS',
                    version: 0
                  }
                }
              }
            },
            {
              name: 'Notification - Update',
              description: 'Update a notification.',
              APIRoute: resources.notificationByNotificationId,
              method: userRepository.updateNotificationDetails,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.notificationByNotificationId
                ),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platform,
                  resources.notificationByNotificationId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{ }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'notificationId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'x-subscription-key',
                    type: 'string',
                    required: true
                  }
                ],
                requestBody: {}
              }
            }
          ]
        },
        {
          name: 'SignalR',
          apis: [
            {
              name: 'SignalR - Negotiate',
              description: 'Subscribe to SignalR events.',
              APIRoute: resources.driveSignalr,
              method: userRepository.postSignalRNegotiate,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(basePath.platformDrive, resources.driveSignalr),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveSignalr
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                responseStructure: {
                  url: '{url}',
                  accessToken: '{{token}}'
                }
              }
            }
          ]
        },
        {
          name: 'Fuel',
          apis: [
            {
              name: 'Stations - Details',
              description: 'Get fuel station details.',
              APIRoute: resources.driveFuelstations,
              method: thingRepository.searchDriveFuelStation,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.DRIVE],
              apiDetails: {
                url: getRoute(
                  basePath.platformDrive,
                  resources.driveFuelstations
                ),
                curlRequest: `curl -v -X POST "${getRoute(
                  basePath.platformDrive,
                  resources.driveFuelstations
                )}  " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key} -d "{ fuelType: 'REGULAR',
            searchOrderType: 'NEAREST',
            latitude: 38.897663,
            longitude: -77.0387679}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  fuelType: 'REGULAR',
                  searchOrderType: 'NEAREST',
                  latitude: 38.897663,
                  longitude: -77.0387679
                }
              }
            }
          ]
        },
        {
          name: 'Sound Settings',
          apis: [
            {
              name: 'Sound Settings - List',
              description: 'Get a list of sound settings for current user.',
              APIRoute: resources.soundSettings,
              method: thingRepository.getSoundSettings,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.soundSettings),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.soundSettings
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Sound Setting - Details',
              description: 'Get sound setting by thing id.',
              APIRoute: resources.soundSettingsByThingId,
              method: thingRepository.getSoundSettingsByThingId,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.soundSettingsByThingId
                ),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.soundSettingsByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Sound Setting - Create',
              description: 'Create a sound setting.',
              APIRoute: resources.soundSettings,
              method: thingRepository.createSoundSettings,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.soundSettings),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.soundSettings
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        -d "{
                          "thingId": "{thingId}",
                          "productId": "{productId}",
                          "sound": false
                           }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  thingId: '{thingId}',
                  productId: '{productId}',
                  sound: false
                }
              }
            },
            {
              name: 'Sound Setting - Delete',
              description: 'Delete sound setting by thing id.',
              APIRoute: resources.soundSettingsByThingId,
              method: thingRepository.deleteSoundSettings,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.soundSettingsByThingId
                ),
                curlRequest: `curl -v -X DELETE " ${getRoute(
                  basePath.platform,
                  resources.soundSettingsByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Sound Setting - Update',
              description: 'Update sound setting by thing id.',
              APIRoute: resources.soundSettingsByThingId,
              method: thingRepository.updateSoundSettings,
              methodType: httpMethods.PUT,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.soundSettingsByThingId
                ),
                curlRequest: `curl -v -X PUT " ${getRoute(
                  basePath.platform,
                  resources.soundSettingsByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        -d "{
                          "sound": true
                           }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  sound: true
                }
              }
            }
          ]
        },
        {
          name: 'Preset Messages',
          apis: [
            {
              name: 'Preset Message - Details',
              description: 'Get preset messages by thing id.',
              APIRoute: resources.presetMessagesByThingId,
              method: thingRepository.getPresetMessagesByThingId,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.presetMessagesByThingId
                ),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.presetMessagesByThingId
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Preset Messages - List',
              description: 'Get list of preset messages for current user.',
              APIRoute: resources.presetMessages,
              method: thingRepository.getPresetMessages,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.presetMessages),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.presetMessages
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Preset Message By Id',
              description: 'Get preset messages by id.',
              APIRoute: resources.presetMessagesById,
              method: thingRepository.getPresetMessagesById,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.presetMessagesById),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.presetMessagesById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'presetMessageId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Preset Message - Create',
              description: 'Create task for current user.',
              APIRoute: resources.presetMessages,
              method: thingRepository.createPresetMessages,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.presetMessages),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.presetMessages
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        -d "{
                          "thingId": "thing-id",
                          "initializeDefaults": true,
                          "presetMessages": [
                            {
                              "id": "id 0 that will be ignored if initializeDefaults = true",
                              "text": "text 0 that will be ignored if initializeDefaults = true"
                            }
                          ]
                        }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  thingId: 'thing-id',
                  initializeDefaults: true,
                  presetMessages: [
                    {
                      id:
                        'id 0 that will be ignored if initializeDefaults = true',
                      text:
                        'text 0 that will be ignored if initializeDefaults = true'
                    }
                  ]
                }
              }
            },
            {
              name: 'Preset Message - Delete',
              description: 'Delete preset messages by id for current user.',
              APIRoute: resources.presetMessagesById,
              method: thingRepository.deletePresetMessages,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.presetMessagesById),
                curlRequest: `curl -v -X DELETE " ${getRoute(
                  basePath.platform,
                  resources.presetMessagesById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'presetMessageId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Preset Message - Update',
              description: 'Update message by message id for current user.',
              APIRoute: resources.presetMessagesById,
              method: thingRepository.updatePresetMessages,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.presetMessagesById),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platform,
                  resources.presetMessagesById
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
            -d "{ op: 'replace',
            path: '/presetMessages',
            value: [
              {
                "id": "{id}",
                "text": "text to be updated"
              }
            ]}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'presetMessagesId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  op: 'replace',
                  path: '/presetMessages',
                  value: [
                    {
                      id: '{id}',
                      text: 'text to be updated'
                    }
                  ]
                }
              }
            }
          ]
        },
        {
          name: 'Authorisation',
          apis: [
            {
              name: 'Auth - Get Firebase Token',
              description:
                'Endpoint to obtain auth token for firebase integration.',
              APIRoute: resources.firebaseAuthToken,
              method: userRepository.getFirebaseAuthToken,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.firebaseAuthToken),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.firebaseAuthToken
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Auth - Logout',
              description: 'Logout endpoint to invalidate JWT.',
              APIRoute: resources.logout,
              method: logoutRepository.logOutUser,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.logout),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.logout
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'idToken',
                    required: true,
                    type: 'string',
                    isHidden: true
                  }
                ],
                requestHeaders: [
                  {
                    name: 'Authorization',
                    required: true,
                    type: 'string'
                  },
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Auth - TMOID - User Profile',
              description: 'User profile endpoint for TMOID.',
              APIRoute: resources.userProfile,
              method: userRepository.getUserProfile,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.userProfile
                )}id_type={idType}&id_value={idValue}&scopes={scopes}&user_roles={userRoles}&contract_status={contractStatus}&query_mode={querymode}&from_index={formIndex}&page_size={pageSize}"`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.userProfile
                )}id_type={idType}&id_value={idValue}&scopes={scopes}&user_roles={userRoles}&contract_status={contractStatus}&query_mode={querymode}&from_index={formIndex}&page_size={pageSize}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'idType',
                    type: 'int',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'idValue',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'scopes',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'userRoles',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'contractStatus',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'querymode',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Auth - Token',
              description: 'Get a user auth token.',
              APIRoute: resources.userAuthToken,
              method: userRepository.createUserAuthToken,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.userAuthToken),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.userAuthToken
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                     `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Auth - TMOID - Token',
              description: 'Token endpoint for TMOID service.',
              APIRoute: resources.tmoIdToken,
              method: userRepository.createTmoIdToken,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.tmoIdToken),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.tmoIdToken
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        -d "{
                          "code": "auth_code",
                "clientId": "{clientId}",
                "redirectUri": "{redirectUri}"
                           }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  code: 'auth_code',
                  clientId: '{clientId}',
                  redirectUri: '{redirectUri}'
                }
              }
            }
          ]
        },
        {
          name: 'Silent Schedules',
          apis: [
            {
              name: 'Silent Schedule - Get',
              description:
                'Get silent schedules for current user by silent schedule id.',
              APIRoute: resources.silentSchedulesById,
              method: thingRepository.getSilentSchedulesById,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.silentSchedulesById),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.silentSchedulesById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'silentScheduleId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Silent Schedule - Create',
              description: 'Create silent schedule for current user.',
              APIRoute: resources.silentSchedules,
              method: thingRepository.createSilentSchedules,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.silentSchedules),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.silentSchedules
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        -d "{
                          "title": "Schedule description",
                          "thingId": "validThingId",
                          "startDate": "2022-04-26",
                          "startTime": "2023-02-16",
                          "duration": 2,
                          "recurringSchedule": {
                            "sunday": false,
                            "monday": true,
                            "tuesday": true,
                            "wednesday": true,
                            "thursday": true,
                            "friday": true,
                            "saturday": false
                          },
                          "active": true,
                          "status": "ACTIVE"
                           }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  title: 'Schedule description',
                  thingId: 'validThingId',
                  startDate: '2022-04-26',
                  startTime: '2023-02-16',
                  duration: 2,
                  recurringSchedule: {
                    sunday: false,
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false
                  },
                  active: true,
                  status: 'ACTIVE'
                }
              }
            },
            {
              name: 'Silent Schedule - Update',
              description:
                'Update silent schedules for current user by silent schedule id.',
              APIRoute: resources.silentSchedulesById,
              method: thingRepository.updateSilentSchedules,
              methodType: httpMethods.PATCH,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.silentSchedulesById),
                curlRequest: `curl -v -X PATCH "${getRoute(
                  basePath.platform,
                  resources.silentSchedulesById
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
            -d "{  "title": "Schedule description",
            "startDate": "2022-04-26",
            "startTime": "2023-02-16",
            "duration": 2,
            "recurringSchedule": {
              "sunday": false,
              "monday": true,
              "tuesday": true,
              "wednesday": true,
              "thursday": true,
              "friday": true,
              "saturday": false
            },
            "active": true,
            "status": "ACTIVE"}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'silentScheduleId',
                    required: true,
                    type: 'string'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  title: 'Schedule description',
                  startDate: '2022-04-26',
                  startTime: '2023-02-16',
                  duration: 2,
                  recurringSchedule: {
                    sunday: false,
                    monday: true,
                    tuesday: true,
                    wednesday: true,
                    thursday: true,
                    friday: true,
                    saturday: false
                  },
                  active: true,
                  status: 'ACTIVE'
                }
              }
            },
            {
              name: 'Silent Schedule - Delete',
              description:
                'Delete silent schedule for current user by silent schedule id.',
              APIRoute: resources.silentSchedulesById,
              method: thingRepository.deleteSilentSchedules,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.silentSchedulesById),
                curlRequest: `curl -v -X DELETE " ${getRoute(
                  basePath.platform,
                  resources.silentSchedulesById
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'silentScheduleId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        },
        {
          name: 'Rewards',
          apis: [
            {
              name: 'Rewards - List',
              description: 'Get a list of rewards for current user.',
              APIRoute: resources.rewards,
              method: thingRepository.getRewards,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.rewards),
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.rewards
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Reward - Create',
              description: 'Create reward for current user.',
              APIRoute: resources.rewards,
              method: thingRepository.createRewards,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH],
              apiDetails: {
                url: getRoute(basePath.platform, resources.rewards),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.rewards
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                          -d "{
                            "type": "MESSAGE",
                            "message": "Well done!",
                            "thingId": "{thingId}"
                             }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  type: 'MESSAGE',
                  message: 'Well done!',
                  thingId: '{thingId}'
                }
              }
            }
          ]
        },
        {
          name: 'Access Management',
          apis: [
            {
              name: 'Access Management - Invitation List',
              description:
                'Retrieve list of access request invitation by user id with optional query params.',
              APIRoute: resources.accessRequestList,
              method: userRepository.getAccessRequestList,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.accessRequestList
                )}role={role}&status={status}&page={page}&size={size}`,
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.accessRequestList
                )}role={role}&status={status}&page={page}&size={size}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'role',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'status',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  },
                  {
                    name: 'page',
                    required: false,
                    type: 'string',
                    hiddenInput: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Access Management - Invitation',
              description: 'Delete an access request invitation by id.',
              APIRoute: resources.accessRequestById,
              method: userRepository.deleteAccess,
              methodType: httpMethods.DELETE,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.accessRequestById),
                curlRequest: `curl -v -X DELETE "${getRoute(
                  basePath.platform,
                  resources.accessRequestById
                )} " -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'accessRequestId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Access Management - Redemption',
              description:
                'Retrieve list of redemption available access request invitation by user MSISDN.',
              APIRoute: resources.availableAccessInvites,
              method: userRepository.getAccessManagementRedemption,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.availableAccessInvites
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.availableAccessInvites
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Guest Sharing - Maximum Number',
              description: 'Get a maximum number of guests for guest sharing.',
              APIRoute: resources.maxGuestLimit,
              method: userRepository.getMaxGuestLimit,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(basePath.platform, resources.maxGuestLimit),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.maxGuestLimit
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Redeem Invitation - Access Code',
              description:
                'Redeem an access request invitation using only access code.',
              APIRoute: resources.accessInviteRedeem,
              method: userRepository.userAccessRedeem,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.accessInviteRedeem),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.accessInviteRedeem
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d ""`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'action',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {}
              }
            },
            {
              name: 'Access Management - Redeem Invitation',
              description: 'Redeem an access request invitation.',
              APIRoute: resources.accessInvite,
              method: userRepository.userAccessInvite,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.accessInvite),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.accessInvite
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d "{
              "msisdn": "{msisdn}",
              "redemptionType": "MSISDN"
            }"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'action',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'inviteId',
                    type: 'string',
                    required: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {
                  msisdn: '{msisdn}',
                  redemptionType: 'MSISDN'
                }
              }
            },
            {
              name: 'Access Management - TrackerCoppa Data',
              description: 'Retrieve tracker coppa data by user access code.',
              APIRoute: resources.accessManagementByCode,
              method: userRepository.getTrackerAccessManagement,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              methodType: httpMethods.GET,
              apiDetails: {
                url: getRoute(
                  basePath.platform,
                  resources.accessManagementByCode
                ),
                curlRequest: `curl -v -X GET "${getRoute(
                  basePath.platform,
                  resources.accessManagementByCode
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    required: true,
                    type: 'string',
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'accessCode',
                    required: true,
                    type: 'string',
                    hiddenInput: true
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Access Management - Without Invite',
              description: 'Share an access request without an invite.',
              APIRoute: resources.shareAccess,
              method: userRepository.userShareAccess,
              methodType: httpMethods.POST,
              authorizedProducts: [APP_MOBILE.WATCH, APP_MOBILE.TRACKER],
              apiDetails: {
                url: getRoute(basePath.platform, resources.shareAccess),
                curlRequest: `curl -v -X POST " ${getRoute(
                  basePath.platform,
                  resources.shareAccess
                )}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" -d ""`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ],
                requestBody: {}
              }
            }
          ]
        },
        {
          name: 'Logs',
          apis: [
            {
              name: 'Communication History Call Log - Paged List',
              description:
                'Get a list of call logs by thing id for user utilizing continuation tokens.',
              APIRoute: resources.callLogsOfUserUtilization,
              method: thingRepository.getCallLogsByPage,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.callLogsOfUserUtilization
                )}pageSize={pageSize}&start-date-time={startDateTime}&end-date-time={endDateTime}`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.callLogsOfUserUtilization
                )}pageSize={pageSize}&start-date-time={startDateTime}&end-date-time={endDateTime}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'int',
                    required: true
                  },
                  {
                    name: 'pageSize',
                    type: 'string',
                    required: true,
                    value: 1
                  },
                  {
                    name: 'startDateTime',
                    type: 'string',
                    required: true,
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    type: 'string',
                    required: true,
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange(),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Communication History SMS Log - Paged List',
              description:
                'Get a list of sms logs by thing id for user utilizing continuation tokens.',
              APIRoute: resources.smsLogsOfUserUtilization,
              method: thingRepository.getSmsLogsByPage,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.smsLogsOfUserUtilization
                )}pageSize={pageSize}&start-date-time={startDateTime}&end-date-time={endDateTime}`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.smsLogsOfUserUtilization
                )}pageSize={pageSize}&start-date-time={startDateTime}&end-date-time={endDateTime}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'int',
                    required: true
                  },
                  {
                    name: 'pageSize',
                    type: 'string',
                    required: true,
                    value: 1
                  },
                  {
                    name: 'startDateTime',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    type: 'string',
                    required: false,
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange(),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Communication History SMS Log - List',
              description: 'Get a list of sms logs by thing id.',
              APIRoute: resources.smsLogsByThingId,
              method: thingRepository.getSmsLogs,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.smsLogsByThingId
                )}start-date-time={startDateTime}&end-date-time={endDateTime}`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.smsLogsByThingId
                )}start-date-time={startDateTime}&end-date-time={endDateTime}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}" 
                        `,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'int',
                    required: true
                  },
                  {
                    name: 'startDateTime',
                    type: 'string',
                    required: true,
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    type: 'string',
                    required: true,
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange(),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            },
            {
              name: 'Communication History Call Log - List',
              description: 'Get a list of call logs by thing id.',
              APIRoute: resources.callLogsByThingId,
              method: thingRepository.getCallLogs,
              authorizedProducts: [APP_MOBILE.WATCH],
              methodType: httpMethods.GET,
              apiDetails: {
                url: `${getRoute(
                  basePath.platform,
                  resources.callLogsByThingId
                )}start-date-time={startDateTime}&end-date-time={endDateTime}`,
                curlRequest: `curl -v -X GET " ${getRoute(
                  basePath.platform,
                  resources.callLogsByThingId
                )}start-date-time={startDateTime}&end-date-time={endDateTime}" -H "Cache-Control: no-cache" -H "x-subscription-key: {subscription-key}"`,
                requestParameters: [
                  {
                    name: 'userId',
                    type: 'string',
                    required: true,
                    displayName: 'User Impersonation (User Id)'
                  },
                  {
                    name: 'thingId',
                    type: 'string',
                    required: true
                  },
                  {
                    name: 'startDateTime',
                    type: 'string',
                    required: true,
                    hiddenInput: true
                  },
                  {
                    name: 'endDateTime',
                    type: 'string',
                    required: true,
                    hiddenInput: true
                  },
                  {
                    name: 'query',
                    isHidden: true,
                    value: getTimeRange(),
                    displayName: 'Query String'
                  }
                ],
                requestHeaders: [
                  { name: 'x-subscription-key', type: 'string', required: true }
                ]
              }
            }
          ]
        }
      ]
    },
    {
      name: 'Grand Central',
      categories: [
        {
          name: 'User',
          apis: [
            {
              name: 'Grand Central',
              APIRoute: 'Grand Central',
              description: 'demo Description',
              methodType: ''
            }
          ]
        }
      ]
    },
    {
      name: 'Platform',
      categories: [
        {
          name: 'demo',
          apis: [
            {
              name: 'demo text',
              APIRoute: 'demo text',
              description: 'demo Description',
              methodType: ''
            }
          ]
        }
      ]
    }
  ];

  return APIList.filter(group => {
    let items = [];
    group.categories &&
      group.categories.forEach(item => {
        let filtered = [];
        item.apis &&
          item.apis.forEach(api => {
            api.serviceName = item.name;
            api.id = getRandomString();
            if (api.authorizedProducts) {
              if (api.authorizedProducts.includes(product)) {
                filtered = [...filtered, api];
              }
            } else filtered = [...filtered, api];
          });
        items = items.concat(filtered);
      });
    group.categories = items;
    return group;
  });
};

import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  withModal,
  TmoButtons,
  TmoInput,
  FormModal
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import mobileAppService from '../../services/mobileAppService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './EditDriveAppVersionItem.module.css';
import editIcon from '../../static/assets/icons/action/edit.svg';

function EditDriveAppVersionItem({ data, openModal, forceRefresh, product }) {
  const {
    distributionChannel,
    platform,
    minVersion,
    minBuildNumber,
    mostUpToDateVersion,
    mostUpToDateBuildNumber
  } = data;
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const driveAppVersionFieldSpecs = () => [
    {
      labelText: 'Min Version',
      fieldName: 'minVersion',
      formComponent: TmoInput,
      inputType: 'primary',
      defaultValue: minVersion,
      placeholder: 'Min Version'
    },
    {
      labelText: 'Min Build Number',
      fieldName: 'minBuildNumber',
      formComponent: TmoInput,
      inputType: 'primary',
      defaultValue: minBuildNumber,
      placeholder: 'Min Build Number'
    },
    {
      labelText: 'Most Up To Date Version',
      fieldName: 'mostUpToDateVersion',
      formComponent: TmoInput,
      inputType: 'primary',
      defaultValue: mostUpToDateVersion,
      placeholder: 'Most Up To Date Version'
    },
    {
      labelText: 'Most Up To Date Build Number',
      fieldName: 'mostUpToDateBuildNumber',
      formComponent: TmoInput,
      inputType: 'primary',
      defaultValue: mostUpToDateBuildNumber,
      placeholder: 'Most Up To Date Build Number'
    }
  ];

  const openEditModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      let formData = {
        MinVersion: formValues.minVersion,
        MinBuildNumber: formValues.minBuildNumber,
        MostUpToDateVersion: formValues.mostUpToDateVersion,
        MostUpToDateBuildNumber: formValues.mostUpToDateBuildNumber
      };
      trackCustomEvents(`Update App Version`, {
        distributionChannel: distributionChannel,
        platform: platform
      });
      try {
        let result = await mobileAppService.updateAppVersions({
          body: formData,
          distributionChannel: distributionChannel,
          platform: platform,
          syncUpAppName: product
        });
        if (result) {
          addSuccessMessage({
            message: 'Successfully updated app version'
          });
          forceRefresh();
        } else {
          addErrorMessage({ message: 'Failed to update app version' });
        }
      } catch (e) {
        addErrorMessage({ message: e.toString() });
      }
    }
  };
  return (
    <div className={style.container}>
      <TmoButtons
        onClick={openEditModal}
        type={'magenta_icon_btn'}
        icon={<ReactSVG src={editIcon} />}
        className={style.button}
        tooltip={'Edit App Version'}
      />
      <FormModal
        title={`Update Drive App Verison`}
        fieldSpecs={driveAppVersionFieldSpecs()}
        modalType={'slide'}
      />
    </div>
  );
}

export default withModal(EditDriveAppVersionItem);

import React from 'react';
import {
  ConfirmationModalV2,
  withModal
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import style from './TodoItem.module.css';

function TodoItem({ id, name, onRemove, openModal }) {
  const handleRemove = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      await onRemove({ id, name });
    }
  };

  const confirmationMessage = (
    <span>
      Are you sure you want to remove this item:{' '}
      <span className={style.remove_name}>{name}</span>?
    </span>
  );

  return (
    <div className={style.container}>
      <div className={style.name}>{name}</div>
      <div className={style.remove} onClick={handleRemove}>
        ×
      </div>
      <ConfirmationModalV2
        message={confirmationMessage}
        title="Confirm Removal"
        acceptButtonText="Delete Item"
        type="danger"
        usePortal={false}
        confirmInput
      />
    </div>
  );
}

export default withModal(TodoItem);

import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect
} from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import {
  DynamicList,
  TmoH3,
  TmoButtons as TmoButton,
  ContentContainer,
  DynamicSort,
  TmoSearch,
  PaginationV2,
  TmoDropDownSelect,
  TmoToggle
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import userService from '../../services/userService';
import Fetcher from '../../components/Fetcher';
import {
  SUA_ROLES,
  APP_MOBILE,
  SUA_ENVIRONMENTS,
  STORAGE_KEYS,
  STORAGE_TYPES,
  SUA_PAGES,
  NOTIFICATIONS_PAGE_SIZE_OPTIONS as AUTH_USERS_PAGE_SIZE_OPTIONS
} from '../../utils/app_constants';
import StatusContext from '../../context/StatusContext';
import config from '../../configs/config';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { useHistory } from 'react-router-dom';
import { storageRead, storageWrite } from 'utils/storageUtils';
import { hasValue } from 'utils/helper_functions';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';
import AuthUserItem from './AuthUserItem';
import Error from 'components/Error/Error';
import AddAuthUser from './AddAuthUser';

import style from './AuthUsers.module.css';
import paginationStyle from '../../static/assets/css/pagination.module.css';
import { RiSearchLine, IoIosArrowDown } from 'react-icons/all';
import arrowLeft from '../../static/assets/icons/left_arrow.svg';
import arrowRight from '../../static/assets/icons/right_arrow.svg';
import delete_icon from '../../static/assets/icons/action/delete.svg';

function AuthUser() {
  const [authUsers, setAuthUsers] = useState([]);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const [filteredList, setFilteredList] = useState();
  const [filteredListLength, setFilteredListLength] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [listView, setlistView] = useState(true);
  const searchtRef = useRef();
  const sortRef = useRef();
  const currentEnvironment = config.apiSettings.environment;
  const syncUpProductContext = useContext(SyncUpProductContext);
  let history = useHistory();

  const [sortOptions] = useState([
    { key: 'username', value: 'Name' },
    { key: 'modifiedDateTime', value: 'Date Modified' },
    { key: 'createdDateTime', value: 'Date Created' }
  ]);

  const selectOptions = AUTH_USERS_PAGE_SIZE_OPTIONS.map(o => ({
    key: o,
    value: `${o} Users`
  }));
  selectOptions.push({
    key: 'All',
    value: 'All Users'
  });

  const actionAuthUsersCallback = useCallback(
    () =>
      userService.getAuthUsers({
        syncUpAppName: syncUpProductContext.syncUpAppName
      }),
    [syncUpProductContext.syncUpAppName]
  );

  const deleteAuthUser = async userId => {
    try {
      trackCustomEvents(`Delete Auth User`, {
        userId
      });
      await userService.deleteAuthUser({
        syncUpAppName: syncUpProductContext.syncUpAppName,
        userId
      });
      const updatedAuthUsers = authUsers.filter(i => i.subject !== userId);
      setAuthUsers(updatedAuthUsers);
      addSuccessMessage({ message: 'User deleted successfully' });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const viewUserDetail = (userId, firstName, lastName, product) => {
    const pageUrl = SUA_PAGES.USER.path.replace(':userId', userId);
    const userTab = {
      name: `${firstName} ${lastName}`,
      userId: userId,
      url: `${pageUrl}/things`,
      product: product
    };
    let updatedItems = [];
    const userTabs = storageRead({
      key: STORAGE_KEYS.USER_TABS,
      storageType: STORAGE_TYPES.SESSION
    });
    let parsedUserTabs = userTabs && JSON.parse(userTabs);

    if (
      parsedUserTabs &&
      !(
        parsedUserTabs.filter(u => u.userId === userId && u.product === product)
          .length > 0
      )
    ) {
      updatedItems = [...parsedUserTabs, userTab];
    }
    if (!parsedUserTabs) {
      updatedItems = [...updatedItems, userTab];
    }
    hasValue(updatedItems) &&
      storageWrite({
        key: STORAGE_KEYS.USER_TABS,
        value: JSON.stringify(updatedItems),
        storageType: STORAGE_TYPES.SESSION
      });
    history.push(`${pageUrl}/things`);
  };

  const headerNameListItemMap = [
    {
      listItemKey: 'username',
      headerDisplayName: 'Username',
      columnClass: style.user_column
    },
    {
      listItemKey: 'subject',
      headerDisplayName: 'Subject'
    },
    {
      listItemKey: 'createdDateTime',
      headerDisplayName: 'Created Date'
    },
    {
      listItemKey: 'createdBy',
      headerDisplayName: 'Created By'
    },
    {
      listItemKey: 'modifiedDateTime',
      headerDisplayName: 'Modified Date'
    },
    {
      listItemKey: 'lastModifiedBy',
      headerDisplayName: 'Modified By'
    },
    {
      listItemKey: 'allowedScopes',
      headerDisplayName: 'Allowed Scopes',
      render: ({ allowedScopes }) =>
        allowedScopes && <> {allowedScopes && allowedScopes.join(', ')} </>
    },
    {
      listItemKey: 'userId',
      headerDisplayName: 'App User',
      render: ({ userId, firstName, lastName }) =>
        userId && (
          <span
            className={style.user_id_column}
            onClick={() => viewUserDetail(userId, firstName, lastName)}
          >
            {userId}
          </span>
        )
    },
    {
      listItemKey: 'subject',
      headerDisplayName: 'Delete User',
      render: ({ subject }) => (
        <div className={style.delete_action}>
          <TmoButton
            onClick={() => deleteAuthUser(subject)}
            type="magenta_link"
            icon={<ReactSVG src={delete_icon} />}
          />
        </div>
      )
    }
  ];

  const handleOnAdd = newUser => {
    setAuthUsers([...authUsers, newUser]);
  };

  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const getCurrentPageUsersList = () => {
    if (filteredList && filteredList.length) {
      const offset = (currentPage - 1) * usersPerPage;
      return filteredList.slice(offset, offset + usersPerPage);
    }
    return [];
  };

  const showPagination = filteredList && filteredList?.length > usersPerPage;

  const handleSearchFilter = data => {
    if (data) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (authUsers && authUsers.length) {
      setFilteredList(authUsers);
      setFilteredListLength(authUsers.length);
    } else {
      setFilteredList([]);
      setFilteredListLength(0);
    }
  }, [authUsers]);

  return (
    <Authorized roles={[SUA_ROLES.ADMIN]}>
      <ContentContainer className={style.container}>
        <PageHeader
          title={'AUTHORIZED USERS'}
          primary
          subtitle={
            filteredListLength > 1 &&
            `Totally ${filteredListLength} users available`
          }
        >
          <TmoSearch
            placeholder="Search by User Name"
            originalList={authUsers}
            options={['username']}
            onFilter={handleSearchFilter}
            ref={searchtRef}
            renderIcon={<RiSearchLine />}
          />
          <DynamicSort
            ref={sortRef}
            listItems={filteredList}
            optionsList={sortOptions}
            onSort={e => {
              handleOnSort(e);
            }}
            renderIcon={<IoIosArrowDown />}
          />
          <AddAuthUser authUsers={authUsers} onAdd={handleOnAdd} />
        </PageHeader>

        {/* Temporarily disabled Get Authorized Users for Kids Watch in STAGE to support Testing */}
        {syncUpProductContext.syncUpAppName === APP_MOBILE.WATCH &&
        currentEnvironment === SUA_ENVIRONMENTS.STAGING ? (
          <TmoH3 className={style.disabled_message}>
            Authorized users feature temporarily disable to support load
            testing.
          </TmoH3>
        ) : (
          <Fetcher
            action={actionAuthUsersCallback}
            onLoad={setAuthUsers}
            render={() =>
              hasValue(filteredList) ? (
                <>
                  <div
                    className={classNames(
                      paginationStyle.pagination_wrapper,
                      style.controls_wrapper
                    )}
                  >
                    <div className={paginationStyle.page_size}>
                      <TmoDropDownSelect
                        optionsList={selectOptions}
                        onChange={e => {
                          if (e === 'All') {
                            setUsersPerPage(
                              filteredList && filteredList.length
                                ? filteredList.length + 1
                                : 0
                            );
                          } else {
                            setUsersPerPage(Number(e));
                          }
                          setCurrentPage(1);
                        }}
                        renderIcon={<IoIosArrowDown />}
                        defaultSelectedValue={
                          selectOptions.filter(e => e.key === usersPerPage)[0]
                        }
                      />
                    </div>

                    {showPagination && (
                      <div>
                        <PaginationV2
                          className={classNames(
                            paginationStyle.custom_pagination,
                            style.controls_wrapper
                          )}
                          totalPages={Math.ceil(
                            filteredList.length / usersPerPage
                          )}
                          selectedPageNumber={currentPage}
                          onPageChanged={currentPage =>
                            setCurrentPage(currentPage)
                          }
                          type="primary"
                          icons={{
                            left: <ReactSVG src={arrowLeft} />,
                            right: <ReactSVG src={arrowRight} />
                          }}
                        />
                      </div>
                    )}

                    <TmoToggle
                      label={'List View'}
                      isChecked={listView}
                      onChange={() => setlistView(!listView)}
                    />
                  </div>
                  <div className={style.results}>
                    {getCurrentPageUsersList && hasValue(filteredList) ? (
                      <>
                        {listView ? (
                          <DynamicList
                            headerNameListItemMap={headerNameListItemMap}
                            listItems={getCurrentPageUsersList()}
                            type="primary"
                          />
                        ) : (
                          getCurrentPageUsersList().map((a, i) => (
                            <AuthUserItem
                              key={i}
                              authUser={a}
                              onDelete={deleteAuthUser}
                            />
                          ))
                        )}
                      </>
                    ) : (
                      <Error
                        heading="No Authorised Users are available"
                        message="Try with a different search criteria"
                      />
                    )}
                  </div>
                </>
              ) : (
                <Error
                  heading="No Authorised Users are available"
                  message="Please add a new authorised User"
                />
              )
            }
          />
        )}
      </ContentContainer>
    </Authorized>
  );
}

export default AuthUser;

import React, { useState, useContext, useCallback } from 'react';
import { TmoCard } from '@tmobile/tmo-bps-syncup-web-component-lib';

import platformService from '../../services/platformService';
import Fetcher from '../Fetcher';
import SupportGroup from './SupportGroup';
import SearchedUserContext from '../../context/SearchedUserContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';

import style from './SupportGroupsGrid.module.css';

function SupportGroupsGrid() {
  useContext(SearchedUserContext);

  const [contacts, setContacts] = useState();
  const syncUpProductContext = useContext(SyncUpProductContext);

  const actionCallback = useCallback(
    () =>
      platformService.getSupportGroupsList({
        syncUpAppName: syncUpProductContext.syncUpAppName
      }),
    [syncUpProductContext.syncUpAppName]
  );

  return (
    <TmoCard className={style.container}>
      <Fetcher
        action={actionCallback}
        onLoad={setContacts}
        render={() =>
          contacts?.count > 0 ? (
            <>
              {contacts.data.map((contact, index) => (
                <SupportGroup key={index} data={contact} />
              ))}
            </>
          ) : (
            <span className={style.message}>
              <span>0</span> Contacts
            </span>
          )
        }
      />
    </TmoCard>
  );
}

export default SupportGroupsGrid;

import React, { useState, useCallback, useContext } from 'react';

import Fetcher from '../../Fetcher';
import deviceService from '../../../services/deviceService';
import HardwareDetailsGridItem from './HardwareDetailsGridItem';
import ActiveUserTabContext from '../../../context/ActiveUserTabContext';

function HardwareDetailsGrid({ imei }) {
  const [hardwareDetails, setHardwareDetails] = useState({});
  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);
  const actionCallback = useCallback(async () => {
    return deviceService.getHardwareDetails({
      imei,
      syncUpAppName: product
    });
  }, [imei, product]);

  return (
    <Fetcher
      action={actionCallback}
      onLoad={setHardwareDetails}
      render={() =>
        hardwareDetails ? (
          <HardwareDetailsGridItem imei={imei} list={hardwareDetails} />
        ) : (
          'No Hardware Details'
        )
      }
    />
  );
}

export default HardwareDetailsGrid;

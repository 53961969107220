import React, { useContext, useState } from 'react';
import {
  TmoCard,
  TmoH1,
  TmoH2,
  TmoButtons
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import firmwareService from '../../services/firmwareService';
import StatusContext from '../../context/StatusContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './PackageUpload.module.css';

function PackageUpload() {
  const [inputFile, setInputFile] = useState(null);
  const [uploadedPackage, setUploadedPackage] = useState(null);
  const { addErrorMessage } = useContext(StatusContext);

  const handleInputChange = e => {
    setInputFile(e.target.files[0]);
    setUploadedPackage(null);
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', inputFile);
    trackCustomEvents(`Firmware Package Upload`);
    try {
      let binaryFile = await firmwareService.postFirmwarePackage({
        body: formData,
        syncUpAppName: 'PETS'
      });
      setUploadedPackage(binaryFile);
      setInputFile(null);
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  return (
    <TmoCard>
      <TmoH1 flush>Upload Firmware Package</TmoH1>
      <form className={style.form} onSubmit={onFormSubmit}>
        <label htmlFor="file">Select File:</label>
        <input
          type="file"
          name="file"
          id="file"
          className={style.file_input}
          placeholder="Firmware Package File"
          onChange={handleInputChange}
        />

        <TmoButtons
          className={style.action}
          disabled={!inputFile}
          buttonType="submit"
        >
          Upload
        </TmoButtons>
      </form>
      {uploadedPackage && (
        <div className={style.result}>
          <TmoH2 className={style.result_title} flush>
            Upload successful
          </TmoH2>
          <label className={style.label}>
            NAME: <span>{uploadedPackage.packageName}</span>
          </label>
          <label className={style.label}>
            URL: <span>{uploadedPackage.packageUrl}</span>
          </label>
        </div>
      )}
    </TmoCard>
  );
}

export default PackageUpload;

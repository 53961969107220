import React from 'react';

import classNames from 'classnames';

import style from './StatusMark.module.css';

function StatusMark({ className, status, size = 'sm' }) {
  return (
    <span
      className={classNames(
        style.container,
        style[status],
        style[size],
        className
      )}
    />
  );
}

export default StatusMark;

import commercialTrackerRepository from '../repositories/commercialTrackerRepository';
import pocRepository from '../repositories/pocRepository';

const commercialTrackerService = {
  getCoolpadGetFormattedLocationByAsset: async () => {
    return await commercialTrackerRepository.getCoolpadGetFormattedLocationByAsset();
  },
  getCoolpadGetFormattedLocationForAllAssets: async () => {
    return await commercialTrackerRepository.getCoolpadGetFormattedLocationForAllAssets();
  },
  getCommercialTagDataStatus: async ({ productId, deviceId }) => {
    const deviceList = await pocRepository.getDevices({ productId });
    return deviceList
      ? deviceList.filter(device => device.deviceId === deviceId)
      : {};
  },
  updateCommercialDoorLocksStatus: async ({
    productId,
    deviceId,
    desiredState
  }) => {
    return await pocRepository.setDesiredState({
      productId,
      deviceId,
      desiredState
    });
  }
};

export default commercialTrackerService;

import React, { useContext, useEffect, useState } from 'react';
import {
  RawDataObject,
  StatusText,
  TmoH3,
  LabelWithInput,
  TmoInput,
  TmoButtons as TmoButton,
  TmoTextarea
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import { prettifyCamelCase } from 'utils/stringUtils';
import classNames from 'classnames';
import SyncUpProductContext from 'context/SyncUpProductContext';

import style from './APIActions.module.css';
import { FaSpinner } from 'react-icons/all';
import { hasValue } from 'utils/helper_functions';

const TryItOut = ({ item, onExecuteApi }) => {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const {
    param: itemParam,
    apiDetails: { requestParameters, requestBody, url }
  } = item;
  const [inputFields, setInputFields] = useState();
  const [isValidJson, setIsValidJson] = useState(true);
  const [loading, setLoading] = useState(false);
  const [requestResponse, setRequestResponse] = useState();
  const [jsonString, setJsonString] = useState(
    JSON.stringify(requestBody, undefined, 2)
  );
  const handleSubmit = async event => {
    setLoading(true);
    event.preventDefault();
    let param = { ...itemParam };
    inputFields &&
      inputFields.forEach(e => {
        param = { ...param, [e.name]: e.value };
      });
    param = {
      ...param,
      responseStatus: true,
      syncUpAppName: syncUpProductContext.syncUpAppName,
      product: syncUpProductContext.syncUpAppName
    };
    if (requestBody) {
      param = { ...param, body: JSON.parse(jsonString) };
    }
    const response = await item.method(param);
    setRequestResponse(response);
    item.status = response.status;
    onExecuteApi(item);
    setLoading(false);
  };
  useEffect(() => {
    if (requestParameters) {
      let inputs = [];
      requestParameters.forEach(e => {
        if (!e.hiddenInput) {
          inputs = [
            ...inputs,
            {
              name: e.name,
              displayName: e.displayName,
              value: e.value,
              required: e.required
            }
          ];
        }
      });
      setInputFields(inputs);
    }
  }, [requestParameters]);

  const handleFormChange = (index, v) => {
    let data = [...inputFields];
    data[index].value = v;
    setInputFields(data);
  };
  const isJsonString = str => {
    try {
      var json = JSON.parse(str);
      return typeof json === 'object';
    } catch (e) {
      return false;
    }
  };

  const handleTextAreaChange = value => {
    setJsonString(value);
    if (isJsonString(value)) {
      setIsValidJson(true);
    } else {
      setIsValidJson(false);
    }
  };

  return (
    <>
      {!hasValue(requestParameters) && <> {url}</>}
      <form onSubmit={handleSubmit}>
        <div className={style.input_grid}>
          {inputFields &&
            inputFields.map((input, index) => (
              <LabelWithInput
                className={style.input}
                labelText={
                  input.displayName
                    ? input.displayName
                    : prettifyCamelCase(input.name)
                }
                name={input.name}
                key={input.name}
                formComponent={TmoInput}
                value={input.value}
                inputType="primary"
                title={input.value}
                required={input.required}
                onChange={v => handleFormChange(index, v)}
              />
            ))}
        </div>
        {requestBody && (
          <>
            <label> Body </label>
            <TmoTextarea
              className={classNames(
                style.text_area,
                !isValidJson ? style.text_area_error : null
              )}
              type={'primary'}
              name={'body'}
              value={jsonString}
              onChange={v => handleTextAreaChange(v)}
            ></TmoTextarea>
            {!isValidJson && (
              <span className={style.error_msg}> Please enter valid JSON </span>
            )}
          </>
        )}
        <div className={style.button_wrapper}>
          {loading ? (
            <TmoButton type="magenta_primary">
              <FaSpinner className={style.spinner} /> <span> loading </span>
            </TmoButton>
          ) : (
            <TmoButton
              type="magenta_primary"
              buttonType="submit"
              disabled={!isValidJson}
            >
              Execute
            </TmoButton>
          )}
        </div>
      </form>
      {requestResponse && (
        <>
          <TmoH3 className={style.response_title}>
            Server response :
            {requestResponse.status && (
              <StatusText
                status={
                  requestResponse.status >= 200 && requestResponse.status < 300
                    ? 'success'
                    : requestResponse.status === 404
                    ? 'warning'
                    : 'alert'
                }
              >
                {requestResponse.status}
              </StatusText>
            )}
          </TmoH3>
          {requestResponse.response && isJsonString(requestResponse.response) && (
            <div className={style.response_wrapper}>
              <RawDataObject rawObject={JSON.parse(requestResponse.response)} />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default TryItOut;

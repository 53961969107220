import React, { useState, useContext, useCallback, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { FaPlus } from 'react-icons/all';
import {
  TmoInput,
  TmoButtons as TmoButton,
  FormModal,
  TmoSvg,
  TmoRadioGroup,
  withModal,
  DynamicSort,
  FilterModal,
  TmoSearch,
  TmoDropDownSelect
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import mobileAppService from '../../services/mobileAppService';
import Fetcher from '../Fetcher';
import AppVersionItem from './AppVersionItem';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { getRandomString } from '../../utils/stringUtils';
import StatusContext from '../../context/StatusContext';
import useVisible from '../../hooks/useVisible';
import { hasValue } from '../../utils/helper_functions';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import Error from '../../components/Error/Error';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';
import { APP_MOBILE } from '../../utils/app_constants';

import style from './AppVersionsList.module.css';

import { RiSearchLine, BsFilterLeft, IoIosArrowDown } from 'react-icons/all';

function AppVersionsList({ match: { url }, history, openModal }) {
  const [versionList, setVersionList] = useState();
  const [filteredList, setFilteredList] = useState();
  const [filteredListLength, setFilteredListLength] = useState(0);
  const syncUpProductContext = useContext(SyncUpProductContext);
  let osVersion = url.split('app-versions/')[1] === 'ios' ? 'iOS' : 'ANDROID';
  const [refreshKey, setRefreshKey] = useState(
    getRandomString() + syncUpProductContext.syncUpAppName
  );
  const forceRefresh = () => setRefreshKey(getRandomString());
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const { visible, setVisible } = useVisible(false);
  const [sortOptions] = useState([
    { key: 'id', value: 'ID' },
    { key: 'platform', value: 'Platform Type' },
    { key: 'buildNumber', value: 'Build Number' },
    { key: 'distributionChannel', value: 'Distribution Channel' },
    { key: 'version', value: 'Version' }
  ]);
  const [driveSortOptions] = useState([
    { key: 'platform', distributionChannelvalue: 'Platform Type' },
    { key: '', value: 'Distribution Channel' },
    { key: 'minVersion', value: 'Min Version' },
    { key: 'minBuildNumber', value: 'Min Build Number' },
    { key: 'mostUpToDateVersion', value: 'Most Up To Date Version' },
    { key: 'mostUpToDateBuildNumber', value: 'Most Up To Date Build Number' }
  ]);

  const [filterOptions] = useState([
    { key: 'platform', value: 'Platform Type' },
    { key: 'distributionChannel', value: 'Distribution Channel' }
  ]);

  const sortRef = useRef();
  const searchtRef = useRef();
  const filtertRef = useRef();
  const formFieldSpecs = [
    {
      labelText: 'Platform',
      fieldName: 'platform',
      formComponent: TmoRadioGroup,
      data: {
        radioGrp: 'iconPosition-radio',
        defaultValue: 'ANDROID',
        options: ['ANDROID', 'iOS']
      },
      defaultValue: osVersion,
      className: style.radio_button
    },
    {
      labelText: 'Distribution Channel',
      fieldName: 'distributionChannel',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Assign config to device groups'
    },
    {
      labelText: 'Version',
      fieldName: 'version',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'App version'
    },
    {
      labelText: 'Build Number',
      fieldName: 'buildNumber',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'App build number'
    },
    {
      labelText: 'App update Required?',
      fieldName: 'required',
      required: true,
      formComponent: TmoDropDownSelect,
      optionsList: ['Yes', 'No'].map(k => ({
        key: k,
        value: k
      })),
      defaultSelectedValue: ['Yes', 'No'].map(k => ({
        key: k,
        value: k
      }))[0],
      defaultValue: 'Yes',
      renderIcon: <IoIosArrowDown />
    }
  ];
  const actionCallback = useCallback(async () => {
    let iosData = [];
    let androidData = [];
    let errorCount = 0;
    try {
      iosData = await mobileAppService.getAppVersions({
        osVersion: 'iOS',
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
    } catch (e) {
      iosData = [];
      errorCount++;
    }
    if (syncUpProductContext.syncUpAppName !== APP_MOBILE.DRIVE) {
      try {
        androidData = await mobileAppService.getAppVersions({
          osVersion: 'ANDROID',
          syncUpAppName: syncUpProductContext.syncUpAppName
        });
      } catch (e) {
        androidData = [];
        errorCount++;
      }
    }
    if (errorCount === 2) {
      // eslint-disable-next-line no-throw-literal
      throw 'Oops Something went wrong.';
    } else {
      return syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE
        ? iosData
        : [...iosData, ...androidData].sort((a, b) =>
            a.id.toString().localeCompare(b.id.toString())
          );
    }
  }, [syncUpProductContext.syncUpAppName]);

  const openAddModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Add New App Version`);
      setVisible(true);
      const body = {
        platform: formValues.platform,
        distributionChannel: formValues.distributionChannel,
        version: formValues.version,
        buildNumber: formValues.buildNumber,
        required: formValues.required === 'Yes' ? 'true' : 'false'
      };
      try {
        await mobileAppService.postAppVersion({
          syncUpAppName: syncUpProductContext.syncUpAppName,
          body: body
        });
        forceRefresh();
        addSuccessMessage({
          message: `Successfully added version: ${formValues.version}`
        });
        setVisible(false);
      } catch (error) {
        setVisible(false);
        addErrorMessage({ error });
      }
    }
  };

  const handleLoadData = useCallback(data => {
    setVersionList(data);
    setFilteredList([...data]);
    setFilteredListLength(data.length);
  }, []);
  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const handleOnFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (searchtRef?.current?.ApplyFilters) {
        searchtRef.current.ApplyFilters(data.list);
      }
    }
  };

  const handleSearchFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (filtertRef?.current?.ApplyFilters) {
        filtertRef.current.ApplyFilters(data.list);
      }
    }
  };

  const handleDeleteAppVersionClicked = async id => {
    try {
      await mobileAppService.deleteAppVersions({
        versionID: id,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      forceRefresh();
      addSuccessMessage({
        message: `App Version deleted successfully`
      });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  return (
    <>
      <PageHeader
        primary
        title={'App Version'}
        subtitle={
          filteredListLength > 1 && `${filteredListLength} versions available`
        }
      >
        <TmoSearch
          placeholder={
            syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE
              ? 'Search App Distribution Channel, Platform'
              : 'Search App Version, Id'
          }
          originalList={versionList}
          options={
            syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE
              ? ['distributionChannel', 'platform']
              : ['id', 'version']
          }
          onFilter={handleSearchFilter}
          ref={searchtRef}
          renderIcon={<RiSearchLine />}
        />
        <DynamicSort
          ref={sortRef}
          listItems={filteredList}
          optionsList={
            syncUpProductContext.syncUpAppName === APP_MOBILE.DRIVE
              ? driveSortOptions
              : sortOptions
          }
          onSort={e => {
            handleOnSort(e);
          }}
          renderIcon={<IoIosArrowDown />}
        />
        <FilterModal
          ref={filtertRef}
          options={filterOptions}
          originalList={versionList}
          listItems={filteredList}
          onFilter={handleOnFilter}
          renderIcon={<BsFilterLeft />}
        />
        {syncUpProductContext.syncUpAppName !== APP_MOBILE.DRIVE && (
          <>
            <TmoButton
              type="magenta_primary"
              icon={<FaPlus />}
              onClick={openAddModal}
            >
              Add New App Version
            </TmoButton>
            <FormModal
              title={`Add new App Version`}
              fieldSpecs={formFieldSpecs}
              modalType={'slide'}
            />
          </>
        )}
      </PageHeader>

      {visible && <TmoSvg className={style.spinner} svgId="loading-spinner" />}
      <div>
        <Fetcher
          key={refreshKey}
          action={actionCallback}
          onLoad={handleLoadData}
          render={() =>
            hasValue(filteredList) ? (
              <>
                <div className={style.items}>
                  {filteredList.map(v => (
                    <AppVersionItem
                      data={v}
                      key={v.id}
                      action={actionCallback}
                      onDelete={handleDeleteAppVersionClicked}
                      product={syncUpProductContext.syncUpAppName}
                      forceRefresh={forceRefresh}
                    />
                  ))}
                </div>
              </>
            ) : (
              <Error
                heading="No App Versions are available"
                message="Please add a new app version"
              />
            )
          }
        />
      </div>
    </>
  );
}

export default withModal(withRouter(AppVersionsList));

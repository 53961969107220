import React, { useContext } from 'react';

import LoggedInUserContext from '../../context/LoggedInUserContext';
import { getOktaGroups } from '../../utils/helper_functions';

function RoleFeature({ groups, children, ...rest }) {
  const { userInfo = {} } = useContext(LoggedInUserContext);
  const oktaGroups = userInfo ? getOktaGroups(userInfo) : [];
  const showFeature = groups.some(r => oktaGroups?.includes(r));

  return showFeature ? <>{children}</> : null;
}

export default RoleFeature;

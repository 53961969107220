import React, { useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  TmoButtons as TmoButton,
  withModal,
  TmoH3,
  RawData,
  ConfirmationModalV2,
  ExpandableText
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import Fetcher from '../Fetcher';
import deviceService from '../../services/deviceService';
import userService from '../../services/userService';
import DeviceOnThing from './device/DeviceOnThing';
import DriveDevice from './device/DriveDevice';
import NoDeviceOnThing from './device/NoDeviceOnThing';
import HardwareDetailsGrid from './hardware/HardwareDetailsGrid';
import SensorDetails from '../sensors/SensorDetails';
import {
  IOT_PRODUCTS,
  APP_MOBILE,
  THING_TYPES
} from '../../utils/app_constants';
import Feature from '../featureWrappers/Feature';
import { DEVICE_MODELS } from '../../utils/deviceConsts';
import VehicleDetails from './thingDetails/VehicleDetails';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import DeviceTwin from './device/DeviceTwin';
import ThingDetails from './thingDetails/ThingDetails';
import TaskDetails from 'components/tasks/TaskDetails';
import PetDetails from './thingDetails/PetDetails.jsx';
import IssueCommands from '../../components/issueCommands/IssueCommands';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import CreateDeviceEvent from 'components/drive/CreateDeviceEvent';
import StatusMark from '../textHelpers/StatusMark';
import HardwareDetailsHeader from './hardware/HardwareDetailsHeader';
import AddDeviceRoadsideServices from 'components/drive/roadsideServices/AddDeviceRoadsideServices';

import style from './Thing.module.css';
import events from '../../static/assets/icons/events_icon.svg';
import trips from '../../static/assets/icons/trips_icon.svg';
import delete_icon from '../../static/assets/icons/action/delete.svg';
import roadSide from '../../static/assets/icons/roadside_icon.svg';
import privacy_icon from '../../static/assets/icons/privacy_status.svg';
import logs from '../../static/assets/icons/logs_icon.svg';
import { FaCaretDown } from 'react-icons/all';

function Thing({
  thing,
  onAddDevice,
  onRemoveDevice,
  onRemoveThing,
  openModal,
  productType
}) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [deviceTwin, setDeviceTwin] = useState();
  const [userTasks, setUserTasks] = useState();
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());

  let history = useHistory();

  const actionCallback = useCallback(
    () =>
      thing?.currentDevice &&
      deviceService.getDeviceTwin({
        deviceId: thing?.currentDevice?.hardwareId,
        syncUpAppName: product
      }),
    [thing.currentDevice, product]
  );

  const actionUserTaskCallback = useCallback(
    () =>
      userService.getUserTasksDetailsByThing({
        userId,
        thingId: thing.id,
        syncUpAppName: product
      }),
    [userId, thing.id, product]
  );

  const handleRemoveThingClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Remove Thing - ${thing.thingType}`, {
        thingId: thing.id,
        name: thing.name
      });
      await onRemoveThing({ thingId: thing.id, name: thing.name });
    }
  };

  const handleRemoveDeviceClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Device`, {
        deviceId:
          product === APP_MOBILE.DRIVE
            ? thing.device.id
            : thing.currentDevice.id,
        thingId: thing.id
      });
      await onRemoveDevice({
        deviceId:
          product === APP_MOBILE.DRIVE
            ? thing.device.id
            : thing.currentDevice.id,
        thingId: thing.id
      });
    }
  };

  const thingComponent = thing => {
    if (product === APP_MOBILE.DRIVE && thing?.product === APP_MOBILE.DRIVE) {
      return (
        <VehicleDetails
          key={thing.id}
          vehicle={thing}
          onRemoveDevice={handleRemoveDeviceClicked}
          onAddDevice={onAddDevice}
          onRemoveThing={handleRemoveThingClicked}
        />
      );
    }
    switch (thing.thingType) {
      case THING_TYPES.PET:
        return (
          <PetDetails
            key={thing.id}
            pet={thing}
            onRemoveDevice={handleRemoveDeviceClicked}
            onAddDevice={onAddDevice}
            onRemoveThing={handleRemoveThingClicked}
          />
        );
      default:
        return (
          <ThingDetails
            thing={thing}
            onRemoveDevice={handleRemoveDeviceClicked}
            onAddDevice={onAddDevice}
            onRemoveThing={handleRemoveThingClicked}
          />
        );
    }
  };

  return (
    <div className={style.thing_container}>
      <div className={style.container_tab}>
        <div className={style.column}>
          <div className={style.heading_row}>
            <div className={style.heading_with_image}>
              <Feature Products={[APP_MOBILE.PETS, APP_MOBILE.DRIVE]}>
                {(thing.frontImageUrl || thing.avatarUrl) && (
                  <div className={style.row}>
                    <div className={style.avatar_container}>
                      <div
                        style={{
                          backgroundImage:
                            'url(' + thing.avatarUrl ||
                            thing.frontImageUrl + ')'
                        }}
                        className={style.avatar}
                      />
                    </div>
                  </div>
                )}
              </Feature>
              <TmoH3 className={style.heading} flush>
                {thing.name}
                <span className={style.status_mark}>
                  <StatusMark status={thing?.status?.toLowerCase()} />
                </span>
              </TmoH3>
            </div>
            <div className={style.action}>
              <RawData
                rawObject={thing}
                title={`Full ${thing.thingType ? thing.thingType : ''} Details`}
                tooltip={`Full ${
                  thing.thingType ? thing.thingType : ''
                } Details`}
                type=" "
                usePortal={false}
                modalType={'slide'}
                buttonType="magenta_primary"
              />
              <Feature isUserDetails Products={[APP_MOBILE.DRIVE]}>
                <TmoButton
                  onClick={() => history.push(`things/${thing.id}/trips`)}
                  type="magenta_primary"
                  tooltip="Trips"
                  icon={<ReactSVG src={trips} />}
                />
                <TmoButton
                  onClick={() =>
                    history.push(
                      `things/${thing.id}/device/${
                        thing.currentDevice
                          ? thing.currentDevice.id
                          : thing.device.id
                      }/events`
                    )
                  }
                  className={style.action_button}
                  type="magenta_primary"
                  tooltip="Events"
                  icon={<ReactSVG src={events} />}
                />
              </Feature>
              {thing?.device?.roadsideStatus === 'ENROLLED' && (
                <>
                  <TmoButton
                    onClick={() =>
                      history.push(`devices/${thing.id}/roadside-services`)
                    }
                    type="magenta_primary"
                    tooltip="Roadside Services"
                    icon={<ReactSVG src={roadSide} />}
                  />
                  <AddDeviceRoadsideServices thingId={thing.id} />
                </>
              )}
              <Feature isUserDetails Products={[APP_MOBILE.WATCH]}>
                {/* Add School Mode Feature // To-Do*/}
                {thing.id &&
                  ((thing.currentDevice && thing.currentDevice.id) ||
                    (thing.device && thing.device.id)) && (
                    <TmoButton
                      onClick={() =>
                        history.push(
                          `things/${thing.id}/device/${
                            thing.currentDevice
                              ? thing.currentDevice.id
                              : thing.device.id
                          }/logs`
                        )
                      }
                      type="magenta_primary"
                      tooltip="Logs"
                      icon={<ReactSVG src={logs} />}
                    />
                  )}
              </Feature>
              <TmoButton
                onClick={handleRemoveThingClicked}
                type="magenta_secondary"
                tooltip="Delete"
                icon={<ReactSVG src={delete_icon} />}
              />
              <ConfirmationModalV2
                message={
                  <div className={style.label}>
                    {`Are you sure you want to remove the ${thing.thingType} `}
                    <span className={style.value}>{thing.name}</span>?
                  </div>
                }
                title={`Delete ${thing.thingType}`}
                acceptButtonText={`Delete ${thing.thingType}`}
                type="danger"
                usePortal={false}
                confirmInput
                modalType="delete"
              />
            </div>
          </div>
          <div className={style.thing_details_container}>
            {thingComponent(thing)}
            <Feature isUserDetails Products={[APP_MOBILE.WATCH]}>
              <div className={style.items}>
                <label className={style.label}>Tasks</label>
                <ExpandableText
                  triggerText="View"
                  closeText="Close"
                  triggerIcon={<FaCaretDown />}
                >
                  <Fetcher
                    key={refreshKey}
                    action={actionUserTaskCallback}
                    onLoad={setUserTasks}
                    render={() =>
                      userTasks?.length > 0 ? (
                        <>
                          {userTasks.map(userTask => (
                            <TaskDetails
                              userTask={userTask}
                              forceRefresh={forceRefresh}
                            />
                          ))}
                        </>
                      ) : (
                        <span className={style.message}>
                          No Tasks are available
                        </span>
                      )
                    }
                  />
                </ExpandableText>
              </div>
            </Feature>
          </div>
        </div>

        {/* Device Details */}
        <div className={style.column}>
          <div className={style.heading_row}>
            <TmoH3 className={style.heading} flush>
              DEVICE DETAILS
            </TmoH3>
            {thing.currentDevice || thing.device ? (
              <div className={classNames(style.row, style.action)}>
                <RawData
                  rawObject={
                    thing.currentDevice ? thing.currentDevice : thing.device
                  }
                  title="Full Device Details"
                  tooltip="Full Device Details"
                  type=" "
                  usePortal={false}
                  modalType={'slide'}
                  buttonType="magenta_primary"
                />
                {thing.thingType === 'PETS' && (
                  <TmoButton
                    onClick={() =>
                      history.push({
                        pathname: `devices/${thing.id}/hardware/${thing.currentDevice.id}/logs`,
                        state: { isThingTab: true }
                      })
                    }
                    type="magenta_secondary"
                    tooltip="Logs"
                    icon={<ReactSVG src={logs} />}
                  />
                )}
                <Feature
                  isUserDetails
                  Products={[
                    APP_MOBILE.PETS,
                    APP_MOBILE.TRACKER,
                    APP_MOBILE.WATCH
                  ]}
                >
                  <IssueCommands
                    isUserDetails
                    deviceId={thing.currentDevice?.id}
                    hardwareId={thing.currentDevice?.hardwareId}
                  />
                </Feature>
                <Feature isUserDetails Products={[APP_MOBILE.DRIVE]}>
                  {thing?.device?.simulatorType === 'NONE' && (
                    <TmoButton
                      onClick={() =>
                        history.push(
                          `things/device/${thing.device.id}/privacy-status`
                        )
                      }
                      type="magenta_primary"
                      tooltip="Privacy Status"
                      icon={<ReactSVG src={privacy_icon} />}
                    />
                  )}
                  <CreateDeviceEvent
                    deviceId={
                      thing.currentDevice
                        ? thing.currentDevice.id
                        : thing.device.id
                    }
                  />
                  {thing.device?.status !== 'ACTIVE' && (
                    <TmoButton
                      onClick={handleRemoveDeviceClicked}
                      type="magenta_secondary"
                      tooltip="Delete"
                      icon={<ReactSVG src={delete_icon} />}
                    />
                  )}
                </Feature>
                <Feature
                  isUserDetails
                  Products={[
                    APP_MOBILE.PETS,
                    APP_MOBILE.TRACKER,
                    APP_MOBILE.WATCH
                  ]}
                >
                  <TmoButton
                    onClick={handleRemoveDeviceClicked}
                    type="magenta_secondary"
                    tooltip="Delete"
                    icon={<ReactSVG src={delete_icon} />}
                  />
                </Feature>
                <ConfirmationModalV2
                  message={
                    <div className={style.label}>
                      {'Are you sure you want to remove the device '}
                      <span className={style.value}>
                        {thing.currentDevice
                          ? thing.currentDevice.id
                          : thing.device.id}
                      </span>
                      ?
                    </div>
                  }
                  title="Delete Device"
                  acceptButtonText="Yes,Delete"
                  type="danger"
                  usePortal={false}
                  confirmInput
                  modalType="delete"
                />
              </div>
            ) : (
              <NoDeviceOnThing
                thingType={thing.thingType}
                thingName={thing.name}
                onAddDevice={onAddDevice}
                thingId={thing.id}
              />
            )}
          </div>
          {thing?.currentDevice ? (
            <DeviceOnThing
              thingName={thing.name}
              device={thing.currentDevice}
              type="primary_display"
            />
          ) : thing?.device ? (
            <DriveDevice thingName={thing.name} device={thing.device} />
          ) : (
            <div className={style.text}>
              There is no device for the {thing.thingType} -
              <strong>{thing.name}</strong>
            </div>
          )}
        </div>

        {/* Device TWIN Details */}
        {IOT_PRODUCTS.includes(productType) && (
          <Fetcher
            action={actionCallback}
            onLoad={setDeviceTwin}
            render={() =>
              deviceTwin ? (
                <div className={style.column}>
                  <div className={style.heading_row}>
                    <TmoH3 className={style.heading} flush>
                      DEVICE TWIN DETAILS
                    </TmoH3>
                    <div className={classNames(style.row, style.action)}>
                      <RawData
                        rawObject={deviceTwin}
                        title="Device Twin Details"
                        tooltip="Device Twin Details"
                        type=" "
                        usePortal={false}
                        modalType={'slide'}
                        buttonType="magenta_primary"
                      />
                    </div>
                  </div>
                  <DeviceTwin device={deviceTwin} />
                </div>
              ) : null
            }
          />
        )}

        {/* Hardware Details */}
        {thing?.currentDevice &&
          thing.currentDevice?.product === DEVICE_MODELS.PETS1 && (
            <div className={style.column}>
              <>
                <div className={style.heading_row}>
                  <TmoH3 className={style.heading} flush>
                    HARDWARE DETAILS
                  </TmoH3>
                  <HardwareDetailsHeader
                    imei={thing.currentDevice?.hardwareId}
                  />
                </div>
                <HardwareDetailsGrid
                  hardware={thing.currentDevice}
                  imei={thing.currentDevice?.hardwareId}
                />
              </>
            </div>
          )}
      </div>
      <Feature
        isUserDetails
        Products={[APP_MOBILE.PETS, APP_MOBILE.TRACKER, APP_MOBILE.WATCH]}
      >
        <SensorDetails thing={thing} />
      </Feature>
    </div>
  );
}

export default withModal(Thing);

import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  ContentContainer,
  NavigationTabGroup,
  NavigationTab
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import FirmwarePackages from '../components/firmware/Packages';
import PackageUpload from '../components/firmware/PackageUpload';
import RoleFeature from '../components/featureWrappers/RoleFeature';
import FirmwareGroups from '../components/firmware/Groups';
import StatusSearch from '../components/firmware/status/StatusSearch';
import SyncUpProductContext from '../context/SyncUpProductContext';
import Configurations from '../components/assetManager/Configurations';
import { APP_MOBILE, IOT_ASSET_TYPES } from '../utils/app_constants';
import Groups from '../components/assetManager/groups/Groups';
import GoBack from 'components/uiHelpers/goBack/GoBack';

import style from './FirmwarePage.module.css';

const baseUrl = '/firmware';
const packageUploadUrl = '/firmware/upload';
const groupsUrl = '/firmware/groups';
const configUrl = '/firmware/configurations';
const statusUrl = '/firmware/status';

function FirmwarePage() {
  const syncUpProductContext = useContext(SyncUpProductContext);

  switch (syncUpProductContext.syncUpAppName) {
    case APP_MOBILE.TRACKER:
    case APP_MOBILE.WATCH:
      return (
        <ContentContainer className={style.firmware_container}>
          <NavigationTabGroup type="primary">
            <NavigationTab to={baseUrl} text="Groups" type="primary-tab" />
            <NavigationTab
              to={configUrl}
              text="Configurations"
              type="primary-tab"
            />
          </NavigationTabGroup>
          <Switch>
            <Route
              exact
              path={baseUrl}
              render={() => <Groups mediaType={IOT_ASSET_TYPES.FIRMWARE} />}
              component={Groups}
            />
            <Route exact path={configUrl} component={Configurations} />
          </Switch>
        </ContentContainer>
      );
    default:
      return (
        <ContentContainer>
          <GoBack padded />
          <NavigationTabGroup>
            <NavigationTab to={baseUrl} text="Packages" />
            <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
              <NavigationTab to={packageUploadUrl} text="Upload" />
            </RoleFeature>
            <NavigationTab to={groupsUrl} text="Groups" />
            <NavigationTab to={statusUrl} text="Status" />
          </NavigationTabGroup>
          <Switch>
            <Route exact path={baseUrl} component={FirmwarePackages} />
            <Route exact path={packageUploadUrl} component={PackageUpload} />
            <Route exact path={groupsUrl} component={FirmwareGroups} />
            <Route exact path={statusUrl} component={StatusSearch} />
          </Switch>
        </ContentContainer>
      );
  }
}

export default FirmwarePage;

import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import TmoH2 from '../../../controlWrappers/TmoH2';
import TmoButton from '../../../forms/TmoButton';
import QRCodeImg from '../../../../static/assets/commercial/images/qr-code.jpg';

import './AddBulkDevice.css';

function AddBulkDevice({ history }) {
  const [inputText, setInputText] = useState();

  return (
    <div className="AddBulkDevice-container">
      <div className="AddBulkDevice-header">
        <TmoH2>Bulk Activation</TmoH2>
      </div>
      <div className="AddBulkDevice-body">
        <input
          className="AddBulkDevice-input"
          type="text"
          id="deviceId"
          placeholder="Pack ID"
          onInput={e => setInputText(e.target.value)}
        />
        <br />
        <label className="AddBulkDevice-label" for="deviceId">
          This can be found on the outside of the pack
        </label>
        <div className="AddBulkDevice-qrcode-div">
          <div className="AddBulkDevice-image-div">
            <img
              alt="qr-code"
              className="AddBulkDevice-image"
              src={QRCodeImg}
            />
          </div>
          <div className="AddBulkDevice-label-div">
            <label className="AddBulkDevice-qr-label" for="deviceId">
              Alternatively, scan the QR code on the pack using the app
            </label>
          </div>
        </div>
        <div className="AddBulkDevice-add-div">
          <TmoButton
            type="secondary-blue"
            onClick={() => history.push(`add/pack/${inputText}`)}
          >
            Add Pack
          </TmoButton>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AddBulkDevice);

import React from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import TimeDisplay from '../../uiHelpers/TimeDisplay';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import ItemDetailsModal from 'components/ItemDetailsModal';
import { GRID } from 'utils/app_constants';

import style from './LocationSensorDetails.module.css';
import clock from '../../../static/assets/icons/time.svg';

function LocationSensorDetails({ sensor, safeZones, index, isHistoryCard }) {
  const {
    altitude,
    latitude,
    longitude,
    radius,
    speed,
    timestamp,
    systemDate,
    updateDate,
    source,
    deviceId
  } = sensor;

  const getNumberOfSafeZones = safeZones => {
    if (safeZones) {
      const petSafeZoneas = safeZones.filter(safeZone =>
        safeZone.deviceIds.includes(deviceId)
      );
      return petSafeZoneas ? petSafeZoneas.length : 0;
    }
    return 'Updating...';
  };

  return (
    <>
      <div className={style.container}>
        <ItemDetailList
          className={classNames(
            isHistoryCard ? GRID.COLUMN_HALF : GRID.COLUMN_FORTH
          )}
          items={{
            ...(index && { index: index }),
            source,
            numberOfSafeZone: getNumberOfSafeZones(safeZones),
            speed
          }}
        />
        {!isHistoryCard && (
          <ItemDetailsModal
            label={`Timestamps`}
            buttonText={'View Timestamps'}
            className={GRID.COLUMN_FORTH}
          >
            <TimeDisplay
              title="Updated At"
              timestamp={updateDate}
              type="primary_time_display"
            />
            {timestamp && (
              <>
                <div className={style.separator} />
                <TimeDisplay
                  title="Timestamp"
                  timestamp={timestamp}
                  type="primary_time_display"
                />
              </>
            )}
            {systemDate && (
              <>
                <div className={style.separator} />
                <TimeDisplay
                  title="Timestamp"
                  timestamp={systemDate}
                  type="primary_time_display"
                />
              </>
            )}
          </ItemDetailsModal>
        )}
        <ItemDetailList
          className={classNames(
            isHistoryCard ? GRID.COLUMN_HALF : GRID.COLUMN_FORTH
          )}
          items={{
            latitude,
            longitude,
            altitude,
            radius
          }}
        />
        {isHistoryCard && (
          <div className={style.timestamp}>
            <ItemDetailsModal
              label={`Timestamps`}
              buttonText={
                <span className={style.timestamp}>
                  <ReactSVG src={clock} /> View Timestamps
                </span>
              }
              className={GRID.COLUMN_FULL}
            >
              <TimeDisplay
                title="Updated At"
                timestamp={updateDate}
                type="primary_time_display"
              />
              {timestamp && (
                <>
                  <div className={style.separator} />
                  <TimeDisplay
                    title="Timestamp"
                    timestamp={timestamp}
                    type="primary_time_display"
                  />
                </>
              )}
              {systemDate && (
                <>
                  <div className={style.separator} />
                  <TimeDisplay
                    title="Timestamp"
                    timestamp={systemDate}
                    type="primary_time_display"
                  />
                </>
              )}
            </ItemDetailsModal>
          </div>
        )}
      </div>
    </>
  );
}

export default LocationSensorDetails;

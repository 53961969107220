import React from 'react';
import { TmoCard, withModal } from '@tmobile/tmo-bps-syncup-web-component-lib';

import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './LogItem.module.css';

function LogItem({ data, isStatus }) {
  const {
    communicationType,
    communicationDirection,
    sessionId,
    contactId,
    displayName,
    type,
    timestamp,
    msisdn,
    duration,
    detail,
    message,
    status
  } = data;

  return (
    <TmoCard className={style.container}>
      {isStatus ? (
        <div className={style.content}>
          <ItemDetailList rowSet={[{ sessionId }]} />
          <ItemDetailList rowSet={[{ contactId }]} />
          <ItemDetailList rowSet={[{ message, detail }]} />
          <ItemDetailList
            rowSet={[
              {
                status,
                timestamp: timestamp
              }
            ]}
          />
        </div>
      ) : (
        <div className={style.content}>
          <ItemDetailList
            rowSet={[
              {
                'Communication Type': communicationType,
                Direction: communicationDirection
              }
            ]}
          />
          <ItemDetailList rowSet={[{ sessionId }]} />
          <ItemDetailList rowSet={[{ contactId }]} />
          <ItemDetailList rowSet={[{ displayName, type }]} />
          <ItemDetailList rowSet={[{ message, detail }]} />
          <ItemDetailList rowSet={[{ duration, msisdn }]} />
          <ItemDetailList rowSet={[{ timestamp }]} />
        </div>
      )}
    </TmoCard>
  );
}

export default withModal(LogItem);

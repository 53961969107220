import React from 'react';
import { withRouter } from 'react-router-dom';

import TmoH1 from '../../controlWrappers/TmoH1';
import TmoH2 from '../../controlWrappers/TmoH2';
import TmoButton from '../../forms/TmoButton';

import DropDown from '../DropDown';
import DeviceTypesFilter from './DeviceTypesFilter';
import DeviceZonesFilter from './DeviceZonesFilter';
import DeviceTypeGrid from './DeviceTypeGrid';
import ZoneGrid from './ZoneGrid';

import style from './Devices.module.css';
const productId = 'zigbee';
const deviceId = 'David_Raspberry_Pi';
const dropDownItems = [
  { label: 'Bellevue Golf Course', value: 'Bellevue Golf Course' },
  { label: 'Sahalee Country Club', value: 'Sahalee Country Club' },
  { label: 'Willows Run Golf Course', value: 'Willows Run Golf Course' }
];
const alertsDataSet = [
  {
    assetID: 'Room 104',
    assetType: 'Door lock',
    message: 'Offline'
  },
  {
    assetID: 'Room 107',
    assetType: 'Door lock',
    message: 'online'
  }
];
function Devices({ match: { url }, history }) {
  const handleFilterClick = e => {};

  return (
    <div className={style.container}>
      <div className={style.left_panel}>
        <TmoH1>Site:</TmoH1>
        <div className={style.site_dropdown}>
          <DropDown
            dropDownItems={dropDownItems}
            handleFilterClick={handleFilterClick}
          />
        </div>
        <div className={style.filters}>
          <TmoH2 className={style.filters_title}>Zones:</TmoH2>
          <div>
            <DeviceZonesFilter />
          </div>
          <TmoH2 className={style.filters_title}>Device Types:</TmoH2>
          <div>
            <DeviceTypesFilter />
          </div>
        </div>
      </div>

      <div className={style.main_panel}>
        <div className={style.add}>
          <TmoButton
            className={style.add_new}
            type="secondary"
            onClick={() => history.push(`devices/add`)}
          >
            +New Devices
          </TmoButton>
        </div>
        <ZoneGrid selectedZone={'1st Floor'} alerts={alertsDataSet} />
        <DeviceTypeGrid productId={productId} deviceId={deviceId} />
      </div>
    </div>
  );
}

export default withRouter(Devices);

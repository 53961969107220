import environment, { ENVIRONMENTS } from '../utils/environment';
import { deepMerge } from '../utils/objectUtils';

const baseRoleMapper = require(`./roleMapper.json`);
const devRoleMapper = require(`./roleMapper.dev.json`);
const stagingRoleMapper = require(`./roleMapper.staging.json`);
const prodRoleMapper = require(`./roleMapper.prod.json`);

let environmentOverride;

switch (environment) {
  case ENVIRONMENTS.DEV:
    environmentOverride = devRoleMapper;
    break;
  case ENVIRONMENTS.STAGING:
    environmentOverride = stagingRoleMapper;
    break;
  case ENVIRONMENTS.PROD:
    environmentOverride = prodRoleMapper;
    break;
  default:
    environmentOverride = {};
}

const roleMapper = deepMerge(baseRoleMapper, environmentOverride);

export default roleMapper;

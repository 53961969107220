import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect,
  useRef
} from 'react';
import FileSaver from 'file-saver';
import classNames from 'classnames';
import Fetcher from '../Fetcher';
import DriveMap from '../drive/DriveMap';
import TmoCard from '../uiHelpers/TmoCard';
import TmoSelect from '../forms/TmoSelect';
import TmoToggle from '../forms/TmoToggle';
import TmoButton from '../forms/TmoButton';
import LabelWithInput from '../forms/LabelWithInput';
import RawDataObject from '../rawData/RawDataObject';
import TmoH1 from '../controlWrappers/TmoH1';
import TmoH2 from '../controlWrappers/TmoH2';
import StatusContext from '../../context/StatusContext';
import PaginationV3 from '../pagination/PaginationV3';
import CommonTestHarnessControls from './CommonTestHarnessControls';
import ContentContainer from '../uiHelpers/ContentContainer';
import AuthEnvironments from '../auth/AuthEnvironments';
import config from '../../configs/config';
import appConfig from '../../configs/config';
import pocService from '../../services/pocService';
import { getRandomString } from '../../utils/stringUtils';
import { STORAGE_TYPES } from '../../utils/app_constants';
import usePersistableState from '../../hooks/usePersistableState';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './Poc.module.css';

const { googleMapSettings, reactGoogleMaps } = appConfig;

function Poc({ className, productId, controls: Controls }) {
  const [devices, setDevices] = useState();
  const [selectedDeviceId, setSelectedDeviceId] = usePersistableState({
    storageKey: `${productId}-selected-device-id`,
    storageType: STORAGE_TYPES.LOCAL
  });
  const [deviceHistory, setDeviceHistory] = useState([]);
  const [
    displayedDeviceHistoryFilter,
    setDisplayedDeviceHistoryFilter
  ] = useState();
  const [showHistory, setShowHistory] = useState(true);
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const { addErrorMessage } = useContext(StatusContext);
  const currentEnvironment = config.apiSettings.environment;
  const devUrl = `https://syncup-admin-dev.t-mobile.com/testharness/${productId}`;
  const stgUrl = `https://syncup-admin-stg.t-mobile.com/testharness/${productId}`;

  const forceRefresh = () => setRefreshKey(getRandomString());

  const fetchDevices = useCallback(
    async () => await pocService.getDevices({ productId }),
    [productId]
  );
  const authorizedEnvironments = useMemo(() => {
    return ['local', 'dev', 'stg'];
  }, []);
  // The callback for setting device history from signalR does not know what
  // the currently selected device ID is, so we just blindly insert it and
  // filter it out here. Computers are hard.
  const currentDeviceHistory = useMemo(() => {
    return deviceHistory.filter(d => d.deviceId === selectedDeviceId);
  }, [deviceHistory, selectedDeviceId]);

  const filteredDeviceHistory = useMemo(() => {
    return currentDeviceHistory.filter(
      displayedDeviceHistoryFilter || (d => d)
    );
  }, [currentDeviceHistory, displayedDeviceHistoryFilter]);

  const device = useMemo(
    () => devices?.find(a => a.deviceId === selectedDeviceId),
    [devices, selectedDeviceId]
  );

  const deviceId = useMemo(() => device?.deviceId, [device]);

  const handleDeviceHistoryExport = async () => {
    if (deviceId) {
      trackCustomEvents(`Export Device History`, {
        productId,
        deviceId
      });
      const history = await pocService.getDeviceHistory({
        productId,
        deviceId
      });
      const historyJsonString = JSON.stringify(history);
      const blob = new Blob([historyJsonString], { type: 'application/json' });
      FileSaver.saveAs(blob, `${deviceId}-history.json`);
    }
  };

  const fetchDeviceHistory = useCallback(async () => {
    setDeviceHistory([]);
    if (deviceId) {
      return await pocService.getDeviceHistory({
        productId,
        deviceId
      });
    }
  }, [productId, deviceId]);

  useEffect(() => {
    if (
      devices?.length &&
      !devices.find(d => d.deviceId === selectedDeviceId)
    ) {
      setSelectedDeviceId(devices[0].deviceId);
    }
  }, [devices, setSelectedDeviceId, selectedDeviceId]);

  const deviceSelectSpecs = {
    options: devices?.map(({ deviceId }) => ({
      value: deviceId
    }))
  };

  const places = useMemo(() => {
    if (showHistory) {
      return filteredDeviceHistory
        .filter(d => d.d2cMsg?.loc?.la && d.d2cMsg?.loc?.lo)
        .reverse()
        .map(l => ({
          latitude: l.d2cMsg.loc.la,
          longitude: l.d2cMsg.loc.lo
        }));
    } else {
      return [
        {
          latitude: device?.d2cMsg?.loc?.la,
          longitude: device?.d2cMsg?.loc?.lo
        }
      ];
    }
  }, [showHistory, filteredDeviceHistory, device]);

  const patchDesiredState = async ({ desiredState }) => {
    const patchedDesiredState = {
      ...device.desiredState,
      ...desiredState
    };

    const { $version, $metadata, ...apiDesiredState } = patchedDesiredState;

    try {
      await pocService.setDesiredState({
        productId,
        deviceId,
        desiredState: apiDesiredState
      });
      setDevices(
        devices.map(d => {
          if (d.deviceId === deviceId) {
            return {
              ...d,
              desiredState: patchedDesiredState
            };
          } else {
            return d;
          }
        })
      );
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const handleLiveStateUpdate = useRef(newDeviceState => {
    setDevices((oldDevices = []) => {
      if (oldDevices.find(d => d.deviceId === newDeviceState.deviceId)) {
        return oldDevices.map(d =>
          d.deviceId === newDeviceState.deviceId ? newDeviceState : d
        );
      } else {
        return [...oldDevices, newDeviceState];
      }
    });

    setDeviceHistory(oldDeviceHistory => {
      if (
        oldDeviceHistory.find(
          d =>
            d.deviceId === newDeviceState.deviceId &&
            d.reportedStateUpdatedAt ===
              newDeviceState.reportedStateUpdatedAt &&
            d.desiredStateUpdatedAt === newDeviceState.desiredStateUpdatedAt
        )
      ) {
        return oldDeviceHistory;
      } else {
        return [newDeviceState, ...oldDeviceHistory];
      }
    });
  });

  useEffect(() => {
    if (authorizedEnvironments.includes(currentEnvironment)) {
      try {
        pocService.connectToDeviceUpdates({
          productId,
          methodHandler: handleLiveStateUpdate.current
        });
      } catch (error) {
        // addErrorMessage({ error });
      }
    }
  }, [currentEnvironment, authorizedEnvironments, productId, addErrorMessage]);

  const mapContainer = <div className={style.loading_element} />;

  const envNotAuthorizedMessage = (
    <TmoH1>
      The Testharness feature is not available in the{' '}
      <span className={style.env}>{currentEnvironment}</span> environment.
      Please use
      <a className={style.link} href={devUrl} title={devUrl}>
        dev
      </a>
      or
      <a className={style.link} href={stgUrl} title={stgUrl}>
        staging
      </a>
    </TmoH1>
  );

  return (
    <AuthEnvironments
      environments={authorizedEnvironments}
      message={envNotAuthorizedMessage}
    >
      <div className={classNames(style.container, className)}>
        <div className={style.right_panel}>
          <Fetcher
            key={refreshKey}
            action={fetchDevices}
            onLoad={setDevices}
            render={() => {
              return devices.length && deviceId ? (
                <ContentContainer>
                  <TmoCard>
                    <div className={style.controls}>
                      <LabelWithInput
                        className={style.controls_device}
                        labelText="Device"
                        formComponent={TmoSelect}
                        selectSpecs={deviceSelectSpecs}
                        onChange={setSelectedDeviceId}
                        value={deviceId}
                        inline={true}
                      />
                      <TmoButton onClick={forceRefresh}>Refresh</TmoButton>
                      <TmoButton onClick={handleDeviceHistoryExport}>
                        Export Device History
                      </TmoButton>
                      <CommonTestHarnessControls
                        device={device}
                        patchDesiredState={patchDesiredState}
                      />
                      {Controls && (
                        <Controls
                          device={device}
                          deviceHistory={currentDeviceHistory}
                          productId={productId}
                          patchDesiredState={patchDesiredState}
                          setDisplayedDeviceHistoryFilter={
                            setDisplayedDeviceHistoryFilter
                          }
                        />
                      )}
                    </div>
                    <div className={style.data_container}>
                      <div className={style.state}>
                        <TmoH2>Current State</TmoH2>
                        <RawDataObject rawObject={device} />
                        {!!filteredDeviceHistory.length && (
                          <div className={style.historical_state}>
                            <TmoH2>Recent State Changes</TmoH2>
                            <PaginationV3
                              items={filteredDeviceHistory}
                              itemsPerPage={20}
                              render={visibleItems => (
                                <RawDataObject rawObject={visibleItems} />
                              )}
                            />
                          </div>
                        )}
                      </div>
                      <div className={style.data}>
                        <Fetcher
                          action={fetchDeviceHistory}
                          onLoad={setDeviceHistory}
                          render={() => (
                            <>
                              <TmoH2>Device History</TmoH2>
                              <LabelWithInput
                                labelText="Show History"
                                formComponent={TmoToggle}
                                isChecked={showHistory}
                                inline={true}
                                onChange={setShowHistory}
                              />
                              <DriveMap
                                googleMapURL={`${reactGoogleMaps.url}${googleMapSettings.apiKey}`}
                                loadingElement={mapContainer}
                                mapElement={mapContainer}
                                containerElement={mapContainer}
                                zoom={12}
                                places={places}
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </TmoCard>
                </ContentContainer>
              ) : (
                <div className={style.no_data}>No data</div>
              );
            }}
          />
        </div>
      </div>
    </AuthEnvironments>
  );
}

export default Poc;

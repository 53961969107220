import React, { useState, useMemo, useEffect } from 'react';
import LabelWithInput from '../../forms/LabelWithInput';
import TmoToggle from '../../forms/TmoToggle';
import TmoSelect from '../../forms/TmoSelect';
import style from './DriveLiteControls.module.css';

function DriveLiteControls({
  className,
  device,
  deviceHistory,
  patchDesiredState,
  setDisplayedDeviceHistoryFilter
}) {
  const [tripNumber, setTripNumber] = useState();

  useEffect(() => {
    setDisplayedDeviceHistoryFilter(() =>
      tripNumber ? d => d.reportedState.tripNumber === tripNumber : null
    );
  }, [tripNumber, deviceHistory, setDisplayedDeviceHistoryFilter]);

  const tripSelectSpecs = useMemo(() => {
    const tripNumbers = [
      ...new Set(deviceHistory.map(d => d.reportedState.tripNumber))
    ].filter(Boolean);

    setTripNumber(tripNumbers[0]);

    return {
      options: tripNumbers.map(value => ({
        value
      }))
    };
  }, [deviceHistory]);

  const handleWifiToggle = async isWifiEnabled => {
    patchDesiredState({
      desiredState: { isWifiEnabled }
    });
  };

  return (
    <>
      {tripNumber && !!deviceHistory?.length && (
        <LabelWithInput
          className={style.control}
          formComponent={TmoSelect}
          selectSpecs={tripSelectSpecs}
          onChange={v => setTripNumber(parseInt(v))}
          value={tripNumber}
          labelText="Trip"
        />
      )}
      <LabelWithInput
        className={style.control}
        formComponent={TmoToggle}
        onChange={handleWifiToggle}
        value={!!device.desiredState?.isWifiEnabled}
        labelText="Wifi"
      />
    </>
  );
}

export default DriveLiteControls;

import React, { useCallback, useContext, useState } from 'react';

import classNames from 'classnames';
import { withModal, TmoH3, RawData } from '@tmobile/tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import deviceService from '../../services/deviceService';
import userService from '../../services/userService';
import DeviceOnThing from './device/DeviceOnThing';
import SensorDetails from '../sensors/SensorDetails';
import { IOT_PRODUCTS, APP_MOBILE } from '../../utils/app_constants';
import Feature from '../featureWrappers/Feature';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import DeviceTwin from './device/DeviceTwin';
import ThingDetails from './thingDetails/ThingDetails';
import TaskDetails from 'components/tasks/TaskDetails';
import StatusMark from '../textHelpers/StatusMark';
import ItemDetailsModal from 'components/ItemDetailsModal';
import IssueCommands from '../../components/issueCommands/IssueCommands';

import style from './Thing.module.css';

function SharedThing({ thing, productType }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [deviceTwin, setDeviceTwin] = useState();
  const [userTasks, setUserTasks] = useState();
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());

  const actionCallback = useCallback(
    () =>
      thing?.currentDevice &&
      deviceService.getDeviceTwin({
        deviceId: thing?.currentDevice?.hardwareId,
        syncUpAppName: product
      }),
    [thing.currentDevice, product]
  );

  const actionUserTaskCallback = useCallback(
    () =>
      userService.getUserTasksDetailsByThing({
        userId,
        thingId: thing.id,
        syncUpAppName: product
      }),
    [userId, thing.id, product]
  );

  const thingComponent = thing => {
    return <ThingDetails thing={thing} />;
  };

  return (
    <div className={style.thing_container}>
      <div className={style.container_tab}>
        <div className={style.column}>
          <div className={style.heading_row}>
            <TmoH3 className={style.heading} flush>
              {thing.name}
              <span className={style.name}>
                <StatusMark status={thing?.status?.toLowerCase()} />
              </span>
            </TmoH3>
            <div className={style.action}>
              <RawData
                rawObject={thing}
                title={`Full ${thing.thingType ? thing.thingType : ''} Details`}
                tooltip={`Full ${
                  thing.thingType ? thing.thingType : ''
                } Details`}
                type=" "
                usePortal={false}
                modalType={'slide'}
                buttonType="magenta_primary"
              />
            </div>
          </div>
          <div className={style.thing_details_container}>
            {thingComponent(thing)}
            <Feature isUserDetails Products={[APP_MOBILE.WATCH]}>
              <ItemDetailsModal buttonText={'View Tasks'} label="Tasks">
                <Fetcher
                  key={refreshKey}
                  action={actionUserTaskCallback}
                  onLoad={setUserTasks}
                  render={() =>
                    userTasks?.length > 0 ? (
                      <>
                        {userTasks.map(userTask => (
                          <ItemDetailsModal
                            key={userTask.title}
                            buttonText={'View Task'}
                            label={`${userTask.title}: `}
                          >
                            <TaskDetails
                              userTask={userTask}
                              forceRefresh={forceRefresh}
                            />
                          </ItemDetailsModal>
                        ))}
                      </>
                    ) : (
                      <span className={style.message}>
                        No Tasks are available
                      </span>
                    )
                  }
                />
              </ItemDetailsModal>
            </Feature>
          </div>
        </div>

        {/* Device Details */}
        <div className={style.column}>
          <div className={style.heading_row}>
            <TmoH3 className={style.heading} flush>
              DEVICE DETAILS
            </TmoH3>
            {(thing.currentDevice || thing.device) && (
              <div className={classNames(style.row, style.action)}>
                <RawData
                  rawObject={
                    thing.currentDevice ? thing.currentDevice : thing.device
                  }
                  title="Full Device Details"
                  tooltip="Full Device Details"
                  type=" "
                  className={style.action_button}
                  usePortal={false}
                  modalType={'slide'}
                  buttonType="magenta_primary"
                />

                <Feature
                  isUserDetails
                  Products={[
                    APP_MOBILE.PETS,
                    APP_MOBILE.TRACKER,
                    APP_MOBILE.WATCH
                  ]}
                >
                  <IssueCommands
                    isUserDetails
                    deviceId={thing.currentDevice?.id}
                    hardwareId={thing.currentDevice?.hardwareId}
                    className={style.action_button}
                  />
                </Feature>
              </div>
            )}
          </div>
          {thing?.currentDevice ? (
            <DeviceOnThing
              thingName={thing.name}
              device={thing.currentDevice}
            />
          ) : null}
        </div>

        {/* Device TWIN Details */}
        {IOT_PRODUCTS.includes(productType) && (
          <Fetcher
            action={actionCallback}
            onLoad={setDeviceTwin}
            render={() =>
              deviceTwin ? (
                <div className={style.column}>
                  <div className={style.heading_row}>
                    <TmoH3 className={style.heading} flush>
                      DEVICE TWIN DETAILS
                    </TmoH3>
                    <div className={classNames(style.row, style.action)}>
                      <RawData
                        rawObject={deviceTwin}
                        title="Device Twin Details"
                        tooltip="Device Twin Details"
                        type=" "
                        className={style.action_button}
                        usePortal={false}
                        modalType={'slide'}
                        buttonType="magenta_primary"
                      />
                    </div>
                  </div>
                  <DeviceTwin device={deviceTwin} />
                </div>
              ) : null
            }
          />
        )}
      </div>
      <Feature
        isUserDetails
        Products={[APP_MOBILE.PETS, APP_MOBILE.TRACKER, APP_MOBILE.WATCH]}
      >
        <SensorDetails thing={thing} />
      </Feature>
    </div>
  );
}

export default withModal(SharedThing);

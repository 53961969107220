import React, { useState } from 'react';

import {
  GoogleMap,
  Marker,
  InfoWindow,
  withGoogleMap,
  withScriptjs
} from 'react-google-maps';
import {
  COMMERCIAL_MAP_CLUSTER_STYLE,
  getMarkerCenter
} from '../../../utils/mapUtils';

import Map_Marker from '../../../static/assets/commercial/images/icon-map-marker-business.svg';
import Golf_Cart_Marker from '../../../static/assets/commercial/images/icon-map-marker-golf-cart.svg';
import Golf_Cart_Marker_Offline from '../../../static/assets/commercial/images/icon-map-marker-golf-cart-offline.svg';

import './CommercialMap.css';

const {
  MarkerClusterer
} = require('react-google-maps/lib/components/addons/MarkerClusterer');

function CommercialMap({ zoom, center, places }) {
  const [markerClicked, setMarkerClicked] = useState(false);

  const onMarkerClick = place => {
    setMarkerClicked(place);
  };

  const { lat, lng, id } = markerClicked;

  return (
    <GoogleMap
      defaultCenter={center}
      defaultZoom={zoom}
      defaultOptions={{
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: false,
        panControl: false,
        rotateControl: false,
        fullscreenControl: false
      }}
      disableDefaultUI
    >
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
        maxZoom={20}
        styles={COMMERCIAL_MAP_CLUSTER_STYLE}
      >
        {places.map((place, index) => (
          <Marker
            key={index}
            position={getMarkerCenter(place)}
            title={JSON.stringify(index)}
            onClick={() => onMarkerClick(place)}
            options={{
              icon: {
                url:
                  place.deviceType === 'location tracker' && !place.offline
                    ? Golf_Cart_Marker
                    : place.offline
                    ? Golf_Cart_Marker_Offline
                    : Map_Marker,
                scaledSize: new window.google.maps.Size(33, 43)
              }
            }}
          />
        ))}

        {markerClicked && (
          <InfoWindow
            onCloseClick={() => setMarkerClicked(false)}
            anchor={id}
            position={{
              lat: lat,
              lng: lng
            }}
          >
            <>{JSON.stringify(markerClicked)}</>
          </InfoWindow>
        )}
      </MarkerClusterer>
    </GoogleMap>
  );
}

export default withScriptjs(withGoogleMap(CommercialMap));

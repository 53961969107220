import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';
import { APP_MOBILE } from 'utils/app_constants';

const thingRepository = {
  getThingsByUserId: async ({
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.userThings}`.replace(':userId', userId),
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSharedThingsByUserId: async ({
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.things,
      basePath: basePath.platform,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },

  getThingsDrive: async ({ userId, syncUpAppName, responseStatus = false }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.things,
      basePath: basePath.platformDrive,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.userImpersonation.key]: userId
      }
    });
  },

  linkDevice: async ({
    thingId,
    hardwareId,
    product,
    model,
    lastFourIcc,
    userId,
    syncUpAppName,
    responseStatus = false,
    body
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.thingLinkDevice.replace(':thingId', thingId),
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      body: body
        ? body
        : {
            identifier: hardwareId,
            kind: 'REGISTER',
            product,
            model,
            lastFourIcc
          }
    });
  },

  getBreeds: async (responseStatus = false) => {
    const {
      basePath,
      resources,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.breeds,
      basePath: basePath.platform,
      httpMethod: httpMethods.GET
    });
  },

  postBreed: async ({ name, petType, body, responseStatus = false }) => {
    const {
      basePath,
      resources,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.breeds,
      basePath: basePath.admin,
      httpMethod: httpMethods.POST,
      body: body ? body : { name, petType }
    });
  },

  addThing: async ({
    thing,
    userId,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.things,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      basePath: basePath.platform,
      body: body ? body : thing
    });
  },

  deleteThing: async ({
    thingId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      httpMethods,
      resources,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.things}/${thingId}`,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      httpMethod: httpMethods.DELETE
    });
  },
  deleteDriveThing: async ({
    thingId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      urlSuffix: `${resources.things}/${thingId}`,
      basePath: basePath.adminDrive,
      responseStatus: responseStatus,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value
      },
      httpMethod: httpMethods.DELETE
    });
  },
  getThingEvents: async ({
    thingId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      httpMethods,
      resources,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.driveEvents.replace(':thingId', thingId),
      basePath: basePath.platformDrive,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      httpMethod: httpMethods.GET
    });
  },

  getThingTrips: async ({
    thingId,
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      httpMethods,
      resources,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.driveTrips.replace(':thingId', thingId),
      basePath: basePath.platformDrive,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      httpMethod: httpMethods.GET
    });
  },

  getRoadsideServices: async ({
    thingId,
    userId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      httpMethods,
      resources,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideServices.replace(':thingId', thingId),
      basePath: basePath.platformDrive,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      httpMethod: httpMethods.GET
    });
  },
  deleteRoadsideServices: async ({
    userId,
    thingId,
    serviceId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      httpMethods,
      resources,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.driveRoadsideServiceById
        .replace(':thingId', thingId)
        .replace(':serviceId', serviceId),
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      httpMethod: httpMethods.DELETE
    });
  },

  addRoadsideServices: async ({
    thingId,
    userId,
    data,
    responseStatus = false,
    body = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      httpMethods,
      resources,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.driveRoadsideServices.replace(':thingId', thingId),
      basePath: basePath.platformDrive,
      body: body ? body : data,
      responseStatus: responseStatus,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      httpMethod: httpMethods.POST
    });
  },

  updateRoadsideServices: async ({
    thingId,
    userId,
    serviceId,
    responseStatus = false,
    body = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      httpMethods,
      resources,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.driveRoadsideServiceById
        .replace(':thingId', thingId)
        .replace(':serviceId', serviceId),
      basePath: basePath.platformDrive,
      body: body,
      responseStatus: responseStatus,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      httpMethod: httpMethods.PATCH
    });
  },

  deleteBreed: async ({ breedId, responseStatus = false }) => {
    const {
      basePath,
      httpMethods,
      resources
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.breed.replace(':breedId', breedId),
      basePath: basePath.admin,
      httpMethod: httpMethods.DELETE
    });
  },

  getVehiclesYears: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesYears,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getVehiclesModels: async ({
    responseStatus = false,
    userId,
    year,
    make,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesModels
        .replace(':year', year)
        .replace(':make', make),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getVehiclesByYear: async ({
    responseStatus = false,
    year,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesByYear.replace(':year', year),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getVehiclesMakes: async ({
    responseStatus = false,
    year,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesMakes.replace(':year', year),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getEngines: async ({
    responseStatus = false,
    year,
    make,
    model,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesEngines
        .replace(':year', year)
        .replace(':make', make)
        .replace(':model', model),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getEnginesByVehicleId: async ({
    responseStatus = false,
    vehicleId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesEnginesbyVehicleId.replace(
        ':vehicleId',
        vehicleId
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getCompatibility: async ({
    responseStatus = false,
    year,
    make,
    model,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesCompatibility
        .replace(':year', year)
        .replace(':make', make)
        .replace(':model', model),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getVehiclesColors: async ({
    responseStatus = false,
    year,
    make,
    model,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesColors
        .replace(':year', year)
        .replace(':make', make)
        .replace(':model', model),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getColorsByVehicleId: async ({
    responseStatus = false,
    vehicleId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveVehiclesColorsbyVehicleId.replace(
        ':vehicleId',
        vehicleId
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getTripsById: async ({
    responseStatus = false,
    thingId,
    tripId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveTripsbyId
        .replace(':thingId', thingId)
        .replace(':tripId', tripId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateTripsById: async ({
    responseStatus = false,
    body,
    thingId,
    tripId,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveTripsbyId
        .replace(':thingId', thingId)
        .replace(':tripId', tripId),

      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getTimelinePaged: async ({
    responseStatus = false,
    thingId,
    userId,
    limit,
    after,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: `${resources.driveTimelinePaged.replace(
        ':thingId',
        thingId
      )}?limit=${limit}&after=${after}`,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getTimelineSummary: async ({
    responseStatus = false,
    thingId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveTimelineSummary.replace(':thingId', thingId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getThingById: async ({
    responseStatus = false,
    thingId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath:
        syncUpAppName === APP_MOBILE.DRIVE
          ? basePath.platformDrive
          : basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.thingById.replace(':thingId', thingId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getRoadsideEligibility: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideEligibility.replace(
        ':deviceId',
        deviceId
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getRoadsideEnrollment: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideEnrollment.replace(
        ':deviceId',
        deviceId
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getRoadsideServiceById: async ({
    responseStatus = false,
    thingId,
    serviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideServiceById
        .replace(':thingId', thingId)
        .replace(':serviceId', serviceId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getEventById: async ({
    responseStatus = false,
    thingId,
    eventId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveEventById
        .replace(':thingId', thingId)
        .replace(':eventId', eventId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getItems: async ({ responseStatus = false, userId, syncUpAppName }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveItems,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  addRoadsideEnrollment: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideEnrollment.replace(
        ':deviceId',
        deviceId
      ),
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteRoadsideEnrollment: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideEnrollment.replace(
        ':deviceId',
        deviceId
      ),
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateDriveWifiDetails: async ({
    responseStatus = false,
    body,
    thingId,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.thingById.replace(':thingId', thingId),
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  postExportTimeline: async ({
    responseStatus = false,
    thingId,
    userId,
    startDate,
    endDate,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: `${resources.driveTimelineExport.replace(
        ':thingId',
        thingId
      )}?startDate=${startDate}&endDate=${endDate}`,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateViewsTimeline: async ({
    responseStatus = false,
    thingId,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.thingViewed.replace(':thingId', thingId),
      httpMethod: httpMethods.PATCH,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  postRoadsideServiceFeedback: async ({
    responseStatus = false,
    thingId,
    userId,
    serviceId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideFeedback
        .replace(':thingId', thingId)
        .replace(':serviceId', serviceId),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateRoadSideServiceFeedback: async ({
    responseStatus = false,
    thingId,
    userId,
    serviceId,
    feedbackId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideFeedback
        .replace(':thingId', thingId)
        .replace(':serviceId', serviceId)
        .replace(':feedbackId', feedbackId),
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  optInRoadsideService: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideOptIn,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  optOutRoadsideService: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideOptOut,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateDriveThing: async ({
    responseStatus = false,
    thingId,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.thingById.replace(':thingId', thingId),
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  searchDriveServiceStation: async ({
    responseStatus = false,
    userId,
    thingId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideStations.replace(':thingId', thingId),
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  serviceQueryPTA: async ({
    responseStatus = false,
    userId,
    thingId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveRoadsideServicesPta.replace(
        ':thingId',
        thingId
      ),
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  searchDriveFuelStation: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.driveFuelstations,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getAlarms: async ({ userId, responseStatus = false, syncUpAppName }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.alarms,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getAlarmsById: async ({
    userId,
    alarmId,
    responseStatus = false,
    syncUpAppName
  }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.alarmsById.replace(':alarmId', alarmId),
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createAlarm: async ({
    userId,
    responseStatus = false,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.alarms,
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateAlarm: async ({
    userId,
    responseStatus = false,
    alarmId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.alarmsById.replace(':alarmId', alarmId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteAlarm: async ({
    userId,
    responseStatus = false,
    alarmId,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.alarmsById.replace(':alarmId', alarmId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteTimeline: async ({
    userId,
    responseStatus = false,
    timelineId,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.timelinesById.replace(':timelineId', timelineId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getTimeline: async ({
    userId,
    responseStatus = false,
    timelineId,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.timelinesById.replace(':timelineId', timelineId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateTimeline: async ({
    userId,
    responseStatus = false,
    timelineId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.timelinesById.replace(':timelineId', timelineId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createTimeline: async ({
    userId,
    responseStatus = false,
    timelineId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.timelines.replace(':timelineId', timelineId),
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getTimelines: async ({ userId, responseStatus = false, syncUpAppName }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.timelines,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSoundSettings: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.soundSettings,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSoundSettingsByThingId: async ({
    thingId,
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.soundSettingsByThingId.replace(':thingId', thingId),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSensorHistoryByThingId: async ({
    thingId,
    responseStatus = false,
    userId,
    syncUpAppName,
    sensorType
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.sensorHistoryByThingId
        .replace(':thingId', thingId)
        .replace(':sensorType', sensorType),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getActivityByThingId: async ({
    thingId,
    responseStatus = false,
    userId,
    syncUpAppName,
    query
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix:
        resources.activityByThingId.replace(':thingId', thingId) + query,
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSilentSchedulesByThingId: async ({
    thingId,
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.silentSchedulesByThingId.replace(
        ':thingId',
        thingId
      ),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getPresetMessagesByThingId: async ({
    thingId,
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.presetMessagesByThingId.replace(':thingId', thingId),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getPresetMessages: async ({
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.presetMessages,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getPresetMessagesById: async ({
    presetMessageId,
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.presetMessagesById.replace(
        ':presetMessageId',
        presetMessageId
      ),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getSilentSchedulesById: async ({
    silentScheduleId,
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.silentSchedulesById.replace(
        ':silentScheduleId',
        silentScheduleId
      ),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getRewards: async ({ responseStatus = false, userId, syncUpAppName }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.rewards,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createSoundSettings: async ({
    userId,
    responseStatus = false,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.soundSettings,
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  createAppVersionCheck: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.appVersionCheck,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  createSyncTwinStateByThingId: async ({
    responseStatus = false,
    userId,
    body,
    thingId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.syncTwinStateByThingId.replace(':thingId', thingId),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  createThingLinkDevice: async ({
    responseStatus = false,
    userId,
    body,
    thingId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.thingLinkDevice.replace(':thingId', thingId),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  createAvatarByThingId: async ({
    responseStatus = false,
    userId,
    body,
    thingId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.avatarByThingId.replace(':thingId', thingId),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  createPresetMessages: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.presetMessages,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  createSilentSchedules: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.silentSchedules,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  createRewards: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.rewards,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteSoundSettings: async ({
    userId,
    responseStatus = false,
    thingId,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.soundSettingsByThingId.replace(':thingId', thingId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteThingById: async ({
    userId,
    responseStatus = false,
    thingId,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.thingById.replace(':thingId', thingId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteAvatarByThingId: async ({
    userId,
    responseStatus = false,
    thingId,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.avatarByThingId.replace(':thingId', thingId),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deletePresetMessages: async ({
    userId,
    responseStatus = false,
    presetMessageId,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.presetMessagesById.replace(
        ':presetMessageId',
        presetMessageId
      ),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateSoundSettings: async ({
    userId,
    thingId,
    responseStatus = false,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.soundSettingsByThingId
        .replace(':userId', userId)
        .replace(':thingId', thingId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PUT,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateSilentSchedules: async ({
    responseStatus = false,
    silentScheduleId,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.silentSchedulesById.replace(
        ':silentScheduleId',
        silentScheduleId
      ),
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updatePresetMessages: async ({
    responseStatus = false,
    presetMessagesId,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.presetMessagesById.replace(
        ':presetMessagesId',
        presetMessagesId
      ),
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getThingParentalControl: async ({
    responseStatus = false,
    thingId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.parentalControlById.replace(':thingId', thingId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getParentalControlList: async ({
    responseStatus = false,
    thingId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.parentalControl.replace(':thingId', thingId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateParentalControl: async ({
    userId,
    thingId,
    body,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.parentalControlById.replace(':thingId', thingId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PUT,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteParentalControl: async ({
    responseStatus = false,
    userId,
    thingId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.parentalControlById.replace(':thingId', thingId),
      httpMethod: httpMethods.DELETE,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getCallLogsByPage: async ({
    responseStatus = false,
    userId,
    thingId,
    query,
    pageSize,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix:
        resources.callLogsOfUserUtilization.replace(':thingId', thingId) +
        `pageSize=${pageSize}&${query}`,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSmsLogsByPage: async ({
    responseStatus = false,
    userId,
    thingId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.smsLogsOfUserUtilization.replace(
        ':thingId',
        thingId
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getCallLogs: async ({
    responseStatus = false,
    userId,
    thingId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.callLogsByThingId.replace(':thingId', thingId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getSmsLogs: async ({
    responseStatus = false,
    userId,
    thingId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.smsLogsByThingId.replace(':thingId', thingId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getCommunicationCount: async ({
    thingId,
    query,
    responseStatus = false,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix:
        resources.communicationCountByThingId.replace(':thingId', thingId) +
        query,
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getLocationHistoryList: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.locationHistoryListByDeviceId.replace(
        ':deviceId',
        deviceId
      ),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getHardwareInfo: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.hardwareInfoByDeviceId.replace(
        ':deviceId',
        deviceId
      ),
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getMobileDeviceConfig: async ({
    responseStatus = false,
    configFile,
    version,
    userId,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix:
        resources.mobileDeviceConfig.replace(':configFile', configFile) +
        `version=${version}`,
      responseStatus: responseStatus,
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deleteSilentSchedules: async ({
    userId,
    responseStatus = false,
    silentScheduleId,
    syncUpAppName
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.silentSchedulesById.replace(
        ':silentScheduleId',
        silentScheduleId
      ),
      basePath: basePath.platform,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getThingLogs: async ({
    thingId,
    query,
    responseStatus = false,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.admin,
      urlSuffix: `${resources.adminLogs}${query}`.replace(':thingId', thingId),
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getThingLogsCount: async ({
    thingId,
    query,
    responseStatus = false,
    syncUpAppName
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.admin,
      urlSuffix: `${resources.adminLogsCount}${query}`.replace(
        ':thingId',
        thingId
      ),
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getThingCallStatus: async ({
    deviceId,
    responseStatus = false,
    syncUpAppName,
    query
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.admin,
      urlSuffix: `${resources.adminCallStatus}${query}`.replace(
        ':deviceId',
        deviceId
      ),
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getThingCallStatusCount: async ({
    deviceId,
    responseStatus = false,
    syncUpAppName,
    query
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.admin,
      urlSuffix: `${resources.adminCallStatusCount}${query}`.replace(
        ':deviceId',
        deviceId
      ),
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  }
};

export default thingRepository;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { FaQuestionCircle } from 'react-icons/all';
import classNames from 'classnames';
import { Tooltip, withModal } from '@tmobile/tmo-bps-syncup-web-component-lib';

import ItemDetailsModal from 'components/ItemDetailsModal';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import TimeDisplay from '../../uiHelpers/TimeDisplay';
import { GRID } from 'utils/app_constants';

import style from './DeviceOnThing.module.css';
import rowStyle from '../../ItemDetailList/ItemDetailRow.module.css';

function Device({ device }) {
  const {
    hardwareId,
    id,
    status,
    onboardingStatus,
    offline,
    offlineReason,
    ratePlan,
    msisdn,
    lineStatus,
    networkSsids,
    latestSensors,
    lostModeData,
    onboardingFailureReason,
    lastCommunicationDateTime,
    onboardingCreatedDateTime,
    model
  } = device || {};

  return (
    <div className={style.container}>
      <ItemDetailList
        className={GRID.COLUMN_FORTH}
        items={{
          HardwareId: hardwareId,
          'Service Status': status,
          'Onboarding Status': onboardingStatus
        }}
      />
      {onboardingStatus === 'FAILED' && onboardingFailureReason && (
        <div className={rowStyle.row}>
          <ItemDetailsModal
            buttonText={'View Details'}
            label="Onboarding Failure Reason"
          >
            {onboardingFailureReason}
          </ItemDetailsModal>
        </div>
      )}
      <ItemDetailList
        className={GRID.COLUMN_FORTH}
        items={{
          'Online Status': offline === true ? 'Offline' : 'Online',
          ...(offline && { offlineReason })
        }}
      />
      <div className={rowStyle.row}>
        {onboardingCreatedDateTime ? (
          <ItemDetailsModal
            buttonText={'View Dates'}
            label="Onboarding Created DateTime"
          >
            <div className={classNames(style.row, style.time_display)}>
              <TimeDisplay
                title=""
                timestamp={onboardingCreatedDateTime}
                type="primary_time_display"
              />
            </div>
          </ItemDetailsModal>
        ) : (
          <div className={rowStyle.row}>
            <label className={rowStyle.label}>
              Onboarding Created DateTime
            </label>
            <span className={rowStyle.value}>Not Available</span>
          </div>
        )}
      </div>
      <div className={rowStyle.row}>
        {lastCommunicationDateTime ? (
          <ItemDetailsModal
            label={`Last Communicated At`}
            buttonText={'View Dates'}
          >
            <div className={classNames(style.row, style.time_display)}>
              <TimeDisplay
                title=""
                timestamp={lastCommunicationDateTime}
                type="primary_time_display"
              />
            </div>
          </ItemDetailsModal>
        ) : (
          <div className={rowStyle.row}>
            <label className={rowStyle.label}>Last Communicated At</label>
            <span className={rowStyle.value}>Not Available</span>
          </div>
        )}
      </div>
      <ItemDetailList
        className={GRID.COLUMN_FORTH}
        items={{
          'Device ID': id,
          ...(latestSensors?.device && {
            'Firmware State':
              latestSensors.device.firmwareState || 'Not Available'
          }),
          ...(latestSensors?.device && {
            'Firmware Version':
              latestSensors.device.firmwareVersion || 'Not Available'
          })
        }}
      />
      {lostModeData && (
        <>
          <div className={rowStyle.row}>
            <Tooltip
              className={style.tooltip}
              tooltip="Indicates the platform intends to have the Lost Mode enabled. While enabled, we continue to attempt to turn on Lost Mode until receiving a sensor reading indicating that Lost Mode is on."
              position="left"
            >
              <label className={rowStyle.label}>
                <FaQuestionCircle className={style.tooltip_icon} />
                Attempting to enable Lost Mode?
              </label>
            </Tooltip>
            <span className={style.span}>
              {lostModeData.lostModeCommandEnabled ? 'Yes' : 'No'}
            </span>
          </div>
          <div className={rowStyle.row}>
            <label className={rowStyle.label}>Lost Mode is</label>
            <span className={rowStyle.value}>
              {(latestSensors.lostMode && latestSensors.lostMode.onOff) ||
                'Not Available'}
            </span>
          </div>
        </>
      )}
      <div className={rowStyle.row}>
        <label className={rowStyle.label}>Networks</label>
        {networkSsids.length > 0 ? (
          <ol className={style.networks}>
            {networkSsids.map(({ id, name }) => (
              <li key={id}> {name}</li>
            ))}
          </ol>
        ) : (
          <span className={style.span}>Not Available</span>
        )}
      </div>
      <ItemDetailList
        className={GRID.COLUMN_FORTH}
        items={{
          ratePlan: ratePlan ? ratePlan : 'Not Available',
          msisdn: msisdn ? msisdn : null,
          lineStatus: lineStatus?.status ? lineStatus?.status : 'Not Available',
          model: model ? model : 'Not Available'
        }}
      />
    </div>
  );
}

export default withModal(withRouter(Device));

import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { NavigationTab as Tab, TmoH3 } from '@tmobile/tmo-bps-syncup-web-component-lib';

import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import LocationSensorSafeZone from './location/LocationSensorSafeZone';
import SensorHistory from './SensorHistory';
import SensorNotAvailable from './SensorNotAvailable';
import SingleSensorDetails from './SingleSensorDetails';
import { APP_MOBILE, SENSOR_TYPES } from '../../utils/app_constants';
import { renameKeys } from '../../utils/objectUtils';

import style from './SensorDetails.module.css';

function SensorDetails({ thing }) {
  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);
  const [tabSelected, setTabSelected] = useState(SENSOR_TYPES.LOCATION);

  const onTabChanged = value => {
    setTabSelected(value);
  };

  const { currentDevice } = thing;

  const sensorType = () => {
    let latestSensors;

    if (currentDevice && currentDevice.latestSensors) {
      latestSensors = currentDevice.latestSensors;
    }
    switch (tabSelected) {
      case SENSOR_TYPES.LOCATION:
        return (
          <LocationSensorSafeZone
            locationSensor={latestSensors && latestSensors.location}
          />
        );
      case SENSOR_TYPES.TEMPERATURE:
        const tempSensor = latestSensors && latestSensors.temperature;

        return (
          <SingleSensorDetails
            sensorData={tempSensor}
            sensorName="Temperature"
          />
        );

      case SENSOR_TYPES.DEVICE:
        const deviceSensor =
          latestSensors &&
          latestSensors?.device &&
          renameKeys(
            { cellularSignalStrength: 'cellularRssi' },
            latestSensors?.device
          );
        if (deviceSensor)
          deviceSensor.cellularRssi =
            deviceSensor.cellularRssi && `-${deviceSensor.cellularRssi}db`;
        return (
          <SingleSensorDetails sensorData={deviceSensor} sensorName="Device" />
        );
      case SENSOR_TYPES.LIGHT:
        return <SensorNotAvailable sensorName="Light" />;
      case SENSOR_TYPES.BUZZER:
        return <SensorNotAvailable sensorName="Buzzer" />;
      case SENSOR_TYPES.ACTIVITY:
        return (
          <SingleSensorDetails
            sensorData={latestSensors && latestSensors.activity}
            sensorName="Activity"
          />
        );
      case SENSOR_TYPES.WLAN:
        return (
          <SingleSensorDetails
            sensorData={latestSensors && latestSensors.wlan}
            sensorName="WLAN"
          />
        );
      case SENSOR_TYPES.CELL:
        return (
          <SingleSensorDetails
            sensorData={latestSensors && latestSensors.cell}
            sensorName="CELL"
          />
        );
      case SENSOR_TYPES.LOSTMODE:
        return (
          <SingleSensorDetails
            sensorData={latestSensors && latestSensors.lostMode}
            sensorName="Lost Mode"
          />
        );
      default:
        return null;
    }
  };

  const sensorTypes = () => {
    switch (product) {
      case APP_MOBILE.TRACKER:
        return [
          SENSOR_TYPES.LOCATION,
          SENSOR_TYPES.DEVICE,
          SENSOR_TYPES.TEMPERATURE,
          SENSOR_TYPES.WLAN
        ];
      case APP_MOBILE.WATCH:
        return [
          SENSOR_TYPES.LOCATION,
          SENSOR_TYPES.DEVICE,
          SENSOR_TYPES.TEMPERATURE,
          SENSOR_TYPES.WLAN,
          SENSOR_TYPES.CELL,
          SENSOR_TYPES.ACTIVITY
        ];
      default:
        return [
          SENSOR_TYPES.LOCATION,
          SENSOR_TYPES.TEMPERATURE,
          SENSOR_TYPES.DEVICE,
          SENSOR_TYPES.BUZZER,
          SENSOR_TYPES.LIGHT,
          SENSOR_TYPES.ACTIVITY,
          SENSOR_TYPES.WLAN,
          SENSOR_TYPES.LOSTMODE
        ];
    }
  };

  return (
    <div className={style.container}>
      <TmoH3 className={style.subheading}>SENSOR DETAILS</TmoH3>
      <div className={style.tabs}>
        {sensorTypes().map(s => (
          <Tab
            key={s}
            className={style.tab}
            isSelected={tabSelected === s}
            text={s}
            value={s}
            onSelect={onTabChanged}
            isHorizontal
          />
        ))}
      </div>
      <div>{sensorType()}</div>
      <div className={style.container_device_history}>
        <SensorHistory
          deviceId={currentDevice && currentDevice.id}
          sensorType={tabSelected}
        />
      </div>
    </div>
  );
}

export default withRouter(SensorDetails);

import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { TmoDropDownSelect } from '@tmobile/tmo-bps-syncup-web-component-lib';

import DateRangePicker from '../uiHelpers/DateRangePicker';
import { getTimestampRangeForLastHours } from '../../utils/dateUtils';
import {
  END_DATE_TIME_KEY,
  LAST_24_HOURS,
  LAST_6_HOURS,
  LAST_WEEK,
  START_DATE_TIME_KEY
} from '../../utils/stringUtils';

import style from './FilterByDate.module.css';

function FilterByDate({
  onFilter,
  selectedButtonName,
  onDefaultButtonSet,
  minValueForPicker,
  label,
  icon,
  type,
  onSelection,
  className
}) {
  const [filterBtnName, setFilterBtnName] = useState(selectedButtonName);
  const [selectOptions] = useState([
    { key: 6, value: 'LAST 6 HOURS', name: LAST_6_HOURS },
    { key: 24, value: 'LAST 24 HOURS', name: LAST_24_HOURS },
    { key: 7 * 24, value: 'WEEK', name: LAST_WEEK },
    { key: 'pickRange', value: 'PICK RANGE', name: 'pickRange' }
  ]);
  const sixHours = 6;
  const twentyFourHours = 24;
  const weekInHours = 7 * 24;

  const handleDefaultButtonSet = useCallback(() => {
    let timeRange;
    switch (selectedButtonName) {
      case LAST_6_HOURS:
        timeRange = getTimestampRangeForLastHours(sixHours);
        break;
      case LAST_24_HOURS:
        timeRange = getTimestampRangeForLastHours(twentyFourHours);
        break;
      case LAST_WEEK:
        timeRange = getTimestampRangeForLastHours(weekInHours);
        break;
      default:
        timeRange = null;
        break;
    }

    onDefaultButtonSet({
      [START_DATE_TIME_KEY]: timeRange?.startTime,
      [END_DATE_TIME_KEY]: timeRange?.endTime
    });
  }, [onDefaultButtonSet, selectedButtonName, weekInHours]);

  useEffect(() => {
    selectedButtonName && handleDefaultButtonSet();
  }, [selectedButtonName, handleDefaultButtonSet]);

  const handleSingleButtonFilter = e => {
    setFilterBtnName(e.target.name);
    const timeRange = getTimestampRangeForLastHours(e.target.value);
    onFilter({
      [START_DATE_TIME_KEY]: timeRange.startTime,
      [END_DATE_TIME_KEY]: timeRange.endTime
    });
  };

  const handleCustomDatePicked = ({ startDateTime, endDateTime }) => {
    onFilter({
      [START_DATE_TIME_KEY]: startDateTime,
      [END_DATE_TIME_KEY]: endDateTime
    });
  };

  return (
    <div className={classNames(style.container, className)}>
      {type === 'dropdown' ? (
        <TmoDropDownSelect
          optionsList={selectOptions}
          onChange={e => {
            if (e === 'pickRange') {
              setFilterBtnName(e);
              onSelection(true);
            } else {
              handleSingleButtonFilter({
                target: { name: 'dateRange', value: e }
              });
              onSelection(false);
            }
          }}
          renderIcon={icon}
          defaultSelectedValue={
            selectOptions.filter(e => e.name === selectedButtonName)[0]
          }
        />
      ) : (
        <>
          <label className={style.label}>{label ? label : 'FILTER BY'}:</label>
          <button
            name={LAST_6_HOURS}
            className={classNames(
              style.button,
              filterBtnName === LAST_6_HOURS && style.button_active
            )}
            type="button"
            onClick={handleSingleButtonFilter}
            value={sixHours}
          >
            Last 6 Hours
          </button>
          <button
            name={LAST_24_HOURS}
            className={classNames(
              style.button,
              filterBtnName === LAST_24_HOURS ? style.button_active : ''
            )}
            type="button"
            onClick={handleSingleButtonFilter}
            value={twentyFourHours}
          >
            Last 24 Hours
          </button>
          <button
            name={LAST_WEEK}
            className={classNames(
              style.button,
              filterBtnName === LAST_WEEK ? style.button_active : ''
            )}
            type="button"
            onClick={handleSingleButtonFilter}
            value={weekInHours}
          >
            Week
          </button>
          <button
            name="pickRange"
            className={classNames(
              style.button,
              filterBtnName === 'pickRange' ? style.button_active : ''
            )}
            type="button"
            onClick={e => setFilterBtnName(e.target.name)}
          >
            Pick Range
          </button>
        </>
      )}
      {filterBtnName === 'pickRange' && (
        <DateRangePicker
          onApply={handleCustomDatePicked}
          minValueForPicker={minValueForPicker}
          isApply
          className={type === 'dropdown' ? style.custom_datepicker : ''}
        />
      )}
    </div>
  );
}

export default FilterByDate;

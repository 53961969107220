import React, { useState } from 'react';

import withModal from '../../modal/withModal';
import TmoSvg from '../../uiHelpers/TmoSvg';
import FloorPlanModal from './FloorPlanModal';
import { commercialDoorLocks } from '../../../utils/pocDummyData';

import Floor_Plan from '../../../static/assets/commercial/images/floor-plan-empty.svg';

import './FloorPlan.css';

function FloorPlan({ openModal }) {
  const [deviceModalContent, setDeviceModalContent] = useState(
    commercialDoorLocks[0]
  );

  const handleDeviceClick = device => {
    openModal();
    setDeviceModalContent(device);
  };

  return (
    <div className="FloorPlan">
      <div className="FloorPlan-hotspots">
        <img src={Floor_Plan} alt="Floor Plan" />
        {commercialDoorLocks.map((device, index) => (
          <div
            key={index}
            id={`hotspot${index + 1}`}
            className="FloorPlan-hotspot"
            onClick={() => handleDeviceClick(device)}
          >
            <TmoSvg
              svgId={device.locked ? 'icon-locked' : 'icon-unlocked'}
              className="FloorPlan-hotspot-icon"
            />
          </div>
        ))}
        <FloorPlanModal device={deviceModalContent} />
      </div>
    </div>
  );
}

export default withModal(FloorPlan);

import React from 'react';

import { ContentContainer } from '@tmobile/tmo-bps-syncup-web-component-lib';
import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import AppVersionsList from '../../components/appVersions/AppVersionsList';

import style from '../../components/appVersions/AppVersionsList.module.css';

function AppVersionsPage() {
  return (
    <Authorized
      roles={[
        SUA_ROLES.ADMIN,
        SUA_ROLES.MOBILE_APP_DEVS,
        SUA_ROLES.SYNCUP_DPI, // TO-DO Rrmove SYNCUP_DPI AFter Testing
        SUA_ROLES.PO,
        SUA_ROLES.PETS_ADMIN,
        SUA_ROLES.PETS_DEV,
        SUA_ROLES.PETS_PO,
        SUA_ROLES.TRACKER_ADMIN,
        SUA_ROLES.TRACKER_DEV,
        SUA_ROLES.TRACKER_PO,
        SUA_ROLES.WATCH_ADMIN,
        SUA_ROLES.WATCH_DEV,
        SUA_ROLES.WATCH_PO
      ]}
      willShowErrorModal
    >
      <ContentContainer className={style.container}>
        <AppVersionsList></AppVersionsList>
      </ContentContainer>
    </Authorized>
  );
}
export default AppVersionsPage;

import moment from 'moment';
import 'moment-timezone';
import {
  DATE_IN_TIME_ZONE,
  DATE_TIME_FORMATS,
  STORAGE_KEYS,
  STORAGE_TYPES,
  TIME_ZONES
} from './app_constants';
import { storageRead } from './storageUtils';

export const launchDay = '2020-02-21T00:00:00';

export const minutesAgo = minutes => {
  return moment().subtract(minutes, 'minutes');
};
export const hoursAgo = hours => {
  return moment().subtract(hours, 'hours');
};

export const utcDate = moment.utc().format('YYYY-MM-DD');

export const utcDateTime = moment.utc().format('YYYY-MM-DDTHH:mm:ss:SSSZ');

export const momentToFormattedDate = (time, format = 'YYYY-MM-DD') => {
  return moment(time)
    .format(format)
    .toString();
};

export const getCurrentDateTimeString = () => moment().format('YYYYMMDDHHmmSS');

export const convertSecondsToHumanTime = s => {
  let ms = s % 1000;
  s = (s - ms) / 1000;
  let secs = s % 60;
  s = (s - secs) / 60;
  let mins = s % 60;
  let hrs = (s - mins) / 60;
  //return hrs + 'h:' + mins + 'm:' + secs + 's';
  return {
    hours: hrs,
    minutes: mins,
    seconds: secs,
    formatted: hrs + 'h:' + mins + 'm:' + secs + 's'
  };
};

export const getFormattedDateForPicker = (separator, dateToFormat) => {
  let newDate = dateToFormat ? new Date(dateToFormat) : new Date();
  separator = separator ? separator : '-';
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date < 10 ? `0${date}` : `${date}`}`;
};

export const getFormattedDateTimeSecond = dateTime => {
  return moment(dateTime)
    .tz(TIME_ZONES.DEFAULT_TIMEZONE)
    .format(DATE_TIME_FORMATS.LONG_DATE_DAY_TIME_SECOND);
};

export const getUnixToFormattedDateTimeSecond = timestamp => {
  return moment
    .unix(timestamp)
    .tz(TIME_ZONES.DEFAULT_TIMEZONE)
    .format(DATE_TIME_FORMATS.LONG_DATE_DAY_TIME_SECOND);
};

export const getEpochDateTime = ({ unixEpochTime, format }) => {
  return unixEpochTime
    ? moment(unixEpochTime).format(
        format ? format : DATE_TIME_FORMATS.LONG_DATE_DAY_TIME_SECOND
      )
    : '';
};

export const getFormattedDateTime = ({ dateTime, timeZone }) => {
  let dateTimeFormat =
    dateTime?.length > 10
      ? DATE_TIME_FORMATS.LONG_DATE_DAY_TIME_SECOND
      : DATE_TIME_FORMATS.LONG_DATE;
  return moment(dateTime)
    .tz(timeZone || moment.tz.guess())
    .format(dateTimeFormat);
};

// TODO: Please clean up this function. We sometimes return an array and
// sometimes return an object. It doesn't look like any callers pass in userTZIdentifier.
// Persisting something in session storage and pulling it out here seems really fragile.
export const getTimestampList = (
  dateTime,
  userTZIdentifier,
  responseTimeZone
) => {
  if (!userTZIdentifier) {
    userTZIdentifier = storageRead({
      key: STORAGE_KEYS.USER_TIMEZONE,
      storageType: STORAGE_TYPES.SESSION
    });
  }

  switch (responseTimeZone) {
    case DATE_IN_TIME_ZONE.USER:
      return getFormattedDateTime({ dateTime, timeZone: userTZIdentifier });
    case DATE_IN_TIME_ZONE.SYSTEM:
      return getFormattedDateTime({
        dateTime,
        timeZone: TIME_ZONES.DEFAULT_TIMEZONE
      });
    case DATE_IN_TIME_ZONE.CARE:
      return getFormattedDateTime({ dateTime });
    case DATE_IN_TIME_ZONE.CARE_AND_USER:
      return [
        {
          User: getFormattedDateTime({ dateTime, timeZone: userTZIdentifier }),
          Care: getFormattedDateTime({ dateTime })
        }
      ];
    default:
      return [
        {
          User: getFormattedDateTime({ dateTime, timeZone: userTZIdentifier }),
          Care: getFormattedDateTime({ dateTime }),
          System: getFormattedDateTime({
            dateTime,
            timeZone: TIME_ZONES.DEFAULT_TIMEZONE
          })
        }
      ];
  }
};

export const getTimestampRangeForLastHours = hours => {
  const currentTime = new Date();
  const startTimeMilliseconds = currentTime.getTime() - hours * 1000 * 60 * 60;
  const startTime = new Date(startTimeMilliseconds);

  return {
    startTime: startTime.toISOString(),
    endTime: currentTime.toISOString()
  };
};

export const getTimestampRangeForLastHoursUTC = hours => {
  const timeRange = getTimestampRangeForLastHours(hours);
  return moment(timeRange.startTime)
    .utc()
    .format('YYYY-MM-DD hh:mm:ss A Z');
};

export const getEpochTime = date => {
  return date ? moment(date).unix() : moment().unix();
};

export const getDateDifference = date => {
  const newDate = moment(date, 'MM/DD/YYYY HH:mm:ss');
  const currentDate = moment();
  return moment.duration(newDate.diff(currentDate)).humanize();
};

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import { storageWrite } from '../../utils/storageUtils';
import { STORAGE_KEYS, STORAGE_TYPES } from '../../utils/app_constants';
import usePersistableState from '../../hooks/usePersistableState';
import { getHomePageUrl } from '../../utils/helper_functions';
import logoutService from '../../services/logoutService';

function LoggedInUser({ children }) {
  const { oktaAuth, authState } = useOktaAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [idToken, setIdToken] = usePersistableState({
    storageKey: STORAGE_KEYS.OKTA_ID_TOKEN,
    storageType: STORAGE_TYPES.LOCAL
  });
  const [userInfo, setUserInfo] = usePersistableState({
    storageKey: STORAGE_KEYS.OKTA_USER_INFO,
    storageType: STORAGE_TYPES.LOCAL
  });

  const logout = useCallback(async () => {
    await oktaAuth.signOut({
      postLogoutRedirectUri: window.location.origin + '/login'
    });
    setUserInfo(undefined);
    setIdToken(undefined);
    await logoutService.invalidateToken();
  }, [oktaAuth, setUserInfo, setIdToken]);

  const contextValue = useMemo(() => {
    const parsedUserInfo = userInfo && JSON.parse(userInfo);
    return {
      isAuthenticated,
      userInfo: parsedUserInfo,
      idToken,
      homePageUrl:
        parsedUserInfo &&
        getHomePageUrl(
          parsedUserInfo[STORAGE_KEYS.OKTA_USER_PLATFRM_GROUPS]
            ? parsedUserInfo[STORAGE_KEYS.OKTA_USER_PLATFRM_GROUPS]
            : parsedUserInfo[STORAGE_KEYS.OKTA_USER_GROUPS]
        ),
      logout
    };
  }, [isAuthenticated, userInfo, idToken, logout]);

  useEffect(() => {
    const authCheck = async () => {
      const isAuthenticatedResult = authState.isAuthenticated;
      if (isAuthenticatedResult) {
        try {
          setUserInfo(JSON.stringify(await oktaAuth.getUser()));
          setIdToken(oktaAuth.getIdToken());
        } catch {
          setIsAuthenticated(false);
          await logout();
        }
      } else if (isAuthenticatedResult === false) {
        storageWrite({
          key: STORAGE_KEYS.INTENDED_URL,
          value: window.location.pathname,
          storageType: STORAGE_TYPES.SESSION
        });
        await logout();
      }
      setIsAuthenticated(isAuthenticatedResult);
    };

    authState && authCheck();
  }, [oktaAuth, setIdToken, setUserInfo, authState, logout]);

  return (
    <LoggedInUserContext.Provider value={contextValue}>
      {children}
    </LoggedInUserContext.Provider>
  );
}

export default LoggedInUser;

import React, { useState, useContext, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import {
  TmoButtons as TmoButton,
  TmoToggle,
  TmoCard,
  PaginationCounts,
  RawData,
  PaginationV2 as Pagination
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import ExportDataAsFile from 'components/ExportDataAsFile';
import { LAST_6_HOURS } from '../../utils/stringUtils';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';
import Fetcher from '../Fetcher';
import FilterByDate from '../filters/FilterByDate';
import LocationSensorSafeZone from '../sensors/location/LocationSensorSafeZone';
import TimeGraph from '../sensors/TimeGraph';
import {
  SENSOR_TYPES,
  SENSOR_HISTORY_QUERY_KEYS
} from '../../utils/app_constants';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import deviceService from '../../services/deviceService';
import { capitalCase, generateQueryString } from '../../utils/helper_functions';
import {
  getFormattedDateForPicker,
  getTimestampRangeForLastHours
} from '../../utils/dateUtils';
import LocationSensorDetails from './location/LocationSensorDetails';
import SingleSensorDetails from './SingleSensorDetails';
import { getRandomString } from '../../utils/stringUtils';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './SensorHistory.module.css';
import view from '../../static/assets/icons/view.svg';
import hide from '../../static/assets/icons/hide.svg';
import { IoIosArrowDown, FaExclamationTriangle } from 'react-icons/all';
import arrowLeft from '../../static/assets/icons/left_arrow.svg';
import arrowRight from '../../static/assets/icons/right_arrow.svg';

const PAGINATION_ROW_COUNT = 100;
const LOCATION_DATE_PICKER_MIN = 14 * 24;

function SensorHistory({ sensorType, deviceId }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [panelPosition, setPanelPosition] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [timeRange, setTimeRange] = useState();
  const [hasExpanded, setHasExpanded] = useState(false);
  const [sensorHistory, setSensorHistory] = useState();
  const [toggleListView, setToggleListView] = useState(false);
  const [filter, setFilter] = useState('');

  const actionCallback = useCallback(() => {
    trackCustomEvents(`Get Sensor History`, {
      userId,
      deviceId,
      sensorType,
      query: createQueryString(timeRange, currentPage),
      syncUpAppName: product
    });
    return deviceService.getSensorHistory({
      userId,
      deviceId,
      sensorType,
      query: createQueryString(timeRange, currentPage),
      syncUpAppName: product
    });
  }, [deviceId, userId, sensorType, timeRange, currentPage, product]);

  useEffect(() => {
    setHasExpanded(false);
  }, [sensorType]);

  useEffect(() => {
    setCurrentPage(1);
  }, [timeRange]);

  const createQueryString = (dateTimeRange, nextPage) => {
    const queryParams = [
      {
        key: SENSOR_HISTORY_QUERY_KEYS.DATA_COUNT,
        value: PAGINATION_ROW_COUNT
      },
      {
        key: SENSOR_HISTORY_QUERY_KEYS.PAGE,
        value: nextPage - 1
      }
    ];
    for (let key in dateTimeRange) {
      queryParams.push({ key: key, value: dateTimeRange[key] });
    }
    return generateQueryString(queryParams);
  };

  const sensorComponent = () => {
    const renderListOfComponent = render =>
      sensorHistory.data.map((data, index) => (
        <div
          key={data.id}
          className={classNames(sensorType, style.single_sensor, style[index])}
        >
          {sensorHistory.data.length > 0 ? render(data, index) : null}
        </div>
      ));

    switch (sensorType) {
      case SENSOR_TYPES.LOCATION:
        return (
          <>
            <LocationSensorSafeZone
              locationSensor={sensorHistory.data}
              onFilterChange={setFilter}
            />
            {showPagination && (
              <div className={style.pagination_container}>
                <Pagination
                  totalPages={sensorHistory.page.lastPage + 1}
                  selectedPageNumber={currentPage}
                  onPageChanged={currentPage => setCurrentPage(currentPage)}
                  type="primary"
                  icons={{
                    left: <ReactSVG src={arrowLeft} />,
                    right: <ReactSVG src={arrowRight} />
                  }}
                  className={style.custom_pagination}
                />
              </div>
            )}
            {toggleListView && (
              <>
                <div className={style.list}>
                  {filter &&
                    sensorHistory.data
                      .filter(e => e.source === filter)
                      .map((s, index) => (
                        <TmoCard key={s.id} className={style.list_item}>
                          <LocationSensorDetails
                            sensor={s}
                            index={index + 1}
                            isHistoryCard
                          />
                        </TmoCard>
                      ))}
                  {!filter &&
                    sensorHistory.data.map((s, index) => (
                      <TmoCard key={s.id} className={style.list_item}>
                        <LocationSensorDetails
                          sensor={s}
                          index={index + 1}
                          isHistoryCard
                        />
                      </TmoCard>
                    ))}
                </div>
              </>
            )}
          </>
        );
      case SENSOR_TYPES.DEVICE:
      case SENSOR_TYPES.TEMPERATURE:
      case SENSOR_TYPES.ACTIVITY:
      case SENSOR_TYPES.LOSTMODE:
      case SENSOR_TYPES.WLAN:
      default:
        return renderListOfComponent((data, index) => (
          <TmoCard>
            <SingleSensorDetails
              sensorName={data.type}
              sensorData={data}
              index={index + 1}
            />
          </TmoCard>
        ));
    }
  };

  const calculateMinAvailableDate = () => {
    if (sensorType === SENSOR_TYPES.LOCATION) {
      return `${getFormattedDateForPicker(
        '-',
        getTimestampRangeForLastHours(LOCATION_DATE_PICKER_MIN).startTime
      )}T00:00`;
    }
    return '';
  };

  const showPagination =
    sensorHistory &&
    sensorHistory.page &&
    sensorHistory.page.totalItems >= PAGINATION_ROW_COUNT;

  return (
    <div className={style.main_container}>
      {/* need to figure out how to display or get data for other sensor types . 
      Added condition to display view history for location only  */}
      {sensorType === SENSOR_TYPES.LOCATION && (
        <div className={style.history_btn_wrapper}>
          <TmoButton
            type="magenta_primary"
            onClick={() => setHasExpanded(!hasExpanded)}
            icon={
              hasExpanded ? (
                <ReactSVG src={hide} className={style.hide_history} />
              ) : (
                <ReactSVG src={view} />
              )
            }
          >
            {hasExpanded ? 'Hide History' : 'View History'}
          </TmoButton>
        </div>
      )}
      {hasExpanded &&
        (!deviceId ? (
          <div className={style.container}>
            There are no {sensorType} sensor history available
          </div>
        ) : (
          <>
            <div className={style.container}>
              <PageHeader title={'LOCATION SENSOR HISTORY'} tabHeader={true}>
                <FilterByDate
                  onFilter={setTimeRange}
                  onDefaultButtonSet={setTimeRange}
                  selectedButtonName={LAST_6_HOURS}
                  minValueForPicker={calculateMinAvailableDate()}
                  type="dropdown"
                  icon={<IoIosArrowDown />}
                  onSelection={setPanelPosition}
                  className={style.full_width}
                />
              </PageHeader>
            </div>
            <div
              className={classNames(
                style.graph_container,
                panelPosition && style.date_range_panel_position
              )}
            >
              {timeRange && (
                <Fetcher
                  action={actionCallback}
                  onLoad={setSensorHistory}
                  render={() =>
                    sensorHistory && sensorHistory.data.length ? (
                      <>
                        <TimeGraph
                          data={sensorHistory.data}
                          minTime={
                            sensorHistory.page.totalItems >=
                            PAGINATION_ROW_COUNT
                              ? Object.values(sensorHistory.data)[
                                  Object.values(sensorHistory.data).length - 1
                                ].timestamp
                              : timeRange['start-date-time']
                          }
                          maxTime={
                            sensorHistory.page.totalItems >=
                            PAGINATION_ROW_COUNT
                              ? sensorHistory.data[0].timestamp
                              : timeRange['end-date-time']
                          }
                        />
                        <span
                          className={classNames(
                            sensorType === SENSOR_TYPES.LOCATION &&
                              style.results_location,
                            style.results
                          )}
                        >
                          <PaginationCounts
                            dataType={sensorType}
                            showPagination={showPagination}
                            data={sensorHistory.data}
                            totalItems={sensorHistory.page.totalItems}
                            resultsToShow={PAGINATION_ROW_COUNT}
                            currentPage={sensorHistory.page.currentPage}
                            pageSize={sensorHistory.page.pageSize}
                            from={sensorHistory.page.from}
                            to={sensorHistory.page.to}
                            isPrimary
                          />
                          {sensorType === SENSOR_TYPES.LOCATION && (
                            <div className={style.controls}>
                              <div className={style.toggle_view}>
                                <span>Toggle List View</span>
                                <TmoToggle
                                  id={getRandomString()}
                                  isChecked={toggleListView}
                                  onChange={() =>
                                    setToggleListView(!toggleListView)
                                  }
                                />
                              </div>
                              <div className={style.history_object}>
                                <RawData
                                  rawObject={sensorHistory}
                                  title="Location History"
                                  type="Location History"
                                  buttonType="magenta_link"
                                  modalType="slide"
                                  usePortal={true}
                                />
                              </div>
                              <ExportDataAsFile
                                buttonText="Download as CSV"
                                className={style.export_btn}
                                data={sensorHistory.data}
                                fileName="sensor-history"
                                fileExtension="csv"
                                isMagentaLink
                              />
                            </div>
                          )}
                        </span>
                        <div className={style.sensors}>{sensorComponent()}</div>
                      </>
                    ) : (
                      <div className={style.no_results}>
                        <FaExclamationTriangle
                          className={style.no_results_icon}
                        />
                        No history available within the last{' '}
                        <span>
                          {moment
                            .utc(timeRange['start-date-time'])
                            .fromNow(true)}
                        </span>{' '}
                        for the <span>{capitalCase(sensorType)}</span> sensor
                      </div>
                    )
                  }
                />
              )}
            </div>
          </>
        ))}
    </div>
  );
}

export default SensorHistory;

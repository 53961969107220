import React from 'react';
import { ReactSVG } from 'react-svg';
import {
  RawData,
  TmoCard,
  ConfirmationButton
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import classNames from 'classnames';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import { APP_MOBILE } from '../../utils/app_constants';
import EditDriveAppVersionItem from './EditDriveAppVersionItem';

import style from './AppVersionItem.module.css';
import gridStyle from '../ItemDetailList/ItemDetailList.module.css';
import andriodIcon from '../../static/assets/icons/andriod_platform_icon.svg';
import iosIcon from '../../static/assets/icons/ios_platform_icon.svg';
import delete_icon from '../../static/assets/icons/action/delete.svg';

function AppVersionItem({ data, onDelete, product, forceRefresh }) {
  const handleDelete = () => {
    onDelete(data.id);
  };

  const {
    platform,
    distributionChannel,
    id,
    version,
    buildNumber,
    required,
    minVersion,
    minBuildNumber,
    mostUpToDateVersion,
    mostUpToDateBuildNumber
  } = data;

  return (
    <div className={style.container}>
      <TmoCard>
        <div className={style.card_container}>
          {product !== APP_MOBILE.DRIVE ? (
            <div className={classNames(gridStyle.row, style.app_id_row)}>
              <label className={gridStyle.label}>ID</label>
              <div className={gridStyle.value}>{id}</div>
            </div>
          ) : (
            <div className={classNames(gridStyle.row, style.app_id_row)}>
              <label className={gridStyle.label}>Distribution Channel</label>
              <div className={gridStyle.value}>{distributionChannel}</div>
            </div>
          )}
          <div className={style.platform_icon}>
            <img
              className={style.icon}
              alt="platform icon"
              src={platform === 'ANDROID' ? andriodIcon : iosIcon}
            />
          </div>
          {product === APP_MOBILE.DRIVE ? (
            <ItemDetailList
              rowSet={[
                {
                  'Min Version': minVersion,
                  'Min Build Number': minBuildNumber
                },
                {
                  'Most Up To Date Verison': mostUpToDateVersion,
                  'Most Up To Date Build Number': mostUpToDateBuildNumber
                }
              ]}
            />
          ) : (
            <ItemDetailList
              rowSet={[
                { 'Distribution Channel': distributionChannel, version },
                { buildNumber, 'App update required': required }
              ]}
            />
          )}
        </div>
        <div className={style.action}>
          <RawData
            rawObject={data}
            title="App Version Details"
            type="Version Details"
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_link"
          />
          {product === APP_MOBILE.DRIVE ? (
            <EditDriveAppVersionItem
              data={data}
              product={product}
              forceRefresh={forceRefresh}
            />
          ) : (
            <ConfirmationButton
              className={style.button}
              buttonType="magenta_secondary"
              handleDeleteClicked={handleDelete}
              modalMessage={
                <>
                  Are you sure you want to delete {data.platform} {data.version}
                </>
              }
              modalTitle="Delete User"
              modalAcceptButtonText={'Yes,Delete'}
              buttonIcon={<ReactSVG src={delete_icon} />}
              tooltip="Delete"
              type="delete"
            />
          )}
        </div>
      </TmoCard>
    </div>
  );
}

export default AppVersionItem;

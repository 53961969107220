import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  NavigationTab as Tab,
  NavigationTabGroup
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import roleMapper from 'roleMapper/roleMapper';
import BulkNotificationFormAutocomplete from '../../components/bulknotification/BulkNotificationFormAutocomplete';
import BulkNotificationFormOpenForm from '../../components/bulknotification/BulkNotificationFormOpenForm';
import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import ContentContainer from '../../components/uiHelpers/ContentContainer';
import EnvRoleFeatureAuthorizer from 'components/featureWrappers/EnvRoleFeatureAuthorizer';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';

import style from './BulkNotificationPage.module.css';

const autocompleteUrl = '/bulk-notifications/auto-complete';
const openFormUrl = '/bulk-notifications/open-form';

function BulkNotificationPage() {
  const { roles, products } = roleMapper.bulkNotification;

  return (
    <EnvRoleFeatureAuthorizer roles={roles} products={products}>
      <Authorized roles={[SUA_ROLES.PO, SUA_ROLES.ADMIN]} willShowErrorModal>
        <ContentContainer className={style.container}>
          <PageHeader title={'BULK NOTIFICATION'} className={style.heading} />
          <NavigationTabGroup type="primary">
            <Tab
              to={autocompleteUrl}
              type="primary-tab"
              text="By Name"
              className={style.tab}
            />
            <Tab
              to={openFormUrl}
              type="primary-tab"
              text="By User ID"
              className={style.tab}
            />
          </NavigationTabGroup>
          <Switch>
            <Route
              exact
              path={autocompleteUrl}
              component={BulkNotificationFormAutocomplete}
            />
            <Route
              exact
              path={openFormUrl}
              component={BulkNotificationFormOpenForm}
            />
          </Switch>
        </ContentContainer>
      </Authorized>
    </EnvRoleFeatureAuthorizer>
  );
}
export default BulkNotificationPage;

import React, { useState, useCallback, useContext } from 'react';

import {
  withModal,
  TmoH3,
  TmoCard,
  ModalV2,
  ModalContext
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import firmwareService from '../../services/firmwareService';
import FirmwareDetails from '../things/firmware/FirmwareDetails';
import TextAlert from '../textHelpers/TextAlert';
import Accordion from '../uiHelpers/Accordion';

import style from './GroupItemDetails.module.css';

function GroupItemDetails({ imei, openModal }) {
  const [firmwareDetails, setFirmwareDetails] = useState();
  const [downloadDetails, setDownloadDetails] = useState([]);
  const { isModalOpen } = useContext(ModalContext);

  const fetchAll = useCallback(async () => {
    return Promise.all([
      firmwareService.getFirmwarePackageByImei(imei.split(':')[2]),
      firmwareService.getFirmwareDownloadStatusByImei(imei.split(':')[2])
    ]);
  }, [imei]);

  const handleSetDetails = useCallback(([firmware, downloads]) => {
    setFirmwareDetails(firmware);
    setDownloadDetails(downloads);
  }, []);

  return (
    <>
      <li className={style.imei} onClick={openModal}>
        {imei}
      </li>
      <ModalV2 title="Firmware Details">
        <Fetcher
          action={isModalOpen && fetchAll}
          onLoad={handleSetDetails}
          render={() => (
            <>
              <TextAlert
                message="* We do not keep track of the current firmware package for an
                imei, the firmware package below represents a target."
              />
              <div className={style.column_container}>
                <div
                  className={downloadDetails.data.length ? style.column : null}
                >
                  <div className={style.row}>
                    <label>
                      Device ID: <span>{imei}</span>
                    </label>
                  </div>
                  <FirmwareDetails details={firmwareDetails} />
                </div>
                {downloadDetails.data.length ? (
                  <div className={style.column}>
                    <TmoCard>
                      <TmoH3 className={style.accordion_title} flush>
                        Previous Firmware Download Status
                      </TmoH3>
                      {downloadDetails.data.map(d => (
                        <Accordion
                          key={d.id}
                          title={`${d.createdDateTime} - ${d.status}`}
                          className={style.accordion_row}
                        >
                          <FirmwareDetails details={d} />
                        </Accordion>
                      ))}
                    </TmoCard>
                  </div>
                ) : null}
              </div>
            </>
          )}
        />
      </ModalV2>
    </>
  );
}

export default withModal(GroupItemDetails);

import React from 'react';

import TmoButton from './TmoButton';
import { FETCH_STATE } from '../../utils/app_constants';
import useManualFetcher from '../../hooks/useManualFetcher';

function TmoButtonAsync({ className, onClick, children, ...rest }) {
  const [fetchState, actionTrigger] = useManualFetcher({
    action: onClick
  });

  return (
    <TmoButton
      className={className}
      onClick={actionTrigger}
      disabled={fetchState === FETCH_STATE.LOADING}
      {...rest}
    >
      {children}
    </TmoButton>
  );
}

export default TmoButtonAsync;

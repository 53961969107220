import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import TmoH1 from '../../controlWrappers/TmoH1';
import AlertsWidgetModal from './AlertsWidgetModal';
import withModal from '../../modal/withModal';
import TmoSvg from '../../uiHelpers/TmoSvg';
import Tab from '../../navigationTabs/Tab';
import TabGroup from '../../navigationTabs/TabGroup';
import {
  alertListDurationData,
  DURATION_TYPES
} from '../../../utils/pocDummyData';

import './AlertsWidget.css';

function AlertsWidget({ openModal }) {
  const [alertDuration, setAlertDuration] = useState('Today');
  const [alertModalContent, setAlertModalContent] = useState(
    alertListDurationData[alertDuration][0]
  );
  const [alertDisplayList, setAlertDisplayList] = useState(
    alertListDurationData[alertDuration]
  );

  const onDurationClicked = value => {
    setAlertDisplayList(alertListDurationData[value]);
    setAlertDuration(value);
  };

  const handleAlertClick = alert => {
    openModal();
    setAlertModalContent(alert);
  };

  const listItems = alertDisplayList.map((alert, index) => (
    <div
      key={index}
      className="AlertsWidget-alert-row"
      onClick={() => handleAlertClick(alert)}
    >
      <div className="AlertsWidget-alert-site">{alert.site}</div>
      <TmoSvg
        svgId={alert.isOpen ? 'icon-error-triangle' : 'icon-alert-closed'}
        className="AlertsWidget-alert-status-icon"
      />
      <div className="AlertsWidget-alert-title">{alert.title}</div>
      <div className="AlertsWidget-alert-datetime">
        {alert.date} {alert.time}
      </div>
    </div>
  ));

  return (
    <div className="AlertsWidget-container">
      <div className="AlertsWidget-header">
        <div className="AlertsWidget-header-heading">
          <TmoH1>Alerts</TmoH1>
        </div>
        <div className="AlertsWidget-header-labels">
          <label>
            <TmoSvg
              svgId="icon-alert-open"
              className="AlertsWidget-alert-status-icon icon-alert-open"
            />
            <span>OPEN</span>
          </label>
          <label>
            <TmoSvg
              svgId="icon-alert-closed"
              className="AlertsWidget-alert-status-icon icon-alert-closed"
            />
            <span>CLOSED</span>
          </label>
        </div>
        <div className="AlertsWidget-tabview">
          <TabGroup className="AlertsWidget-tabs">
            {Object.values(DURATION_TYPES).map(d => (
              <Tab
                key={d}
                className="AlertsWidget-tab"
                isSelected={alertDuration === d}
                text={d.replace(/([A-Z])/g, ' $1').trim()}
                value={d}
                onSelect={onDurationClicked}
              />
            ))}
          </TabGroup>
        </div>
      </div>
      <div className="AlertsWidget-alertlist">{listItems}</div>
      <AlertsWidgetModal alert={alertModalContent} />
    </div>
  );
}

export default withModal(withRouter(AlertsWidget));

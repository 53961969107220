import React, { useState, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import Mark from '../../../node_modules/mark.js/dist/mark';
import classNames from 'classnames';
import { TmoCard, TmoInput } from '@tmobile/tmo-bps-syncup-web-component-lib';

import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './PortalSearch.module.css';
import explore_icon from '../../static/assets/icons/explore_icon.svg';
import searchIcon from '../../static/assets/icons/help_search.svg';

function PortalSearch({ renderItem, list, isAdmin, disableInput }) {
  const [searchText, setSearchText] = useState('');
  const formatListData = listData => {
    let categories = [...new Set(listData.map(e => e?.categoryId))];
    let formattedList = [];
    categories.forEach(item => {
      let temp = listData.filter(data => data?.categoryId === item);

      if (temp && temp[0] && temp[0].hasSubCategory) {
        let subCategories = [...new Set(temp.map(e => e?.subCategoryId))];

        let subCategoryList = [];
        let categoryItem = {
          category: temp[0].name,
          categoryId: temp[0].categoryId,
          order: temp && temp[0].categoryOrder ? temp[0].categoryOrder : 100,
          categoryOrder:
            temp && temp[0].categoryOrder ? temp[0].categoryOrder : 100,
          categoryDesc: temp[0].categoryDesc,
          hasSubCategory: true,
          categoryIcon: temp[0].icon
        };

        subCategories.forEach(e => {
          let subTemp = temp.filter(data => data?.subCategoryId === e);
          subCategoryList.push({
            subCategory: subTemp[0].subCategory,
            subCategoryId: subTemp[0].subCategoryId,
            order: subTemp && subTemp[0].subOrder ? subTemp[0].subOrder : 100,
            subOrder:
              subTemp && subTemp[0].subOrder ? subTemp[0].subOrder : 100,
            details: subTemp.sort((a, b) => a.faqOrder - b.faqOrder)
          });
        });
        categoryItem = { ...categoryItem, details: subCategoryList };
        formattedList.push(categoryItem);
      } else {
        formattedList.push({
          category: temp[0].name,
          categoryId: temp[0].categoryId,
          order: temp && temp[0].categoryOrder ? temp[0].categoryOrder : 100,
          categoryOrder:
            temp && temp[0].categoryOrder ? temp[0].categoryOrder : 100,
          categoryDesc: temp[0].categoryDesc,
          details: temp.sort((a, b) => a.faqOrder - b.faqOrder),
          categoryIcon: temp[0].icon
        });
      }
    });
    return formattedList.sort((a, b) => a.order - b.order);
  };
  const handleInput = text => {
    setSearchText(text);
    const instance = new Mark(document.querySelector('#searchResult'));
    instance.unmark({
      done: () => {
        instance.mark(text, { separateWordSearch: false });
      }
    });
    trackCustomEvents(`Portal Help Search`, {
      searchText: text
    });
  };
  const filteredList = useMemo(() => {
    let faqList = [];
    list.forEach(category => {
      let catTemp = {};
      if (category.faqList) {
        catTemp = { ...category, categoryId: category.id, faqList: [] };
        category.faqList.forEach(item => {
          let temp = { ...catTemp, ...item, faqId: item.id };
          faqList = [...faqList, temp];
        });
      } else {
        catTemp = { ...category, categoryId: category.id, faqList: [] };
        faqList = [...faqList, catTemp];
      }
    });

    return searchText
      ? formatListData(
          faqList?.filter(
            item =>
              item['name'].toLowerCase().includes(searchText.toLowerCase()) ||
              item['description']
                .toLowerCase()
                .includes(searchText.toLowerCase()) ||
              item['title'].toLowerCase().includes(searchText.toLowerCase()) ||
              (item['categoryDesc'] &&
                item['categoryDesc']
                  .toLowerCase()
                  .includes(searchText.toLowerCase())) ||
              (item['shortDesc'] &&
                item['shortDesc']
                  .toLowerCase()
                  .includes(searchText.toLowerCase())) ||
              (item['hasSubCategory'] &&
                item['subCategory']
                  .toLowerCase()
                  .includes(searchText.toLowerCase()))
          )
        )
      : formatListData(faqList);
  }, [list, searchText]);
  return (
    <>
      {isAdmin && (
        <form
          className={classNames(
            style.search_section,
            isAdmin && style.no_padding,
            isAdmin && style.search_control
          )}
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          {isAdmin ? (
            <ReactSVG src={searchIcon} className={style.search_icon} />
          ) : (
            <img
              src={explore_icon}
              className={style.explore_input_icon}
              alt="Search icon"
            />
          )}
          <TmoInput
            className={classNames(style.input, isAdmin && style.admin_search)}
            size="lg"
            placeholder="Search"
            type="text"
            autoFocus
            onChange={handleInput}
            value={searchText}
            aria-label="Search"
            disabled={isAdmin && disableInput}
          />
        </form>
      )}
      {filteredList?.length ? (
        <TmoCard
          shadow={false}
          className={classNames(
            style.no_padding,
            isAdmin && style.search_scroll
          )}
          customId="searchResult"
        >
          {filteredList.map((item, index) => renderItem(item, index))}
        </TmoCard>
      ) : (
        <>
          <div className={style.no_result_wrapper}>
            <div aria-label="No results found">No results found</div>
          </div>
        </>
      )}
    </>
  );
}

export default PortalSearch;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Security } from '@okta/okta-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import App from './App';
import Status from './components/status/Status';

import * as serviceWorker from './serviceWorker';
import store from './reducers';
import config from './configs/config';
import { authClient, history, restoreOriginalUri } from './auth/authClient';
import { IS_AZURE_AUTH } from './utils/app_constants';

import './index.css';

const { oktaSettings, msalSettings } = config;
const container = document.getElementById('root');

const msalInstance = new PublicClientApplication(msalSettings);
async function initializeMsal() {
  await msalInstance.initialize();
}
initializeMsal();

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback(event => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <Router history={history}>
      {IS_AZURE_AUTH ? (
        <Status>
          <App instance={msalInstance} />
        </Status>
      ) : (
        <Security oktaAuth={authClient} restoreOriginalUri={restoreOriginalUri}>
          <Status>
            <App oktaBaseUrl={oktaSettings.baseUrl} />
          </Status>
        </Security>
      )}
    </Router>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import config from '../configs/config';
import commercialTrackerRequest from '../repositories/commercialTrackerRequest';

const commercialTrackerRepository = {
  getCoolpadGetFormattedLocationByAsset: async () => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.commercialTrackerPlatform;

    return await commercialTrackerRequest({
      urlSuffix: resources.coolpadGetFormattedLocationByAsset,
      basePath: basePath,
      httpMethod: httpMethods.GET,
      headers: {
        Accept: headers.accept.value,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value
      }
    });
  },
  getCoolpadGetFormattedLocationForAllAssets: async () => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.commercialTrackerPlatform;

    return await commercialTrackerRequest({
      urlSuffix: resources.coolpadGetFormattedLocationForAllAssets,
      basePath: basePath,
      httpMethod: httpMethods.GET,
      headers: {
        Accept: headers.accept.value,
        [headers.contentTypeJSON.key]: headers.contentTypeJSON.value
      }
    });
  }
};

export default commercialTrackerRepository;

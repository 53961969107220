import userRepository from '../repositories/userRepository';
import thingService from '../services/thingService';
import deviceService from '../services/deviceService';
import { DATE_IN_TIME_ZONE } from '../utils/app_constants';
import { getTimestampList, getFormattedDateTime } from '../utils/dateUtils';

const userService = {
  getUsersBySearch: async ({ q, syncUpAppName }) => {
    return await userRepository.getUsersBySearch({ q, syncUpAppName });
  },
  /* for tlife search */
  getTlifeUsersBySearch: async ({ q, syncUpAppName, page }) => {
    return await userRepository.getTlifeUsersBySearch({ q, syncUpAppName, page });
  },

  getUserById: async ({ id, syncUpAppName }) => {
    return await userRepository.getUserById({ id, syncUpAppName });
  },
  getUserNotificationTokens: async ({ userId, syncUpAppName }) => {
    return await userRepository.getUserNotificationTokens({
      userId,
      syncUpAppName
    });
  },
  deleteUserById: async ({ id, syncUpAppName }) => {
    if (syncUpAppName === 'DRIVE') {
      return await userRepository.deleteDriveUserById({ id, syncUpAppName });
    } else {
      return await userRepository.deleteUserById({ id, syncUpAppName });
    }
  },
  registerUser: async ({ body, syncUpAppName }) => {
    return await userRepository.registerUser({ body, syncUpAppName });
  },
  getContacts: async ({ userId, syncUpAppName }) => {
    const results = await userRepository.getContacts({ userId, syncUpAppName });
    return results;
  },
  getPhoneLocationDetails: async ({ userId, syncUpAppName }) => {
    return await userRepository.getPhoneLocationDetails({
      userId,
      syncUpAppName
    });
  },
  deletePhoneLocation: async ({ userId, thingId, syncUpAppName }) => {
    return await userRepository.deletePhoneLocation({
      userId,
      thingId,
      syncUpAppName
    });
  },
  createPhoneLocationGroups: async ({ userId, syncUpAppName, body }) => {
    return await userRepository.createPhoneLocationGroups({
      userId,
      syncUpAppName,
      body
    });
  },
  createInviteLocationGroups: async ({ userId, syncUpAppName, body }) => {
    return await userRepository.createInviteLocationGroups({
      userId,
      syncUpAppName,
      body
    });
  },

  redeemInvitesLocationGroups: async ({
    userId,
    syncUpAppName,
    groupId,
    inviteId
  }) => {
    return await userRepository.redeemInvitesLocationGroups({
      userId,
      syncUpAppName,
      groupId,
      inviteId
    });
  },

  getPhoneLocationGroups: async ({ userId, syncUpAppName }) => {
    return await userRepository.getPhoneLocationGroups({
      userId,
      syncUpAppName
    });
  },
  deletePhoneLocationMemberInvite: async ({
    groupId,
    inviteId,
    userId,
    syncUpAppName
  }) => {
    return await userRepository.deletePhoneLocationMemberInvite({
      groupId,
      inviteId,
      userId,
      syncUpAppName
    });
  },
  deletePhoneLocationGroup: async ({ groupId, userId, syncUpAppName }) => {
    return await userRepository.deletePhoneLocationGroup({
      groupId,
      userId,
      syncUpAppName
    });
  },
  deletePhoneLocationGroupMember: async ({
    groupId,
    userId,
    memberId,
    syncUpAppName
  }) => {
    return await userRepository.deletePhoneLocationGroupMember({
      groupId,
      userId,
      memberId,
      syncUpAppName
    });
  },
  deletePhoneLocationGroupAvatar: async ({
    groupId,
    userId,
    syncUpAppName
  }) => {
    return await userRepository.deletePhoneLocationGroupAvatar({
      groupId,
      userId,
      syncUpAppName
    });
  },
  swapPhoneLocationGroupMember: async ({
    userId,
    groupId,
    targetUserId,
    syncUpAppName,
    body
  }) => {
    return await userRepository.swapPhoneLocationGroupMember({
      userId,
      groupId,
      targetUserId,
      syncUpAppName,
      body
    });
  },
  getPhoneLocationGroupUsers: async ({ userId, groupId, syncUpAppName }) => {
    return await userRepository.getPhoneLocationGroupUsers({
      userId,
      groupId,
      syncUpAppName
    });
  },
  getPhoneLocationGroupVB: async ({ userId, groupId, syncUpAppName }) => {
    return await userRepository.getPhoneLocationGroupVB({
      userId,
      groupId,
      syncUpAppName
    });
  },
  getSchoolModeDetails: async ({ userId, syncUpAppName }) => {
    const results = await userRepository.getSchoolModeDetails({
      userId,
      syncUpAppName
    });
    return results;
  },
  getUserTasksDetails: async ({ userId, syncUpAppName }) => {
    const results = await userRepository.getUserTasksDetails({
      userId,
      syncUpAppName
    });
    return results;
  },

  getUserTasksDetailsByThing: async ({ userId, thingId, syncUpAppName }) => {
    const results = await userRepository.getUserTasksDetailsByThing({
      userId,
      thingId,
      syncUpAppName
    });
    return results;
  },

  postSchoolModeDetails: async ({ data, userId, syncUpAppName }) => {
    return await userRepository.postSchoolModeDetails({
      data,
      userId,
      syncUpAppName
    });
  },

  deleteSchoolModeDetails: async ({
    scheduledTaskId,
    userId,
    syncUpAppName
  }) => {
    return await userRepository.deleteSchoolModeDetails({
      scheduledTaskId,
      userId,
      syncUpAppName
    });
  },

  deleteUserTask: async ({ userTaskId, userId, syncUpAppName }) => {
    return await userRepository.deleteUserTask({
      userTaskId,
      userId,
      syncUpAppName
    });
  },

  getUserNotifications: async ({ userId, query, syncUpAppName }) => {
    if (syncUpAppName === 'DRIVE') {
      const rawNotifications = await userRepository.getDriveUserNotificationsList(
        {
          userId,
          query,
          syncUpAppName
        }
      );

      let sortByCreatedDateTime = rawNotifications.sort((a, b) =>
        b.sentAt.localeCompare(a.sentAt)
      );
      return sortByCreatedDateTime.map(item => {
        const { notificationMethod, payload, notificationType, sentAt } = item;

        const formattedTime = getTimestampList(
          sentAt,
          null,
          DATE_IN_TIME_ZONE.USER
        );

        const obj = JSON.parse(payload);

        return {
          title: notificationMethod,
          body: obj,
          event: notificationType,
          thingId: obj.content?.customData?.thingId,
          deviceId: obj.content?.customData?.deviceId,
          timeSent: formattedTime
        };
      });
    } else {
      const rawNotifications = await userRepository.getUserNotificationsList({
        userId,
        query,
        syncUpAppName
      });

      const getNotificationEvent = customData => {
        if (customData.geofenceId) {
          return `Geofence Breach [ID: ${customData.geofenceId}]`;
        }

        if (customData.batteryPercentage) {
          return `Battery Low [${customData.batteryPercentage}%]`;
        }

        if (customData.event === 'DEVICE_ACTIVATION') {
          return `Device Activation`;
        }

        return 'Others';
      };

      let sortByCreatedDateTime = rawNotifications.sort((a, b) =>
        b.createdDateTime.localeCompare(a.createdDateTime)
      );

      return sortByCreatedDateTime.map(item => {
        let notificationName, notificationBody, event, thingId, deviceId;

        const { notificationData, createdDateTime } = item;
        const formattedTime = getTimestampList(
          createdDateTime,
          null,
          DATE_IN_TIME_ZONE.USER
        );

        if (notificationData && notificationData.notificationContent) {
          const { notificationContent } = notificationData;
          notificationName = notificationContent.name;
          notificationBody = notificationContent.body;

          if (notificationContent.customData) {
            const { customData } = notificationContent;
            thingId = customData.thingId ? customData.thingId : null;
            deviceId = customData.deviceId ? customData.deviceId : null;
            event = getNotificationEvent(customData);
          }
        }

        return {
          title: notificationName,
          body: notificationBody,
          event: event,
          thingId: thingId,
          deviceId: deviceId,
          timeSent: formattedTime
        };
      });
    }
  },
  postBulkNotification: async ({ data, syncUpAppName }) => {
    return await userRepository.postBulkNotification({ data, syncUpAppName });
  },
  getUserSafeZones: async ({ id, syncUpAppName }) => {
    var geoFenceData = await userRepository.getUserSafeZones({
      id,
      syncUpAppName
    });
    if (syncUpAppName === 'DRIVE') {
      geoFenceData = geoFenceData.map(obj => ({
        ...obj,
        geofence: {
          shape: 'CIRCLE',
          point: {
            longitude: obj.latitude,
            latitude: obj.longitude
          },
          radius: obj.radius
        }
      }));
      return geoFenceData;
    } else {
      return geoFenceData;
    }
  },
  createUserSafeZones: async ({ userId, syncUpAppName, body }) => {
    return await userRepository.createUserSafeZones({
      userId,
      syncUpAppName,
      body
    });
  },
  getAuthUsers: async ({ syncUpAppName }) => {
    const authUser = await userRepository.getAuthUsers({ syncUpAppName });
    return authUser.map(i => ({
      ...i,
      createdDateTime: getTimestampList(
        i['createdDateTime'],
        null,
        DATE_IN_TIME_ZONE.SYSTEM
      ),
      modifiedDateTime: getTimestampList(
        i['modifiedDateTime'],
        null,
        DATE_IN_TIME_ZONE.SYSTEM
      )
    }));
  },
  deleteAuthUser: async ({ syncUpAppName, userId }) => {
    return await userRepository.deleteAuthUser({ syncUpAppName, userId });
  },
  createAuthUser: async ({ syncUpAppName, data }) => {
    return await userRepository.createAuthUser({ syncUpAppName, data });
  },
  getObservedUsers: async ({ observedUsers = [], syncUpAppName }) => {
    const results = await Promise.all(
      observedUsers.map(async observedUser => {
        const searchResult = await userRepository.getUsersBySearch({
          q: observedUser.hardwareId,
          syncUpAppName
        });
        if (searchResult.length) {
          const deviceResult = await thingService.getThingsByUserId({
            userId: searchResult[0]?.userProfile?.userId,
            syncUpAppName
          });

          const thing = deviceResult.find(t =>
            syncUpAppName === 'DRIVE'
              ? t.device &&
                t.device.id
                  .toLowerCase()
                  .includes(observedUser.hardwareId.toLowerCase())
              : t.currentDevice &&
                t.currentDevice.hardwareId
                  .toLowerCase()
                  .includes(observedUser.hardwareId.toLowerCase())
          );

          if (thing) {
            if (syncUpAppName === 'DRIVE') {
              const {
                onboardStatus,
                lastKnownAt,
                lastKnownAtText,
                device
              } = thing;

              var progress = '';
              switch (onboardStatus) {
                case 'ONBOARDED':
                  progress = 'Onboarding Complete (4)';
                  break;
                case 'NEEDS_PERSONALIZATION':
                  progress = 'Data Received (3)';
                  break;
                case 'NEEDS_TELEMETRY':
                  progress = 'Data Received (3)';
                  break;
                default:
                  progress = '';
                  break;
              }
              return {
                hardwareId: device.id,
                user: `${searchResult[0]?.userProfile?.firstName} ${searchResult[0]?.userProfile?.lastName}`,
                accountActivated: 'Yes',
                lastUpdate: getFormattedDateTime({
                  dateTime: device.lastConnectedAt
                }),
                Progress: progress,
                version: device.mcuFirmwareVersion,
                deviceStatus: device.status,
                onboardingStatus: onboardStatus,
                wifiStatus: device.wifiStatus,
                lastLocationUpdate: lastKnownAtText
                  ? getFormattedDateTime({
                      dateTime: lastKnownAt
                    })
                  : undefined,
                full_things_data: thing
              };
            } else {
              const { currentDevice } = thing;

              return {
                ...observedUser,
                accountActivated: 'Yes',
                lastUpdate: getFormattedDateTime({
                  dateTime: currentDevice.lastCommunicationDateTime
                }),
                batteryLevel: currentDevice.latestSensors.device?.batteryLevel,
                version: currentDevice.latestSensors.device?.softwareVersion,
                serviceStatus: currentDevice.status,
                onboardingStatus: currentDevice.onboardingStatus,
                onlineStatus: currentDevice.offline ? 'Offline' : 'Online',
                lastLocationUpdate: currentDevice.latestSensors.location
                  ? getFormattedDateTime({
                      dateTime: currentDevice.latestSensors.location.updateDate
                    })
                  : undefined
              };
            }
          } else {
            if (syncUpAppName === 'DRIVE') {
              const deviceList = await deviceService.getDevicesByUserId({
                userId: searchResult[0]?.userProfile?.userId,
                syncUpAppName
              });

              const device = deviceList.find(t =>
                t.id
                  .toLowerCase()
                  .includes(observedUser.hardwareId.toLowerCase())
              );
              const thingWithoutDevice = deviceResult.find(
                t => t.device == null
              );
              return {
                ...observedUser,
                user: `${searchResult[0]?.userProfile?.firstName} ${searchResult[0]?.userProfile?.lastName}`,
                accountActivated: 'Yes',
                lastUpdate: getFormattedDateTime({
                  dateTime: device.lastConnectedAt
                }),
                Progress:
                  device.status === 'CLAIMED'
                    ? 'IMEI Scanned (2)'
                    : device.status === 'UNPLUGGED'
                    ? thingWithoutDevice
                      ? 'Onboarding Complete (4)'
                      : 'IMEI Scanned (2)'
                    : '',
                version: device.mcuFirmwareVersion,
                deviceStatus: device.status,
                onboardingStatus: 'Not Available',
                wifiStatus: device.wifiStatus,
                lastLocationUpdate: 'Not Available',
                full_device_data: device
              };
            } else {
              return {
                ...observedUser,
                accountActivated: 'Yes'
              };
            }
          }
        } else {
          return {
            ...observedUser,
            accountActivated: 'No'
          };
        }
      })
    );

    results.sort((a, b) => (a.user < b.user ? -1 : 1));
    return results;
  },
  executeUserConsentCOAPCommand: async ({ data, channelId, isTmoUser }) => {
    return await userRepository.executeUserConsentCOAPCommand({
      data,
      channelId,
      isTmoUser
    });
  }
};

export default userService;

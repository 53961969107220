import React from 'react';
import { ContentContainer } from '@tmobile/tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import Recommission from '../../components/bulkRecommission/Recommission';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';

import style from './BulkRecommission.module.css';

function BulkUnclaim({ match: { url } }) {
  return (
    <Authorized
      roles={[SUA_ROLES.ADMIN, SUA_ROLES.SUPPLY_CHAIN]}
      willShowErrorModal
    >
      <ContentContainer className={style.container}>
        <PageHeader title={'BULK RECOMMISSION'} className={style.heading} />
        <Recommission />
      </ContentContainer>
    </Authorized>
  );
}

export default BulkUnclaim;

import React from 'react';
import classNames from 'classnames';

import DynamicList from '../../uiHelpers/DynamicList';

import './TrackersSiteList.css';

function TrackersSiteList({
  className,
  headerNameListItemMap,
  listItems,
  onSort,
  sortDirection
}) {
  return (
    <div className={classNames('TrackerSite-list', className)}>
      <DynamicList
        className="border-lite"
        headerNameListItemMap={headerNameListItemMap}
        listItems={listItems}
        onSort={onSort}
        sortDirection={sortDirection}
      />
    </div>
  );
}

export default TrackersSiteList;

import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { Tooltip } from '@tmobile/tmo-bps-syncup-web-component-lib';

import TmoH4 from '../controlWrappers/TmoH4';

import style from './ExpandableText.module.css';
import arrowDown from '../../static/assets/icons/leftpanel/arrow_down.svg';
import arrowUp from '../../static/assets/icons/leftpanel/arrow_up.svg';

const ExpandableText = ({
  className,
  label,
  triggerText,
  closeText,
  triggerIcon,
  innerHeading,
  theme,
  isNavigationMenu,
  hasRef,
  boldLabel = false,
  customText,
  noHover,
  customIcon,
  expanded = false,
  menuIcon,
  showMenuItems,
  subMenuIcon,
  isNavCollapsed = false,
  ...props
}) => {
  const [hasExpanded, setHasExpanded] = useState(expanded);
  const ref = useRef();
  useOnClickOutside(ref, () => setHasExpanded(false));

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      if (hasRef) {
        const listener = event => {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener('mousedown', listener);
        return () => {
          document.removeEventListener('mousedown', listener);
        };
      }
    }, [ref, handler]);
  }

  return (
    <div
      ref={ref}
      className={classNames(
        style.container,
        hasExpanded && style.expanded,
        style[theme],
        className
      )}
    >
      <label
        className={classNames(style.label, boldLabel && style.label_bold)}
        onClick={() => setHasExpanded(!hasExpanded)}
      >
        {menuIcon && showMenuItems && (
          <div className={style.menu_icon}>
            <ReactSVG src={menuIcon} />
          </div>
        )}
        {menuIcon && !showMenuItems && (
          <Tooltip tooltip={triggerText} position="bottom">
            <div className={style.menu_icon}>
              <ReactSVG src={menuIcon} />
            </div>
          </Tooltip>
        )}
        {((menuIcon && showMenuItems) || !menuIcon) && (
          <>
            {label}
            {customText && customText}
            <span
              className={classNames(style.trigger, noHover ? style.no_bg : '')}
            >
              {!hasExpanded && closeText
                ? customText
                  ? customText
                  : triggerText
                : closeText}
              {!closeText
                ? customIcon
                  ? hasExpanded
                    ? customIcon.arrowUp
                    : customIcon.arrowDown
                  : triggerText
                : null}
              {triggerIcon && !menuIcon && (
                <span className={style.trigger_icon}>{triggerIcon}</span>
              )}
              {triggerIcon && menuIcon && (
                <div className={style.expandable_menu_icon}>
                  <ReactSVG
                    src={hasExpanded ? arrowUp : arrowDown}
                    className={style.no_data_img}
                  />
                </div>
              )}
            </span>
          </>
        )}
      </label>
      {hasExpanded && (
        <div className={style.content}>
          <div
            className={classNames(
              style.content_inner,
              isNavCollapsed && style.content_collapsed_inner
            )}
          >
            {innerHeading && (
              <TmoH4 className={style.content_inner_heading}>
                {innerHeading}
              </TmoH4>
            )}
            {isNavigationMenu ? (
              <span onClick={() => setHasExpanded(false)}>
                {props.children}
              </span>
            ) : (
              <>{props.children}</>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandableText;

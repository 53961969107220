import React from 'react';
import { TmoH1, TmoCard } from '@tmobile/tmo-bps-syncup-web-component-lib';

import Authorized from '../../../auth/Authorized';
import { SUA_ROLES } from '../../../utils/app_constants';
import AppCenterStatus from './AppCenterStatus';

function Diagnostics() {
  return (
    <Authorized
      roles={[
        SUA_ROLES.ADMIN,
        SUA_ROLES.PO,
        SUA_ROLES.PETS_ADMIN,
        SUA_ROLES.PETS_DEV,
        SUA_ROLES.PETS_PO
      ]}
      willShowErrorModal
    >
      <TmoCard>
        <TmoH1>App Center</TmoH1>
        <AppCenterStatus />
      </TmoCard>
    </Authorized>
  );
}

export default Diagnostics;

import React from 'react';
import {
  TmoCard,
  TmoH1,
  ContentContainer
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { xcode } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { BATTERY_STATUS_CODES } from '../../utils/glossary_constants';
import style from './BatteryStatusCodes.module.css';

function BatteryStatusCodes() {
  const batteryCodes = BATTERY_STATUS_CODES.map((item, index) => {
    return (
      <div className={style.code} key={index}>
        <SyntaxHighlighter language={'java'} style={xcode}>
          {item.description}
        </SyntaxHighlighter>
      </div>
    );
  });

  return (
    <ContentContainer className={style.container}>
      <TmoH1>Battery Status</TmoH1>
      <TmoCard>{batteryCodes}</TmoCard>
    </ContentContainer>
  );
}

export default BatteryStatusCodes;

import React, { useContext, useState } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoDropDownSelect,
  TmoButtons
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import iotFirmwareService from '../../../services/iotFirmwareService';
import StatusContext from '../../../context/StatusContext';
import SyncUpProductContext from '../../../context/SyncUpProductContext';

import style from './AssignConfig.module.css';
import { IoIosArrowDown } from 'react-icons/all';
import update from '../../../static/assets/icons/check.svg';
import cancel from '../../../static/assets/icons/action/close.svg';

function AssignConfig({
  selectOptions,
  group,
  onAssignConfigs,
  onCancel,
  firmwareId
}) {
  const [selectedValue, setSelectedValue] = useState(
    firmwareId ? firmwareId : selectOptions[0].key
  );
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const { groupName, id } = group;

  const handleOnClick = e => {
    setSelectedValue(e);
  };
  const handleUpdateConfig = async () => {
    try {
      let fwGroup = async () => {
        return await iotFirmwareService.updateIotFirmwareGroup({
          groupId: id,
          body: {
            groupName: groupName,
            firmwareId: selectedValue
          },
          product: syncUpProductContext.syncUpAppName
        });
      };
      onAssignConfigs(await fwGroup());
      addSuccessMessage({
        message: `Successfully added config to group`
      });
    } catch (error) {
      addErrorMessage({ error });
    }
  };
  return (
    <>
      <label className={style.label}>Config ID </label>
      <TmoDropDownSelect
        className={style.custom_dropdown}
        optionsList={selectOptions}
        renderIcon={<IoIosArrowDown />}
        defaultSelectedValue={
          firmwareId
            ? selectOptions.filter(e => e.key === firmwareId)[0]
            : selectOptions[0]
        }
        onChange={handleOnClick}
      />
      <div className={style.actions}>
        <TmoButtons
          type="magenta_primary"
          onClick={handleUpdateConfig}
          icon={<ReactSVG src={update} />}
        >
          Update
        </TmoButtons>
        <TmoButtons
          type="magenta_secondary"
          onClick={() => {
            onCancel(false);
          }}
          icon={<ReactSVG src={cancel} />}
        >
          Cancel
        </TmoButtons>
      </div>
    </>
  );
}

export default AssignConfig;

import React from 'react';
import style from './LostModeTlife.module.css';
import LostModeImg from '../../static/assets/commercial/images/loatModeimg.jpg';

function LostModeTlife() {
  const handleInstallClick = () => {
    let userAgent = navigator.userAgent;
    let appStore = 'https://apps.apple.com/us/app/t-life/id1111876388';
    let googlePlay = 'https://play.google.com/store/apps/details?id=com.tmobile.tuesdays';
    if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return window.location.assign(appStore);
    }else{
      return window.location.assign(googlePlay);
    }
  };

    return (
    <div className={style.container}>
          <img className={style.t_img} alt="LostModeImg" src={LostModeImg} width="200px" height="198"/>
        <div className={style.content}>
           <div className={style.t_h1Class}><h2>Get started with the <br></br> SyncUP TRACKER 2 and <br></br> the T-Life app</h2></div>
            <button onClick={handleInstallClick} className={style.t_button}>Get T-Life</button> 
            <div className={style.t_name}>Found a lost Tracker? <br></br> <span className={style.t_sname}>Please bring it to the nearest T-Mobile store <br></br>and we'll reunite it with its owner.</span>            
            </div>
        </div>
    </div>
  );
}

export default LostModeTlife;

import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect
} from 'react';
import { ReactSVG } from 'react-svg';

import {
  withModal,
  TmoInput,
  FilterModal,
  TmoSearch,
  DynamicSort,
  TmoDropDownSelect,
  PaginationV2,
  TmoButtons,
  FormModal,
  TmoSvg
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import iotFirmwareService from '../../../services/iotFirmwareService';
import Fetcher from '../../Fetcher';
import useVisible from '../../../hooks/useVisible';
import RoleFeature from '../../featureWrappers/RoleFeature';
import Group from './Group';
import {
  IOT_ASSET_TYPES,
  FIRMWARE_PAGE_SIZE_OPTIONS
} from '../../../utils/app_constants';
import StatusContext from '../../../context/StatusContext';
import SyncUpProductContext from '../../../context/SyncUpProductContext';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';
import PageHeader from '../../../components/uiHelpers/pageHeader/PageHeader';
import Error from '../../../components/Error/Error';
import { hasValue } from 'utils/helper_functions';

import style from './Groups.module.css';
import paginationStyle from '../../../static/assets/css/pagination.module.css';
import arrowLeft from '../../../static/assets/icons/left_arrow.svg';
import arrowRight from '../../../static/assets/icons/right_arrow.svg';
import {
  BsFilterLeft,
  RiSearchLine,
  IoIosArrowDown,
  FaPlus
} from 'react-icons/all';

const formFieldSpecs = [
  {
    labelText: 'Group Name',
    fieldName: 'group_name',
    formComponent: TmoInput,
    inputType: 'primary',
    required: true,
    placeholder: 'Group Name'
  }
];

function Groups({ openModal }) {
  const [groups, setGroups] = useState();
  const [filteredList, setFilteredList] = useState();
  const [filteredListLength, setFilteredListLength] = useState(0);
  const [firmwarePerPage, setFirmwarePerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const { visible, setVisible } = useVisible(false);
  const mediaType = IOT_ASSET_TYPES.FIRMWARE;
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);

  const [sortOptions] = useState([
    { key: 'groupName', value: 'Group Name' },
    { key: 'id', value: 'Group Id' },
    { key: 'updatedAt', value: 'Updated At' },
    { key: 'updatedBy', value: 'Updated By' }
  ]);
  const [filterOptions] = useState([
    { key: 'firmwareId', value: 'Firmware Id' }
  ]);

  const sortRef = useRef();
  const searchtRef = useRef();
  const filtertRef = useRef();

  const selectOptions = FIRMWARE_PAGE_SIZE_OPTIONS.map(o => ({
    key: o,
    value: `${o} Firmware`
  }));
  selectOptions.push({
    key: 'All',
    value: 'All Firmware'
  });

  const actionCallback = useCallback(() => {
    return iotFirmwareService.getAllIotFirmwareGroups({
      product: syncUpProductContext.syncUpAppName
    });
  }, [syncUpProductContext]);

  const openAddModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Add New ${mediaType} Group`, {
        groupName: formValues ? formValues.group_name : ''
      });
      try {
        await iotFirmwareService.createIotFirmwareGroup({
          body: {
            groupName: formValues.group_name
          },
          product: syncUpProductContext.syncUpAppName
        });
        setGroups(await actionCallback());
        addSuccessMessage({
          message: `Successfully added ${mediaType} group: ${formValues.group_name}`
        });

        setVisible(false);
      } catch (error) {
        setVisible(false);
        addErrorMessage({ error });
      }
    }
  };

  const handleUpdateGroups = async () => {
    setGroups(await actionCallback());
  };

  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const handleOnFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (searchtRef?.current?.ApplyFilters) {
        searchtRef.current.ApplyFilters(data.list);
      }
    }
  };

  const getCurrentPage = () => {
    if (filteredList && filteredList.length) {
      const offset = (currentPage - 1) * firmwarePerPage;
      return filteredList.slice(offset, offset + firmwarePerPage);
    }
    return [];
  };

  const showPagination = filteredList && filteredList?.length > firmwarePerPage;

  const handleSearchFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (filtertRef?.current?.ApplyFilters) {
        filtertRef.current.ApplyFilters(data.list);
      }
    }
  };

  useEffect(() => {
    if (groups && groups.length) {
      setFilteredList(groups);
      setFilteredListLength(groups.length);
    } else {
      setFilteredList([]);
      setFilteredListLength(0);
    }
  }, [groups]);

  return (
    <>
      <PageHeader
        primary
        title={`${mediaType} Groups`}
        subtitle={
          filteredListLength > 1 &&
          `Totally ${filteredListLength} devices available`
        }
      >
        <TmoSearch
          placeholder="Search by Group Name"
          originalList={groups}
          options={['groupName']}
          onFilter={handleSearchFilter}
          ref={searchtRef}
          renderIcon={<RiSearchLine />}
        />
        <DynamicSort
          ref={sortRef}
          listItems={filteredList}
          optionsList={sortOptions}
          onSort={e => {
            handleOnSort(e);
          }}
          renderIcon={<IoIosArrowDown />}
        />
        <FilterModal
          ref={filtertRef}
          options={filterOptions}
          originalList={groups}
          listItems={filteredList}
          onFilter={handleOnFilter}
          renderIcon={<BsFilterLeft />}
        />
        <RoleFeature groups={['SyncUpFirmwareSupport', 'SyncUpAdmins']}>
          <TmoButtons
            type="magenta_primary"
            icon={<FaPlus />}
            onClick={openAddModal}
          >
            Add New {mediaType} Group
          </TmoButtons>
        </RoleFeature>
        <FormModal
          title={`Add new ${mediaType.toLowerCase()} group`}
          fieldSpecs={formFieldSpecs}
          modalType={'slide'}
        />
      </PageHeader>
      <div className={paginationStyle.pagination_wrapper}>
        <div className={paginationStyle.page_size}>
          <TmoDropDownSelect
            optionsList={selectOptions}
            onChange={e => {
              if (e === 'All') {
                setFirmwarePerPage(
                  filteredList && filteredList.length
                    ? filteredList.length + 1
                    : 0
                );
              } else {
                setFirmwarePerPage(Number(e));
              }
              setCurrentPage(1);
            }}
            renderIcon={<IoIosArrowDown />}
            defaultSelectedValue={
              selectOptions.filter(e => e.key === firmwarePerPage)[0]
            }
          />
        </div>
        {showPagination && (
          <div>
            <PaginationV2
              className={paginationStyle.custom_pagination}
              totalPages={Math.ceil(filteredList.length / firmwarePerPage)}
              selectedPageNumber={currentPage}
              onPageChanged={currentPage => setCurrentPage(currentPage)}
              type="primary"
              icons={{
                left: <ReactSVG src={arrowLeft} />,
                right: <ReactSVG src={arrowRight} />
              }}
            />
          </div>
        )}
      </div>
      {visible && <TmoSvg className={style.spinner} svgId="loading-spinner" />}
      <Fetcher
        action={actionCallback}
        onLoad={setGroups}
        render={() =>
          hasValue(groups) ? (
            <div className={style.results}>
              {getCurrentPage && hasValue(filteredList) ? (
                getCurrentPage().map(group => (
                  <Group
                    key={group.id}
                    details={group}
                    fieldSpecs={formFieldSpecs}
                    onRemove={handleUpdateGroups}
                    onAdd={handleUpdateGroups}
                    onAssignConfig={handleUpdateGroups}
                  />
                ))
              ) : (
                <Error
                  heading="No Firmware Groups are available"
                  message="Try with a different search/filter criteria"
                />
              )}
            </div>
          ) : (
            <Error
              heading="No Firmware Groups are available"
              message="Please add a new Firmware Groups"
            />
          )
        }
      />
    </>
  );
}

export default withModal(Groups);

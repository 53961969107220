import { useState, useEffect } from 'react';
import { FETCH_STATE } from '../utils/app_constants';

function useAutomaticFetcher({ action, onLoad, refreshInterval }) {
  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const [error, setError] = useState();

  useEffect(() => {
    let isComponentMounted = true;
    const loadData = async () => {
      try {
        setError();
        setFetchState(FETCH_STATE.LOADING);
        const data = await action();
        if (isComponentMounted) {
          onLoad(data);
          setFetchState(FETCH_STATE.COMPLETE);
        }
      } catch (e) {
        setError(e);
        setFetchState(FETCH_STATE.ERROR);
      }
    };

    if (action) {
      loadData();
    } else {
      setFetchState(FETCH_STATE.IDLE);
    }

    if (refreshInterval) {
      const interval = setInterval(() => action(), refreshInterval);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {
      isComponentMounted = false;
    };
  }, [action, onLoad, refreshInterval]);

  return [fetchState, error];
}

export default useAutomaticFetcher;

import React, { useState, useContext, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { useParams } from 'react-router-dom';
import {
  TmoButtons as TmoButton,
  RawData,
  TmoDropDownSelect,
  TmoH3,
  TmoToggle,
  DynamicList,
  StatusText
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import FilterByDate from '../filters/FilterByDate';
import thingService from '../../services/thingService';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import {
  DATE_IN_TIME_ZONE,
  LOGS_PAGE_SIZE_OPTIONS,
  LOGS_FILTER_OPTIONS,
  DEFAULT_LOGS_FILTER_OPTIONS
} from '../../utils/app_constants';
import { LAST_6_HOURS } from '../../utils/stringUtils';
import { getTimestampList } from '../../utils/dateUtils';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import LogItem from './LogItem';
import {
  hasValue,
  generateQueryString,
  exportCustomData
} from '../../utils/helper_functions';
import Error from '../Error/Error';

import style from './LogsStatus.module.css';
import paginationStyle from '../../static/assets/css/pagination.module.css';
import arrowLeft from '../../static/assets/icons/left_arrow.svg';
import arrowRight from '../../static/assets/icons/right_arrow.svg';
import download from '../../static/assets/icons/download.svg';
import { IoIosArrowDown } from 'react-icons/all';

function LogsStatus() {
  const [currentPage, setCurrentPage] = useState(1);
  const [panelPosition, setPanelPosition] = useState(false);
  const [timeRange, setTimeRange] = useState();
  const [logs, setLogs] = useState();
  const [logsCount, setLogsCount] = useState();
  const [continuationToken, setContinuationToken] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenStack, setTokenStack] = useState([]);
  const [filteredList, setFilteredList] = useState();
  const [logsPerPage, setLogsPerPage] = useState(15);
  const [selectedFilter, setSelectedFilter] = useState(
    DEFAULT_LOGS_FILTER_OPTIONS
  );
  const [listView, setlistView] = useState(true);
  let { deviceId } = useParams();

  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);

  const selectOptions = LOGS_PAGE_SIZE_OPTIONS.map(o => ({
    key: o,
    value: `${o} Logs`
  }));

  const selectFilterOptions = LOGS_FILTER_OPTIONS.map(o => ({
    key: o.toLocaleUpperCase(),
    value: `${o} Logs`
  }));

  selectFilterOptions.push({
    key: 'All',
    value: 'All Logs'
  });

  const exportHeaders = [
    'sessionId',
    'contactId',
    'status',
    'message',
    'detail',
    'timestamp'
  ];

  const headerNameMap = [
    {
      listItemKey: 'sessionId',
      headerDisplayName: 'Session ID',
      render: ({ sessionId }) => <>{sessionId ? sessionId : '-'}</>
    },
    {
      listItemKey: 'contactId',
      headerDisplayName: 'Contact ID',
      render: ({ contactId }) => <>{contactId ? contactId : '-'}</>
    },
    {
      listItemKey: 'status',
      headerDisplayName: 'Status',
      render: ({ status }) => (
        <StatusText status={checkStatus(status)}>{status}</StatusText>
      )
    },
    {
      listItemKey: 'message',
      headerDisplayName: 'Message',
      columnClass: style.body_column,
      render: ({ message }) => <> {message && JSON.stringify(message)} </>
    },
    {
      listItemKey: 'detail',
      headerDisplayName: 'Detail',
      columnClass: style.body_column,
      render: ({ detail }) => <> {detail && JSON.stringify(detail)} </>
    },
    {
      listItemKey: 'timestamp',
      headerDisplayName: 'Timestamp',
      render: ({ timestamp }) => <> {timestamp ? timestamp : '-'} </>
    }
  ];

  const timeRangeToQueryParams = (timeRange, logsPerPage, selectedFilter) => {
    const queryParams = [];
    for (let key in timeRange) {
      queryParams.push({ key: key, value: timeRange[key] });
    }
    logsPerPage &&
      queryParams.push({
        key: 'pageSize',
        value: logsPerPage
      });
    selectedFilter &&
      queryParams.push({
        key: 'communicationType',
        value: selectedFilter
      });
    return queryParams;
  };

  const actionCallback = useCallback(() => {
    trackCustomEvents(`Get Thing Call Status`, {
      deviceId,
      query: generateQueryString(
        timeRangeToQueryParams(timeRange, logsPerPage, selectedFilter)
      ),
      syncUpAppName: product,
      continuationToken: continuationToken
    });
    return thingService.getThingCallStatus({
      deviceId,
      query: generateQueryString(
        timeRangeToQueryParams(timeRange, logsPerPage, selectedFilter)
      ),
      syncUpAppName: product,
      continuationToken: continuationToken
    });
  }, [
    deviceId,
    timeRange,
    continuationToken,
    logsPerPage,
    product,
    selectedFilter
  ]);

  const actionCallbackCount = useCallback(() => {
    trackCustomEvents(`Get Device Call Status Count`, {
      deviceId,
      query: generateQueryString(
        timeRangeToQueryParams(timeRange, null, selectedFilter)
      ),
      syncUpAppName: product
    });
    return thingService.getThingCallStatusCount({
      deviceId,
      query: generateQueryString(
        timeRangeToQueryParams(timeRange, null, selectedFilter)
      ),
      syncUpAppName: product
    });
  }, [deviceId, timeRange, product, selectedFilter]);

  const getCurrentPageLogsList = () => {
    if (filteredList && filteredList.length) {
      const offset = (currentPage - 1) * logsPerPage;
      return filteredList.slice(offset, offset + logsPerPage);
    }
    return [];
  };

  const checkStatus = value => {
    return value === 'ACTIVE' ||
      value === 'ONBOARDED' ||
      value === 'enabled' ||
      value === 'Online'
      ? 'success'
      : 'alert';
  };

  const handleLoadData = useCallback(data => {
    if (data && data.videoCallStatusLogs && data.videoCallStatusLogs.length) {
      setLogs(data.videoCallStatusLogs);
    } else {
      setLogs([]);
    }

    if (data && data.continuationTokenUrlEncoded) {
      setToken(data.continuationTokenUrlEncoded);
    } else {
      setToken(null);
    }
  }, []);

  const handleCountData = useCallback(data => {
    if (data && data.recordsCount) {
      setLogsCount(data.recordsCount);
    } else {
      setLogsCount(0);
    }
  }, []);

  const handleNextClick = () => {
    setTokenStack([...tokenStack, token]);
    setContinuationToken(token);
  };

  const handlePreviousClick = () => {
    let temp = tokenStack;
    let tempToken = temp.pop();
    setTokenStack([...temp]);
    temp && temp.length > 0
      ? setContinuationToken(tempToken)
      : setContinuationToken(null);
  };

  const resetToken = () => {
    setTokenStack([]);
    setToken(null);
    setContinuationToken(null);
  };

  const handleLogsPerPage = logs => {
    resetToken();
    setLogsPerPage(logs);
  };

  const handleFilter = filter => {
    resetToken();
    setSelectedFilter(filter);
  };

  useEffect(() => {
    if (logs && logs.length) {
      setFilteredList(logs);
    } else {
      setFilteredList([]);
    }
  }, [logs]);

  return (
    <>
      <div className={style.subheader}>
        <TmoH3 className={style.subheading}>Status</TmoH3>
        <div className={style.controls}>
          <div className={style.action}>
            <RawData
              className={style.raw_data}
              rawObject={logs}
              title="Full Logs Details"
              tooltip="Full Logs Details"
              type=" "
              modalType={'slide'}
              usePortal={false}
              buttonType="magenta_secondary_icon"
            />
          </div>
          <TmoDropDownSelect
            optionsList={selectFilterOptions}
            onChange={e => {
              if (e === 'All') {
                handleFilter(null);
              } else {
                handleFilter(e);
              }
            }}
            renderIcon={<IoIosArrowDown />}
            defaultSelectedValue={
              selectFilterOptions.filter(e => e.key === selectedFilter)[0]
            }
          />
          <FilterByDate
            onFilter={e => {
              resetToken();
              setTimeRange(e);
            }}
            onDefaultButtonSet={setTimeRange}
            selectedButtonName={LAST_6_HOURS}
            icon={<IoIosArrowDown />}
            type="dropdown"
            className={style.date_filter}
            onSelection={setPanelPosition}
          />
        </div>
      </div>
      <div
        className={classNames(
          style.container,
          panelPosition && style.date_range_panel_position
        )}
      >
        {timeRange && (
          <Fetcher
            action={actionCallbackCount}
            onLoad={handleCountData}
            render={() => (
              <Fetcher
                action={actionCallback}
                onLoad={handleLoadData}
                render={() => (
                  <>
                    <span
                      className={classNames(
                        style.message,
                        hasValue(logs) && style.message_margin
                      )}
                    >
                      <span>{logsCount ? logsCount : 0}</span> log status from{' '}
                      {''}
                      <span>
                        {getTimestampList(
                          timeRange['start-date-time'],
                          null,
                          DATE_IN_TIME_ZONE.SYSTEM
                        )}
                      </span>
                      {''} to {''}
                      <span>
                        {getTimestampList(
                          timeRange['end-date-time'],
                          null,
                          DATE_IN_TIME_ZONE.SYSTEM
                        )}
                      </span>
                    </span>
                    {logs && logs.length > 0 && (
                      <>
                        {hasValue(logs) && (
                          <>
                            <div
                              className={classNames(
                                style.controls_wrapper,
                                style.logs_controls
                              )}
                            >
                              <TmoButton
                                onClick={() => {
                                  exportCustomData({
                                    headers: exportHeaders,
                                    fileName: 'Logs_Status.csv',
                                    hardwareIds: logs
                                  });
                                }}
                                className={style.download_btn}
                                icon={<ReactSVG src={download} />}
                                type="magenta_primary"
                              >
                                Download All as CSV
                              </TmoButton>
                            </div>
                            <div
                              className={classNames(
                                style.controls_wrapper,
                                paginationStyle.pagination_wrapper
                              )}
                            >
                              <div className={style.pagination_buttons}>
                                <TmoDropDownSelect
                                  className={style.custom_dropdown}
                                  optionsList={selectOptions}
                                  onChange={e => {
                                    if (e === 'All') {
                                      handleLogsPerPage(
                                        filteredList && filteredList.length
                                          ? filteredList.length + 1
                                          : 0
                                      );
                                    } else {
                                      handleLogsPerPage(Number(e));
                                    }
                                    setCurrentPage(1);
                                  }}
                                  renderIcon={<IoIosArrowDown />}
                                  defaultSelectedValue={
                                    selectOptions.filter(
                                      e => e.key === logsPerPage
                                    )[0]
                                  }
                                />
                              </div>
                              <div className={style.pagination_buttons}>
                                <TmoButton
                                  onClick={handlePreviousClick}
                                  disabled={!hasValue(tokenStack)}
                                  type="magenta_secondary"
                                  icon={<ReactSVG src={arrowLeft} />}
                                  tooltip={'Previous Page'}
                                />
                                <TmoButton
                                  onClick={handleNextClick}
                                  disabled={!token}
                                  type="magenta_secondary"
                                  icon={<ReactSVG src={arrowRight} />}
                                  tooltip={'Next Page'}
                                />
                              </div>
                              <TmoToggle
                                label={'List View'}
                                isChecked={listView}
                                onChange={() => setlistView(!listView)}
                              />
                            </div>
                          </>
                        )}
                        <div className={style.items}>
                          {getCurrentPageLogsList && hasValue(filteredList) ? (
                            <>
                              {listView ? (
                                <DynamicList
                                  headerNameListItemMap={headerNameMap}
                                  listItems={getCurrentPageLogsList()}
                                  type="primary"
                                  className={style.list_table}
                                  verticalScroll={true}
                                />
                              ) : (
                                getCurrentPageLogsList().map(t => (
                                  <LogItem key={t.id} data={t} isStatus />
                                ))
                              )}
                            </>
                          ) : (
                            <Error
                              heading="No Logs are available"
                              message="Try with a different date range or search criteria"
                            />
                          )}
                        </div>
                      </>
                    )}
                  </>
                )}
              />
            )}
          />
        )}
      </div>
    </>
  );
}

export default LogsStatus;

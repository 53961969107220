import {
  TEMPERATURE_SENSOR_HISTORY_REQUEST,
  TEMPERATURE_SENSOR_HISTORY_SUCCESS,
  TEMPERATURE_SENSOR_HISTORY_ERROR
} from '../utils/app_constants';

const TEMPERATURE_SENSOR_HISTORY_INITIAL_STATE = {};

export const temperatureSensorHistoryReducer = (
  state = TEMPERATURE_SENSOR_HISTORY_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TEMPERATURE_SENSOR_HISTORY_REQUEST:
      return {
        ...state,
        [action.deviceId]: {
          payload: {},
          isLoading: true,
          hasError: false
        }
      };
    case TEMPERATURE_SENSOR_HISTORY_SUCCESS:
      return {
        ...state,
        [action.deviceId]: {
          isLoading: false,
          hasError: false,
          payload: { ...action.payload }
        }
      };
    case TEMPERATURE_SENSOR_HISTORY_ERROR:
      return {
        ...state,
        [action.deviceId]: {
          isLoading: false,
          hasError: true,
          payload: { ...action.payload }
        }
      };
    default:
      return state;
  }
};

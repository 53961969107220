import React, { useState } from 'react';

import './DropDown.css';

function DropDown({ dropDownItems, handleFilterClick }) {
  const [items] = useState(dropDownItems);
  return (
    <select
      className="Select-box TmoSelect"
      onChange={e => handleFilterClick(e)}
    >
      {items.map(item => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
}

export default DropDown;

import React from 'react';
import classNames from 'classnames';

import TmoSvg from './uiHelpers/TmoSvg';
import ErrorWithImage from '../components/Error/Error';
import useAutomaticFetcher from '../hooks/useAutomaticFetcher';
import { FETCH_STATE } from '../utils/app_constants';

import style from './Fetcher.module.css';

function Fetcher({
  action,
  render,
  onLoad,
  refreshInterval,
  spinner = true,
  spinnerSize = 'standard',
  loadingText = false,
  loadingTextLabel
}) {
  const [fetchState, error] = useAutomaticFetcher({
    action,
    onLoad,
    refreshInterval
  });

  switch (fetchState) {
    case FETCH_STATE.LOADING:
      return spinner ? (
        <>
          {!loadingText ? (
            <div className={style.wrapper}>
              <TmoSvg
                className={classNames(style.spinner, style[spinnerSize])}
                svgId="loading-spinner"
              />
            </div>
          ) : (
            <div className={style.items}>
              <label className={style.label}>{loadingTextLabel}</label>
              <span className={style.label_span}> Loading </span>
            </div>
          )}
        </>
      ) : null;
    case FETCH_STATE.ERROR:
      return (
        <ErrorWithImage
          heading="Failed to fetch"
          message={error && error.toString()}
        />
      );

    case FETCH_STATE.COMPLETE:
      return render();
    default:
      return null;
  }
}

export default Fetcher;

import qaRepository from 'repositories/qaRepository';

const qaService = {
  getPipelineData: async () => {
    const result = await qaRepository.getPipelineData({});
    return result.map(obj => ({
      ...obj,
      pipeline_name: obj.pipeline_name === null ? '' : obj.pipeline_name,
      pipeline_failed: obj.failed === 0 ? false : true
    }));
  },

  getBuildFailuresDetails: async buildId => {
    return await qaRepository.getBuildFailuresDetails({ buildId });
  }
};

export default qaService;

import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { FaLongArrowAltLeft } from 'react-icons/all';
import back from '../../../static/assets/icons/chevron_left.svg';

import style from './GoBack.module.css';
import { ReactSVG } from 'react-svg';

export default function GoBack({ padded, className, url, primary, hideTab }) {
  const history = useHistory();

  return (
    <div
      onClick={() => {
        hideTab && hideTab(false);
        url ? history.push(url) : history.goBack();
      }}
      className={classNames(
        style.container,
        className,
        padded && style.padded,
        primary && style.primary
      )}
    >
      {primary ? (
        <ReactSVG src={back} className={style.back_icon} />
      ) : (
        <FaLongArrowAltLeft className={style.icon} />
      )}
      Back
    </div>
  );
}

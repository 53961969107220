import React from 'react';
import { withRouter } from 'react-router-dom';
import { withModal } from '@tmobile/tmo-bps-syncup-web-component-lib';

import ItemDetailList from '../../ItemDetailList/ItemDetailList';

import style from './DriveDevice.module.css';

function DriveDevice({ device }) {
  const {
    id,
    status,
    mcuFirmwareVersion,
    modemFirmwareVersion,
    iccId,
    name,
    decommissionedBy,
    decommissionedAt,
    lastConnectedAt,
    statusAt,
    roadsideStatus,
    roadsideStatusAt,
    simulatorType,
    model,
    claimedBy,
    claimedAt
  } = device || {};

  return (
    <div className={style.container}>
      <ItemDetailList
        items={{
          id,
          status,
          mcuFirmwareVersion,
          modemFirmwareVersion,
          iccId,
          name,
          decommissionedBy,
          decommissionedAt,
          lastConnectedAt,
          statusAt,
          roadsideStatus,
          roadsideStatusAt,
          simulatorType,
          model,
          claimedBy,
          claimedAt
        }}
      />
    </div>
  );
}

export default withModal(withRouter(DriveDevice));

import React from 'react';

import TmoH3 from '../../controlWrappers/TmoH3';
import { FaEllipsisV } from 'react-icons/all';

function TrackerDashboardGridHeader({ title }) {
  return (
    <>
      <TmoH3 className="TrackerDashboardGrid-heading">{title}</TmoH3>
      <FaEllipsisV className="TrackerDashboard-dropdown" />
    </>
  );
}

export default TrackerDashboardGridHeader;

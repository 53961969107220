import React from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

import TmoH2 from '../../../controlWrappers/TmoH2';
import TmoH3 from '../../../controlWrappers/TmoH3';
import TmoButton from '../../../forms/TmoButton';

import './ActivateDevice.css';

function ActivateDevice({ history }) {
  return (
    <div className="ActivateDevice-container">
      <div className="ActivateDevice-back">
        <button
          className="ActivateDevice-back-btn"
          onClick={() => history.goBack()}
        >
          <FaAngleLeft className="ActivateDevice-angle-left" />
          Add devices
        </button>
      </div>
      <div className="ActivateDevice-main">
        <div className="ActivateDevice-header">
          <TmoH2>Success!</TmoH2>
          <TmoH3>Activation is in progress</TmoH3>
        </div>
        <div className="ActivateDevice-body">
          <label className="ActivateDevice-label" for="deviceId"></label>

          <div className="ActivateDevice-add-div">
            <TmoButton
              type="secondary-blue"
              onClick={() => history.push(`/commercial-tracker/devices`)}
            >
              Got IT
            </TmoButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ActivateDevice);

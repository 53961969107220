import {
  NOTIFICATION_USER_REQUEST,
  NOTIFICATION_USER_SUCCESS,
  NOTIFICATION_USER_ERROR,
  NOTIFICATION_USER_INITIAL_STATE
} from '../utils/app_constants';

const NOTIFICATION_INITIAL_STATE = {
  data: [],
  isLoading: false,
  isError: false
};

export const notificationReducer = (
  state = NOTIFICATION_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case NOTIFICATION_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: []
      };
    case NOTIFICATION_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case NOTIFICATION_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload
      };
    case NOTIFICATION_USER_INITIAL_STATE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: []
      };
    default:
      return state;
  }
};

import moment from 'moment';
import analyticsRepository from '../repositories/analyticsRepository';
import { generateQueryString } from '../utils/helper_functions';
import { getTimestampList, hoursAgo } from '../utils/dateUtils';
import { DATE_IN_TIME_ZONE } from '../utils/app_constants';

const analyticsService = {
  getAppCenterStatus: async () => {
    return await analyticsRepository.getAppCenterStatus();
  },
  getAppCenterIncidents: async () => {
    let result = await analyticsRepository.getAppCenterIncidents();
    let formatTimeStamps = result.incidents.map(i => {
      return {
        ...i,
        created_at: getTimestampList(
          i['created_at'],
          null,
          DATE_IN_TIME_ZONE.SYSTEM
        ),
        resolved_at: getTimestampList(
          i['resolved_at'],
          null,
          DATE_IN_TIME_ZONE.SYSTEM
        )
      };
    });
    return { ...{ page: result.page }, ...{ incidents: formatTimeStamps } };
  },
  getPlatformLogSearch: async ({ customQuery = '' }) => {
    const queryParams = [
      {
        key: 'query',
        value: customQuery
      }
    ];
    const queryString = generateQueryString(queryParams);
    return await analyticsRepository.getPlatformLogSearch(queryString);
  },
  getApptentiveReviews: async ({ appPlatform, startDate, endDate }) => {
    let reviewData = await analyticsRepository.getApptentiveReviews(
      appPlatform,
      startDate,
      endDate
    );
    reviewData.reviews = reviewData.reviews.map(i => ({
      ...i,
      date: getTimestampList(i.date, null, DATE_IN_TIME_ZONE.SYSTEM)
    }));
    return reviewData;
  },
  getApptentiveRatings: async ({ appPlatform, startDate }) => {
    return await analyticsRepository.getApptentiveRatings({
      appPlatform,
      startDate
    });
  },
  getDeviceMap: async startTime => {
    const deviceData = await analyticsRepository.getDeviceMap(startTime);
    let devices = [];
    if (deviceData) {
      let arr = deviceData.split(/[,\n]+/);
      let obj = {};
      arr.map((item, index) => {
        switch (index % 4) {
          case 0:
            obj = { ...obj, lat: parseFloat(item) };
            break;
          case 1:
            obj = { ...obj, lng: parseFloat(item) };
            break;
          case 2:
            obj = { ...obj, hardwareId: item };
            break;
          default:
            obj = { ...obj, updateDate: parseInt(item) };
            if (moment(obj.updateDate).isSameOrAfter(hoursAgo(1))) {
              return devices.push({ ...obj, marker: 'green' });
            } else if (
              moment(obj.updateDate).isBefore(hoursAgo(1)) &&
              moment(obj.updateDate).isSameOrAfter(hoursAgo(6))
            ) {
              return devices.push({ ...obj, marker: 'grey' });
            } else if (moment(obj.updateDate).isSameOrBefore(hoursAgo(6))) {
              return devices.push({ ...obj, marker: 'red' });
            }
            devices.push({ obj });
            break;
        }
        return arr;
      });
    }
    return devices;
  }
};

export default analyticsService;

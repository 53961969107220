import React from 'react';

import TmoCheckbox from '../../forms/TmoCheckbox';

import style from './DeviceTypesFilter.module.css';

function DeviceTypesFilter() {
  const dataSet = [
    { id: 121, value: 'Trackers', selection: false },
    { id: 70, value: 'Lighting Control', selection: true },
    { id: 119, value: 'Access Points', selection: true },
    { id: 96, value: 'Door Locks', selection: true },
    { id: 1, value: 'Smoke Sensors', selection: false, disabled: true },
    { id: 2, value: 'Water Sensors', selection: false, disabled: true },
    { id: 3, value: 'Cameras', selection: false, disabled: true }
  ];

  const onCheckboxClicked = e => {};

  const listItems = dataSet.map((data, index) => (
    <div key={index} className={style.row}>
      <TmoCheckbox
        isChecked={data.selection}
        id={data.id}
        value={data.value}
        onChange={onCheckboxClicked}
        disabled={data?.disabled}
      />
    </div>
  ));

  return <div className={style.container}>{listItems}</div>;
}

export default DeviceTypesFilter;

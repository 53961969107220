import React from 'react';
import classNames from 'classnames';
import { TmoCard, RawData, withModal } from '@tmobile/tmo-bps-syncup-web-component-lib';

import { prettifyCamelCase } from 'utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import { capitalize } from '../../utils/stringUtils';
import { GRID, DAYS_OF_WEEK } from 'utils/app_constants';
import DeleteSchoolMode from './DeleteSchoolMode';

import gridStyle from '../ItemDetailList/ItemDetailList.module.css';
import style from './ScheduledTask.module.css';

function ScheduledTask({ schoolMode, openModal, forceRefresh }) {
  const {
    id,
    displayName,
    attributes,
    createdDateTime,
    lastExecuted,
    scheduleType,
    schedule,
    active
  } = schoolMode;

  return (
    <TmoCard shadow={false} key={id}>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <label className={style.display_name}>
            {prettifyCamelCase(displayName.toLowerCase())}
          </label>
          <span className={style.product}>
            {prettifyCamelCase(attributes.product.toLowerCase())}
          </span>
        </div>
        <div className={style.action_controls}>
          <RawData
            rawObject={schoolMode}
            tooltip="School Mode Object"
            title={`Full Object for ${displayName}`}
            className={style.object}
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_primary_icon"
            type=" "
          />
          <DeleteSchoolMode
            schoolMode={schoolMode}
            forceRefresh={forceRefresh}
          />
        </div>
      </div>
      <div className={style.content} key={id}>
        <ItemDetailList
          rowSet={[
            { 'Primary AccountHolder Id': attributes.thingId },
            { 'Thing Id': attributes.thingId },
            { scheduleType, 'Start Time': schedule.localStartTime }
          ]}
        />
        {scheduleType === 'WeekdayDuration' && (
          <div className={classNames(gridStyle.row, gridStyle.column_2)}>
            <label className={gridStyle.label}>Weekdays</label>
            <span className={gridStyle.value}>
              {DAYS_OF_WEEK.map((day, index) => (
                <>
                  {day != null && (
                    <>
                      <span
                        className={classNames(
                          style.days,
                          schedule.weekdays.includes(day.toLocaleLowerCase()) &&
                            style.active_days
                        )}
                      >
                        {capitalize(day.charAt(0))}
                      </span>
                      {index < DAYS_OF_WEEK.length - 1 && '-'}
                    </>
                  )}
                </>
              ))}
            </span>
          </div>
        )}
        <ItemDetailList
          className={
            scheduleType === 'WeekdayDuration'
              ? GRID.COLUMN_HALF
              : GRID.COLUMN_FULL
          }
          items={{ duration: schedule.durationInMinutes }}
        />
        <ItemDetailList
          rowSet={[
            {
              ...(lastExecuted && { lastExecuted }),
              ...(createdDateTime && { createdDateTime })
            },
            {
              TimeZone: schedule.olsenTimeZone,
              Status: active ? 'ACTIVE' : 'Paused'
            }
          ]}
        />
      </div>
    </TmoCard>
  );
}

export default withModal(ScheduledTask);

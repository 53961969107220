import React from 'react';
import { FormModal } from '@tmobile/tmo-bps-syncup-web-component-lib';

function AddThing({ fieldSpecs, thingType }) {
  return (
    <FormModal
      submitText={`Add ${thingType}`}
      title={`Add ${thingType}`}
      fieldSpecs={fieldSpecs}
      modalType={'slide'}
    />
  );
}

export default AddThing;

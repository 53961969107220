import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import TmoCheckbox from '../../components/forms/TmoCheckbox';
import {
  appInsights,
  trackCustomEvents
} from '../../utils/appInsight_analytics';
import { FaChevronDown, FaChevronUp } from 'react-icons/all';

import style from './Accordion.module.css';

import arrow_up from '../../static/assets/icons/arrow_up_icon.svg';
import arrow_down from '../../static/assets/icons/arrow_down_icon.svg';

function Accordion({
  title,
  className,
  children,
  titleClassName,
  contentClassName,
  customIcon,
  ariaLabel,
  ariaRole,
  insights,
  tabIndex,
  data,
  onSelection,
  showCheckBox = false,
  userId = undefined,
  isHelp = false,
  isAdmin = false
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0');
  const content = useRef(null);
  const accordionContent = useRef(null);

  const attributes = {};

  function toggleAccordion() {
    insights &&
      !isOpen &&
      appInsights &&
      appInsights.trackEvent({
        name: 'Accordion Expand Event',
        properties: { insights, userId }
      });
    isHelp &&
      !isOpen &&
      trackCustomEvents(`Portal Help Accordion Expand`, {
        title: isHelp.title
      });
    setIsOpen(!isOpen);
  }
  if (tabIndex) {
    attributes['tabIndex'] = tabIndex;
    attributes['onKeyUp'] = e => {
      if (e.key === 'Enter') {
        toggleAccordion();
      }
    };
  }
  useEffect(() => {
    setHeight(!isOpen ? '0' : `${content.current.scrollHeight}px`);
    isOpen &&
      setTimeout(
        () =>
          accordionContent.current.scrollIntoView({
            behaviour: 'smooth',
            block: 'nearest'
          }),
        200
      );
  }, [isOpen, content, accordionContent]);

  return (
    <div
      className={classNames(style.container, className)}
      ref={accordionContent}
    >
      <div
        className={classNames(
          style.column,
          titleClassName,
          isOpen && style.open,
          (isHelp || isAdmin) && isOpen && style.open_accordion
        )}
        onClick={toggleAccordion}
        aria-label={
          isOpen
            ? `Select title : ${ariaLabel} to collapse.`
            : `Select title : ${ariaLabel} to expand.`
        }
        role={ariaRole}
        {...attributes}
      >
        <span className={style.title}>{title}</span>
        {customIcon ? (
          showCheckBox ? (
            <div className={style.checkbox_wrapper}>
              <TmoCheckbox
                onChange={onSelection}
                id={data.id}
                isChecked={data.selected}
                onClick
                hideLabel
              />
            </div>
          ) : (
            <ReactSVG
              src={isOpen ? arrow_up : arrow_down}
              aria-label={
                isOpen
                  ? 'select arrow button to collapse'
                  : 'select arrow button to expand'
              }
              role="button"
            />
          )
        ) : (
          <>
            {isOpen ? (
              <FaChevronUp className={style.icon} />
            ) : (
              <FaChevronDown className={style.icon} />
            )}
          </>
        )}
      </div>
      {isOpen && (
        <div
          ref={content}
          style={{ maxHeight: `${height}` }}
          className={classNames(style.content, contentClassName)}
        >
          <div className={classNames(style.text, isAdmin && style.faq_wrapper)}>
            {React.cloneElement(children, { isOpen: isOpen })}
          </div>
        </div>
      )}
    </div>
  );
}

export default Accordion;

import React, { useState, Fragment } from 'react';
import { ReactSVG } from 'react-svg';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Circle,
  InfoWindow
} from 'react-google-maps';
import { TmoButtons as TmoButton } from '@tmobile/tmo-bps-syncup-web-component-lib';

import { mapStyle } from '../../utils/app_constants';
import { getCircleCenter, getMarkerCenter } from '../../utils/mapUtils';
import SafeZoneInfoWindow from './SafeZoneInfoWindow';

import style from './SafeZone.module.css';
import button_marker from '../../static/assets/marker_magenta.svg';
import { GiPositionMarker } from 'react-icons/all';

function SafeZone({ center, zoom, places }) {
  const [safeZoneClicked, setSafeZoneClicked] = useState();

  const circleClicked = (event, place) => {
    setSafeZoneClicked(place);
  };

  return (
    <div className={style.container}>
      <div className={style.buttons}>
        {places.map((place, index) => {
          return (
            <TmoButton
              key={index}
              onClick={event => circleClicked(event, place)}
              className={style.button}
              type="magenta_primary"
              icon={<ReactSVG src={button_marker} />}
              textVisible
            >
              {place.info.keyValuePairs.name}
            </TmoButton>
          );
        })}
      </div>
      <GoogleMap
        defaultZoom={zoom}
        defaultCenter={center}
        mapStyle
        options={{ styles: mapStyle }}
      >
        {places.map((place, index) => {
          return (
            <Fragment key={place.id}>
              {place.marker && <Marker position={getMarkerCenter(place)} />}
              {place.circle && place.circle.radius && (
                <>
                  <Marker
                    position={getCircleCenter(place)}
                    onClick={event => circleClicked(event, place)}
                    icon={<GiPositionMarker />}
                    title="Safe Zone"
                  />
                  <Circle
                    defaultCenter={getCircleCenter(place)}
                    radius={place.circle.radius}
                    options={place.circle.options}
                    onClick={event => circleClicked(event, place)}
                  />
                </>
              )}

              {safeZoneClicked && (
                <InfoWindow
                  anchor={safeZoneClicked.id}
                  onCloseClick={() => setSafeZoneClicked(false)}
                  position={{
                    lat: safeZoneClicked.circle.center.latitude,
                    lng: safeZoneClicked.circle.center.longitude
                  }}
                >
                  <SafeZoneInfoWindow safeZoneClicked={safeZoneClicked} />
                </InfoWindow>
              )}
            </Fragment>
          );
        })}
      </GoogleMap>
    </div>
  );
}

export default withScriptjs(withGoogleMap(SafeZone));

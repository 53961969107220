import React, { useMemo } from 'react';
import classNames from 'classnames';
import PaginationPage from './PaginationPage';

import style from './PaginationV2.module.css';

function PaginationV2({
  className,
  selectedPageNumber,
  totalPages,
  onPageChanged,
  maxPagesShown = 5
}) {
  const minPageNumber = useMemo(() => {
    const idealNeighborCount = Math.floor(maxPagesShown / 2);
    if (selectedPageNumber > idealNeighborCount + 1) {
      if (selectedPageNumber + idealNeighborCount <= totalPages) {
        return selectedPageNumber - idealNeighborCount;
      } else {
        if (totalPages >= maxPagesShown) {
          return totalPages - maxPagesShown + 1;
        }
      }
    }
    return 1;
  }, [selectedPageNumber, maxPagesShown, totalPages]);

  const pageNumbers = useMemo(
    () =>
      [...Array(Math.min(maxPagesShown, totalPages)).keys()].map(
        n => n + minPageNumber
      ),
    [totalPages, maxPagesShown, minPageNumber]
  );

  const isFirstPageShown = useMemo(() => pageNumbers[0] !== 1, [pageNumbers]);

  const isLastPageShown = useMemo(
    () => pageNumbers[pageNumbers.length - 1] !== totalPages,
    [pageNumbers, totalPages]
  );

  const leftJumpPageNumber = useMemo(
    () => pageNumbers[0] > 2 && Math.max(1, selectedPageNumber - maxPagesShown),
    [pageNumbers, selectedPageNumber, maxPagesShown]
  );

  const rightJumpPageNumber = useMemo(
    () =>
      pageNumbers[pageNumbers.length - 1] < totalPages - 1 &&
      Math.min(totalPages, selectedPageNumber + maxPagesShown),
    [pageNumbers, selectedPageNumber, maxPagesShown, totalPages]
  );

  return (
    <div className={classNames(style.container, className)}>
      <ul className={style.list}>
        {isFirstPageShown && (
          <PaginationPage pageNumber={1} onPageChanged={onPageChanged} />
        )}
        {leftJumpPageNumber && (
          <PaginationPage
            pageNumber={leftJumpPageNumber}
            onPageChanged={onPageChanged}
            text="&laquo;"
          />
        )}
        {pageNumbers.map(pageNumber => (
          <PaginationPage
            key={pageNumber}
            pageNumber={pageNumber}
            onPageChanged={onPageChanged}
            isSelected={pageNumber === selectedPageNumber}
          />
        ))}
        {rightJumpPageNumber && (
          <PaginationPage
            pageNumber={rightJumpPageNumber}
            onPageChanged={onPageChanged}
            text="&raquo;"
          />
        )}
        {isLastPageShown && (
          <PaginationPage
            pageNumber={totalPages}
            onPageChanged={onPageChanged}
          />
        )}
      </ul>
    </div>
  );
}

export default PaginationV2;

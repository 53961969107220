import {
  DEVICES_USER_REQUEST,
  DEVICES_USER_SUCCESS,
  DEVICES_USER_ERROR,
  DEVICES_USER_INITIAL_STATE
} from '../utils/app_constants';

const DEVICES_INITIAL_STATE = {
  data: [],
  isLoading: false,
  isError: false
};

export const devicesReducer = (state = DEVICES_INITIAL_STATE, action) => {
  switch (action.type) {
    case DEVICES_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: []
      };
    case DEVICES_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case DEVICES_USER_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload
      };
    case DEVICES_USER_INITIAL_STATE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: []
      };
    default:
      return state;
  }
};

import React from 'react';
import { TmoH2, TmoCard, withModal } from '@tmobile/tmo-bps-syncup-web-component-lib';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './SimulatorCard.module.css';

function DriveNewSimulator({ simulator }) {
  const {
    name,
    wifiSsid,
    model,
    manufacturer,
    hardwareId,
    mcuFirmwareVersion,
    modemFirmwareVersion,
    simulatorType,
    status,
    statusAt,
    roadsideStatus,
    roadsideStatusAt
  } = simulator;

  return (
    <TmoCard className={style.container}>
      <TmoH2 className={style.heading}>{hardwareId}</TmoH2>
      <div className={style.content}>
        <ItemDetailList
          rowSet={[
            { name, wifiSsid },
            { model, manufacturer },
            { simulatorType, mcuFirmwareVersion },
            { modemFirmwareVersion, status },
            { statusAt, roadsideStatus },
            { roadsideStatusAt }
          ]}
        />
      </div>
    </TmoCard>
  );
}

export default withModal(DriveNewSimulator);

import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import LeftPanelNavigation from './LeftPanelNavigation';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import ThemeContext from '../../context/ThemeContext';
import { SUA_PAGES, THEMES } from '../../utils/app_constants';
import ThingsLogo from './ThingsLogo';
import SearchPanel from './userSearch/SearchPanel';

import style from './LeftPanel.module.css';
import hamburgerMenu from '../../static/assets/icons/leftpanel/hamburger_menu.svg';

function LeftPanel({ onToggled, isExpanded, isOpen }) {
  const { isAuthenticated } = useContext(LoggedInUserContext);
  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const isSearchPage = location.pathname.includes(SUA_PAGES.SEARCH.path);
  return (
    isAuthenticated &&
    theme === THEMES.THEME_DEFAULT && (
      <div
        className={classNames(
          isExpanded || isSearchPage
            ? style.container
            : style.container && style.collapsed,
          isSearchPage ? style.search_container : null,
          isOpen ? style.mobile_menu : null
        )}
        id={style['scrollable']}
      >
        {isSearchPage ? (
          <SearchPanel />
        ) : (
          <>
            <div className={style.logo_container}>
              {isExpanded && <ThingsLogo />}
              <ReactSVG
                src={hamburgerMenu}
                className={style.hamburger}
                onClick={() => onToggled()}
              />
            </div>
            <LeftPanelNavigation showMenuItems={isExpanded} />
          </>
        )}
      </div>
    )
  );
}

export default LeftPanel;

import platformRepository from '../repositories/platformRepository';

const platformService = {
  getOnCallSupportGroup: async () => {
    return await platformRepository.getOnCallSupportGroup();
  },
  getSupportGroupsList: async () => {
    return await platformRepository.getSupportGroupsList();
  }
};

export default platformService;

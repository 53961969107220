import React from 'react';
import Poc from '../Poc';
import Tracker2Controls from './Tracker2Controls';
import { SUA_PAGES } from '../../../utils/app_constants';
import Authorized from '../../../auth/Authorized';

import './Tracker2.module.css';

function Tracker2({ className }) {
  return (
    <Authorized
      roles={[...SUA_PAGES.TEST_HARNESS.subPages.TRACKER.authorizedRoles]}
      willShowErrorModal
    >
      <Poc productId="TRACKER" controls={Tracker2Controls} />
    </Authorized>
  );
}

export default Tracker2;

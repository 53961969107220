import React, { useState, useContext } from 'react';
import {
  ConfirmationModal,
  TmoCard,
  DynamicList
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import useManualFetcher from '../../hooks/useManualFetcher';
import deviceService from '../../services/deviceService';
import FileUpload from '../fileUpload/FileUpload';
import { arrayBufferToString, pluralize } from '../../utils/stringUtils';
import StatusContext from '../../context/StatusContext';
import { FETCH_STATE } from '../../utils/app_constants';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import SyncUpProductContext from 'context/SyncUpProductContext';

import style from './Recommission.module.css';
import sort_icon from '../../static/assets/icons/sort_icon.svg';

const headerNameListItemMap = [
  {
    listItemKey: 'hardwareId',
    headerDisplayName: 'IMEI',
    sortable: true
  },
  {
    listItemKey: 'message',
    headerDisplayName: 'Message',
    sortable: true
  }
];

const Recommission = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hardwareIds, setHardwareIds] = useState();
  const [listView, setListView] = useState(true);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const [fetchState, actionTrigger, error, resetFetchState] = useManualFetcher({
    action: () => {
      trackCustomEvents(`Recommission Devices`, {
        hardwareIds: hardwareIds.map(i => i.hardwareId)
      });
      return deviceService.bulkRecommissionrDevice({
        hardwareIds: {
          requestIothubDevicesList: hardwareIds.map(i => i.hardwareId)
        },
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
    },
    onLoad: data => {
      addSuccessMessage({
        message: 'Bulk Recommission completed successfully'
      });
      setHardwareIds(data);
    },
    onError: error => {
      addErrorMessage({ error });
    },
    onComplete: () => {
      closeModal();
    }
  });

  const showUnclaimModal = () => hardwareIds && setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const parseInputFile = file => {
    const reader = new FileReader();
    reader.onabort = () =>
      addErrorMessage({ details: 'file reading was aborted' });
    reader.onerror = () => addErrorMessage({ details: 'file reading failed' });
    reader.onload = () => {
      const binaryStr = reader.result;
      const csv = arrayBufferToString(binaryStr);
      const hardwareIds = csv
        .split('\n')
        .map(unparsedHardwareId => {
          const hardwareId = unparsedHardwareId.trim().replace(/\D/g, '');
          if (hardwareId) {
            return {
              hardwareId,
              status: 'Staged',
              message: 'Click recommission to start the process'
            };
          }
          return null;
        })
        .filter(Boolean);
      setHardwareIds(hardwareIds);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleReset = () => {
    resetFetchState();
    setHardwareIds();
  };

  return (
    <>
      <FileUpload
        title="Please load the IMEIs in csv file (separated in rows)"
        inputPlaceholderText=" Upload a CSV file by clicking here or dragging and dropping it"
        inputSubtitleText="Only CSV, max.50 MB "
        onReset={handleReset}
        onUpload={parseInputFile}
        actionBtnClick={showUnclaimModal}
        actionBtnName="Recommission All"
        className={style.file_upload}
        fetchState={fetchState}
        hardwareIds={hardwareIds}
        onToggleChange={setListView}
      />
      {hardwareIds &&
        (listView ? (
          <DynamicList
            headerNameListItemMap={headerNameListItemMap}
            listItems={hardwareIds}
            type="primary"
            sortIcon={sort_icon}
          />
        ) : (
          <div className={style.display}>
            {hardwareIds.map((item, index) => (
              <TmoCard className={style.item} key={index}>
                <div className={style.imei_id}>{item.hardwareId}</div>
                <div>{item.message}</div>
              </TmoCard>
            ))}
          </div>
        ))}
      <ConfirmationModal
        isOpen={isModalOpen}
        onAcceptBtnClicked={actionTrigger}
        onDenyBtnClicked={closeModal}
        buttonsDisabled={fetchState === FETCH_STATE.LOADING}
        title={`Are you sure you want to recommission these devices?`}
        message={`You are about to recommission ${
          hardwareIds?.length
        } ${pluralize({
          string: 'device',
          count: hardwareIds?.length
        })}. This operation is not reversible.`}
        acceptBtnTitle="Recommission"
        denyBtnTitle="Cancel"
        modalType="primary"
      />
      {error && `There was an error ${error}`}
    </>
  );
};

export default Recommission;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { FaAngleLeft } from 'react-icons/fa';

import AddSingleDevice from './AddSingleDevice';
import AddBulkDevice from './AddBulkDevice';

import './AddDevice.css';

function AddDevice({ match, history }) {
  return (
    <div className="AddDevice-container">
      <div className="AddDevice-header">
        <button className="AddDevice-back-btn" onClick={() => history.goBack()}>
          <FaAngleLeft className="AddDevice-angle-left" /> Devices
        </button>
      </div>
      <div className="AddDevice-main">
        <AddSingleDevice />
        <AddBulkDevice />
      </div>
    </div>
  );
}

export default withRouter(AddDevice);

import Map_Cluster_Marker from '../static/assets/commercial/images/icon-map-cluster-marker.svg';

export const averageGeolocation = coords => {
  if (coords.length === 1) {
    return coords[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (let coord of coords) {
    let latitude = ((coord.latitude || coord.lat) * Math.PI) / 180;
    let longitude = ((coord.longitude || coord.lng) * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  let total = coords.length;

  x = x / total;
  y = y / total;
  z = z / total;

  let centralLongitude = Math.atan2(y, x);
  let centralSquareRoot = Math.sqrt(x * x + y * y);
  let centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: (centralLatitude * 180) / Math.PI,
    lng: (centralLongitude * 180) / Math.PI
  };
};

export const getBoundsZoomLevel = (bounds, mapDim) => {
  let WORLD_DIM = { height: 256, width: 256 };
  let ZOOM_MAX = 21;

  function latRad(lat) {
    let sin = Math.sin((lat * Math.PI) / 180);
    let radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  let ne = bounds.getNorthEast();
  let sw = bounds.getSouthWest();

  let latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  let lngDiff = ne.lng() - sw.lng();
  let lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  let latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  let lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
};

export const getCircleCenter = place => {
  if (place.circle.center != null) {
    return getLatLng(place.circle.center);
  }
};

export const getMarkerCenter = place => {
  return getLatLng(place);
};

const getLatLng = cords => {
  return {
    lat: parseFloat(cords.latitude || cords.lat),
    lng: parseFloat(cords.longitude || cords.lng)
  };
};

export const getMapCenter = places =>
  averageGeolocation(
    places.map(p => ({
      lat: p.lat || p.geofence?.point?.latitude || p.latitude,
      lng: p.lng || p.geofence?.point?.longitude || p.longitude
    }))
  );

export const COMMERCIAL_MAP_CLUSTER_STYLE = [
  {
    textColor: '#ffffff',
    textSize: 15,
    height: 32,
    width: 32,
    url: Map_Cluster_Marker
  }
];

export const geoCenterOfUsa = { lat: 37.0902, lng: -95.7129 };

import React, { useContext, useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';

import Error from './Error';
import Dashboard from './analytics/Dashboard';
import BulkUnclaim from './bulk/BulkUnclaim';
import BulkRecommission from './bulk/BulkRecommission';
import BulkDeviceExistence from './bulk/BulkDeviceExistence';
import SimulatorPage from './SimulatorPage';
import BulkNotificationPage from './bulk/BulkNotificationPage';
import BreedsPage from './breeds/BreedsPage';
import ObservedUsersPage from './users/ObservedUsersPage';
import ProductMetrics from './leftNavigation/ProductMetrics';
import ErrorMetrics from './leftNavigation/ErrorMetrics';
import TestDashboard from './leftNavigation/TestDashboard';
import TicketingDashboard from './leftNavigation/TicketingDashboard';
import PierTickets from './leftNavigation/PierTickets';
import QueryTool from './leftNavigation/QueryTool';
import AuthUsersPage from './users/AuthUsers';
import RecentDevicesPage from './RecentDevicesPage';
import SoftwareVersions from './glossary/SoftwareVersions';
import ErrorCodes from './glossary/ErrorCodes';
import BatteryStatusCodes from './glossary/BatteryStatusCodes';
import CommercialTracker from './poc/CommercialTracker';
import CommercialTrackerUserManagement from './poc/CommercialTrackerUserManagement';
import LoggedInUserContext from '../context/LoggedInUserContext';
import SyncUpProductContext from '../context/SyncUpProductContext';
import { SUA_PAGES, ERROR_SCREEN_MESSAGE } from '../utils/app_constants';
import FirmwarePage from './FirmwarePage';
import MediaPage from './MediaPage';
import AppVersionsPage from './leftNavigation/AppVersionsPage';
import Kidswatch from '../components/poc/kidswatch/Kidswatch';
import Tracker2 from '../components/poc/tracker2/Tracker2';
import GenericPoc from '../components/poc/GenericPoc';
import { PrivateRoute } from '../auth/PrivateRoute';
import PlatformSupport from './leftNavigation/PlatformSupport';
import FAQPage from './FAQPage';
import APIStatusPage from './APIStatus/APIStatusPage';
import DPISupport from './DPISupport/DPISupport';
import CoapSupportPage from '../pages/coap/CoapSupportPage';
import UserSearchPage from './UserSearchPage';
//TO-DO Uncomment For Home Screen
// import HomePage from './HomePage';
import { appInsights } from '../utils/appInsight_analytics';
import usePrevious from '../hooks/usePrevious';
import QADashboardPage from './QADashboard/QADashboardPage';
import IntakeFormPage from './intakeForms/IntakeFormPage';
import OperationsPage from './operations/OperationsPage';

function MainRoutes() {
  const { isAuthenticated, homePageUrl, userInfo } = useContext(
    LoggedInUserContext
  );
  const { syncUpAppName } = useContext(SyncUpProductContext);
  const location = useLocation();
  const prevLocation = usePrevious(location.pathname + location.search);
  useEffect(() => {
    const currentPath = location.pathname + location.search;
    if (currentPath !== prevLocation) {
      userInfo &&
        appInsights &&
        appInsights.trackPageView({
          name: `Admin Portal Page Tracking`,
          uri: window.location.href,
          properties: {
            selectedProduct: syncUpAppName,
            path: currentPath,
            userDetails: {
              groups: userInfo.Groups,
              thingsPlatformGroups: userInfo.ThingsPlatformGroups
            }
          }
        });
    }
  }, [location, userInfo, prevLocation, syncUpAppName]);

  return (
    isAuthenticated && (
      <>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              return <Redirect to={homePageUrl} />;
            }}
          />
          <Route
            exact
            path="/dashboard"
            render={() => (
              <Redirect
                to={`${SUA_PAGES.ANALYTICS.subPages.DASHBOARD.path}${SUA_PAGES.ANALYTICS.subPages.DASHBOARD.defaultPage}`}
              />
            )}
          />
          <Route
            exact
            path="/commercial-tracker"
            render={() => (
              <Redirect
                to={`${SUA_PAGES.COMMERCIAL_TRACKER.path}${SUA_PAGES.COMMERCIAL_TRACKER.defaultPage}`}
              />
            )}
          />
          <Route
            exact
            path={SUA_PAGES.COMMERCIAL_TRACKER_USER_MANAGEMENT.path}
            render={() => (
              <Redirect
                to={`${SUA_PAGES.COMMERCIAL_TRACKER_USER_MANAGEMENT.path}${SUA_PAGES.COMMERCIAL_TRACKER_USER_MANAGEMENT.defaultPage}`}
              />
            )}
          />
          <Route
            exact
            path="/bulk-notifications"
            render={() => (
              <Redirect
                to={`${SUA_PAGES.BULK_OPERATIONS.subPages.BULK_NOTIFICATION_AUTOCOMPLETE.path}${SUA_PAGES.BULK_OPERATIONS.subPages.BULK_NOTIFICATION_AUTOCOMPLETE.defaultPage}`}
              />
            )}
          />
          <PrivateRoute
            path={
              SUA_PAGES.BULK_OPERATIONS.subPages.BULK_NOTIFICATION_AUTOCOMPLETE
                .path
            }
            roles={
              SUA_PAGES.BULK_OPERATIONS.subPages.BULK_NOTIFICATION_AUTOCOMPLETE
                .authorizedRoles
            }
            component={BulkNotificationPage}
          />
          <PrivateRoute
            path={SUA_PAGES.FIRMWARE.path}
            roles={SUA_PAGES.FIRMWARE.authorizedRoles}
            component={FirmwarePage}
          />
          <PrivateRoute
            path={SUA_PAGES.MEDIA.path}
            roles={SUA_PAGES.MEDIA.authorizedRoles}
            component={MediaPage}
          />
          <PrivateRoute
            path={SUA_PAGES.SEARCH.path}
            roles={SUA_PAGES.SEARCH.authorizedRoles}
            component={UserSearchPage}
          />
          {/* TO-DO Uncomment For Home Screen */}
          {/* <PrivateRoute
            path={SUA_PAGES.HOME.path}
            roles={SUA_PAGES.HOME.authorizedRoles}
            component={HomePage}
          /> */}
          {/* { <Route path={SUA_PAGES.USER.path} component={UserPage} /> } */}
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.BUSINESS_METRICS.path}
            roles={
              SUA_PAGES.ANALYTICS.subPages.BUSINESS_METRICS.authorizedRoles
            }
            component={ProductMetrics}
          />
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.ERROR_METRICS.path}
            roles={SUA_PAGES.ANALYTICS.subPages.ERROR_METRICS.authorizedRoles}
            component={ErrorMetrics}
          />
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.TEST_DASHBOARD.path}
            roles={SUA_PAGES.ANALYTICS.subPages.TEST_DASHBOARD.authorizedRoles}
            component={TestDashboard}
          />
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.DASHBOARD.path}
            roles={SUA_PAGES.ANALYTICS.subPages.DASHBOARD.authorizedRoles}
            component={Dashboard}
          />
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.PIER_TICKETING.path}
            roles={SUA_PAGES.ANALYTICS.subPages.PIER_TICKETING.authorizedRoles}
            component={PierTickets}
          />
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.QUERY_TOOL.path}
            roles={SUA_PAGES.ANALYTICS.subPages.QUERY_TOOL.authorizedRoles}
            component={QueryTool}
          />
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.TICKETING_METRICS.path}
            roles={
              SUA_PAGES.ANALYTICS.subPages.TICKETING_METRICS.authorizedRoles
            }
            component={TicketingDashboard}
          />
          <PrivateRoute
            path={SUA_PAGES.PLATFORM_SUPPORT.path}
            roles={SUA_PAGES.PLATFORM_SUPPORT.authorizedRoles}
            component={PlatformSupport}
          />
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.OBSERVED_USERS.path}
            roles={SUA_PAGES.ANALYTICS.subPages.OBSERVED_USERS.authorizedRoles}
            component={ObservedUsersPage}
          />
          <PrivateRoute
            path={SUA_PAGES.AUTH_USERS.path}
            roles={SUA_PAGES.AUTH_USERS.authorizedRoles}
            component={AuthUsersPage}
          />
          <PrivateRoute
            path={SUA_PAGES.GLOSSARY_PAGES.subPages.BREEDS.path}
            roles={SUA_PAGES.GLOSSARY_PAGES.subPages.BREEDS.authorizedRoles}
            component={BreedsPage}
          />
          <PrivateRoute
            path={SUA_PAGES.ANALYTICS.subPages.RECENT_DEVICES.path}
            roles={SUA_PAGES.ANALYTICS.subPages.RECENT_DEVICES.authorizedRoles}
            component={RecentDevicesPage}
          />
          <PrivateRoute
            path={SUA_PAGES.SIMULATORS.path}
            roles={SUA_PAGES.SIMULATORS.authorizedRoles}
            component={SimulatorPage}
          />
          <PrivateRoute
            path={SUA_PAGES.APP_VERSIONS.path}
            roles={SUA_PAGES.APP_VERSIONS.authorizedRoles}
            component={AppVersionsPage}
          />
          <PrivateRoute
            path={SUA_PAGES.BULK_OPERATIONS.subPages.BULK_UNCLAIM.path}
            roles={
              SUA_PAGES.BULK_OPERATIONS.subPages.BULK_UNCLAIM.authorizedRoles
            }
            component={BulkUnclaim}
          />
          <PrivateRoute
            path={SUA_PAGES.BULK_OPERATIONS.subPages.BULK_RECOMMISSION.path}
            roles={
              SUA_PAGES.BULK_OPERATIONS.subPages.BULK_RECOMMISSION
                .authorizedRoles
            }
            component={BulkRecommission}
          />
          <PrivateRoute
            path={SUA_PAGES.BULK_OPERATIONS.subPages.BULK_VALIDATE_DEVICES.path}
            roles={
              SUA_PAGES.BULK_OPERATIONS.subPages.BULK_VALIDATE_DEVICES
                .authorizedRoles
            }
            component={BulkDeviceExistence}
          />
          <PrivateRoute
            path={SUA_PAGES.DPI_SUPPORT.path}
            roles={SUA_PAGES.DPI_SUPPORT.authorizedRoles}
            component={DPISupport}
          />
          <PrivateRoute
            path={SUA_PAGES.COAP_SUPPORT.path}
            roles={SUA_PAGES.COAP_SUPPORT.authorizedRoles}
            component={CoapSupportPage}
          />
          <Route
            path={SUA_PAGES.GLOSSARY_PAGES.subPages.SOFTWARE_VERSIONS.path}
            component={SoftwareVersions}
          />
          <Route
            path={SUA_PAGES.GLOSSARY_PAGES.subPages.ERROR_CODES.path}
            component={ErrorCodes}
          />
          <Route
            path={SUA_PAGES.GLOSSARY_PAGES.subPages.BATTERY_STATUS.path}
            component={BatteryStatusCodes}
          />
          <PrivateRoute
            path={SUA_PAGES.COMMERCIAL_TRACKER.path}
            roles={SUA_PAGES.COMMERCIAL_TRACKER.authorizedRoles}
            component={CommercialTracker}
          />
          <PrivateRoute
            path={SUA_PAGES.COMMERCIAL_TRACKER_USER_MANAGEMENT.path}
            roles={SUA_PAGES.COMMERCIAL_TRACKER_USER_MANAGEMENT.authorizedRoles}
            component={CommercialTrackerUserManagement}
          />
          <PrivateRoute
            path={SUA_PAGES.TEST_HARNESS.subPages.KIDSWATCH.path}
            roles={SUA_PAGES.TEST_HARNESS.subPages.KIDSWATCH.authorizedRoles}
            component={Kidswatch}
          />
          <PrivateRoute
            path={SUA_PAGES.TEST_HARNESS.subPages.TRACKER.path}
            roles={SUA_PAGES.TEST_HARNESS.subPages.TRACKER.authorizedRoles}
            component={Tracker2}
          />
          <Route path={'/testharness/:productId'} component={GenericPoc} />
          <PrivateRoute
            path={SUA_PAGES.PRODUCT_FAQ.path}
            roles={SUA_PAGES.PRODUCT_FAQ.authorizedRoles}
            component={FAQPage}
          />
          <PrivateRoute
            path={SUA_PAGES.API_STATUS.path}
            roles={SUA_PAGES.API_STATUS.authorizedRoles}
            component={APIStatusPage}
          />
          <PrivateRoute
            path={SUA_PAGES.QA_DASHBOARD.path}
            roles={SUA_PAGES.QA_DASHBOARD.authorizedRoles}
            component={QADashboardPage}
          />
          <PrivateRoute
            path={SUA_PAGES.INTAKE_FORMS.path}
            roles={SUA_PAGES.INTAKE_FORMS.authorizedRoles}
            component={IntakeFormPage}
          />
          <PrivateRoute
            path={SUA_PAGES.OPERATIONS.path}
            roles={SUA_PAGES.OPERATIONS.authorizedRoles}
            component={OperationsPage}
          />
          <Route
            render={() => (
              <Error
                heading={ERROR_SCREEN_MESSAGE.PAGE_NOT_FOUND.heading}
                message={ERROR_SCREEN_MESSAGE.PAGE_NOT_FOUND.message}
              />
            )}
          />
        </Switch>
      </>
    )
  );
}

export default MainRoutes;

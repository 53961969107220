import React, { useState } from 'react';

import TmoH2 from '../../controlWrappers/TmoH2';
import TmoH3 from '../../controlWrappers/TmoH3';
import TmoSvg from '../../uiHelpers/TmoSvg';
import FloorPlan from '../floorPlan/FloorPlan';
import { getMapCenter } from '../../../utils/mapUtils';
import { commercialLocationTrackers } from '../../../utils/pocDummyData';
import CommercialMap from '../map/CommercialMap';
import appConfig from '../../../configs/config';

import style from './ZoneGrid.module.css';
import TmoCard from '../../uiHelpers/TmoCard';

const { googleMapSettings, reactGoogleMaps } = appConfig;

function ZoneGrid({ selectedZone, alerts }) {
  const [isFloorPlanOpen, setIsFloorPlanOpen] = useState(false);

  const alertsList = alerts.map((alert, index) => (
    <div key={index} className="ZoneGrid-alerts-list-item">
      <div className="">
        {alert.assetID} {alert.assetType}: {alert.message}
      </div>
    </div>
  ));

  const mapContainer = <div className={style.loading_element} />;

  return (
    <div className={style.container}>
      <div className={style.header}>
        <TmoH2>{selectedZone}</TmoH2>
        <span
          className={style.floorplan_trigger}
          onClick={() => setIsFloorPlanOpen(!isFloorPlanOpen)}
        >
          {isFloorPlanOpen ? 'Hide' : 'Show'} Floor Plan
        </span>
      </div>

      <div className={style.alerts}>
        <TmoSvg svgId="icon-error" className={style.alerts_icon} />
        <TmoH3 className={style.alerts_header}>Alerts:</TmoH3>
        <div className={style.alerts_list}>{alertsList}</div>
      </div>

      {isFloorPlanOpen && (
        <TmoCard>
          <FloorPlan />
        </TmoCard>
      )}

      <div className={style.map}>
        <CommercialMap
          center={getMapCenter(commercialLocationTrackers)}
          zoom={16}
          places={commercialLocationTrackers}
          googleMapURL={`${reactGoogleMaps.url}${googleMapSettings.apiKey}`}
          loadingElement={mapContainer}
          mapElement={mapContainer}
          containerElement={mapContainer}
        />
      </div>
    </div>
  );
}

export default ZoneGrid;

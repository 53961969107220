import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import config from '../configs/config';

const { appInsightsSettings } = config;

const reactPlugin = new ReactPlugin();
let appInsights = null;
appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: appInsightsSettings.faqInstrumentationKey,
    disableAjaxTracking: true,
    extensions: [reactPlugin]
  }
});
appInsights.loadAppInsights();

function trackCustomEvents(name, data) {
  const userInfo = localStorage.getItem('okta-user-info');
  const syncUpProduct = localStorage.getItem('SyncUp-Product');
  let userDetails = null;
  if (userInfo) {
    const info = JSON.parse(userInfo);
    userDetails = info
      ? {
          groups: info.Groups,
          thingsPlatformGroups: info.ThingsPlatformGroups
        }
      : null;
  }

  appInsights &&
    appInsights.trackEvent({
      name: `Portal Event: "${name}" triggered`,
      properties: {
        eventData: data ?? null,
        urlDetails: {
          domain: window.location.origin,
          pathName: window.location.pathname,
          querryParams: window.location.search,
          pageUrl: window.location.href
        },
        userDetails: userDetails,
        product: syncUpProduct
      }
    });
}

export { reactPlugin, appInsights, trackCustomEvents };

import React, { useContext } from 'react';
import {
  TmoCard,
  RawData,
  withModal,
  TmoButtons as TmoButton,
  ConfirmationModalV2,
  StatusPill
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import thingService from '../../services/thingService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import SyncUpProductContext from '../../context/SyncUpProductContext';

import style from './DeviceRoadsideServiceGrid.module.css';
import { ReactSVG } from 'react-svg';
import deleteIcon from '../../static/assets/icons/action/delete.svg';

function DeviceRoadsideServiceGrid({
  roadsideServices,
  openModal,
  forceRefresh
}) {
  const {
    id,
    thingId,
    serviceType,
    status,
    firstName,
    lastName,
    primaryPhoneNumber,
    callKey
  } = roadsideServices;

  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);

  const handleRemoveServiceClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Roadside Service`, {
        thingId: thingId,
        serviceId: id
      });
      try {
        await thingService.deleteRoadsideServices({
          thingId: thingId,
          serviceId: id,
          syncUpAppName: syncUpProductContext.syncUpAppName
        });
        addSuccessMessage({
          message: `Successfully deleted roadside service`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <TmoCard>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <div className={style.status}>
            <StatusPill
              className={style.status_pill}
              inline={false}
              status={status === 'COMPLETED' ? 'active' : status}
            >
              {status}
            </StatusPill>
          </div>
          <div className={style.user_name} title={`${firstName} ${lastName}`}>
            {firstName} {lastName}
          </div>
        </div>
        <div className={style.action_controls}>
          <RawData
            rawObject={roadsideServices}
            title={`Full User Object for ${serviceType}`}
            tooltip={`Full User Object for ${serviceType}`}
            type=" "
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_primary"
          />
          <TmoButton
            className={style.action_button}
            onClick={handleRemoveServiceClicked}
            type="magenta_secondary"
            icon={<ReactSVG src={deleteIcon} />}
            tooltip="Delete"
          />

          <ConfirmationModalV2
            modalType="delete"
            message={`Are you sure you want to remove Service with Id: ${id}?`}
            title="Confirm Service Removal"
            acceptButtonText="Yes,Delete"
            type="danger"
            usePortal={false}
            confirmInput
          />
        </div>
      </div>
      <div className={style.content}>
        <ItemDetailList
          rowSet={[
            { id, thingId },
            { serviceType, primaryPhoneNumber },
            { callKey }
          ]}
        />
      </div>
    </TmoCard>
  );
}

export default withModal(DeviceRoadsideServiceGrid);

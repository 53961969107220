import React from 'react';
import classNames from 'classnames';

import style from './ContentContainer.module.css';

function ContentContainer({ className, children }) {
  return (
    <div className={classNames(className, style.container)}>{children}</div>
  );
}

export default ContentContainer;

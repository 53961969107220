import React, { useState, Fragment, useContext } from 'react';

import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Circle,
  InfoWindow
} from 'react-google-maps';
import SafeZoneInfoWindow from '../safeZones/SafeZoneInfoWindow';
import { getCircleCenter, getMarkerCenter } from '../../utils/mapUtils';
import SyncUpProductContext from '../../context/SyncUpProductContext';

import Safe_Zone_Green from '../../static/assets/Marker_Green.png';
import Pet_Red from '../../static/assets/Marker_Red.png';

function ThingSafeZone({ center, zoom, places, mapStyles }) {
  const [zoneClicked, setZoneClicked] = useState();
  const syncUpProductContext = useContext(SyncUpProductContext);

  const safeZoneClicked = place => {
    setZoneClicked(place);
  };

  const productLabel = `${syncUpProductContext.syncUpAppName
    .charAt(0)
    .toUpperCase()}${syncUpProductContext.syncUpAppName
    .slice(1)
    .toLowerCase()}`;

  return (
    <GoogleMap
      defaultCenter={center}
      defaultZoom={zoom}
      mapContainerStyle={{
        height: '70vh',
        width: '100%'
      }}
      options={{ styles: mapStyles }}
    >
      {places.map(place => {
        return (
          <Fragment key={place.id}>
            <Marker
              position={getMarkerCenter(place.marker)}
              icon={Pet_Red}
              title={JSON.stringify(getMarkerCenter(place.marker))}
              label={productLabel}
            />

            {place.safeZone && (
              <Fragment key={place.id}>
                <Marker
                  position={getCircleCenter(place)}
                  onClick={() => safeZoneClicked(place)}
                  icon={Safe_Zone_Green}
                  title={JSON.stringify(getCircleCenter(place))}
                  label={place.index.toString()}
                />
                <Circle
                  defaultCenter={getCircleCenter(place)}
                  radius={place.circle.radius}
                  title={JSON.stringify(getCircleCenter(place))}
                  options={place.circle.options}
                  onClick={() => safeZoneClicked(place)}
                />
              </Fragment>
            )}
          </Fragment>
        );
      })}

      {zoneClicked && (
        <InfoWindow
          anchor={zoneClicked.id}
          onCloseClick={() => setZoneClicked(false)}
          position={{
            lat: zoneClicked.circle.center.latitude,
            lng: zoneClicked.circle.center.longitude
          }}
        >
          <SafeZoneInfoWindow safeZoneClicked={zoneClicked} />
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

export default withScriptjs(withGoogleMap(ThingSafeZone));

import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
require('highcharts/modules/exporting')(Highcharts);

const Chart = ({ options, highcharts }) => (
  <HighchartsReact
    highcharts={highcharts}
    constructorType={'chart'}
    options={options}
  />
);

export default Chart;

import React, { useState, useContext, useEffect, useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import { useParams } from 'react-router-dom';
import {
  TmoButtons as TmoButton,
  FormModal,
  withModal,
  TmoInput,
  TmoToggle
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import userService from '../../services/userService';
import LocationGroupUsers from './LocationGroupUsers';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import { getGroupsBackUrl, hasValue } from 'utils/helper_functions';
import GoBack from 'components/uiHelpers/goBack/GoBack';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';
import StatusContext from 'context/StatusContext';
import { trackCustomEvents } from 'utils/appInsight_analytics';

import style from './PhoneLocationGroupUsers.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';
import { FaPlus } from 'react-icons/fa';

function PhoneLocationGroupUsers({ match, history, hideTab, openModal }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [phoneLocationGroupUsers, setPhoneLocationGroupUsers] = useState();
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  let { groupId } = useParams();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const TABS = ['Members', 'Invited Members'];
  const [selectedMemberType, setSelectedMemberType] = useState(TABS[0]);
  const formFieldSpecs = [
    {
      labelText: 'First Name',
      fieldName: 'firstName',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'First Name'
    },
    {
      labelText: 'Last Name',
      fieldName: 'lastName',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true,
      placeholder: 'Last Name'
    },
    {
      labelText: 'MSISDN',
      fieldName: 'msisdn',
      formComponent: TmoInput,
      inputType: 'primary',
      required: true
    },
    {
      labelText: 'Emergency Contact',
      fieldName: 'emergencyContact',
      formComponent: TmoToggle,
      inputType: 'primary'
    }
  ];

  const actionCallback = useCallback(
    () =>
      userService.getPhoneLocationGroupUsers({
        userId,
        groupId: groupId,
        syncUpAppName: product
      }),
    [userId, groupId, product]
  );

  const openAddModal = async () => {
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Add new member invite to Group(${groupId})`);
      const body = {
        groupId,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        msisdn: formValues.msisdn,
        emergencyContact: formValues.emergencyContact
      };
      try {
        await userService.createInviteLocationGroups({
          userId,
          syncUpAppName: product,
          body: body
        });
        setSelectedMemberType(TABS[1]);
        forceRefresh();
        addSuccessMessage({
          message: `Successfully invited member: ${formValues.firstName}`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  const handleMemberRefresh = () => {
    setSelectedMemberType(TABS[0]);
    forceRefresh();
  };

  useEffect(() => {
    hideTab && hideTab(true);
  }, [hideTab]);

  const getFilteredList = () => {
    if (selectedMemberType === TABS[1]) {
      return phoneLocationGroupUsers.filter(
        item => item.role === 'INVITED_MEMBER'
      );
    } else {
      return phoneLocationGroupUsers.filter(
        item => item.role !== 'INVITED_MEMBER'
      );
    }
  };

  return (
    <>
      <div className={style.container}>
        <GoBack
          primary
          url={getGroupsBackUrl(userId, 'phone-location-groups', history)}
          hideTab={hideTab}
        />
        <PageHeader
          tabs={TABS}
          tabSelected={selectedMemberType}
          onTabSelected={val => setSelectedMemberType(val)}
          tabHeader={true}
          className={style.header}
        >
          <TmoButton
            className={style.button}
            onClick={forceRefresh}
            type="magenta_secondary"
            tooltip="Refresh"
            icon={<ReactSVG src={refreshIcon} />}
          />
          <TmoButton
            type="magenta_primary"
            icon={<FaPlus />}
            onClick={openAddModal}
          >
            Invite Member
          </TmoButton>
          <FormModal
            title={`Invite Member`}
            fieldSpecs={formFieldSpecs}
            modalType={'slide'}
          />
        </PageHeader>
      </div>
      <>
        <Fetcher
          key={refreshKey}
          action={actionCallback}
          onLoad={setPhoneLocationGroupUsers}
          render={() =>
            hasValue(getFilteredList()) ? (
              <div className={style.items}>
                {getFilteredList().map((user, index) => (
                  <LocationGroupUsers
                    key={`member${index}`}
                    user={user}
                    forceRefresh={forceRefresh}
                    onMemberRefresh={handleMemberRefresh}
                  />
                ))}
              </div>
            ) : (
              <div className={style.message}>
                {' '}
                Group does not have{' '}
                {selectedMemberType === TABS[1] && 'Invited'} Members
              </div>
            )
          }
        />
      </>
    </>
  );
}

export default withModal(PhoneLocationGroupUsers);

export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEV: 'dev',
  STAGING: 'staging',
  PROD: 'prod'
};

let currentEnvironment = ENVIRONMENTS.LOCAL;

switch (window.location.host.toLowerCase()) {
  case 'syncup-admin-dev.t-mobile.com':
    currentEnvironment = ENVIRONMENTS.DEV;
    break;
  case 'syncup-admin-stg.t-mobile.com':
    currentEnvironment = ENVIRONMENTS.STAGING;
    break;
  case 'syncup-admin.t-mobile.com':
    currentEnvironment = ENVIRONMENTS.PROD;
    break;
  default:
    currentEnvironment = ENVIRONMENTS.LOCAL;
    break;
}

export default currentEnvironment;

import React from 'react';
import classNames from 'classnames';

import './InfoBlock.css';

function InfoBlock({ dataSet, classNameDiv, classNameContent }) {
  const listItems = dataSet.map((data, index) => (
    <div key={index} className={classNames('InfoBlock-div', classNameDiv)}>
      <span className={classNames('InfoBlock-content', classNameContent)}>
        {data.header} <small>{data.subscript}</small>
        <span>{data.value}</span>
      </span>
    </div>
  ));

  return <div className="InfoBlock-container">{listItems}</div>;
}

export default InfoBlock;

import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';

import { hasValue } from 'utils/helper_functions';
import Error from '../../components/Error/Error';
import Fetcher from 'components/Fetcher';
import qaService from 'services/qaService';
import Chart from 'components/charts/Chart';
import BuildDetails from './BuildDetails';

const Dashboard = () => {
  const [pipeLineList, setPipeLineList] = useState();
  const [selectedBuild, setSelectedBuild] = useState();
  const [filteredList, setFilteredList] = useState();

  const handleLoadData = useCallback(data => {
    setPipeLineList(data);
  }, []);

  const actionCallback = useCallback(() => {
    return qaService.getPipelineData();
  }, []);

  const getLast50Runs = useCallback(data => {
    if (!data) return {};
    const groupedData = {};
    data.forEach(item => {
      groupedData[item.pipeline_name] = groupedData[item.pipeline_name] || [];
      groupedData[item.pipeline_name].push(item);
    });

    const last50Runs = {};
    for (const pipelineName in groupedData) {
      const runs = groupedData[pipelineName];
      const sorted = runs.sort((a, b) =>
        moment(a.executed_on).diff(b.executed_on)
      );
      last50Runs[pipelineName] = sorted.slice(-50);
    }
    return last50Runs;
  }, []);

  useEffect(() => {
    setFilteredList(getLast50Runs(pipeLineList));
  }, [pipeLineList, getLast50Runs]);

  const options = {
    exporting: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    chart: {
      type: 'spline',
      style: {
        fontFamily: 'Tele-Neo-Regular, sans-serif'
      }
    },
    title: {
      text: 'Consolidated View of the Regression Pipeline Data'
    },
    xAxis: {
      title: {
        text: 'Build Number'
      },
      visible: false,
      crosshair: true
    },

    yAxis: {
      title: {
        text: 'Pass Percentage',
        style: {
          fontWeight: 'bold',
          fontSize: '14px'
        }
      },
      min: 0,
      max: 100,
      tickInterval: 20
    },
    legend: {
      enabled: true,
      itemMarginTop: 10,
      itemStyle: {
        fontSize: '14px'
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        },
        cursor: 'pointer',
        point: {
          events: {
            click: function() {
              const clickedBuild = pipeLineList.find(
                data => data.build_number === this.build_number
              );
              setSelectedBuild(clickedBuild);
            }
          }
        }
      }
    },
    tooltip: {
      formatter() {
        return `${this.point.pipeline_name}<br/>
          Build Number: <b>${this.point.build_number}</b><br/>
          Pass Percentage: <b>${this.y}</b>`;
      }
    },
    series:
      filteredList &&
      Object.entries(filteredList).map(([pipelineName, runs]) => ({
        name: pipelineName || 'Unknown',
        data: runs.map(({ pass_percentage, build_number }) => ({
          y: pass_percentage,
          build_number,
          pipeline_name: pipelineName || 'Unknown'
        }))
      }))
  };

  return (
    <Fetcher
      action={actionCallback}
      onLoad={handleLoadData}
      render={() =>
        hasValue(filteredList) ? (
          <>
            <Chart options={options} highcharts={Highcharts} />
            {selectedBuild && <BuildDetails build={selectedBuild} />}
          </>
        ) : (
          <Error heading="Pipelines details not found" />
        )
      }
    />
  );
};
export default Dashboard;

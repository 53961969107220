import React from 'react';

import classNames from 'classnames';
import TmoH3 from '../controlWrappers/TmoH3';

import style from './TextAlert.module.css';

function TextAlert({
  className,
  type = 'info',
  title,
  icon,
  message,
  confirm
}) {
  return (
    <div className={classNames(style.container, style[type], className)}>
      {title && <TmoH3>{title}</TmoH3>}
      <p className={style.message}>
        {icon && <div className={style.icon}>{icon}</div>}
        {message}
      </p>
      {confirm && <div className={style.confirm}>{confirm}</div>}
    </div>
  );
}

export default TextAlert;

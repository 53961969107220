import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  TmoH3,
  TmoH4,
  DynamicList,
  TmoCard,
  TmoTextarea,
  TmoButtons as TmoButton,
  TmoSvg
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import analyticsService from '../../../services/analyticsService';
import StatusContext from '../../../context/StatusContext';
import SyncUpProductContext from '../../../context/SyncUpProductContext';
import CustomQueryDateFilter from './CustomQueryDateFilter';
import { LAST_1_HOURS } from '../../../utils/stringUtils';
import { getTimestampRangeForLastHours } from '../../../utils/dateUtils';
import { APPCENTER_SUGGESTED_QUERIES_PETS } from '../../../utils/customAppcenterSuggestions';
import { APPCENTER_SUGGESTED_QUERIES_WATCH } from '../../../utils/customAppcenterSuggestionsWatch';
import { APPCENTER_SUGGESTED_QUERIES_TRACKER } from '../../../utils/customAppcenterSuggestionsTracker';
import useVisible from '../../../hooks/useVisible';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

import style from './CustomAppCenterQueryComponent.module.css';

function CustomAppCenterQueryComponent({ className }) {
  const { addErrorMessage, addWarningMessage } = useContext(StatusContext);
  const [customQuery, setCustomQuery] = useState();
  const [selectedQuery, setSelectedQuery] = useState();

  const [dataTable, setDataTable] = useState();
  const [timeStamp, setTimeStamp] = useState();
  const { visible, setVisible } = useVisible(false);

  const syncUpProductContext = useContext(SyncUpProductContext);

  const handleOnQuery = async e => {
    setDataTable();
    e.preventDefault();
    if (!customQuery) {
      addWarningMessage({
        message: 'Appcenter query field cannot be empty'
      });
      return;
    }

    setVisible(true);
    try {
      trackCustomEvents(`Run Query`, {
        customQuery: customQuery.replace(/\n/g, '')
      });
      const response = await analyticsService.getPlatformLogSearch({
        customQuery: customQuery.replace(/\n/g, '')
      });
      const data = handleParseMassageData(response[0].tables[0]);

      setVisible(false);
      setDataTable(data);
    } catch (error) {
      setVisible(false);
      addErrorMessage({
        message: 'Something Bad happened',
        error: error
      });
    }
  };

  const handleParseMassageData = table => {
    try {
      const headers = table.columns.map((header, index) => ({
        listItemKey: index,
        headerDisplayName: header.name.toUpperCase()
      }));
      const rows = table.rows.map(columns => Object.assign({}, columns));

      return { headers, rows };
    } catch (error) {
      throw error;
    }
  };

  const handleOnReset = e => {
    e.preventDefault();
    setCustomQuery();
  };

  const handleFilterChange = timeRange => {
    if (!timeRange) {
      timeRange = getTimestampRangeForLastHours(1);
    }
    setTimeStamp(timeRange);
    if (selectedQuery) {
      setCustomQuery(
        selectedQuery.replace(':StartDate', timeRange['start-date-time'])
      );
    }
  };

  const queryButtonClicked = suggestion => {
    setSelectedQuery(suggestion.query);
    const timeRange = getTimestampRangeForLastHours(1);
    if (!timeStamp) {
      setTimeStamp(timeRange);
      setCustomQuery(suggestion.query.replace(':StartDate', timeRange.endTime));
    } else {
      setCustomQuery(suggestion.query.replace(':StartDate', timeRange.endTime));
    }
  };
  useEffect(() => {}, [customQuery, timeStamp]);

  return (
    <div className={style.container}>
      <PageHeader title={'Query Parameters'} analyticsHeader />
      <TmoCard className={className}>
        <form className={style.container}>
          <CustomQueryDateFilter
            onFilter={handleFilterChange}
            onDefaultButtonSet={setTimeStamp}
            selectedButtonName={LAST_1_HOURS}
          />
          <TmoTextarea
            onChange={setCustomQuery}
            className={style.textarea}
            rows="5"
            placeholder="Appcenter Query here.."
            value={customQuery}
          />
          <div>
            <div className={style.suggestions}>
              <TmoH4>Suggested Queries: </TmoH4>
              {APPCENTER_SUGGESTED_QUERIES_PETS.map(
                suggestion =>
                  syncUpProductContext.syncUpAppName === 'PETS' && (
                    <TmoButton
                      size="sm"
                      className={classNames(
                        style.suggestion_button,
                        customQuery === suggestion.query
                          ? style.suggestion_button_active
                          : null
                      )}
                      onClick={e => {
                        e.preventDefault();
                        setCustomQuery(suggestion.query);
                      }}
                    >
                      {suggestion.name}
                    </TmoButton>
                  )
              )}
              {APPCENTER_SUGGESTED_QUERIES_TRACKER.map(
                suggestion =>
                  syncUpProductContext.syncUpAppName === 'TRACKER' && (
                    <TmoButton
                      size="sm"
                      className={classNames(
                        style.suggestion_button,
                        customQuery === suggestion.query
                          ? style.suggestion_button_active
                          : null
                      )}
                      onClick={e => {
                        e.preventDefault();
                        setCustomQuery(suggestion.query);
                      }}
                    >
                      {suggestion.name}
                    </TmoButton>
                  )
              )}
              {APPCENTER_SUGGESTED_QUERIES_WATCH.map(
                suggestion =>
                  syncUpProductContext.syncUpAppName === 'KIDSWATCH' && (
                    <TmoButton
                      size="sm"
                      className={classNames(
                        style.suggestion_button,
                        customQuery === suggestion.query
                          ? style.suggestion_button_active
                          : null
                      )}
                      onClick={e => {
                        e.preventDefault();
                        queryButtonClicked(suggestion);
                        // setSelectedQuery(suggestion.query);
                        // setCustomQuery(suggestion.query.replace(':StartDate', timeStamp));
                      }}
                    >
                      {suggestion.name}
                    </TmoButton>
                  )
              )}
            </div>
          </div>
          <div className={style.query_buttons}>
            <TmoButton
              type="magenta_secondary"
              className={style.query_button}
              onClick={handleOnReset}
            >
              Reset
            </TmoButton>
            <TmoButton
              className={style.query_button}
              type="magenta_primary"
              onClick={handleOnQuery}
            >
              Run Query
            </TmoButton>
          </div>
        </form>
        {visible && (
          <TmoSvg className={style.spinner} svgId="loading-spinner" />
        )}
        {dataTable && (
          <div className={style.container}>
            {dataTable.rows.length === 0 && (
              <TmoH4>
                There is not data available by the query you provided
              </TmoH4>
            )}
            {dataTable.rows.length > 0 && (
              <>
                <TmoH3>Result Count: {dataTable.rows.length}</TmoH3>
                <DynamicList
                  headerNameListItemMap={dataTable.headers}
                  listItems={dataTable.rows}
                />
              </>
            )}
          </div>
        )}
      </TmoCard>
    </div>
  );
}

export default CustomAppCenterQueryComponent;

import React, { useState } from 'react';
import classNames from 'classnames';
import {
  TmoButtons as TmoButton,
  TmoToggle
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import { getRandomString } from '../../utils/stringUtils';
import { FETCH_STATE } from '../../utils/app_constants';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import { exportCustomData } from '../../utils/helper_functions';
import { getCurrentDateTimeString } from '../../utils/dateUtils';

import style from './FileUpload.module.css';
import upload_icon from '../../static/assets/icons/upload_file.svg';
import file from '../../static/assets/icons/file.svg';
import delete_icon from '../../static/assets/icons/delete_icon.svg.svg';

function FileUpload({
  className,
  title,
  onUpload,
  onReset,
  actionBtnName,
  actionBtnClick,
  inputPlaceholderText,
  inputSubtitleText,
  isUnclaim,
  fetchState,
  hardwareIds,
  onToggleChange
}) {
  const [inputKeyState, setInputKeyState] = useState('');
  const [inputFileName, setInputFileName] = useState('');
  const [inputFile, setInputFile] = useState('');
  const [listView, setListView] = useState(true);
  const exportHeaders = isUnclaim
    ? ['hardwareId', 'status', 'message', 'product']
    : ['hardwareId', 'message'];
  const fileName = `Recommission-${getCurrentDateTimeString()}.csv`;

  const handleFileUpload = ({ currentTarget: { files } }) => {
    if (!files.length) {
      return;
    }

    const file = files[0];
    setInputFileName(file.name);
    setInputFile(file);
    onUpload(file);
  };

  const onResetClicked = e => {
    let randomString = getRandomString();
    setInputKeyState(randomString);
    setInputFileName();
    setInputFile();
    onReset(e);
  };

  const onActionBtnClick = () => {
    actionBtnClick(inputFile);
  };

  const handleToggle = () => {
    onToggleChange(!listView);
    setListView(!listView);
  };

  return (
    <div className={className}>
      <div className={style.container}>
        <div className={style.column}>
          <span className={style.title}>{title}</span>
        </div>
        <div
          className={classNames(
            isUnclaim ? style.input_unclaim : style.input_container
          )}
        >
          {isUnclaim ? (
            <>
              <label className={style.label} htmlFor="file">
                {inputFileName ? inputFileName : inputPlaceholderText}
              </label>
              <input
                type="file"
                name="file"
                id="file"
                key={inputKeyState || ''}
                className={style.input}
                placeholder={inputPlaceholderText}
                onChange={handleFileUpload}
              />
              <TmoButton
                className={style.reset}
                onClick={onResetClicked}
                type="secondary"
              >
                Reset
              </TmoButton>
              {actionBtnName && (
                <TmoButton
                  onClick={onActionBtnClick}
                  type="primary"
                  disabled={!inputFile}
                  className={style.action}
                >
                  {actionBtnName}
                </TmoButton>
              )}
            </>
          ) : (
            <>
              <label
                className={classNames(
                  style.label,
                  inputFileName && style.label_disabled
                )}
                htmlFor="file"
              >
                <div className={style.upload_placeholder}>
                  <ReactSVG src={upload_icon} />
                  <div>
                    <div>{inputPlaceholderText}</div>
                    <div className={style.subtitle}>{inputSubtitleText}</div>
                  </div>
                </div>
              </label>
              <input
                type="file"
                name="file"
                id="file"
                key={inputKeyState || ''}
                className={style.input}
                placeholder={inputPlaceholderText}
                onChange={handleFileUpload}
              />
              {inputFileName && (
                <div className={style.file_row}>
                  <div className={style.file_name}>
                    <ReactSVG src={file} />
                    <span>{inputFileName}</span>
                  </div>
                  <TmoButton
                    onClick={onResetClicked}
                    type="magenta_link"
                    icon={<ReactSVG src={delete_icon} />}
                    tooltip="Delete"
                  />
                </div>
              )}
              <div className={style.action_row}>
                <div className={style.controls}>
                  {fetchState === FETCH_STATE.COMPLETE && (
                    <TmoButton
                      onClick={() => {
                        trackCustomEvents(`Recommission Export`, {
                          fileName
                        });
                        exportCustomData({
                          headers: exportHeaders,
                          fileName,
                          hardwareIds
                        });
                      }}
                      type="magenta_secondary"
                    >
                      Download
                    </TmoButton>
                  )}
                  {actionBtnName && (
                    <TmoButton
                      onClick={onActionBtnClick}
                      type="magenta_primary"
                      disabled={!inputFile}
                    >
                      {actionBtnName}
                    </TmoButton>
                  )}
                </div>
                <div className={style.toggle}>
                  {inputFile && (
                    <TmoToggle
                      id={getRandomString()}
                      isChecked={listView}
                      onChange={handleToggle}
                      label={'List View'}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FileUpload;

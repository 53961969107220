import React from 'react';

import './TrackerDashboard.css';

import TrackerDashboardGrid from './TrackerDashboardGrid';

function TrackerDashboard() {
  const dataSet = [
    { header: 121, value: 'Total Trackers', subscript: '' },
    { header: 70, value: 'Active Today', subscript: '' },
    { header: 119, value: 'Inside Safe Zones', subscript: '' },
    { header: 9.6, value: 'Total Data Used', subscript: 'GB' }
  ];

  return (
    <div className="TrackerDashboard-container">
      <TrackerDashboardGrid dataSet={dataSet} />
    </div>
  );
}

export default TrackerDashboard;

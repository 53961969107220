import { APP_MOBILE } from 'utils/app_constants';
import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';

const mobileAppRepository = {
  getAppVersions: async ({
    osVersion,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      basePath,
      headers
    } = config.apiSettings.syncUpPlatform;

    switch (syncUpAppName) {
      case APP_MOBILE.DRIVE:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: resources.driveAppVersions,
          basePath: basePath.adminDrive,
          httpMethod: httpMethods.GET,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName
          }
        });
      default:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: resources.appVersions.replace(':os', osVersion),
          basePath: basePath.platform,
          httpMethod: httpMethods.GET,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName,
            [headers.defaultAppUserImpersonation.key]:
              headers.defaultAppUserImpersonation.value
          }
        });
    }
  },
  deleteAppVersions: async ({
    versionID,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      basePath,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deleteAppVersion.replace(':versionId', versionID),
      basePath: basePath.admin,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  postAppVersion: async ({ body, syncUpAppName, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      basePath,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.appVersions.replace('s/:os', ''),
      basePath: basePath.admin,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      body: body
    });
  },
  getAvailableVersions: async ({
    responseStatus = false,
    distributionChannel,
    platform,
    userId
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.versionsAvailable
        .replace(':distributionChannel', distributionChannel)
        .replace(':platform', platform),
      headers: { [headers.userImpersonation.key]: userId }
    });
  },

  updateAppVersion: async ({
    syncUpAppName,
    userId,
    distributionChannel,
    platform,
    body,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      basePath,
      headers
    } = config.apiSettings.syncUpPlatform;
    switch (syncUpAppName) {
      case APP_MOBILE.DRIVE:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: resources.appVersionUpdate
            .replace(':distributionChannel', distributionChannel)
            .replace(':platform', platform),
          body: body,
          basePath: basePath.adminDrive,
          httpMethod: httpMethods.PUT,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName
          }
        });
      default:
        return await syncUpPlatformRequest({
          urlSuffix: resources.appVersionUpdate
            .replace(':distributionChannel', distributionChannel)
            .replace(':platform', platform),
          basePath: basePath.platformDrive,
          httpMethod: httpMethods.PUT,
          responseStatus: responseStatus,
          headers: { [headers.userImpersonation.key]: userId },
          body: body
        });
    }
  }
};

export default mobileAppRepository;

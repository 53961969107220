import React from 'react';
import { ContentContainer } from '@tmobile/tmo-bps-syncup-web-component-lib';

import InstabugAccessForm from './InstabugAccessForm';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

const IntakeFormPage = () => {
  return (
    <ContentContainer>
      <PageHeader title={'Intake Form'}></PageHeader>
      <InstabugAccessForm />
    </ContentContainer>
  );
};
export default IntakeFormPage;

import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import {
  TmoCard,
  TmoButtons as TmoButton,
  withModal,
  RawData,
  ConfirmationModalV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import { prettifyCamelCase } from 'utils/stringUtils';
import { removeDashes } from '../../utils/helper_functions';
import userService from 'services/userService';
import StatusContext from '../../context/StatusContext';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import deleteIcon from '../../static/assets/icons/action/delete.svg';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import LocationGroupsMemberSwap from './LocationGroupsMemberSwap';

import style from './LocationGroupUsers.module.css';
import RedeemInvite from './RedeemInvite';

function LocationGroupUsers({
  user,
  openModal,
  history,
  forceRefresh,
  onMemberRefresh
}) {
  const {
    id,
    role,
    msisdn,
    emergencyContact,
    firstName,
    lastName,
    inviteId,
    name,
    avatar
  } = user;

  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);

  const handleRemoveClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Group Member Invite`, {
        groupId: id,
        inviteId: inviteId
      });
      try {
        await userService.deletePhoneLocationMemberInvite({
          groupId: id,
          inviteId: inviteId,
          userId: userId,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Invite id: ${inviteId} removed successfully`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };
  const handleMemberDelete = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Group Member`, {
        groupId: id,
        memberId: user.userId
      });
      try {
        await userService.deletePhoneLocationGroupMember({
          groupId: id,
          userId: userId,
          memberId: user.userId,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Member id: ${user.userId} removed successfully`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };
  return (
    <TmoCard shadow={false} key={id}>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <div className={style.avatar}>
            {avatar ? (
              <img className={style.avatar_img} src={avatar} alt="User" />
            ) : (
              firstName?.charAt(0).toUpperCase()
            )}
          </div>
          <div className={style.avatar_details}>
            <label
              className={style.display_name}
              title={`${prettifyCamelCase(
                firstName?.toLowerCase()
              )} ${prettifyCamelCase(lastName?.toLowerCase())}`}
            >
              {prettifyCamelCase(firstName?.toLowerCase())}{' '}
              {prettifyCamelCase(lastName?.toLowerCase())}
            </label>
            <span
              className={
                role === 'INVITED_MEMBER'
                  ? style.invited_member
                  : style.role_name
              }
            >
              {removeDashes(role)}
            </span>
          </div>
        </div>
        <div className={style.action_controls}>
          {role === 'MEMBER' && (
            <LocationGroupsMemberSwap user={user} forceRefresh={forceRefresh} />
          )}
          <RawData
            rawObject={user}
            tooltip="User Object"
            title={`User Object for ${firstName}`}
            className={style.object}
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_primary_icon"
            type=" "
          />
          {role === 'INVITED_MEMBER' ? (
            <>
              <RedeemInvite
                inviteId={inviteId}
                groupId={id}
                onForceRefresh={onMemberRefresh}
              />

              <TmoButton
                onClick={handleRemoveClicked}
                className={style.button_remove}
                type="magenta_secondary"
                tooltip="Delete Invited Member"
                icon={<ReactSVG src={deleteIcon} />}
              />
              <ConfirmationModalV2
                modalType="delete"
                message={
                  <>
                    Are you sure you want to remove invite for {firstName}{' '}
                    {lastName} with invite ID: <strong>{inviteId}</strong>?
                  </>
                }
                title="Confirm Invite Removal"
                acceptButtonText="Yes,Delete"
                type="danger"
                usePortal={false}
                confirmInput
                buttonIcon={<ReactSVG src={deleteIcon} />}
              />
            </>
          ) : (
            <>
              <TmoButton
                onClick={handleMemberDelete}
                className={style.button_remove}
                type="magenta_secondary"
                tooltip="Delete Member"
                icon={<ReactSVG src={deleteIcon} />}
              />
              <ConfirmationModalV2
                modalType="delete"
                message={<>Are you sure you want to delete</>}
                title="Delete Member"
                acceptButtonText="Yes,Delete"
                type="danger"
                usePortal={false}
                confirmInput
                buttonIcon={<ReactSVG src={deleteIcon} />}
              />
            </>
          )}
        </div>
      </div>
      <div className={style.content} key={id}>
        <ItemDetailList
          rowSet={[
            { 'Group Id': id },
            { 'Group Name': name },
            { 'First Name': firstName, 'Last Name': lastName },
            { 'User Id': user.userId },
            inviteId ? { 'Invite Id': inviteId } : {},
            { MSISDN: msisdn, 'Emergency Contact': emergencyContact }
          ]}
        />
      </div>
    </TmoCard>
  );
}

export default withModal(withRouter(LocationGroupUsers));

import React from 'react';

import TmoH1 from '../components/controlWrappers/TmoH1';
import { ERROR_SCREEN_MESSAGE } from '../utils/app_constants';

import style from './Error.module.css';

function Error({ heading, message }) {
  return (
    <div className={style.container}>
      <TmoH1 className={style.heading}>
        {heading ? heading : ERROR_SCREEN_MESSAGE.PAGE_NOT_FOUND.heading}
      </TmoH1>
      <p className={style.message}>
        {message ? message : ERROR_SCREEN_MESSAGE.PAGE_NOT_FOUND.message}
      </p>
    </div>
  );
}

export default Error;

import React, { useState } from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import { TmoH3 } from '@tmobile/tmo-bps-syncup-web-component-lib';

import Accordion from '../../components/uiHelpers/Accordion';
import { capitalCase } from '../../utils/helper_functions';
import { APP_MOBILE } from '../../utils/app_constants';

import style from './PortalFaqContent.module.css';

import kidsIcon from '../../static/assets/icons/asidepanel/watch.svg';
import driveIcon from '../../static/assets/icons/asidepanel/drive.svg';
import trackerIcon from '../../static/assets/icons/asidepanel/tracker.svg';
import chevronDown from '../../static/assets/icons/chevron_down.svg';
import chevronUp from '../../static/assets/icons/chevron_right.svg';

function FaqDetails({ details }) {
  const { description } = details;
  return (
    <div className={style.details_wrapper}>
      <p
        className={style.description}
        aria-label={description}
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
}

function PortalFaqContent({ item, innerAccordion }) {
  const { category, details, categoryIcon } = item;
  const [showContent, setShowContent] = useState(true);
  const displayAllIcons = () => (
    <>
      <ReactSVG
        title="Tracker"
        src={trackerIcon}
        className={style.product_icon}
      />
      <ReactSVG
        title="Kidswatch"
        src={kidsIcon}
        className={style.product_icon}
      />
      <ReactSVG title="Drive" src={driveIcon} className={style.product_icon} />
    </>
  );
  const accordionTitle = (title, shortDesc, products) => (
    <div className={style.row}>
      <div className={style.title_wrapper} aria-label={title}>
        <div className={style.title}>
          <span className={style.value}>{title}</span>
          {shortDesc && <p className={style.short_desc}>{shortDesc}</p>}
        </div>
        <div className={style.icon_wrapper}>
          {products && products.length ? (
            <>
              {products.includes(APP_MOBILE.TRACKER) && (
                <ReactSVG
                  title="Tracker"
                  src={trackerIcon}
                  className={style.product_icon}
                />
              )}

              {products.includes(APP_MOBILE.WATCH) && (
                <ReactSVG
                  title="Kidswatch"
                  src={kidsIcon}
                  className={style.product_icon}
                />
              )}

              {products.includes(APP_MOBILE.DRIVE) && (
                <ReactSVG
                  title="Drive"
                  src={driveIcon}
                  className={style.product_icon}
                />
              )}
            </>
          ) : (
            <>{displayAllIcons()}</>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <>
      <div
        className={classNames(
          style.category_wrapper,
          innerAccordion && style.sub_category
        )}
      >
        <div className={style.category_title}>
          <div className={style.icon}>
            <ReactSVG src={categoryIcon} />
          </div>
          <div className={style.content}>
            <TmoH3
              className={classNames(
                style.sticky_tiles,
                innerAccordion && style.sub_category_title
              )}
              flush
              ariaLabel={capitalCase(
                innerAccordion ? item.subCategory : category
              )}
              ariaRole="heading"
            >
              <span>
                {capitalCase(innerAccordion ? item.subCategory : category)}
              </span>
            </TmoH3>
            {!innerAccordion && (
              <p className={style.short_desc}>{item.categoryDesc}</p>
            )}
          </div>
        </div>
        <div className={style.input_wrapper}>
          {showContent ? (
            <ReactSVG src={chevronDown} onClick={() => setShowContent(false)} />
          ) : (
            <ReactSVG src={chevronUp} onClick={() => setShowContent(true)} />
          )}
        </div>
      </div>
      {showContent && (
        <>
          {item && item.hasSubCategory && (
            <>
              {details &&
                details.map((data, index) => (
                  <div
                    className={style.sub_category_wrapper}
                    key={`portal-faq-fragment-${index}`}
                  >
                    <PortalFaqContent item={data} innerAccordion />
                  </div>
                ))}
            </>
          )}
          {!item.hasSubCategory &&
            details &&
            details.map((data, index) => (
              <Accordion
                key={`${data.id}-${index}`}
                className={style.accordion_row}
                title={accordionTitle(
                  data.title,
                  data.shortDesc,
                  data.products
                )}
                titleClassName={style.accordion_column}
                contentClassName={style.accordion_content}
                customIcon
                ariaLabel={data.title}
                ariaRole="accordion"
                data={data}
                isHelp={{
                  id: data.id,
                  title: `${category ? category : 'Analytics'} - ${data.title}`
                }}
              >
                <FaqDetails details={data} />
              </Accordion>
            ))}
        </>
      )}
    </>
  );
}

export default PortalFaqContent;

import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import { getTimestampRangeForLastHours } from '../../../utils/dateUtils';

import style from './CustomQueryDateFilter.module.css';

import {
  END_DATE_TIME_KEY,
  LAST_24_HOURS,
  LAST_6_HOURS,
  LAST_1_HOURS,
  START_DATE_TIME_KEY
} from '../../../utils/stringUtils';

function CustomQueryDateFilter({
  onFilter,
  selectedButtonName,
  onDefaultButtonSet,
  label
}) {
  const [filterBtnName, setFilterBtnName] = useState(selectedButtonName);
  const oneHours = 1;
  const sixHours = 6;
  const twentyFourHours = 24;

  const handleDefaultButtonSet = useCallback(() => {
    let timeRange;
    switch (selectedButtonName) {
      case LAST_1_HOURS:
        timeRange = getTimestampRangeForLastHours(oneHours);
        break;
      case LAST_6_HOURS:
        timeRange = getTimestampRangeForLastHours(sixHours);
        break;
      case LAST_24_HOURS:
        timeRange = getTimestampRangeForLastHours(twentyFourHours);
        break;
      default:
        timeRange = null;
        break;
    }

    onDefaultButtonSet({
      [START_DATE_TIME_KEY]: timeRange?.startTime,
      [END_DATE_TIME_KEY]: timeRange?.endTime
    });
  }, [onDefaultButtonSet, selectedButtonName, oneHours]);

  useEffect(() => {
    selectedButtonName && handleDefaultButtonSet();
  }, [selectedButtonName, handleDefaultButtonSet]);

  const handleSingleButtonFilter = e => {
    setFilterBtnName(e.target.name);
    const timeRange = getTimestampRangeForLastHours(e.target.value);
    onFilter({
      [START_DATE_TIME_KEY]: timeRange.startTime,
      [END_DATE_TIME_KEY]: timeRange.endTime
    });
  };

  return (
    <div className={style.container}>
      <label className={style.label}>{label ? label : 'FILTER BY'}:</label>
      <button
        name={LAST_1_HOURS}
        className={classNames(
          style.button,
          filterBtnName === LAST_1_HOURS && style.button_active
        )}
        type="button"
        onClick={handleSingleButtonFilter}
        value={oneHours}
      >
        LAST 1 HOUR
      </button>
      <button
        name={LAST_6_HOURS}
        className={classNames(
          style.button,
          filterBtnName === LAST_6_HOURS && style.button_active
        )}
        type="button"
        onClick={handleSingleButtonFilter}
        value={sixHours}
      >
        LAST 6 HOURS
      </button>
      <button
        name={LAST_24_HOURS}
        className={classNames(
          style.button,
          filterBtnName === LAST_24_HOURS ? style.button_active : ''
        )}
        type="button"
        onClick={handleSingleButtonFilter}
        value={twentyFourHours}
      >
        LAST 24 HOURS
      </button>
    </div>
  );
}

export default CustomQueryDateFilter;

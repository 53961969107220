import React, {
  useState,
  useCallback,
  useMemo,
  useContext,
  useEffect
} from 'react';
import classNames from 'classnames';
import { FaSyncAlt } from 'react-icons/all';
import {
  TmoButtons as TmoButton,
  TmoCard,
  withModal,
  TmoH2,
  TmoDropDownSelect
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import SharedThing from './SharedThing';
import thingService from '../../services/thingService';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import { hasValue } from 'utils/helper_functions';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';

import style from './Things.module.css';
import { IoIosArrowDown } from 'react-icons/all';

function SharedThings({ className, productType, thingTypeName, refresh }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [currentThingId, setCurrentThingId] = useState();
  const [things, setThings] = useState();
  const [lastRefresh, setLastRefresh] = useState(getRandomString());
  const [firstUpdate] = useState(refresh);

  const handleManualRefresh = () => {
    setLastRefresh(getRandomString());
  };

  const actionCallback = useCallback(
    () =>
      thingService.getSharedThingsByUserId({
        userId,
        syncUpAppName: product
      }),
    [userId, product]
  );

  const handleSetThings = useCallback(things => {
    setCurrentThingId(null);
    setThings(things);
  }, []);

  const activeThings = useMemo(
    () => things?.filter(t => t.status !== 'DELETED'),
    [things]
  );

  useEffect(() => {
    refresh !== firstUpdate && handleManualRefresh(); //children function of interest
  }, [refresh, firstUpdate]);

  const thing = useMemo(() => {
    const safeThings = activeThings || [];
    return (
      safeThings.find(thing => thing.id === currentThingId) || safeThings[0]
    );
  }, [currentThingId, activeThings]);

  const deletedThingsCount = useMemo(() => {
    return things?.filter(t => t.status === 'DELETED').length;
  }, [things]);

  return (
    <TmoCard
      key={lastRefresh}
      className={classNames(style.container, className)}
    >
      <Fetcher
        action={actionCallback}
        onLoad={handleSetThings}
        render={() => (
          <>
            {things?.length < 2 ? (
              <PageHeader title={`${thingTypeName}`} />
            ) : (
              <div className={style.heading}>
                <div className={style.thing_details}>
                  <div className={style.thing_number}>
                    <TmoH2 flush className={style.label}>
                      {activeThings.length > 1 ? 'Select Shared' : 'Shared'}{' '}
                      {thingTypeName}
                    </TmoH2>
                    <div className={style.subtitle}>
                      {things?.length} {thingTypeName.toLowerCase()} are in this
                      list
                      {deletedThingsCount > 0 && (
                        <small className={style.heading_deleted_things}>
                          (*Not displaying <strong>{deletedThingsCount}</strong>{' '}
                          deleted {thingTypeName})
                        </small>
                      )}
                    </div>
                  </div>
                  <div className={style.custom_dropdown}>
                    {hasValue(activeThings) && activeThings.length > 1 && (
                      <TmoDropDownSelect
                        optionsList={activeThings?.map(o => ({
                          key: o.id,
                          value: o.name
                        }))}
                        onChange={setCurrentThingId}
                        renderIcon={<IoIosArrowDown />}
                        defaultSelectedValue={
                          activeThings &&
                          activeThings.map(o => ({
                            key: o.id,
                            value: o.name
                          }))[0]
                        }
                      />
                    )}
                  </div>
                </div>
                <div className={style.thing_actions}>
                  {thing && (
                    <TmoButton
                      type="magenta_secondary"
                      tooltip="Refresh"
                      className={style.button}
                      onClick={handleManualRefresh}
                    >
                      <FaSyncAlt className={style.button_icon} />
                    </TmoButton>
                  )}
                </div>
              </div>
            )}
            {thing ? (
              <SharedThing thing={thing} productType={productType} />
            ) : (
              <div className={style.no_things}>
                No <strong>{thingTypeName}</strong> found
              </div>
            )}
          </>
        )}
      />
    </TmoCard>
  );
}

export default withModal(SharedThings);

import React from 'react';

import CommercialLogo from '../../../static/assets/commercial/images/commercial-logo.jpg';
import TmoButton from '../../forms/TmoButton';
import TmoSvg from '../../uiHelpers/TmoSvg';

import './CommercialHeader.css';

function CommercialHeader() {
  return (
    <div className="Header-commercial-container">
      <div className="Header-commercial-logo">
        <img alt="Rocky Lake Properties" src={CommercialLogo} />
      </div>
      <div className="Header-commercial-actions">
        <TmoButton type="secondary" className="Header-commercial-action-btn">
          + Widget
        </TmoButton>
        <TmoButton
          type="secondary"
          className="Header-commercial-action-btn Header-commercial-action-btn-dashboard"
        >
          <TmoSvg svgId="icon-dashboard-layout" className="Dashboard-layout" />
        </TmoButton>
      </div>
    </div>
  );
}

export default CommercialHeader;

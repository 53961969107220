import React, { useState, useContext, useEffect } from 'react';
import { NavigationTab as Tab } from '@tmobile/tmo-bps-syncup-web-component-lib';

import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { LOG_TYPE } from '../../utils/app_constants';
import { getUserThingBackUrl } from '../../utils/helper_functions';
import GoBack from 'components/uiHelpers/goBack/GoBack';
import LogsHistory from './LogsHistory';
import LogsStatus from './LogsStatus';

import style from './Logs.module.css';

function Logs({ hideTab }) {
  const [tabSelected, setTabSelected] = useState(LOG_TYPE.HISTORY);
  const onTabChanged = value => {
    setTabSelected(value);
  };
  const {
    selectedTab: { userId }
  } = useContext(ActiveUserTabContext);

  const logType = () => {
    switch (tabSelected) {
      case LOG_TYPE.HISTORY:
        return <LogsHistory />;
      case LOG_TYPE.STATUS:
        return <LogsStatus />;
      default:
        return <LogsHistory />;
    }
  };

  useEffect(() => {
    hideTab && hideTab(true);
  }, [hideTab]);

  return (
    <>
      <GoBack
        url={getUserThingBackUrl(userId, 'things')}
        hideTab={hideTab}
        primary
      />
      <div className={style.tabs}>
        <Tab
          className={style.tab}
          isSelected={tabSelected === LOG_TYPE.HISTORY}
          text={LOG_TYPE.HISTORY}
          value={LOG_TYPE.HISTORY}
          onSelect={onTabChanged}
          isHorizontal
        />
        <Tab
          className={style.tab}
          isSelected={tabSelected === LOG_TYPE.STATUS}
          text={LOG_TYPE.STATUS}
          value={LOG_TYPE.STATUS}
          onSelect={onTabChanged}
          isHorizontal
        />
      </div>
      <div>{logType()}</div>
    </>
  );
}

export default Logs;

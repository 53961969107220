import React, { useCallback, useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import userService from 'services/userService';
import { APP_MOBILE, SUA_PAGES } from 'utils/app_constants';
import { getSearchedUsers, hasValue } from 'utils/helper_functions';
import Fetcher from 'components/Fetcher';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import classNames from 'classnames';
import ProductIcon from 'components/uiHelpers/ProductIcon';
import ProductFilter from './ProductFilter';
import ErrorWithImage from 'components/Error/Error';
import style from './UserSearchPanel.module.css';
import { STORAGE_KEYS, STORAGE_TYPES } from '../../../utils/app_constants';
import {
  storageRead
} from '../../../utils/storageUtils';
import {
  TmoButtons as TmoButton
} from '@tmobile/tmo-bps-syncup-web-component-lib';
function UesrSearchResults({ userSearchTerm }) {
  const [userSearchResults, setUserSearchResults] = useState([]);
  const [error, setError] = useState();
  const [filteredUserList, setFilteredUserList] = useState();
  const { setSelectedTab, selectedTab } = useContext(ActiveUserTabContext);
  const { userId, product } = { ...selectedTab };
  const sessionSearchType = storageRead({
    key: STORAGE_KEYS.SEARCH_TYPE,
    storageType: STORAGE_TYPES.LOCAL
  });
  const [pageData, setPageData] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    if (sessionSearchType || userSearchTerm) {
      setCurrentPage(0);
      setUserSearchResults([]);
      setFilteredUserList([]);
    }
  }, [sessionSearchType,userSearchTerm]);
  const updatePageNumber = (currPage) =>{
    setCurrentPage(currPage + 1)
  }
  const userActionCallback = useCallback(() => {
    /* for tlife search */
    if (sessionSearchType === 'LEGACY') {
      setUserSearchResults([]);
      setFilteredUserList([]);
      setCurrentPage(0);
      return Promise.allSettled([
        userService.getUsersBySearch({
          q: userSearchTerm,
          syncUpAppName: APP_MOBILE.DRIVE
        }),
        userService.getUsersBySearch({
          q: userSearchTerm,
          syncUpAppName: APP_MOBILE.WATCH
        }),
        userService.getUsersBySearch({
          q: userSearchTerm,
          syncUpAppName: APP_MOBILE.TRACKER
        })
      ]);
    } else if(sessionSearchType === 'TLIFE')  {
      return Promise.allSettled([
        userService.getTlifeUsersBySearch({
          q: userSearchTerm,
          syncUpAppName: [APP_MOBILE.TRACKER, APP_MOBILE.WATCH, APP_MOBILE.DRIVE],
          page:currentPage
        })
      ]);
    }
  }, [userSearchTerm,sessionSearchType,currentPage]);

  const getTlifeSearchedUsers = dataVal => {
    let datas = dataVal?.map(usr => ({
      ...usr['userProfile'],
      ...usr['userApplicationProfile'],
      flag: 'tlife'
    }));

    let groupedObjects = [];
    let newArray = [];
    datas?.forEach((usrTlife) => {
      groupedObjects.push(
        usrTlife['tracker'] != null
          ? {...usrTlife['tracker'], ...usrTlife }
          : '',
        usrTlife['kidswatch'] != null
          ? {...usrTlife['kidswatch'], ...usrTlife }
          : '',
        usrTlife['phoneLocation'] != null
          ? { ...usrTlife['phoneLocation'], ...usrTlife }
          : '',
        usrTlife['drive'] != null ? {...usrTlife['drive'], ...usrTlife } : ''
      );
      newArray = groupedObjects.filter(
        value => Object.keys(value).length !== 0
      );
    });
    return newArray;
  };
  const uniqueById = (items) =>{
    const uniqueStrings = [];
    const uniqueArray = items.filter((o) => {
      const s = JSON.stringify(o);
    if (!uniqueStrings.includes(s)) {
        uniqueStrings.push(s);
        return true;
    }
    return false;
    });
    return uniqueArray;
  }
  const handleLoad = useCallback(
    data => {
      setError(null);
      let result = [];
      let groupedObj = [];
      let groupedObjects2 = [];
      let allDatas = [];
      let count = 0;         
      data &&
      data.forEach(e => {
        if(sessionSearchType === 'LEGACY')  {
          if (e.status === 'fulfilled' && hasValue(e.value)) {
            result = [...result, ...e.value];
          }
        }else if(sessionSearchType === 'TLIFE'){
          if (e.status === 'fulfilled' &&  hasValue(e.value)) {
            if(e.value['pageDetails'] !==null){
            setPageData(e.value['pageDetails'])
            setCurrentPage(e.value['pageDetails']['currentPage'])

          }
          if(e.value['pagedUserProfileDetails'] !==null){
             allDatas = e.value['pagedUserProfileDetails']?.map(usrs => ({
              ...usrs
            })); 
          }
            result = [...result, ...allDatas];
          }
          }
          e.status === 'rejected' && count++;
        });
        data.length === count && setError(data[0].reason);
        let getSearchedUsersValue = getSearchedUsers(result);                                                            
        if (sessionSearchType === 'TLIFE') {
          getSearchedUsersValue.forEach(usr => {
          groupedObj = getTlifeSearchedUsers([{ ...usr }]); 
            const iterator = groupedObj.values();
            for (const letter of iterator) {
              groupedObjects2.push(letter);
            setUserSearchResults(prevState =>{ return uniqueById([...prevState,...groupedObjects2])});
            setFilteredUserList([prevState => { return uniqueById([...[...prevState,...groupedObjects2]])}]);
          }
      });
    }else if (sessionSearchType === 'LEGACY') {
      setUserSearchResults(getSearchedUsersValue);
      setFilteredUserList([...getSearchedUsersValue]);
    }
  },
  [sessionSearchType]
);
const handleOnFilter = useCallback(data => {
  setFilteredUserList(data);
}, []);
const handleClick = user => {
  const pageUrl = SUA_PAGES.USER.path.replace(':userId', user.userId);
  setSelectedTab({
    name: user.firstName,
    userId: user.userId,
    url: `${pageUrl}/things`,
    product: user.product
  });
};
const showPagination = pageData && pageData.lastPage > currentPage;
  return (
    <>
      {userSearchTerm ? (
        <>
          <ProductFilter
            onFilter={handleOnFilter}
            userList={userSearchResults}
          />
          <div className={style.user_result_container} id={style['scrollable']}>
            <Fetcher
              action={userActionCallback}
              onLoad={handleLoad}
              render={() => (
                <>
                  {error && (
                    <ErrorWithImage
                      heading="Failed to fetch"
                      message={error && error.toString()}
                    />
                  )}
                  {filteredUserList &&
                    filteredUserList.map(user => {
                      return (
                        <div
                          key={`${user.userId}-${user.product}`}
                          className={classNames(
                            style.user_card,
                            user.userId === userId &&
                              user.product === product &&
                              style.card_selected
                          )}
                          onClick={() => handleClick(user)}
                        >
                          <div className={style.user_info}>
                            <div className={style.title}>
                              {user?.firstName} {user?.lastName}
                            </div>
                            <div className={style.sub_title}>{user?.email}</div>
                          </div>
                          <div className={style.product_icon}>
                            <ProductIcon product={user.product} />
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
            />
           { (showPagination && sessionSearchType === 'TLIFE') && pageData.lastPage > 0 ?
          <div className={style.loadMore}onClick={() => updatePageNumber(currentPage)}>
            <TmoButton type="magenta_link" className={style.Load_button}> Load More </TmoButton>
            </div> : ''
           }
          </div>
        </>
      ) : (
        <div className={style.no_result}> </div>
      )}
    </>
  );
}

export default withRouter(UesrSearchResults);

export default function KidswatchPlayStore() {
  const userAgent = navigator.userAgent;
  let appStore = 'https://apps.apple.com/mx/app/syncup-kids/id1503394062?l=en';
  let googlePlay =
    'https://play.google.com/store/apps/details?id=com.tmobile.kids&hl=en_US&gl=US';
  let webUrl =
    'https://www.t-mobile.com/devices/timex-familyconnect-kids-smartwatch';

  if (userAgent.match(/iPhone|iPad|iPod/i)) {
    return window.location.assign(appStore);
  } else if (userAgent.match(/android/i)) {
    return window.location.assign(googlePlay);
  } else {
    return window.location.assign(webUrl);
  }
}

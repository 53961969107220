import React, { useState, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  NavigationTab as Tab,
  NavigationTabGroup as TabGroup,
  ContentContainer
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import { APP_MOBILE, SUA_ROLES } from '../../utils/app_constants';
import { getRandomString } from '../../utils/stringUtils';
import Diagnostics from '../../components/dashboard/diagnostics/Diagnostics';
import CustomAppCenterQueryComponent from '../../components/dashboard/customAppcenterQuery/CustomAppCenterQueryComponent';
import PowerBi from '../../components/dashboard/powerBi/PowerBi';
import Analytics from '../../components/dashboard/analytics/Analytics';
import DevicesMap from '../../components/dashboard/powerBi/DevicesMap';
import FindMyWatch from '../../components/dashboard/powerBi/FindMyWatch';
import DevicesPairingStats from '../../components/dashboard/powerBi/DevicePairingStats';
import LaunchStats from '../../components/dashboard/powerBi/LaunchStats';
import Reviews from '../../components/dashboard/reviews/Reviews';
import Ratings from '../../components/dashboard/ratings/Ratings';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import style from './Dashboard.module.css';

function Dashboard() {
  const [lastRefresh] = useState(getRandomString()); // TODO: Add refresh button

  const analyticsUrl = '/dashboard/analytics';
  const diagnosticsUrl = '/dashboard/diagnostics';
  const devicesUrl = '/dashboard/devices';
  const findMyWatchUrl = '/dashboard/feature-report';
  const devicesPairingStatsUrl = '/dashboard/device-pairing-stats';
  const LaunchStatsUrl = '/dashboard/launch-stats';
  const customAppcenterQuery = '/dashboard/custom-appcenter-query';
  const reviewsUrl = '/dashboard/reviews';
  const ratingsUrl = '/dashboard/ratings';
  const syncUpProductContext = useContext(SyncUpProductContext);

  const productTabs = () => {
    switch (syncUpProductContext.syncUpAppName) {
      case APP_MOBILE.TRACKER:
        return (
          <ContentContainer className={style.container}>
            <TabGroup sticky type="primary" className={style.dashboard_tabs}>
              <Tab
                to={analyticsUrl}
                isSelected={true}
                text="Analytics"
                type="primary-tab"
              />
              <Tab to={devicesUrl} text="Devices Map" type="primary-tab" />
              <Tab
                to={customAppcenterQuery}
                text="Custom Query"
                type="primary-tab"
              />
            </TabGroup>
            <div className={style.content}>
              <Switch key={lastRefresh}>
                <Route
                  exact
                  path={analyticsUrl}
                  component={Analytics}
                  type="primary-tab"
                />
                <Route
                  exact
                  path={devicesUrl}
                  component={DevicesMap}
                  type="primary-tab"
                />
                <Route
                  exact
                  path={customAppcenterQuery}
                  component={CustomAppCenterQueryComponent}
                  type="primary-tab"
                />
              </Switch>
            </div>
          </ContentContainer>
        );
      case APP_MOBILE.WATCH:
        return (
          <ContentContainer className={style.container}>
            <TabGroup sticky type="primary" className={style.dashboard_tabs}>
              <Tab
                to={analyticsUrl}
                isSelected={true}
                text="Analytics"
                type="primary-tab"
              />
              <Tab to={devicesUrl} text="Devices Map" type="primary-tab" />
              <Tab
                to={findMyWatchUrl}
                text="Feature Report"
                type="primary-tab"
              />
              <Tab
                to={devicesPairingStatsUrl}
                text="Device Pairing Stats"
                type="primary-tab"
              />
              <Tab to={LaunchStatsUrl} text="Launch Stats" type="primary-tab" />
              <Tab
                to={customAppcenterQuery}
                text="Custom Query"
                type="primary-tab"
              />
            </TabGroup>
            <div className={style.content}>
              <Switch key={lastRefresh}>
                <Route exact path={analyticsUrl} component={PowerBi} />
                <Route exact path={devicesUrl} component={DevicesMap} />
                <Route exact path={findMyWatchUrl} component={FindMyWatch} />
                <Route
                  exact
                  path={devicesPairingStatsUrl}
                  component={DevicesPairingStats}
                />
                <Route exact path={LaunchStatsUrl} component={LaunchStats} />
                <Route
                  exact
                  path={customAppcenterQuery}
                  component={CustomAppCenterQueryComponent}
                />
              </Switch>
            </div>
          </ContentContainer>
        );
      case APP_MOBILE.DRIVE:
        return (
          <ContentContainer className={style.container}>
            <TabGroup sticky type="primary" className={style.dashboard_tabs}>
              <Tab
                to={analyticsUrl}
                isSelected={true}
                text="Analytics"
                type="primary-tab"
              />
              <Tab to={LaunchStatsUrl} text="Launch Stats" type="primary-tab" />
            </TabGroup>
            <div className={style.content}>
              <Switch key={lastRefresh}>
                <Route exact path={analyticsUrl} component={PowerBi} />
                <Route exact path={LaunchStatsUrl} component={LaunchStats} />
              </Switch>
            </div>
          </ContentContainer>
        );
      case APP_MOBILE.FAMILYLOCATION:
        return (
          <ContentContainer className={style.container}>
            <TabGroup sticky type="primary" className={style.dashboard_tabs}>
              <Tab
                to={analyticsUrl}
                isSelected={true}
                text="Analytics"
                type="primary-tab"
              />
            </TabGroup>
            <div className={style.content}>
              <Switch key={lastRefresh}>
                <Route exact path={analyticsUrl} component={PowerBi} />
              </Switch>
            </div>
          </ContentContainer>
        );
      default:
        return (
          <ContentContainer className={style.container}>
            <TabGroup sticky type="primary" className={style.dashboard_tabs}>
              <Tab to={analyticsUrl} text="Analytics" type="primary-tab" />
              <Tab to={diagnosticsUrl} text="Diagnostics" type="primary-tab" />
              <Tab to={devicesUrl} text="Devices Map" type="primary-tab" />
              <Tab
                to={customAppcenterQuery}
                text="Custom Query"
                type="primary-tab"
              />
              <Tab to={reviewsUrl} text="Reviews" type="primary-tab" />
              <Tab to={ratingsUrl} text="Ratings" type="primary-tab" />
            </TabGroup>
            <div className={style.content}>
              <Switch key={lastRefresh}>
                <Route exact path={analyticsUrl} component={PowerBi} />
                <Route exact path={diagnosticsUrl} component={Diagnostics} />
                <Route exact path={devicesUrl} component={DevicesMap} />
                <Route
                  exact
                  path={customAppcenterQuery}
                  component={CustomAppCenterQueryComponent}
                />
                <Route exact path={reviewsUrl} component={Reviews} />
                <Route exact path={ratingsUrl} component={Ratings} />
              </Switch>
            </div>
          </ContentContainer>
        );
    }
  };

  return (
    <Authorized
      roles={[
        SUA_ROLES.ADMIN,
        SUA_ROLES.PO,
        SUA_ROLES.PETS_ADMIN,
        SUA_ROLES.PETS_DEV,
        SUA_ROLES.PETS_PO,
        SUA_ROLES.TRACKER_ADMIN,
        SUA_ROLES.TRACKER_DEV,
        SUA_ROLES.TRACKER_PO,
        SUA_ROLES.WATCH_ADMIN,
        SUA_ROLES.WATCH_DEV,
        SUA_ROLES.WATCH_PO
      ]}
      willShowErrorModal
    >
      {productTabs()}
    </Authorized>
  );
}

export default Dashboard;

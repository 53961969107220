import React from 'react';
import config from '../../configs/config';
import TmoH1 from '../controlWrappers/TmoH1';

/* TODO Call the correct api endpoint to get the the sensor list*/
const sensorList = [];

function DistinctSoftwareVersions() {
  let env = config.apiSettings.environment;

  return (
    <div>
      <TmoH1>Distinct Software Versions for All Users in {env} </TmoH1>
      {sensorList.map((user, index) => (
        <div key={index}>
          <span>{index + 1}.</span> -{' '}
          <a
            href={
              '/user-by-software-version?version=' + user.softwareWifiVersion
            }
          >
            {user.softwareWifiVersion}
          </a>
        </div>
      ))}
    </div>
  );
}
export default DistinctSoftwareVersions;

import React from 'react';

import TimeDisplay from '../../uiHelpers/TimeDisplay';

import style from './LocationHistoryInfoWindow.module.css';

function LocationHistoryInfoWindow({ historyClicked }) {
  const {
    index,
    deviceId,
    hardwareId,
    updateDate,
    latitude,
    longitude,
    altitude,
    radius,
    speed,
    source
  } = historyClicked;

  return (
    <div className={style.container}>
      <div className={style.info_header}>
        <h3 className={style.info_header_heading}>GeoCode Details</h3>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Index: </label>
          <label className={style.label_content}>{index}</label>
        </div>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Device ID: </label>
          <label className={style.label_content}>{deviceId}</label>
        </div>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Hardware ID: </label>
          <label className={style.label_content}>{hardwareId}</label>
        </div>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Latitude: </label>
          <label className={style.label_content}>{latitude}</label>
        </div>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Longitude: </label>
          <label className={style.label_content}>{longitude}</label>
        </div>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Altitude: </label>
          <label className={style.label_content}>{altitude}</label>
        </div>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Source: </label>
          <label className={style.label_content}>{source}</label>
        </div>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Speed: </label>
          <label className={style.label_content}>{speed}</label>
        </div>
      </div>
      <div className={style.row}>
        <div>
          <label className={style.label}>Radius: </label>
          <label className={style.label_content}>{radius}</label>
        </div>
      </div>
      <div className={style.column}>
        <div className={style.row}>
          <TimeDisplay title="Updated" timestamp={updateDate} />
        </div>
      </div>
    </div>
  );
}
export default LocationHistoryInfoWindow;

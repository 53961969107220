import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoButtons,
  withModal,
  ConfirmationModalV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import userService from '../../services/userService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import StatusContext from '../../context/StatusContext';

import style from './LocationGroupsAvatarDelete.module.css';
import delete_icon from '../../static/assets/icons/delete_avatar.svg';

function LocationGroupsAvatarDelete({ group, openModal, forceRefresh }) {
  const { id } = group;
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);

  const handleAvatarDelete = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Avatar`, {
        groupId: id
      });
      try {
        await userService.deletePhoneLocationGroupAvatar({
          groupId: id,
          userId: userId,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Avatar is removed successfully`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };
  return (
    <div className={style.avatar_wrapper}>
      <div className={style.avatar_delete}>
        <TmoButtons
          className={style.button}
          onClick={handleAvatarDelete}
          type="magenta_primary"
          tooltip="Delete Avatar"
          icon={<ReactSVG src={delete_icon} />}
        />
        <ConfirmationModalV2
          modalType="delete"
          message={<>Are you sure you want to delete Avatar?</>}
          title="Delete Avatar"
          acceptButtonText="Yes,Delete"
          type="danger"
          usePortal={false}
          confirmInput
          buttonIcon={<ReactSVG src={delete_icon} />}
        />
      </div>
    </div>
  );
}

export default withModal(LocationGroupsAvatarDelete);

import React from 'react';
import classNames from 'classnames';
import GoBack from '../goBack/GoBack';
import {
  NavigationTab as Tab,
  NavigationTabGroup as TabGroup
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import style from './PageHeader.module.css';

const PageHeader = ({
  title,
  subtitle,
  className,
  children,
  tabHeader = false,
  primary = '',
  backUrl,
  hideTab,
  analyticsHeader,
  secondary,
  tabs,
  tabSelected,
  onTabSelected
}) => {
  const TitleSecion = ({ tabHeader, title, subtitle, analyticsHeader }) => {
    return (
      <div className={style.title_group}>
        {tabs ? (
          <TabGroup className={style.tab_group} type="secondary">
            {tabs.map(tab => (
              <Tab
                onSelect={val => onTabSelected(val)}
                key={tab}
                value={tab}
                type="secondary-tab"
                isSelected={tab === tabSelected}
                text={tab}
              />
            ))}
          </TabGroup>
        ) : tabHeader ? (
          <h3 className={style.title} data-testid="heaading-h3">
            {' '}
            {title}{' '}
          </h3>
        ) : (
          <h1 className={style.title} data-testid="heaading-h1">
            {' '}
            {title}{' '}
          </h1>
        )}
        {subtitle && (
          <div className={style.sub_title} data-testid="sub-title">
            {subtitle}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames(
        style.page_header,
        tabHeader && style.tab_header,
        analyticsHeader && style.analytics_header,
        primary && style.page_header_search_controls,
        secondary && style.secondary_page_header,
        className
      )}
    >
      {backUrl ? (
        <div className={style.title_wrapper} data-testid="goback-wrapper">
          <GoBack
            url={backUrl}
            className={style.back_button}
            hideTab={hideTab}
            primary
          />
          <TitleSecion
            tabHeader={tabHeader}
            title={title}
            subtitle={subtitle}
          />
        </div>
      ) : (
        <TitleSecion tabHeader={tabHeader} title={title} subtitle={subtitle} />
      )}
      <div className={style.controls} data-testid="control-section">
        {children}
      </div>
    </div>
  );
};
export default PageHeader;

import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import { Tooltip } from '@tmobile/tmo-bps-syncup-web-component-lib';
import _ from 'underscore';

import {
  getEnabledProducts,
  getProductsStatus,
  getOktaGroups
} from '../utils/helper_functions';
import { prettifyCamelCase } from 'utils/stringUtils';
import SyncUpProductContext from '../context/SyncUpProductContext';
import LoggedInUserContext from '../context/LoggedInUserContext';
import {
  SUA_PAGES,
  APP_MOBILE,
  DIMENSIONS,
  MISC_PRODUCTS
} from 'utils/app_constants';
import ProductIcon from './uiHelpers/ProductIcon';
import useWindowWidth from '../hooks/useWindowWidth';
import config from '../configs/config';

import style from './SyncUpAppNameSelect.module.css';
import search from '../static/assets/icons/asidepanel/search.svg';
import QADashboard from '../static/assets/icons/asidepanel/qa_dashboard.svg';
import ONBoarding from '../static/assets/icons/asidepanel/on_boarding.svg';

function SyncUpAppNameSelect() {
  const { userInfo } = useContext(LoggedInUserContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const history = useHistory();
  const location = useLocation();
  const width = useWindowWidth();
  const env = config.apiSettings.environment;
  const isSearchPage = location.pathname.includes(SUA_PAGES.SEARCH.path);
  const isQAPage = location.pathname.includes(SUA_PAGES.QA_DASHBOARD.path);
  const isIntakePage = location.pathname.includes(SUA_PAGES.INTAKE_FORMS.path);
  const selectSpecs = userInfo
    ? getProductsStatus(getOktaGroups(userInfo))
    : {};
  const enabledProducts = userInfo
    ? getEnabledProducts(getOktaGroups(userInfo))
    : {};
  if (!syncUpProductContext.syncUpAppName && enabledProducts[0]) {
    syncUpProductContext.setSyncUpApp(enabledProducts[0].value);
  }

  const OnProductChange = value => {
    history.push(SUA_PAGES.APP_VERSIONS.path);
    syncUpProductContext.setSyncUpApp(value);
  };

  const onMISCProductChange = value => {
    history.push(SUA_PAGES.QA_DASHBOARD.path);
    syncUpProductContext.setSyncUpApp(value);
  };

  const onIntakeFormProductChange = value => {
    history.push(SUA_PAGES.INTAKE_FORMS.path);
    syncUpProductContext.setSyncUpApp(value);
  };

  const authRoutes = (authEnv, authRole) => {
    return (
      authEnv.includes(env) &&
      !!_.intersection(getOktaGroups(userInfo), authRole).length
    );
  };

  return (
    <div className={style.wrapper}>
      <Tooltip
        tooltip="Search"
        position={width <= DIMENSIONS.MOBILE_WIDTH ? 'top' : 'bottom'}
      >
        <ReactSVG
          src={search}
          onClick={() => history.push(SUA_PAGES.SEARCH.path)}
          className={classNames(
            style.product_list,
            isSearchPage && style.active
          )}
        />
      </Tooltip>
      {selectSpecs?.options?.map((t, i) => (
        <>
          {t.value !== APP_MOBILE.FAMILYLOCATION &&
            t.value !== APP_MOBILE.PETS && (
              <Tooltip
                tooltip={prettifyCamelCase(t.value.toLowerCase())}
                position={width <= DIMENSIONS.MOBILE_WIDTH ? 'top' : 'bottom'}
                key={`${t.value}-${i}`}
                className={classNames(
                  APP_MOBILE.WATCH === t.value && style.tooltip
                )}
              >
                <div
                  key={`${t.value}button-${i}`}
                  className={classNames(
                    style.product_list,
                    syncUpProductContext.syncUpAppName === t.value &&
                      !isSearchPage &&
                      !isQAPage &&
                      !isIntakePage &&
                      style.active
                  )}
                  onClick={e => {
                    OnProductChange(t.value);
                  }}
                >
                  <ProductIcon
                    product={t.value}
                    className={classNames(style.items, style.product_item)}
                  />
                </div>
              </Tooltip>
            )}
        </>
      ))}
      {authRoutes(
        SUA_PAGES.QA_DASHBOARD.authorizedEnvironments,
        SUA_PAGES.QA_DASHBOARD.authorizedRoles
      ) && (
        <Tooltip
          tooltip="QA Dashboard"
          position={width <= DIMENSIONS.MOBILE_WIDTH ? 'top' : 'right'}
        >
          <ReactSVG
            src={QADashboard}
            onClick={() => onMISCProductChange(MISC_PRODUCTS.QA_DASHBOARD)}
            className={classNames(style.product_list, isQAPage && style.active)}
          />
        </Tooltip>
      )}
      <Tooltip
        tooltip="Onboarding"
        position={width <= DIMENSIONS.MOBILE_WIDTH ? 'top' : 'right'}
      >
        <ReactSVG
          src={ONBoarding}
          onClick={() => onIntakeFormProductChange(MISC_PRODUCTS.INTAKE_FORMS)}
          className={classNames(
            style.product_list,
            isIntakePage && style.active
          )}
        />
      </Tooltip>
    </div>
  );
}

export default SyncUpAppNameSelect;

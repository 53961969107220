import React, { useContext, useState } from 'react';
import {
  TmoButtons as TmoButton,
  withModal,
  ModalV2,
  TmoInput
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import deviceService from '../../services/deviceService';
import classNames from 'classnames';
import StatusContext from '../../context/StatusContext';
import ActiveUserTabContext from 'context/ActiveUserTabContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './ClaimDeviceForUser.module.css';
import claim from '../../static/assets/icons/claim_icon.svg';

function ClaimDeviceForUser({
  userId,
  openModal,
  closeModal,
  setVisible,
  className,
  refreshDetails,
  modalType
}) {
  const [deviceId, setDeviceId] = useState(null);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const activeUserTabContext = useContext(ActiveUserTabContext);
  const product = activeUserTabContext.selectedTab.product;

  const handleClaimDevice = async e => {
    e.preventDefault();
    try {
      setVisible(true);
      closeModal();
      trackCustomEvents(`Claim Device`, {
        userId: userId,
        deviceId: deviceId,
        product: product
      });
      await deviceService.claimDriveDevice({
        userId: userId,
        deviceId: deviceId,
        syncUpAppName: product
      });

      addSuccessMessage({
        message: <>Device Claimed Successfully.</>
      });
      setVisible(false);
      refreshDetails();
    } catch (error) {
      setVisible(false);
      addErrorMessage({ error });
      refreshDetails();
    }
  };

  return (
    <div className={classNames(style.container, className)}>
      <TmoButton
        type="magenta_primary"
        onClick={openModal}
        tooltip="Claim Device"
        icon={<ReactSVG src={claim} />}
      />
      <ModalV2
        title={`Claim Device`}
        portal={false}
        className={style.modal_heading}
        modalType={modalType}
      >
        <div className={style.input_container}>
          <label flush className={style.label}>
            Device ID
          </label>
          <TmoInput
            type="text"
            name="url"
            value={deviceId}
            className={style.text_input}
            placeholder={`Type here...`}
            onChange={setDeviceId}
            inputType="primary"
          />
          <div className={style.action_row}>
            <TmoButton
              onClick={closeModal}
              className={style.action}
              type="magenta_link"
            >
              Cancel
            </TmoButton>
            <TmoButton
              onClick={handleClaimDevice}
              className={style.action}
              type="magenta_primary"
            >
              Claim
            </TmoButton>
          </div>
        </div>
      </ModalV2>
    </div>
  );
}

export default withModal(ClaimDeviceForUser);

import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import {
  withModal,
  ModalV2,
  TmoButtons as TmoButton,
  TmoDropDownSelect
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import { ReactSVG } from 'react-svg';

import CommandCard from './CommandCard';
import { getSimulatedCommands } from '../../utils/deviceConsts';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';

import style from './IssueCommands.module.css';
import commands from '../../static/assets/icons/commands.svg';
import { IoIosArrowDown } from 'react-icons/all';

function IssueCommands({
  match,
  deviceId,
  hardwareId,
  openModal,
  className,
  isUserDetails
}) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [commandList] = useState(getSimulatedCommands(product));
  const [selectedCategory, setSelectedCategory] = useState();
  const [categorySpec, setCategorySpec] = useState();
  const handleCategoryChange = value => {
    const tempList = commandList.filter(category => category.name === value);
    setSelectedCategory(tempList[0]);
  };

  useEffect(() => {
    commandList && setSelectedCategory(commandList[0]);
    commandList &&
      setCategorySpec(
        commandList.map(k => ({
          key: k.name,
          value: k.name
        }))
      );
  }, [commandList, setSelectedCategory, setCategorySpec]);

  return (
    <>
      <TmoButton
        onClick={openModal}
        className={classNames(style.action_button, className)}
        type={isUserDetails ? 'magenta_primary' : 'magenta_link'}
        tooltip={isUserDetails ? 'Commands' : ''}
        icon={<ReactSVG src={commands} />}
      >
        {' '}
        {!isUserDetails && <>Commands</>}
      </TmoButton>
      <ModalV2
        title={`Issue Command For Device: ${deviceId}`}
        modalType="slide"
        portal={true}
      >
        <div className={style.scrollable_content}>
          {selectedCategory && (
            <div className={style.container}>
              <label className={style.label}> Command Category </label>
              <TmoDropDownSelect
                className={style.select_margin}
                key={selectedCategory.name}
                optionsList={categorySpec}
                onChange={handleCategoryChange}
                renderIcon={<IoIosArrowDown />}
                defaultSelectedValue={
                  categorySpec.filter(e => e.key === selectedCategory.name)[0]
                }
                formMargin
              />
              <CommandCard
                key={`${selectedCategory.name}-options`}
                label={selectedCategory.name}
                userId={userId}
                deviceId={deviceId ? deviceId : match.params.deviceId}
                commandData={selectedCategory}
                hardwareId={hardwareId ? hardwareId : match.params.hardwareId}
              />
            </div>
          )}
        </div>
      </ModalV2>
    </>
  );
}

export default withModal(IssueCommands);

import { useRef, useEffect, useState } from 'react';

function useVisible(initialVisibility) {
  const [visible, setVisible] = useState(initialVisibility);
  const visibilityRef = useRef(null);

  const handleClickOutside = event => {
    if (
      visibilityRef.current &&
      !visibilityRef.current.contains(event.target)
    ) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return { visibilityRef, visible, setVisible };
}

export default useVisible;

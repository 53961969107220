import React, { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { STATUS_TYPE } from '../../utils/app_constants';
import StatusContext from '../../context/StatusContext';
import Message from '../errorHandler/Message';
import { getRandomString } from '../../utils/stringUtils';

import style from './Status.module.css';

function Status({ className, children }) {
  const [messages, setMessages] = useState([]);

  const addMessage = useRef(({ message, details, type }) => {
    setMessages([
      ...messages,
      {
        id: getRandomString(),
        message,
        details,
        type
      }
    ]);
  });

  const contextValue = useRef({
    addSuccessMessage: props =>
      addMessage.current({ ...props, type: STATUS_TYPE.SUCCESS }),
    addErrorMessage: ({ message, details, error }) =>
      addMessage.current({
        message: message || 'An unexpected error has occurred',
        details: details || error?.toString(),
        type: STATUS_TYPE.ERROR
      }),
    addWarningMessage: props =>
      addMessage.current({ ...props, type: STATUS_TYPE.WARNING }),
    addInfoMessage: props =>
      addMessage.current({ ...props, type: STATUS_TYPE.INFO })
  });

  return createPortal(
    <>
      <div className={classNames(style.container, className)}>
        {messages.map(({ id, message, details, type }) => (
          <Message key={id} message={message} details={details} type={type} />
        ))}
      </div>
      <StatusContext.Provider value={contextValue.current}>
        {children}
      </StatusContext.Provider>
    </>,
    document.body
  );
}

export default Status;

import { getMediaPriorityName } from 'utils/mediaUtils';
import iotFirmwareRepository from '../repositories/iotFirmwareRepository';

const iotFirmwareService = {
  getAllIotFirmware: async ({ product }) => {
    const firmware = await iotFirmwareRepository.getAllIotFirmware({ product });
    let sortByStatus = firmware.sort((a, b) => b.isActive - a.isActive);
    return sortByStatus.map(item => {
      return { ...item, priority: getMediaPriorityName(item.priority) };
    });
  },
  createIotFirmware: async ({ body, product }) => {
    return await iotFirmwareRepository.createIotFirmware({
      body,
      product
    });
  },
  getIotFirmwareById: async ({ firmwareId, product }) => {
    return await iotFirmwareRepository.getIotFirmwareById({
      firmwareId,
      product
    });
  },
  uploadIotFirmwareAsset: async ({ firmwareId, body, product }) => {
    return await iotFirmwareRepository.uploadIotFirmwareAsset({
      firmwareId,
      body,
      product
    });
  },
  updateIotFirmware: async ({ firmwareId, body, product }) => {
    return await iotFirmwareRepository.updateIotFirmware({
      firmwareId,
      body,
      product
    });
  },
  deleteIotFirmware: async ({ firmwareId, product }) => {
    return await iotFirmwareRepository.deleteIotFirmware({
      firmwareId,
      product
    });
  },
  getAllIotFirmwareGroups: async ({ product }) => {
    return await iotFirmwareRepository.getAllIotFirmwareGroups({
      product
    });
  },
  getIotFirmwareDevicesInGroup: async ({ groupId, product }) => {
    return await iotFirmwareRepository.getIotFirmwareDevicesInGroup({
      groupId,
      product
    });
  },
  createIotFirmwareGroup: async ({ body, product }) => {
    return await iotFirmwareRepository.createIotFirmwareGroup({
      body,
      product
    });
  },
  updateIotFirmwareGroup: async ({ groupId, body, product }) => {
    return await iotFirmwareRepository.updateIotFirmwareGroup({
      groupId,
      body,
      product
    });
  },
  deleteIotFirmwareGroup: async ({ groupId, product }) => {
    return await iotFirmwareRepository.deleteIotFirmwareGroup({
      groupId,
      product
    });
  },
  addDevicesToFirmwareGroup: async ({ groupId, body, product }) => {
    return await iotFirmwareRepository.addDevicesToFirmwareGroup({
      groupId,
      body,
      product
    });
  },
  deleteDeviceFromFirmwareGroup: async ({ groupId, body, product }) => {
    return await iotFirmwareRepository.deleteDeviceFromFirmwareGroup({
      groupId,
      body,
      product
    });
  }
};

export default iotFirmwareService;

import React from 'react';
import classNames from 'classnames';

import style from './TmoButton.module.css';

function TmoButton({
  className,
  icon,
  children,
  disabled,
  selected,
  size = 'md',
  type = 'primary',
  ...rest
}) {
  return (
    <button
      className={classNames(
        style.button,
        style[size],
        style[type],
        disabled && style.disabled,
        selected && style.selected,
        className
      )}
      disabled={disabled}
      selected={selected}
      {...rest}
    >
      {icon && <span className={style.icon}>{icon}</span>}
      {children}
    </button>
  );
}

export default TmoButton;

import React, { useState, useContext, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoCard,
  TmoInput,
  withModal,
  LabelWithInput,
  TmoRadioGroup,
  TmoDropDownSelect,
  TmoButtons as TmoButton,
  TmoH4,
  ConfirmationModalV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import { FaSpinner, IoIosArrowDown } from 'react-icons/all';

import config from '../../configs/config';
import Clipboard from 'components/Clipboard';
import Authorized from '../../auth/Authorized';
import { authClient, getMSALToken } from '../../auth/authClient';
import { SUA_ROLES, IS_AZURE_AUTH } from '../../utils/app_constants';
import { prettifyCamelCase } from '../../utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import {
  CHANNEL_ID,
  NON_TMO_CHANNEL_ID,
  NON_TMO_USER_CONSENT,
  TMO_USER_CONSENT,
  USER_TYPES
} from 'utils/coapConsts';
import userService from 'services/userService';
import StatusContext from 'context/StatusContext';

import style from './CoapSupport.module.css';
import upload_icon from '../../static/assets/icons/action/upload.svg';

const {
  schema,
  hostName,
  resources,
  httpMethods,
  headers,
  basePath
} = config.apiSettings.syncUpPlatform;

function WatchCoapSupport({ openModal }) {
  const [token, setToken] = useState();
  const [inputFields, setInputFields] = useState(NON_TMO_USER_CONSENT);
  const [selectedUserType, setSelectedUserType] = useState(USER_TYPES[0]);
  const [selectedChannelId, setSelectedChannelId] = useState(
    NON_TMO_CHANNEL_ID
  );
  const [loading, setLoading] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const selectOptions = CHANNEL_ID.map(o => ({
    key: o.name,
    value: o.product
  }));

  const getFullUrl = () => {
    return `${schema}://${hostName}${basePath.coreAdminV1}${
      selectedUserType === USER_TYPES[0]
        ? resources.coapWatchNonTmoCommand
        : resources.coapWatchTmoCommand
    }`;
  };

  const handleTypeChange = v => {
    setSelectedUserType(v);
    setSelectedChannelId(
      v === USER_TYPES[0] ? NON_TMO_CHANNEL_ID : CHANNEL_ID[0].name
    );
  };
  const getFormattedRequest = (rowData, isCurl = false) => {
    const obj = {};
    const otherKeys = {};
    const requestKey =
      selectedUserType === USER_TYPES[1]
        ? 'presTmoUserRequestModels'
        : 'presNonTmoUserRequestModels';
    rowData.forEach((item, index) => {
      const value =
        isCurl && item.placeHolder ? `{{${item.placeHolder}}}` : item.value;
      if (selectedUserType === USER_TYPES[1] && index <= 2) {
        otherKeys[item.name] = value;
      } else {
        obj[item.name] = value;
      }
    });
    const requestBody = {
      [requestKey]: [
        {
          ...(selectedUserType === USER_TYPES[1] && { ...otherKeys }),
          consent: [{ ...obj }]
        }
      ]
    };
    return requestBody;
  };

  const getCurlRequest = () => {
    const rowData =
      selectedUserType === USER_TYPES[1]
        ? TMO_USER_CONSENT
        : NON_TMO_USER_CONSENT;
    const defaultRequestBody = getFormattedRequest(rowData, true);
    return `curl --location --request POST '${getFullUrl()}'\
    --header '${headers.subscriptionKey.key}: ${headers.subscriptionKey.value}'\
    --header 'Content-Type: application/json'\
    --header  'ChannelId: ${selectedChannelId}'\
    --header 'send-downstream: true'\
    --header 'save-consent: true'\
    --header 'Authorization: ${token}'\
    --data-raw '${JSON.stringify(defaultRequestBody)}'`;
  };
  useEffect(() => {
    let formData =
      selectedUserType === USER_TYPES[0]
        ? NON_TMO_USER_CONSENT
        : TMO_USER_CONSENT;
    formData = JSON.parse(JSON.stringify(formData));
    let formFields = [];
    formData.forEach(item => {
      formFields = [
        ...formFields,
        {
          name: item.name,
          value: item.placeHolder ? null : item.value,
          placeHolder: item.placeHolder,
          required: item.required
        }
      ];
    });
    setInputFields(formFields);
  }, [selectedUserType]);

  const handleSubmit = async event => {
    event.preventDefault();
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      setLoading(true);
      const requestBody = getFormattedRequest(inputFields);
      try {
        await userService.executeUserConsentCOAPCommand({
          data: requestBody,
          channelId: selectedChannelId,
          isTmoUser: selectedUserType === USER_TYPES[1]
        });
        setLoading(false);
        addSuccessMessage({
          message: `Command Sent Successfully`
        });
      } catch (error) {
        setLoading(false);
        addErrorMessage({ error });
      }
    }
  };
  const handleFormChange = (index, v) => {
    let data = [...inputFields];
    data[index].value = v;
    setInputFields(data);
  };

  useEffect(() => {
    const fetchToken = async () => {
      const token = IS_AZURE_AUTH
        ? await getMSALToken()
        : authClient.getIdToken();
      setToken(token);
    };
    fetchToken();
  }, []);
  return (
    <Authorized
      roles={[SUA_ROLES.ADMIN, SUA_ROLES.MOBILE_APP_DEVS]}
      willShowErrorModal
    >
      <div className={style.top_content}>
        <TmoRadioGroup
          name="modeSelection"
          data={{
            radioGrp: 'iconPosition-radio',
            defaultValue: USER_TYPES[0],
            options: USER_TYPES
          }}
          onChange={handleTypeChange}
        />
        {selectedUserType === USER_TYPES[1] && (
          <div className={style.product_dropdown}>
            <label className={style.product_label}> Product </label>
            <TmoDropDownSelect
              optionsList={selectOptions}
              onChange={setSelectedChannelId}
              renderIcon={<IoIosArrowDown />}
              defaultSelectedValue={selectOptions[0]}
            />
          </div>
        )}

        <ItemDetailList
          rowSet={[{ Endpoint: httpMethods.POST + ' ' + getFullUrl() }]}
        />
      </div>
      <TmoCard>
        <form onSubmit={handleSubmit}>
          <div className={style.input_grid}>
            {inputFields &&
              inputFields.map((input, index) => (
                <LabelWithInput
                  className={style.input}
                  labelText={
                    input.displayName
                      ? input.displayName
                      : prettifyCamelCase(input.name)
                  }
                  name={input.name}
                  key={input.name}
                  formComponent={TmoInput}
                  value={input.value}
                  placeholder={input.placeHolder}
                  inputType="primary"
                  title={input.value}
                  required={input.required}
                  onChange={v => handleFormChange(index, v)}
                />
              ))}
          </div>

          <div className={style.button_wrapper}>
            {loading ? (
              <TmoButton type="magenta_primary">
                <FaSpinner className={style.button_spinner} />{' '}
                <span> loading </span>
              </TmoButton>
            ) : (
              <TmoButton
                type="magenta_primary"
                className={style.submit_button}
                buttonType="submit"
                icon={<ReactSVG src={upload_icon} alt="Execute" />}
              >
                Execute
              </TmoButton>
            )}
          </div>
        </form>
        <TmoH4> Curl Request </TmoH4>
        <Clipboard
          value={getCurlRequest()}
          clipboardSty={style.clipboard}
          buttonPositon="bottom"
        />
      </TmoCard>
      <ConfirmationModalV2
        message={`Are you sure you want to execute command`}
        title={`Execute Command`}
        acceptButtonText="Yes,Execute"
        type="danger"
        confirmInput
        usePortal={false}
        modalType="delete"
        confirmText="Execute"
      />
    </Authorized>
  );
}

export default withModal(WatchCoapSupport);

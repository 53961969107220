import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';
import {
  withModal,
  ConfirmationModalV2,
  TmoButtons as TmoButton
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import faqContentService from '../../services/faqContentService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import useWindowWidth from '../../hooks/useWindowWidth';
import { DIMENSIONS } from '../../utils/app_constants';

import style from './DeleteBulkFAQ.module.css';
import deleteIcon from '../../static/assets/icons/action/delete.svg';

function DeleteBulkFAQ({
  openModal,
  data,
  selectedFaqs,
  clearSelection,
  onDataChange
}) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const width = useWindowWidth();
  const handleRemoveFaq = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      let selectedData = [];
      data.forEach(category => {
        let tempIds = category.faqList.filter(e => e.selected).map(e => e.id);
        if (tempIds && tempIds.length > 0) {
          selectedData = [...selectedData, ...tempIds];
        }
      });
      let ids = selectedData.join(',');
      trackCustomEvents(`Bulk Faq Delete`, {
        faqIds: ids,
        product: syncUpProductContext.syncUpAppName
      });
      try {
        await faqContentService.deleteBulkFAQ({
          faqIds: ids,
          product: syncUpProductContext.syncUpAppName
        });
        onDataChange();
        clearSelection();
        addSuccessMessage({
          message: `Successfully deleted ${selectedFaqs} FAQs`
        });
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };
  const deleteMessage = (
    <>
      Are you sure you want to remove following FAQs: <br />
      {data &&
        data.map((e, i) => (
          <React.Fragment key={`bulk-delete-faq-${i}`}>
            {e && e.selected && (
              <>
                <strong>{e.title}</strong>
                <br />
              </>
            )}
          </React.Fragment>
        ))}
    </>
  );
  return (
    <>
      <div className={style.delete_row_wrapper}>
        <TmoButton
          onClick={clearSelection}
          className={style.action_button}
          type="magenta_primary"
        >
          Cancel
        </TmoButton>
        <TmoButton
          icon={<ReactSVG src={deleteIcon} />}
          onClick={handleRemoveFaq}
          className={style.action_button}
          disabled={!selectedFaqs}
          type="magenta_secondary"
          position={width <= DIMENSIONS.MOBILE_WIDTH && 'top'}
        >
          Delete {selectedFaqs} FAQ's
        </TmoButton>
      </div>
      <ConfirmationModalV2
        message={deleteMessage}
        title="Confirm FAQ Removal"
        acceptButtonText="Delete"
        type="danger"
        confirmInput
        usePortal={false}
        modalType="delete"
        confirmText="delete"
      />
    </>
  );
}

export default withModal(DeleteBulkFAQ);

import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';

const deviceRepository = {
  getLogsByIMEI: async ({
    logsRequestObj,
    syncUpAppName,
    queryString = '',
    responseStatus = false
  }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;
    let query = '';
    if (queryString) {
      query = queryString;
    } else {
      const { hardwareId, timeRange } = logsRequestObj;
      query = `hardware-id=${hardwareId}&start-date-time=${
        timeRange['start-date-time']
      }&end-date-time=${timeRange['end-date-time']}`;
    }

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.logs}`.replace(':query', query),

      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getDevicesByUserId: async ({
    userId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, headers, basePath } = config.apiSettings.syncUpPlatform;

    switch (syncUpAppName) {
      case 'DRIVE':
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: resources.driveDevice,
          basePath: basePath.platformDrive,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName,
            [headers.userImpersonation.key]: userId
          }
        });

      default:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: `${resources.userDevices}`.replace(':userId', userId),
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName
          }
        });
    }
  },
  claimDriveDevice: async ({
    userId,
    deviceId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      headers,
      basePath,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      urlSuffix: `${resources.driveClaimDevice}`.replace(':deviceId', deviceId),
      basePath: basePath.platformDrive,
      httpMethod: httpMethods.POST,
      responseStatus: responseStatus,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getPrivacyStatus: async ({
    userId,
    deviceId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      basePath,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      urlSuffix: `${resources.privacyStatus}`.replace(':deviceId', deviceId),
      basePath: basePath.adminDrive,
      httpMethod: httpMethods.GET,
      responseStatus: responseStatus,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getSensorHistory: async ({
    userId,
    deviceId,
    sensorType,
    query,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    const urlSuffix = `${resources.sensorHistory}${query}`
      .replace(':userId', userId)
      .replace(':deviceId', deviceId)
      .replace(':sensorType', sensorType);

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  unregisterDevice: async ({
    userId,
    deviceId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    switch (syncUpAppName) {
      case 'DRIVE':
        return await syncUpPlatformRequest({
          urlSuffix: `${resources.driveClaimDevice}`.replace(
            ':deviceId',
            deviceId
          ),
          basePath: basePath.platformDrive,
          httpMethod: httpMethods.DELETE,
          responseStatus: responseStatus,
          headers: {
            [headers.SyncUpProduct.key]: syncUpAppName,
            [headers.userImpersonation.key]: userId
          }
        });
      default:
        return await syncUpPlatformRequest({
          responseStatus: responseStatus,
          urlSuffix: resources.device.replace(
            ':deviceId',
            deviceId,
            syncUpAppName
          ),
          basePath: basePath.platform,
          httpMethod: httpMethods.DELETE,
          headers: {
            [headers.userImpersonation.key]: userId,
            Accept: headers.contentTypeJSON.value,
            [headers.SyncUpProduct.key]: syncUpAppName
          }
        });
    }
  },

  removeRealDriveDevice: async ({
    userId,
    deviceId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.driveClaimDevice}`.replace(':deviceId', deviceId),
      basePath: basePath.adminDrive,
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  bulkUnregisterDevice: async ({
    syncUpAppName,
    hardwareIds,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.decommission,
      basePath: basePath.admin,
      httpMethod: httpMethods.POST,
      body: hardwareIds,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  bulkRecommissionrDevice: async ({
    body,
    hardwareIds,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.recommission,
      basePath: basePath.coreAdminV1,
      httpMethod: httpMethods.POST,
      body: body ? body : hardwareIds,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  bulkvalDeviceExistancerDevice: async ({
    body,
    hardwareIds,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.validateDeviceExistance,
      basePath: basePath.coreAdminV1,
      httpMethod: httpMethods.POST,
      body: body ? body : hardwareIds,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getDevicesByQuery: async ({ query, page, results, syncUpAppName }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: `${resources.deviceSearch}${query}&page=${page -
        1}&size=${results}`,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getSoftwareHardwareVersions: async () => {
    const { resources, basePath } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      basePath: basePath.analytics,
      urlSuffix: resources.versionCheck
    });
  },
  getRecentDevices: async () => {
    const { recentDevicesUrl } = config.apiSettings.azureFunctions;

    const response = await fetch(recentDevicesUrl);

    if (response.ok) {
      const responseText = await response.text();
      if (responseText) {
        return JSON.parse(responseText);
      }
      return;
    }
    throw Error(response.status);
  },
  getLostModeHistory: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.lostModeHistory}`.replace(':deviceId', deviceId),
      httpMethod: httpMethods.GET,
      basePath: responseStatus ? basePath.platform : basePath.admin,
      headers:
        responseStatus && userId
          ? {
              [headers.userImpersonation.key]: userId,
              [headers.SyncUpProduct.key]: syncUpAppName
            }
          : {}
    });
  },
  healthCheck: async ({
    userId,
    deviceId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { basePath, resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.healthCheck.replace(':deviceId', deviceId),
      basePath: basePath.platform,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getHardwareDetails: async ({
    imei,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.hardwareDetails}${imei}`,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getHardwareByQuery: async ({ query, page, results, syncUpAppName }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: `${resources.hardwareDetails}?imei=${query}&page=${page -
        1}&size=${results}`,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getIotHardwareByQuery: async ({ query, page, results, syncUpAppName }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: `${resources.iotHardwareDetails}/${query}`,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getDeviceTwin: async ({
    deviceId,
    syncUpAppName,
    responseStatus = false
  }) => {
    const { resources, headers } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.deviceTwin.replace(':deviceId', deviceId),
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  updateDeviceTrackingRate: async ({
    userId,
    responseStatus = false,
    body,
    deviceId,
    syncUpAppName,
    requestObj
  }) => {
    const {
      basePath,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.updateDeviceTrackingRate.replace(
        ':deviceId',
        deviceId
      ),
      basePath: basePath.platform,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      body: body ? body : requestObj
    });
  },
  updateDeviceLostMode: async ({
    body,
    userId,
    deviceId,
    syncUpAppName,
    requestObj,
    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.updateLostModeSensor.replace(':deviceId', deviceId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PUT,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      body: body ? body : requestObj
    });
  },
  uploadDeviceLogs: async ({
    userId,
    deviceId,
    syncUpAppName,

    responseStatus = false
  }) => {
    const {
      basePath,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.uploadDeviceLogs.replace(':deviceId', deviceId),
      basePath: basePath.admin,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  updateDeviceBuzzerSensor: async ({
    userId,
    responseStatus = false,
    body,
    deviceId,
    syncUpAppName,
    requestObj
  }) => {
    const {
      basePath,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.updateBuzzerSensor.replace(':deviceId', deviceId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PUT,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      body: body ? body : requestObj
    });
  },
  executeCOAPCommand: async ({
    syncUpAppName,
    responseStatus = false,
    body,
    requestObj
  }) => {
    const {
      basePath,
      services,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${services.internal}${resources.coaptrackercommand}`,
      basePath: basePath.adminTracker,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      body: body ? body : JSON.parse(requestObj)
    });
  },
  updateDeviceLightSensor: async ({
    userId,
    responseStatus = false,
    body,
    deviceId,
    syncUpAppName,
    requestObj
  }) => {
    const {
      basePath,
      resources,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.updateLightSensor.replace(':deviceId', deviceId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PUT,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      },
      body: body ? body : requestObj
    });
  },
  getDeviceById: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      urlSuffix: resources.device.replace(':deviceId', deviceId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateDeviceById: async ({
    responseStatus = false,
    deviceId,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      body: body,
      httpMethod: httpMethods.PATCH,
      urlSuffix: resources.device.replace(':deviceId', deviceId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  drieDeviceDecommission: async ({ body, responseStatus = false }) => {
    const {
      basePath,
      resources,
      httpMethods
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      urlSuffix: resources.driveDevicesDecommisson,
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      httpMethod: httpMethods.POST,
      body: body
    });
  },

  mergeThing: async ({
    responseStatus = false,
    thingIdFrom,
    thingIdInto,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platformDrive,
      responseStatus: responseStatus,
      body: body,
      httpMethod: httpMethods.DELETE,
      urlSuffix: resources.driveMergeThing
        .replace(':thingIdFrom', thingIdFrom)
        .replace(':thingIdInto', thingIdInto),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getDeviceSensorData: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.sensorDataByDeviceId.replace(':deviceId', deviceId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getDeviceSensorHistory: async ({
    responseStatus = false,
    deviceId,
    sensorId,
    query,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.sensorHistoryBySensorId
        .replace(':deviceId', deviceId)
        .replace(':sensorId', sensorId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getDeviceSensorByType: async ({
    responseStatus = false,
    deviceId,
    sensorType,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.sensorBySensorType
        .replace(':deviceId', deviceId)
        .replace(':sensorType', sensorType),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getDeviceEnabledHardware: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.enabledHardwareByDeviceId.replace(
        ':deviceId',
        deviceId
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getDeviceTrackingRate: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.updateDeviceTrackingRate.replace(
        ':deviceId',
        deviceId
      ),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getDeviceTemperature: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.temperatureByDeviceId.replace(':deviceId', deviceId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getDeviceActivity: async ({
    responseStatus = false,
    deviceId,
    userId,
    query,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix:
        resources.activityByDeviceId.replace(':deviceId', deviceId) + query,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  getDeviceRatePlan: async ({ responseStatus = false, imei, userId }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.checkRatePlanById.replace(':imei', imei),
      headers: { [headers.userImpersonation.key]: userId }
    });
  },

  getDeviceMotionLock: async ({
    responseStatus = false,
    deviceId,
    userId,
    syncUpAppName
  }) => {
    const { resources, basePath, headers } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.motionLockByDeviceId.replace(':deviceId', deviceId),
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deviceLocation: async ({
    responseStatus = false,
    userId,
    deviceId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.locationByDeviceId.replace(':deviceId', deviceId),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deviceOnboardingGrant: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.deviceOnboardingGrant,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deviceOnboardingPreCheck: async ({
    responseStatus = false,
    userId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.deviceOnboardingPrecheck,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deviceRatePlan: async ({
    responseStatus = false,
    userId,
    body,
    hardwareId,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.checkRatePlanbyHardwareId.replace(
        ':hardwareId',
        hardwareId
      ),
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  deviceMotionLock: async ({ responseStatus = false, userId, body }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      basePath: basePath.platform,
      urlSuffix: resources.motionLock,
      httpMethod: httpMethods.POST,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId
      }
    });
  },

  updateLostModeSensor: async ({
    userId,
    deviceId,
    body,
    responseStatus = false,
    syncUpAppName
  }) => {
    const {
      basePath,
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.updateLostModeSensor.replace(':deviceId', deviceId),
      basePath: basePath.platform,
      httpMethod: httpMethods.PUT,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateDeviceHardwarEnabled: async ({
    responseStatus = false,
    userId,
    deviceId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.changeEnabledHardwareByDevice.replace(
        ':deviceId',
        deviceId
      ),
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },

  updateDevice: async ({
    responseStatus = false,
    userId,
    deviceId,
    body,
    syncUpAppName
  }) => {
    const {
      resources,
      basePath,
      headers,
      httpMethods
    } = config.apiSettings.syncUpPlatform;
    return await syncUpPlatformRequest({
      basePath: basePath.platform,
      responseStatus: responseStatus,
      urlSuffix: resources.device.replace(':deviceId', deviceId),
      httpMethod: httpMethods.PATCH,
      body: body,
      headers: {
        [headers.userImpersonation.key]: userId,
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  }
};

export default deviceRepository;

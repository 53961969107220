import React, { useState, useCallback, useContext } from 'react';
import {
  TmoCard,
  withModal,
  TmoH1,
  PaginationV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import Fetcher from '../Fetcher';
import StatusContext from '../../context/StatusContext';
import FirmwareDetails from '../things/firmware/FirmwareDetails';
import firmwareService from '../../services/firmwareService';
import SyncUpProductContext from '../../context/SyncUpProductContext';

import style from '../../pages/FirmwarePage.module.css';

const resultsToShow = 18;

function Packages() {
  const [packages, setPackages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);

  const actionCallback = useCallback(() => {
    return firmwareService.getFirmwarePackages({
      page: currentPage,
      results: resultsToShow,
      product: syncUpProductContext.syncUpAppName
    });
  }, [currentPage, syncUpProductContext]);

  const handleRemovePackage = async id => {
    try {
      await firmwareService.deleteFirmwarePackage(id);
      setPackages(await actionCallback());
      addSuccessMessage({
        message: `Package id: ${id} removed successfully`
      });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const showPagination = packages?.page?.totalItems >= resultsToShow;

  return (
    <TmoCard>
      <TmoH1 flush>Firmware Packages</TmoH1>
      <Fetcher
        action={actionCallback}
        onLoad={setPackages}
        render={() =>
          packages ? (
            <>
              <div className={style.items}>
                {packages.map(item => (
                  <TmoCard key={item.id} className={style.item}>
                    <FirmwareDetails
                      onRemovePackage={handleRemovePackage}
                      details={item}
                    />
                  </TmoCard>
                ))}
              </div>
              {showPagination && (
                <PaginationV2
                  totalPages={packages.page.lastPage + 1}
                  selectedPageNumber={currentPage}
                  onPageChanged={currentPage => setCurrentPage(currentPage)}
                  maxPagesShown={resultsToShow}
                />
              )}
            </>
          ) : (
            'No Firmware Packages'
          )
        }
      />
    </TmoCard>
  );
}

export default withModal(Packages);

import firmwareRepository from '../repositories/firmwareRepository';
import { getFormattedDateTime } from '../utils/dateUtils';

const firmwareService = {
  getFirmwarePackages: async ({ page, results, product }) => {
    const res = await firmwareRepository.getFirmwarePackages({
      page,
      results,
      product
    });
    return res.data.filter(a => a.product === product);
  },
  getFirmwareVersionsFromPackages: async () => {
    const results = await firmwareRepository.getFirmwareVersionsFromPackages();
    return [...new Set(results.data.map(r => r.version))];
  },
  deleteFirmwarePackage: async id => {
    return await firmwareRepository.deleteFirmwarePackage(id);
  },
  getFirmwarePackageByImei: async imei => {
    const results = await firmwareRepository.getFirmwarePackageByImei(imei);
    return {
      ...results,
      expirationDateTime: getFormattedDateTime({
        dateTime: results['expirationDateTime']
      }),
      createdDateTime: getFormattedDateTime({
        dateTime: results['createdDateTime']
      }),
      modifiedDateTime: getFormattedDateTime({
        dateTime: results['modifiedDateTime']
      })
    };
  },
  postFirmwarePackage: async ({ body, syncUpAppName }) => {
    return await firmwareRepository.postFirmwarePackage({
      body,
      syncUpAppName
    });
  },
  getFirmwareGroups: async () => {
    return await firmwareRepository.getFirmwareGroups();
  },
  patchFirmwareGroup: async ({ body, groupId }) => {
    return await firmwareRepository.patchFirmwareGroup({ body, groupId });
  },
  addFirmwareGroup: async group => {
    return await firmwareRepository.addFirmwareGroup(group);
  },
  deleteFirmwareGroup: async groupId => {
    return await firmwareRepository.deleteFirmwareGroup(groupId);
  },
  getFirmwareDownloadStatusByImei: async imei => {
    return await firmwareRepository.getFirmwareDownloadStatusByImei(imei);
  },
  getFirmwareDownloadStatus: async ({ query, page, resultsSize }) => {
    return await firmwareRepository.getFirmwareDownloadStatus({
      query,
      page,
      resultsSize
    });
  }
};

export default firmwareService;

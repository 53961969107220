import React, { useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import ModalContext from '../../context/ModalContext';
import TmoH2 from '../controlWrappers/TmoH2';
import TmoSvg from '../uiHelpers/TmoSvg';
import { keyCodes } from '../../utils/keyboardUtils';

import style from './ModalV2.module.css';

function ModalV2({
  className,
  children,
  title,
  type,
  resetModal,
  portal = true,
  id,
  label,
  modalType = '',
  modalSize = 'lg'
}) {
  const overlayRef = useRef();
  const generatedId = uuidv4();
  const { isModalOpen, closeModal } = useContext(ModalContext);
  const [showAnimation, setShowAnimation] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      return;
    }

    const closeModalOnEsc = event => {
      if (event.keyCode === keyCodes.ESCAPE) {
        resetModal && resetModal();
        closeModal();
      }
    };

    document.addEventListener('keydown', closeModalOnEsc, false);

    return () => {
      document.removeEventListener('keydown', closeModalOnEsc, false);
    };
  }, [closeModal, isModalOpen, resetModal]);

  useEffect(() => {
    if (modalType === 'slide') {
      if (isModalOpen) {
        setModalVisible(true);
        document.body.style.overflow = 'hidden';
        isModalVisible &&
          setTimeout(() => {
            setShowAnimation(true);
          }, 50);
      } else {
        document.body.style.overflow = 'unset';
        setShowAnimation(false);
        setTimeout(() => {
          setModalVisible(false);
        }, 400);
      }
    } else {
      isModalOpen ? setModalVisible(true) : setModalVisible(false);
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen, isModalVisible, modalType]);

  const handleOverlayClick = e => {
    if (e.target === overlayRef.current) {
      resetModal && resetModal();
      closeModal();
    }
  };

  const content = isModalVisible ? (
    <div
      className={classNames(style.modal, className, style[modalType])}
      ref={overlayRef}
      onClick={handleOverlayClick}
      role="dialog"
      id={id || generatedId}
      aria-labelledby={label}
      aria-modal="true"
    >
      <div
        className={classNames(
          style.container,
          style[modalSize],
          showAnimation ? style.show : null
        )}
      >
        <div className={classNames(style.header, style[type])}>
          <TmoH2 className={style.title}>
            <span title={title}> {title} </span>
          </TmoH2>
          <TmoSvg
            svgId="x"
            className={style.close}
            onClick={() => {
              resetModal && resetModal();
              closeModal();
            }}
          />
        </div>
        <div className={style.content}>{children}</div>
      </div>
    </div>
  ) : null;

  return portal ? createPortal(content, document.body) : content;
}

export default ModalV2;

export const storageWrite = ({ key, value, storageType }) => {
  try {
    window[storageType][key] = value;
  } catch {}
};
export const storageRead = ({ key, storageType }) => {
  try {
    return window[storageType][key];
  } catch {}
};
export const storageClear = ({ storageType }) => {
  try {
    return window[storageType].clear();
  } catch {}
};
export const storageRemoveItem = ({ key, storageType }) => {
  try {
    return window[storageType].removeItem(key);
  } catch {}
};

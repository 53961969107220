import React from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import { TmoButtons as TmoButton } from '@tmobile/tmo-bps-syncup-web-component-lib';

import { FaFileDownload } from 'react-icons/all';
import download from '../static/assets/icons/download.svg';

function ExportDataAsFile({
  data,
  className,
  buttonText,
  fileName,
  fileExtension,
  isMagentaLink
}) {
  const exportToCSV = () => {
    if (!data || !data.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(data[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      data
        .map(row => {
          return keys
            .map(k => {
              let cell = row[k] === null || row[k] === undefined ? '' : row[k];
              cell =
                cell instanceof Date ? cell.toLocaleString() : cell.toString();
              if (cell.search(/([",\n])/g) >= 0) {
                cell = `"${cell}"`;
              }
              return cell;
            })
            .join(separator);
        })
        .join('\n');

    const blob = new Blob([csvContent], {
      type: `text/${fileExtension};charset=utf-8`
    });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${fileName}.${fileExtension}`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      {isMagentaLink ? (
        <TmoButton
          className={classNames(className)}
          onClick={() => exportToCSV(data, fileName)}
          icon={<ReactSVG src={download} />}
          type="magenta_link"
        >
          {buttonText ? buttonText : 'Export Data'}
        </TmoButton>
      ) : (
        <TmoButton
          className={classNames(className)}
          onClick={() => exportToCSV(data, fileName)}
          icon={<FaFileDownload />}
        >
          {buttonText ? buttonText : 'Export Data'}
        </TmoButton>
      )}
    </>
  );
}

export default ExportDataAsFile;

import React from 'react';
import classNames from 'classnames';
import { TmoCard, RawData } from '@tmobile/tmo-bps-syncup-web-component-lib';

import SensorNotAvailable from './SensorNotAvailable';
import TimeDisplay from '../uiHelpers/TimeDisplay';
import { prettifyCamelCase } from '../../utils/stringUtils';
import ItemDetailsModal from 'components/ItemDetailsModal';
import { GRID } from 'utils/app_constants';

import style from './SingleSensorDetails.module.css';
import rowStyle from '../ItemDetailList/ItemDetailRow.module.css';

function SingleSensorDetails({ sensorData, sensorName, index }) {
  if (!sensorData) {
    return <SensorNotAvailable sensorName={sensorName} />;
  }

  return (
    <div className={style.container}>
      {index && <span className={style.index}>{index}</span>}
      <div className={style.column}>
        {Object.entries(sensorData).map(([key, value], index) =>
          typeof value === 'object' &&
          Object.keys(value).length !== 0 &&
          value[0] !== 'NO_ERROR' ? (
            <div className={rowStyle.row} key={index}>
              <ItemDetailsModal
                buttonText={`View ${prettifyCamelCase(
                  key.replace('activity', '')
                )}`}
                label={`${prettifyCamelCase(key)} `}
                className={GRID.COLUMN_FORTH}
              >
                <div className={style.inner_object_container}>
                  {value.map((item, index) => (
                    <TmoCard className={style.inner_object} key={index}>
                      {Object.entries(item).map(([k, v], index) => (
                        <div className={rowStyle.row} key={index}>
                          <label className={style.label}>
                            {prettifyCamelCase(k)}
                          </label>
                          <span className={style.value}>{v}</span>
                        </div>
                      ))}
                    </TmoCard>
                  ))}
                </div>
              </ItemDetailsModal>
            </div>
          ) : (
            (!!value?.length || typeof value == 'number') && (
              <div
                key={index}
                className={classNames(
                  rowStyle.row,
                  index < 2 && rowStyle.row_half,
                  style[key]
                )}
              >
                <label className={style.label}>{prettifyCamelCase(key)}</label>
                <span className={style.value}>{value}</span>
              </div>
            )
          )
        )}
        <div className={rowStyle.row}>
          <ItemDetailsModal
            buttonText={'View Dates'}
            label="Updated At"
            className={GRID.COLUMN_FORTH}
          >
            <TimeDisplay
              timestamp={sensorData.updateDate}
              type="primary_time_display"
            />
          </ItemDetailsModal>
        </div>
        <div className={rowStyle.row}>
          <ItemDetailsModal
            buttonText={'View Timestamp'}
            label="Timestamp"
            className={GRID.COLUMN_FORTH}
          >
            <TimeDisplay
              timestamp={sensorData.timestamp}
              type="primary_time_display"
            />
          </ItemDetailsModal>
        </div>
      </div>
      <div className={style.column}>
        <RawData
          rawObject={sensorData}
          title={`Full ${sensorData.type} Sensor Details`}
          className={style.raw_data}
          buttonType="magenta_link"
          usePortal={false}
          modalType={'slide'}
        />
      </div>
    </div>
  );
}

export default SingleSensorDetails;

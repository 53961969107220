import React, { useState, useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import {
  TmoButtons as TmoButton,
  TmoInput
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import { capitalCase } from '../../utils/helper_functions';

import style from './TmoCustomSelect.module.css';

import selectDownIcon from '../../static/assets/icons/arrow_down_icon.svg';
import selectUpIcon from '../../static/assets/icons/arrow_up_icon.svg';
import checkIcon from '../../static/assets/icons/icon_checked.svg';
import closeIcon from '../../static/assets/icons/close_icon.svg';
import { FaPlus } from 'react-icons/all';

export default function TmoCustomSelect({
  optionsList,
  defaultText,
  placeholder,
  addToTop,
  onChange
}) {
  const [selectState, setSelectState] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(defaultText);
  const [showInput, setShowInput] = useState(false);
  const node = useRef();
  const selectedOption = useRef();
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setSelectedValue(defaultText);
  }, [defaultText]);

  const handleClickOutside = e => {
    if (node.current.contains(e.target)) {
      return;
    }
    setSelectState(false);
  };

  const handleListDisplay = () => {
    setSelectState(!selectState);
  };
  const renderCustomDetails = () => {
    return (
      <div className={style.custom_add}>
        {showInput ? (
          <>
            <TmoInput
              type="text"
              placeholder={placeholder}
              onChange={setInputValue}
              value={inputValue}
              inputType="primary"
            />
            <ReactSVG
              src={checkIcon}
              className={style.check_icon}
              aria-label="Check Icon"
              role="button"
              onClick={() => {
                optionsList.push({
                  value: capitalCase(inputValue),
                  key: inputValue
                });
                setSelectedValue(capitalCase(inputValue));
                onChange(inputValue?.toLocaleLowerCase());
                setInputValue('');
                setShowInput(!showInput);
                setSelectState(false);
              }}
            />
            <ReactSVG
              src={closeIcon}
              className={style.close_icon}
              aria-label="Close Icon"
              role="button"
              onClick={() => {
                setInputValue('');
                setShowInput(!showInput);
              }}
            />
          </>
        ) : (
          <TmoButton
            type="magenta_secondary"
            icon={<FaPlus />}
            onClick={() => setShowInput(!showInput)}
          >
            {placeholder}
          </TmoButton>
        )}
      </div>
    );
  };
  const handleOptionClick = e => {
    let key = e?.target ? e?.target?.getAttribute('data-name') : '';
    let value = e?.target ? e?.target?.getAttribute('data-value') : '';
    value && setSelectedValue(value);
    onChange(key);
    setSelectState(false);
  };

  return (
    <div className={style.custom_select_wrapper} ref={node}>
      <div
        className={classNames(style.input, style.selected)}
        onClick={handleListDisplay}
        role="combobox"
        aria-expanded="false"
        aria-controls="combobox"
      >
        <div aria-label={selectedValue} role="listbox">
          {selectedValue}
        </div>

        <ReactSVG
          src={selectState ? selectUpIcon : selectDownIcon}
          className={style.icon}
          aria-label="Select Icon"
          role="button"
        />
      </div>
      {selectState && (
        <div
          className={style.select_options}
          role="listitem"
          aria-label="Select Options"
        >
          {addToTop && renderCustomDetails()}
          {optionsList?.length > 0 &&
            optionsList.map((e, index) => {
              return (
                <div
                  className={classNames(
                    style.option_list,
                    e.value?.toString().replace(/ /g, '') ===
                      defaultText.toString().replace(/ /g, '')
                      ? style.selected
                      : null
                  )}
                  data-name={e.key}
                  ref={
                    e.value?.toString().replace(/ /g, '') ===
                    defaultText.toString().replace(/ /g, '')
                      ? selectedOption
                      : null
                  }
                  data-value={e.value}
                  key={`${e}-${index}`}
                  onClick={handleOptionClick}
                  title={e.value}
                  role="listitem"
                  aria-label={e.value}
                >
                  {e.value}
                </div>
              );
            })}
          {!addToTop && renderCustomDetails()}
        </div>
      )}
    </div>
  );
}

import React, { useState, useContext, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
  TmoCard,
  TmoButtons,
  RawData,
  withModal,
  ConfirmationModalV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import { prettifyCamelCase } from 'utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import userService from '../../services/userService';
import Fetcher from '../Fetcher';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import StatusContext from '../../context/StatusContext';
import LocationGroupsAvatarDelete from './LocationGroupsAvatarDelete';

import style from './LocationGroups.module.css';

import userIcon from '../../static/assets/icons/leftpanel/auth_user.svg';
import mapIcon from '../../static/assets/icons/map-pin.svg';
import delete_icon from '../../static/assets/icons/action/delete.svg';

function LocationGroups({ group, openModal, history, forceRefresh }) {
  const { id, name, avatar } = group;
  const [members, setMembers] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);

  const actionCallback = useCallback(
    () =>
      userService.getPhoneLocationGroupUsers({
        userId,
        groupId: id,
        syncUpAppName: product
      }),
    [userId, id, product]
  );
  const handleGroupDelete = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Phone Location Group`, {
        groupId: id
      });
      try {
        await userService.deletePhoneLocationGroup({
          groupId: id,
          userId: userId,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Group id: ${id} removed successfully`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };
  return (
    <TmoCard shadow={false} key={id}>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <div className={style.avatar_wrapper}>
            <div className={style.avatar}>
              {avatar ? (
                <img className={style.avatar_img} src={avatar} alt="User" />
              ) : (
                name?.charAt(0).toUpperCase()
              )}
            </div>
            {avatar && (
              <div className={style.avatar_delete}>
                <LocationGroupsAvatarDelete
                  group={group}
                  forceRefresh={forceRefresh}
                />
              </div>
            )}
          </div>
          <div className={style.label_wrapper}>
            <label
              className={style.display_name}
              title={prettifyCamelCase(name.toLowerCase())}
            >
              {prettifyCamelCase(name.toLowerCase())}
            </label>
            <Fetcher
              action={actionCallback}
              onLoad={setMembers}
              loadingText={true}
              render={e =>
                members?.length ? (
                  <span className={style.product}>
                    {members.length === 0
                      ? `No Members are available`
                      : `${members.length} Group ${
                          members.length === 1 ? 'Member is' : 'Members are'
                        } available`}
                  </span>
                ) : (
                  <span className={style.product}>
                    No Members are available
                  </span>
                )
              }
            />
          </div>
        </div>
        <div className={style.action_controls}>
          <TmoButtons
            onClick={handleGroupDelete}
            className={style.button_remove}
            type="magenta_secondary"
            tooltip="Delete"
            icon={<ReactSVG src={delete_icon} />}
          />
          <ConfirmationModalV2
            modalType="delete"
            message={<>Are you sure you want to delete</>}
            title="Delete Group"
            acceptButtonText="Yes,Delete"
            type="danger"
            usePortal={false}
            confirmInput
            buttonIcon={<ReactSVG src={delete_icon} />}
          />
          <RawData
            rawObject={group}
            tooltip="Group Object"
            title={`Group Object for ${name}`}
            className={style.object}
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_primary_icon"
            type=" "
          />
        </div>
      </div>
      <div className={style.content} key={id}>
        <ItemDetailList rowSet={[{ Id: id }, { Name: name }]} />
      </div>
      <div className={style.action_controls}>
        <TmoButtons
          onClick={() => history.push(`phone-location-groups/${id}/users`)}
          className={style.action_button}
          type="magenta_link"
          icon={<ReactSVG src={userIcon} />}
        >
          Members
        </TmoButtons>
        <TmoButtons
          onClick={() =>
            history.push(`phone-location-groups/${id}/virtual-boundary`)
          }
          className={style.action_button}
          type="magenta_link"
          icon={<ReactSVG src={mapIcon} />}
        >
          Virtual Boundary
        </TmoButtons>
      </div>
    </TmoCard>
  );
}

export default withModal(withRouter(LocationGroups));

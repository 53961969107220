import React from 'react';
import classNames from 'classnames';

import { StatusText, TmoCard } from '@tmobile/tmo-bps-syncup-web-component-lib';
import APIActions from './APIActions';

import style from './APICard.module.css';
import gridStyle from '../../components/ItemDetailList/ItemDetailList.module.css';

const APICard = ({ api, onExecuteApi }) => {
  const {
    name,
    serviceName,
    description,
    methodType,
    status,
    APIRoute,
    apiDetails
  } = api;
  return (
    <TmoCard className={style.container}>
      <div className={style.service_title}> {name} </div>
      <span>{serviceName} </span>
      <div className={style.content}>
        <div className={classNames(gridStyle.row, gridStyle.column_1)}>
          <label className={gridStyle.label}> Description </label>
          <span className={gridStyle.value}> {description} </span>
        </div>

        <div className={classNames(gridStyle.row, gridStyle.column_2)}>
          <label className={gridStyle.label}> Method </label>
          <span className={gridStyle.value}> {methodType} </span>
        </div>
        <div className={classNames(gridStyle.row, gridStyle.column_2)}>
          <label className={gridStyle.label}> Status </label>
          <span className={gridStyle.value}>
            {status ? (
              <StatusText
                status={
                  status >= 200 && status < 300
                    ? 'success'
                    : status === 404
                    ? 'warning'
                    : 'alert'
                }
              >
                {status}
              </StatusText>
            ) : (
              <> - </>
            )}
          </span>
        </div>
        <div className={classNames(gridStyle.row, gridStyle.column_1)}>
          <label className={gridStyle.label}> API Route </label>
          <span className={gridStyle.value}> {APIRoute} </span>
        </div>
      </div>
      <div className={style.footer_action}>
        {apiDetails && (
          <APIActions item={api} isCardView onExecuteApi={onExecuteApi} />
        )}
      </div>
    </TmoCard>
  );
};

export default APICard;

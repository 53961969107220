import React, { useContext } from 'react';
import { ReactSVG } from 'react-svg';

import {
  ConfirmationModalV2,
  TmoButtons as TmoButton,
  Tooltip,
  withModal
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import ActiveUserTabContext from 'context/ActiveUserTabContext';
import StatusContext from 'context/StatusContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import deleteIcon from '../../static/assets/icons/action/delete.svg';
import userService from 'services/userService';

const DeleteTask = ({ id, title, openModal, forceRefresh, isCardView }) => {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const handleRemoveServiceClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Remove Task`, {
        userId: userId,
        userTaskId: id,
        syncUpAppName: product
      });
      try {
        await userService.deleteUserTask({
          userId: userId,
          userTaskId: id,
          syncUpAppName: product
        });
        addSuccessMessage({
          message: `Successfully deleted user task`
        });
        forceRefresh();
      } catch (error) {
        addErrorMessage({ error });
      }
    }
  };

  return (
    <>
      {/* To-DO enable delete button when delete API is ready */}
      <Tooltip tooltip="Delete" position={isCardView ? 'bottom' : 'left'}>
        <TmoButton
          disabled
          onClick={handleRemoveServiceClicked}
          type={isCardView ? 'magenta_secondary' : 'magenta_link'}
          icon={<ReactSVG src={deleteIcon} />}
        />
      </Tooltip>
      <ConfirmationModalV2
        modalType="delete"
        message={`Are you sure you want to remove task ${title}?`}
        title="Confirm Service Removal"
        acceptButtonText="Yes,Delete"
        type="danger"
        usePortal={false}
        confirmInput
      />
    </>
  );
};

export default withModal(DeleteTask);

import React, { useContext } from 'react';
import { IFrame as Iframe, TmoCard } from '@tmobile/tmo-bps-syncup-web-component-lib';

import config from '../../../configs/config';
import SyncUpProductContext from '../../../context/SyncUpProductContext';

const {
  iframeLaunchStatsWatchURL,
  iframeLaunchStatsDriveURL
} = config.powerBiSettings;

function LaunchStats() {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const iframe = () => {
    switch (syncUpProductContext.syncUpAppName) {
      case 'KIDSWATCH':
        return iframeLaunchStatsWatchURL;
      case 'DRIVE':
        return iframeLaunchStatsDriveURL;
      default:
        return '';
    }
  };

  return (
    <TmoCard>
      <Iframe src={iframe()} width="100%" height="836px" />
    </TmoCard>
  );
}

export default LaunchStats;

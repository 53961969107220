import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import {
  FaInfoCircle,
  FaCheck,
  FaExclamationTriangle,
  FaTimesCircle
} from 'react-icons/fa';
import { STATUS_TYPE } from '../../utils/app_constants';

import style from './Message.module.css';

const DISPLAY_MODE = {
  SHOWN: 'shown',
  EXITING: 'exiting',
  HIDDEN: 'hidden'
};

function Message({ message, details, type = STATUS_TYPE.ERROR }) {
  const [displayMode, setDisplayMode] = useState(
    message ? DISPLAY_MODE.SHOWN : DISPLAY_MODE.HIDDEN
  );
  useEffect(() => {
    if (displayMode === DISPLAY_MODE.SHOWN) {
      setTimeout(() => setDisplayMode(DISPLAY_MODE.EXITING), 5000);
    }
    if (displayMode === DISPLAY_MODE.EXITING) {
      setTimeout(() => setDisplayMode(DISPLAY_MODE.HIDDEN), 200); // Keep in sync with css animation
    }
  }, [displayMode, type]);

  if (displayMode === DISPLAY_MODE.HIDDEN) {
    return null;
  }

  const getMessageIcon = () => {
    switch (type) {
      case STATUS_TYPE.SUCCESS:
        return <FaCheck className={style.icon} />;
      case STATUS_TYPE.INFO:
        return <FaInfoCircle className={style.icon} />;
      case STATUS_TYPE.WARNING:
        return <FaExclamationTriangle className={style.icon} />;
      case STATUS_TYPE.ERROR:
        return <FaTimesCircle className={style.icon} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames(style.container, style[displayMode], style[type])}
    >
      <div className={style.header}>
        {getMessageIcon()}
        <div className={style.message}>{message}</div>
      </div>
      {details && (
        <details className={style.details}>
          <summary>Details</summary>
          <div className={style.details}>{details}</div>
        </details>
      )}
    </div>
  );
}

export default Message;

import React, { useCallback, useState } from 'react';

import { APP_PLATFORM_APPTENTIVE } from '../../../utils/app_constants';
import Fetcher from '../../../components/Fetcher';
import analyticService from '../../../services/analyticsService';
import { utcDate } from '../../../utils/dateUtils';
import { PIE_CHART_OPTIONS } from '../../../utils/chart_constants';
import { chartToolTip } from '../../../utils/helper_functions';
import Highcharts from 'highcharts';
import Chart from '../../charts/Chart';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

import style from './Ratings.module.css';

function Ratings() {
  const [iosRatings, setIosRatings] = useState();
  const [androidRatings, setAndroidRatings] = useState();
  const appPlatform = APP_PLATFORM_APPTENTIVE;
  const startDate = utcDate;

  const actionIOSCallback = useCallback(
    () =>
      analyticService.getApptentiveRatings({
        appPlatform: appPlatform.IOS,
        startDate
      }),
    [appPlatform.IOS, startDate]
  );

  const actionAndroidCallback = useCallback(
    () =>
      analyticService.getApptentiveRatings({
        appPlatform: appPlatform.ANDROID,
        startDate
      }),
    [appPlatform.ANDROID, startDate]
  );

  const prepareChartOption = ratingData => {
    return {
      ...PIE_CHART_OPTIONS,
      series: [
        {
          type: 'pie',
          name: 'Ratings',
          color: '#e20074',
          data: Object.entries(ratingData.ratings[0].all_version.counts)
        }
      ],
      tooltip: {
        formatter: function() {
          return chartToolTip({ Ratings: this.key, Count: this.y });
        }
      }
    };
  };

  return (
    <>
      <PageHeader title={'Ratings'} analyticsHeader />
      <div className={style.row}>
        <div className={style.column}>
          <Fetcher
            action={actionIOSCallback}
            onLoad={setIosRatings}
            render={() =>
              iosRatings && iosRatings.ratings[0].all_version.average ? (
                <>
                  <span className={style.title}>Average iOS Rating:</span>
                  <span className={style.label}>
                    {iosRatings.ratings[0].all_version.average.toFixed(2)}
                  </span>
                  <Chart
                    options={prepareChartOption(iosRatings)}
                    highcharts={Highcharts}
                  />
                </>
              ) : (
                'No Results'
              )
            }
          />
        </div>
        <div className={style.column}>
          <Fetcher
            action={actionAndroidCallback}
            onLoad={setAndroidRatings}
            render={() =>
              androidRatings &&
              androidRatings.ratings[0].all_version.average ? (
                <>
                  <span className={style.title}>Average Android Rating:</span>
                  <span className={style.label}>
                    {androidRatings.ratings[0].all_version.average.toFixed(2)}
                  </span>
                  <Chart
                    options={prepareChartOption(androidRatings)}
                    highcharts={Highcharts}
                  />
                </>
              ) : (
                'No Results'
              )
            }
          />
        </div>
      </div>
    </>
  );
}

export default Ratings;

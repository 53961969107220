import React, { useState, useContext, useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import {
  withModal,
  TmoButtons as TmoButton
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import userService from '../../services/userService';
import Location from './Location';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';

import style from './PhoneLocation.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';

function PhoneLocation({ openModal }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [phoneLocation, setPhoneLocation] = useState([]);
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());

  const actionCallback = useCallback(
    () =>
      userService.getPhoneLocationDetails({
        userId,
        syncUpAppName: product
      }),
    [userId, product]
  );

  return (
    <>
      <PageHeader
        title={'Phone Location'}
        tabHeader={true}
        subtitle={
          phoneLocation.length > 1 &&
          `${phoneLocation.length} Phone locations are available`
        }
      >
        <TmoButton
          className={style.button}
          onClick={forceRefresh}
          type="magenta_secondary"
          tooltip="Refresh"
          icon={<ReactSVG src={refreshIcon} />}
        />
      </PageHeader>
      <>
        <Fetcher
          key={refreshKey}
          action={actionCallback}
          onLoad={setPhoneLocation}
          render={() =>
            phoneLocation?.length > 0 ? (
              <div className={style.items}>
                {phoneLocation.map(location => (
                  <Location location={location} forceRefresh={forceRefresh} />
                ))}
              </div>
            ) : (
              <span className={style.message}>
                User does not have phone location
              </span>
            )
          }
        />
      </>
    </>
  );
}

export default withModal(PhoneLocation);

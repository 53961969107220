import React, { useState, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { RawData } from '@tmobile/tmo-bps-syncup-web-component-lib';

import userService from '../../../services/userService';
import ActiveUserTabContext from '../../../context/ActiveUserTabContext';
import appConfig from '../../../configs/config';
import Fetcher from '../../Fetcher';
import LocationSensorDetails from './LocationSensorDetails';
import SingleLocationSafeZone from '../../map/SingleLocationSafeZone';
import LocationHistorySafeZone from '../../map/LocationHistorySafeZone';
import {
  prepareSafeZoneAndLocationHistories,
  prepareSafeZonePlottingData
} from '../../../utils/SafeZoneHelper';
import { getMarkerCenter } from '../../../utils/mapUtils';
import { mapStyle } from '../../../utils/app_constants';

import SensorNotAvailable from '../SensorNotAvailable';

import style from './LocationSensorSafeZone.module.css';

const { googleMapSettings, reactGoogleMaps } = appConfig;

function LocationSensorSafeZone({ locationSensor, onFilterChange }) {
  const [safeZones, setSafeZones] = useState();
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const actionCallback = useCallback(
    () =>
      userService.getUserSafeZones({
        id: userId,
        syncUpAppName: product
      }),
    [userId, product]
  );

  if (!locationSensor) {
    return <SensorNotAvailable sensorName="Location" />;
  }

  const mapContainer = <div className={style.loading_element} />;
  const zoom = 7;

  const {
    altitude,
    latitude,
    longitude,
    radius,
    speed,
    timestamp,
    updateDate,
    source,
    deviceId,
    id
  } = locationSensor;

  const singleThingAsCenter = {
    latitude: latitude,
    longitude: longitude,
    updateDate: updateDate,
    source: source,
    systemDate: timestamp,
    radius: radius,
    deviceId: deviceId,
    id: id,
    speed: speed,
    altitude: altitude
  };

  const mergeLocationSafezoneObject = (locationObject, safeZonesObject) => {
    if (safeZonesObject) {
      const petSafeZones = safeZonesObject.filter(safeZone =>
        safeZone.deviceIds.includes(locationObject.deviceId)
      );
      return { ...locationObject, ...petSafeZones };
    }
    return locationObject;
  };
  return (
    <div className={style.container}>
      {!locationSensor.length && (
        <LocationSensorDetails
          sensor={singleThingAsCenter}
          safeZones={safeZones}
        />
      )}
      <div className={style.row}>
        <div
          className={classNames(
            locationSensor.length ? style.history_map : style.latest_map,
            style.map
          )}
        >
          <Fetcher
            action={actionCallback}
            onLoad={setSafeZones}
            render={() =>
              safeZones ? (
                <>
                  {!locationSensor.length ? (
                    <SingleLocationSafeZone
                      center={getMarkerCenter(singleThingAsCenter)}
                      zoom={zoom}
                      places={prepareSafeZonePlottingData(
                        safeZones,
                        singleThingAsCenter
                      )}
                      mapStyles={mapStyle}
                      googleMapURL={`${reactGoogleMaps.url}${googleMapSettings.apiKey}`}
                      loadingElement={mapContainer}
                      mapElement={mapContainer}
                      containerElement={mapContainer}
                    />
                  ) : (
                    <LocationHistorySafeZone
                      center={getMarkerCenter(locationSensor[0])}
                      zoom={zoom}
                      places={prepareSafeZoneAndLocationHistories(
                        safeZones,
                        locationSensor
                      )}
                      mapStyles={mapStyle}
                      googleMapURL={`${reactGoogleMaps.url}${googleMapSettings.apiKey}`}
                      loadingElement={mapContainer}
                      mapElement={mapContainer}
                      containerElement={mapContainer}
                      onChange={onFilterChange}
                    />
                  )}
                </>
              ) : (
                'No Results'
              )
            }
          />
        </div>
      </div>
      {!locationSensor.length && (
        <div className={style.data_wrapper}>
          <RawData
            rawObject={mergeLocationSafezoneObject(locationSensor, safeZones)}
            title="Full Location Sensor Details"
            type="Location"
            className={style.raw_data}
            buttonType="magenta_link"
            usePortal={false}
            modalType={'slide'}
          />
        </div>
      )}
    </div>
  );
}

export default LocationSensorSafeZone;

import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';

const mediaRepository = {
  addMediaMeta: async ({
    assetId,
    assetName,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.media
        .replace(':assetId', assetId)
        .replace(':assetName', assetName)
        .replace(':productId', syncUpAppName),
      body: body,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getMediaMeta: async ({
    assetId,
    assetName,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.media
        .replace(':assetId', assetId)
        .replace(':assetName', assetName)
        .replace(':productId', syncUpAppName),
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  updateMediaMeta: async ({
    assetId,
    assetName,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.media
        .replace(':assetId', assetId)
        .replace(':assetName', assetName)
        .replace(':productId', syncUpAppName),
      body: body,
      httpMethod: httpMethods.PUT,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  deleteMediaMeta: async ({
    assetId,
    assetName,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.media
        .replace(':assetId', assetId)
        .replace(':assetName', assetName)
        .replace(':productId', syncUpAppName),
      httpMethod: httpMethods.DELETE,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getAllMedia: async ({ syncUpAppName, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.allMedia,
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  uploadMediaAsset: async ({
    assetId,
    assetName,
    syncUpAppName,
    body,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.mediaAsset
        .replace(':assetId', assetId)
        .replace(':assetName', assetName)
        .replace(':productId', syncUpAppName),
      body: body,
      formData: true,
      httpMethod: httpMethods.POST,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  },
  getMediaAsset: async ({
    assetId,
    assetName,
    syncUpAppName,
    responseStatus = false
  }) => {
    const {
      resources,
      httpMethods,
      headers
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.media
        .replace(':assetId', assetId)
        .replace(':assetName', assetName)
        .replace(':productId', syncUpAppName),
      httpMethod: httpMethods.GET,
      headers: {
        [headers.SyncUpProduct.key]: syncUpAppName
      }
    });
  }
};

export default mediaRepository;

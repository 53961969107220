import React from 'react';

import style from './SensorNotAvailable.module.css';

function SensorNotAvailable({ sensorName }) {
  return (
    <div className={style.container}>
      <label className={style.label}>
        {sensorName} sensor details are not available
      </label>
    </div>
  );
}

export default SensorNotAvailable;

import React, { useState, useRef } from 'react';
import classNames from 'classnames';

import { FaChevronDown, FaChevronUp } from 'react-icons/all';
import style from './UserAccordion.module.css';

function UserAccordion({
  title,
  className,
  children,
  titleClassName,
  contentClassName,
  ariaLabel,
  ariaRole,
  tabIndex
}) {
  const [isOpen, setIsOpen] = useState(false);
  const content = useRef(null);
  const accordionContent = useRef(null);
  const attributes = {};

  function toggleAccordion() {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  }
  if (tabIndex) {
    attributes['tabIndex'] = tabIndex;
    attributes['onKeyUp'] = e => {
      if (e.key === 'Enter') {
        toggleAccordion();
      }
    };
  }

  return (
    <div
      className={classNames(style.container, className)}
      ref={accordionContent}
    >
      <div
        className={classNames(
          style.column,
          titleClassName,
          isOpen && style.open
        )}
        aria-label={
          isOpen
            ? `Select title : ${ariaLabel} to collapse.`
            : `Select title : ${ariaLabel} to expand.`
        }
        role={ariaRole}
        {...attributes}
      >
        <span className={style.title}>{title}</span>
        <div onClick={toggleAccordion}>
          {!isOpen ? (
            <FaChevronUp className={style.icon} />
          ) : (
            <FaChevronDown className={style.icon} />
          )}
        </div>
      </div>
      {!isOpen && (
        <div
          ref={content}
          className={classNames(style.content, contentClassName)}
        >
          <div className={style.text}>
            {React.cloneElement(children, { isOpen: isOpen })}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserAccordion;

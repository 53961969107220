import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  NavigationTab as Tab,
  NavigationTabGroup as TabGroup,
  ContentContainer
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import { getRandomString } from '../../utils/stringUtils';
import InSightsErroReport from '../../components/dashboard/powerBi/InSightsErroReport';

import style from './ProductMetrics.module.css';

function ErrorMetrics() {
  const [lastRefresh] = useState(getRandomString()); // TODO: Add refresh button

  const inSightsErroReportMetricssUrl = '/error-metrics';

  return (
    <Authorized
      roles={[
        SUA_ROLES.ADMIN,
        SUA_ROLES.PO,
        SUA_ROLES.PETS_ADMIN,
        SUA_ROLES.PETS_DEV,
        SUA_ROLES.PETS_PO,
        SUA_ROLES.TRACKER_ADMIN,
        SUA_ROLES.TRACKER_DEV,
        SUA_ROLES.TRACKER_PO,
        SUA_ROLES.WATCH_ADMIN,
        SUA_ROLES.WATCH_DEV,
        SUA_ROLES.WATCH_PO
      ]}
      willShowErrorModal
    >
      <ContentContainer className={style.container}>
        <TabGroup sticky type="primary" className={style.custom_tabs}>
          <Tab
            to={inSightsErroReportMetricssUrl}
            text="Error Metrics"
            type="primary-tab"
          />
        </TabGroup>
        <div className={style.content}>
          <Switch key={lastRefresh}>
            <Route
              exact
              path={inSightsErroReportMetricssUrl}
              component={InSightsErroReport}
            />
          </Switch>
        </div>
      </ContentContainer>
    </Authorized>
  );
}

export default ErrorMetrics;

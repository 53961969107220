import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import {
  TmoCard,
  RawData,
  ConfirmationButton,
  withModal,
  TmoSvg,
  StatusText
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import moment from 'moment';

import { GRID, STORAGE_KEYS, STORAGE_TYPES } from '../../utils/app_constants';
import UserAccordion from 'components/uiHelpers/UserAccordion';
import TimeDisplay from '../uiHelpers/TimeDisplay';
import {
  formatPhoneNumber,
  capitalize,
  pluralize,
  prettifyCamelCase
} from '../../utils/stringUtils';
import { hasValue, sleep } from '../../utils/helper_functions';
import { storageWrite } from '../../utils/storageUtils';
import userService from '../../services/userService';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import Fetcher from '../Fetcher';
import Feature from 'components/featureWrappers/Feature';
import { APP_MOBILE } from '../../utils/app_constants';
import ClaimDeviceForUser from 'components/devices/ClaimDeviceForUser';
import ItemDetailsModal from 'components/ItemDetailsModal';
import useVisible from 'hooks/useVisible';
import StatusContext from '../../context/StatusContext';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';

import style from './UserDetails.module.css';
import rowStyle from '../ItemDetailList/ItemDetailRow.module.css';
import delete_icon from '../../static/assets/icons/action/delete.svg';
import androidIcon from '../../static/assets/icons/andriod_platform_icon.svg';
import iosIcon from '../../static/assets/icons/ios_platform_icon.svg';

function UserDetails({ user, userRefreshDetails, onTabClose }) {
  const [notificationTokens, setNotificationTokens] = useState([]);
  const {
    email,
    createdDateTime,
    modifiedDateTime,
    firstName,
    lastName,
    userId,
    locale = {},
    msisdns = [],
    avatarUrl,
    preferences = {},
    terms = {},
    icn = {},
    coppa = {},
    location = {},
    geolocations = {}
  } = user;
  const {
    selectedTab: { product }
  } = useContext(ActiveUserTabContext);

  const { visible, setVisible } = useVisible(false);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const sortedAppVersions = useMemo(
    () =>
      notificationTokens?.sort((a, b) =>
        a.modifiedDateTime < b.modifiedDateTime ? 1 : -1
      ),
    [notificationTokens]
  );

  const action = useCallback(async () => {
    await sleep(1500);
    return userService.getUserNotificationTokens({
      userId: userId,
      syncUpAppName: product
    });
  }, [userId, product]);

  storageWrite({
    key: STORAGE_KEYS.USER_TIMEZONE,
    value: locale ? locale?.timeZone : 'America/Los_Angeles',
    storageType: STORAGE_TYPES.SESSION
  });

  const handleDeleteUserClicked = async () => {
    try {
      await userService.deleteUserById({
        id: user.userId,
        syncUpAppName: product
      });
      addSuccessMessage({
        message: `User: ${user.firstName} ${user.lastName} deleted successfully`
      });
      onTabClose();
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const refreshDetails = () => {
    userRefreshDetails();
  };

  return (
    <div className={style.container}>
      <TmoCard className={style.user_container}>
        <div className={style.accordion}>
          <UserAccordion
            className={style.accordion_row}
            title={
              <div className={style.accordion_user}>
                {avatarUrl && (
                  <div className={style.row}>
                    <div className={style.avatar_container}>
                      <div
                        style={{
                          backgroundImage: 'url(' + avatarUrl + ')'
                        }}
                        className={style.avatar}
                      />
                    </div>
                  </div>
                )}
                <div className={style.name_email}>
                  <span className={style.name}>
                    {capitalize(firstName)} {capitalize(lastName)}
                  </span>
                  <span className={style.label_span}> {email}</span>
                </div>
                <div className={style.action_button}>
                  <RawData
                    rawObject={user}
                    title="Full User Details"
                    tooltip="Full User Details"
                    type=" "
                    modalType={'slide'}
                    className={style.raw_data}
                    usePortal={false}
                    buttonType="magenta_primary"
                  />
                  <Feature isUserDetails Products={[APP_MOBILE.DRIVE]}>
                    <ClaimDeviceForUser
                      userId={user.userId}
                      setVisible={setVisible}
                      className={style.claim_button}
                      refreshDetails={refreshDetails}
                      modalType="delete"
                    />
                  </Feature>
                  <ConfirmationButton
                    className={style.button}
                    type="delete"
                    tooltip="Delete"
                    buttonType="magenta_secondary"
                    handleDeleteClicked={handleDeleteUserClicked}
                    modalMessage={
                      <div className={style.modal_message}>
                        Are you sure you want to delete the user{' '}
                        <span className={style.modal_message_span}>
                          {user.firstName} {user.lastName}
                        </span>
                        ?
                      </div>
                    }
                    modalTitle="Delete User"
                    modalAcceptButtonText={'Yes,Delete'}
                    buttonIcon={<ReactSVG src={delete_icon} alt="delete" />}
                  />
                </div>
              </div>
            }
            titleClassName={style.accordion_title}
            contentClassName={style.accordion_column}
            ariaLabel={'user'}
          >
            <div className={style.user_details}>
              <div className={rowStyle.row}>
                <label className={rowStyle.label}>ID</label>
                <span className={style.label_span}> {userId}</span>
              </div>
              {hasValue(msisdns) && (
                <div className={rowStyle.row}>
                  <label className={rowStyle.label}>
                    {pluralize({
                      string: 'MSISDN',
                      count: msisdns?.length
                    }).toUpperCase()}
                  </label>
                  <span className={style.label_span}>
                    {msisdns
                      .map(number => formatPhoneNumber(number))
                      .join(', ')}
                  </span>
                </div>
              )}
              <Fetcher
                action={action}
                onLoad={setNotificationTokens}
                spinner={true}
                loadingText={true}
                loadingTextLabel="App Versions"
                render={() => (
                  <ItemDetailsModal
                    label={'App Versions'}
                    buttonText={'View Versions'}
                    className={GRID.COLUMN_FORTH}
                  >
                    {hasValue(notificationTokens)
                      ? sortedAppVersions.map((app, index) => (
                          <TmoCard key={index} className={style.version_card}>
                            <div className={style.row}>
                              <div className={style.versions_items}>
                                <label className={style.label}>ID</label>
                                <span className={style.label_span}>
                                  {userId}
                                </span>
                              </div>
                              <ReactSVG
                                className={style.icon}
                                src={
                                  app.platform === 'ANDROID'
                                    ? androidIcon
                                    : iosIcon
                                }
                              />
                            </div>
                            <div className={style.row}>
                              <div className={style.versions_items}>
                                <label className={style.label}>Version</label>
                                <span className={style.label_span}>
                                  {app.version ? app.version : 'Not Available'}
                                </span>
                              </div>
                              <div className={style.versions_items}>
                                <label className={style.label}>Bundle ID</label>
                                <span className={style.label_span}>
                                  {app.bundleId
                                    ? app.bundleId
                                    : 'Not Available'}
                                </span>
                              </div>
                              <div className={style.versions_items}>
                                <label className={style.label}>Updated</label>
                                <span className={style.label_span}>
                                  {moment(app.modifiedDateTime).format('LL')}
                                </span>
                              </div>
                            </div>
                          </TmoCard>
                        ))
                      : 'Not Available'}
                  </ItemDetailsModal>
                )}
              />
              <ItemDetailList
                className={GRID.COLUMN_FORTH}
                items={{
                  product,
                  ...(hasValue(locale) && {
                    'Time zone': locale?.timeZone
                  }),
                  ...(hasValue(geolocations) && {
                    State: geolocations[0]?.state
                  }),
                  ...(hasValue(location) && {
                    Location: `${location?.latitude}, ${location?.longitude}`
                  })
                }}
              />

              <ItemDetailsModal
                buttonText={'View Dates'}
                label="Created/Modified Dates"
                className={GRID.COLUMN_FORTH}
              >
                <TimeDisplay
                  title="Created At"
                  timestamp={createdDateTime}
                  type="primary_time_display"
                />
                <div className={style.separator}></div>
                <TimeDisplay
                  title="Modified At"
                  timestamp={modifiedDateTime}
                  type="primary_time_display"
                />
              </ItemDetailsModal>

              {hasValue(preferences) && (
                <ItemDetailsModal
                  label={'Notification Preferences'}
                  buttonText={'View Preferences'}
                  className={GRID.COLUMN_FORTH}
                >
                  <div className={style.preferences}>
                    {Object.entries(preferences)
                      .sort(([k, value]) => {
                        return value ? -1 : 1;
                      })
                      .map(([key, value], index) =>
                        typeof value === 'object' ? (
                          Object.entries(value).map(([k, v]) => (
                            <div className={rowStyle.row}>
                              <p className={rowStyle.label} key={index}>
                                {prettifyCamelCase(k.replace('Enabled', ''))}
                              </p>
                              <StatusText
                                status={v === true ? 'success' : 'alert'}
                                className={style.lable_status}
                              >
                                {v === true ? 'Enabled' : 'Disabled'}
                              </StatusText>
                            </div>
                          ))
                        ) : (
                          <div className={rowStyle.row}>
                            <p className={rowStyle.label} key={index}>
                              {prettifyCamelCase(key.replace('Enabled', ''))}
                            </p>
                            <StatusText
                              status={value === true ? 'success' : 'alert'}
                              className={style.lable_status}
                            >
                              {value === true ? 'Enabled' : 'Disabled'}
                            </StatusText>
                          </div>
                        )
                      )}
                  </div>
                </ItemDetailsModal>
              )}
              {visible && (
                <TmoSvg className={style.spinner} svgId="loading-spinner" />
              )}
            </div>
          </UserAccordion>
        </div>
      </TmoCard>
      {hasValue(terms) ||
        hasValue(icn) ||
        (hasValue(coppa) && (
          <TmoCard className={style.column}>
            {hasValue(terms) && (
              <div className={style.row}>
                <label className={style.label}>
                  Terms Version:
                  <span className={style.label_span}>{terms?.version}</span>
                </label>
              </div>
            )}
            {hasValue(icn) && (
              <div className={style.row}>
                <label className={style.label}>
                  ICN Version:
                  <span className={style.label_span}>{icn?.version}</span>
                </label>
              </div>
            )}
            {hasValue(coppa) && (
              <div className={style.row}>
                <label className={style.label}>
                  COPPA Version:
                  <span className={style.label_span}>{coppa?.version}</span>
                </label>
              </div>
            )}
          </TmoCard>
        ))}
    </div>
  );
}

export default withModal(UserDetails);

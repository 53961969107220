import React from 'react';
import classNames from 'classnames';

import style from './TmoToggle.module.css';

function TmoToggle({
  isChecked = false,
  onChange,
  disabled,
  id,
  className,
  label,
  value,
  size = 'md'
}) {
  const handleChange = ({ target: { checked } }) => {
    onChange(checked);
  };

  return (
    <div className={style.container}>
      <span className={style.label}>{label}</span>
      <input
        className={classNames(style.input, className)}
        id={id}
        type="checkbox"
        checked={isChecked || value}
        onChange={handleChange}
        disabled={disabled}
      />
      <label className={classNames(style.button, style[size])} htmlFor={id} />
    </div>
  );
}

export default TmoToggle;

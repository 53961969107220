import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {
  ConfirmationModal,
  TmoButtons as TmoButton,
  withModal,
  RawData,
  StatusPill,
  ConfirmationModalV2,
  TmoCard
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import useManualFetcher from '../../hooks/useManualFetcher';
import StatusContext from '../../context/StatusContext';
import AddDeviceForThing from './AddDeviceForThing';
import Feature from '../featureWrappers/Feature';
import { FETCH_STATE, APP_MOBILE } from '../../utils/app_constants';
import simulatorService from '../../services/simulatorService';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { removeDashes } from '../../utils/helper_functions';
import CreateDeviceEvent from 'components/drive/CreateDeviceEvent';
import { DEVICE_STATUS } from '../../utils/deviceConsts';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import IssueCommands from '../../components/issueCommands/IssueCommands';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import ItemDetailsModal from 'components/ItemDetailsModal';
import TimeDisplay from 'components/uiHelpers/TimeDisplay';
import { GRID } from 'utils/app_constants';

import style from './Device.module.css';
import deleteIcon from '../../static/assets/icons/action/delete.svg';
import heartIcon from '../../static/assets/icons/heart.svg';
import logsIcon from '../../static/assets/icons/logs_icon.svg';
import plugIn from '../../static/assets/icons/plug_in.svg';

function Device({
  className,
  availableThings,
  device: {
    id,
    hardwareId,
    status,
    onboardingStatus,
    userId,
    msisdn,
    ratePlan,
    lineStatus,
    simulatorType,
    product,
    model,
    onboardingCreatedDateTime,
    onboardingUpdateDateTime
  },
  pUserId,
  onRemoveDevice,
  onRemoveRealDevice,
  onAddClicked,
  onPlugInDevice,
  history,
  device,
  openModal
}) {
  const activeUserTabContext = useContext(ActiveUserTabContext);
  const productType = activeUserTabContext.selectedTab.product;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const showUnclaimModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [fetchState, actionTrigger] = useManualFetcher({
    action: () => {
      trackCustomEvents(`plug in simulated device`, {
        userId: pUserId,
        deviceId: id,
        syncUpAppName: productType
      });
      simulatorService.plugInDriveSimulator({
        userId: pUserId,
        deviceId: id,
        syncUpAppName: productType,
        simulator: {
          eventType: 'device_plugged',
          longitude: 21.1458,
          latitude: 79.088158
        }
      });
    },
    onLoad: () => {
      addSuccessMessage({
        message: 'Plug in completed successfully'
      });
    },
    onError: error => {
      addErrorMessage({ error });
    },
    onComplete: () => {
      closeModal();
      onPlugInDevice();
    }
  });

  const handleAddClicked = async ({ thingId, hardwareId }) => {
    trackCustomEvents(`Add device to ${productType}`, {
      thingId,
      hardwareId,
      product,
      model
    });
    await onAddClicked({ thingId, hardwareId, model, product });
  };

  const handleRemoveClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Simulated Device`, {
        deviceId: id
      });
      await onRemoveDevice({ id });
    }
  };

  const handleUnplugRemoveClicked = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete Real Device`, {
        deviceId: id
      });
      await onRemoveRealDevice({ id });
    }
  };
  return (
    <TmoCard>
      <div className={classNames(style.container, className)}>
        <div className={style.card_header}>
          <div className={style.card_title}>
            <div className={style.status}>
              <StatusPill
                className={style.status_pill}
                inline={false}
                status={removeDashes(status)}
              >
                {status}
              </StatusPill>
            </div>
            <div className={style.device_id} title={id}>
              {' '}
              {id}
            </div>
          </div>
          <div className={style.action_controls}>
            <div className={style.action_button}>
              <RawData
                rawObject={device}
                title="Full Device Details"
                tooltip="Full Device Details"
                type=" "
                usePortal={false}
                modalType={'slide'}
                buttonType="magenta_primary"
              />
            </div>
            <Feature isUserDetails Products={[APP_MOBILE.DRIVE]}>
              {status === 'ACTIVE' && simulatorType === 'NONE' && (
                <>
                  <TmoButton
                    onClick={handleUnplugRemoveClicked}
                    className={style.button_remove}
                    type="magenta_secondary"
                    icon={<ReactSVG src={deleteIcon} />}
                    tooltip="Delete"
                  />
                  <ConfirmationModalV2
                    modalType="delete"
                    message={
                      <>
                        Are you sure you want to remove Device ID:{' '}
                        <strong>{id}</strong>?
                      </>
                    }
                    title="Confirm Device Removal"
                    acceptButtonText="Yes,Delete"
                    type="danger"
                    usePortal={false}
                    buttonIcon={<ReactSVG src={deleteIcon} />}
                    confirmInput
                  />
                </>
              )}

              {(status === 'CLAIMED' ||
                status === 'UNPLUGGED' ||
                status === 'OFFLINE' ||
                status === 'INACTIVE') && (
                <>
                  <TmoButton
                    onClick={handleRemoveClicked}
                    className={style.button_remove}
                    type="magenta_secondary"
                    tooltip="Delete"
                    icon={<ReactSVG src={deleteIcon} />}
                  />
                  <ConfirmationModalV2
                    modalType="delete"
                    message={
                      <>
                        Are you sure you want to remove Device ID:{' '}
                        <strong>{id}</strong>?
                      </>
                    }
                    title="Confirm Device Removal"
                    acceptButtonText="Yes,Delete"
                    type="danger"
                    usePortal={false}
                    confirmInput
                    buttonIcon={<ReactSVG src={deleteIcon} />}
                  />
                </>
              )}
            </Feature>
            <Feature
              isUserDetails
              Products={[
                APP_MOBILE.PETS,
                APP_MOBILE.TRACKER,
                APP_MOBILE.WATCH,
                APP_MOBILE.FAMILYLOCATION
              ]}
            >
              <TmoButton
                onClick={() =>
                  history.push(`devices/${id}/hardware/${hardwareId}/logs`)
                }
                className={style.action_button}
                type="magenta_primary"
                tooltip="Logs"
                icon={<ReactSVG src={logsIcon} />}
              />
            </Feature>
          </div>
        </div>
        <div className={style.content}>
          <ItemDetailList
            rowSet={[
              {
                hardwareId,
                ...(onboardingStatus && {
                  'Onboarding Status': onboardingStatus
                })
              },
              {
                userId,
                msisdn: msisdn ? msisdn : null
              },
              {
                ratePlan: ratePlan ? ratePlan : 'Not Available',
                lineStatus: lineStatus ? lineStatus.status : 'Not Available'
              }
            ]}
          />
          <ItemDetailsModal
            buttonText={'View Dates'}
            label="Created/Modified Dates"
            className={GRID.COLUMN_FORTH}
          >
            <TimeDisplay
              title="Created Date"
              timestamp={onboardingCreatedDateTime}
              type="primary_time_display"
            />
            <div className={style.separator}></div>
            <TimeDisplay
              title="Modified Date"
              timestamp={onboardingUpdateDateTime}
              type="primary_time_display"
            />
          </ItemDetailsModal>
          <div className={style.footer_action}>
            <div>
              <Feature isUserDetails Products={[APP_MOBILE.DRIVE]}>
                {(status === 'CLAIMED' || status === 'UNPLUGGED') &&
                  simulatorType && (
                    <>
                      <TmoButton
                        className={style.action_button}
                        type="magenta_link"
                        icon={<ReactSVG src={plugIn} />}
                        onClick={showUnclaimModal}
                      >
                        Plug-In
                      </TmoButton>
                      <ConfirmationModal
                        modalType="primary"
                        isOpen={isModalOpen}
                        onAcceptBtnClicked={actionTrigger}
                        onDenyBtnClicked={closeModal}
                        buttonsDisabled={fetchState === FETCH_STATE.LOADING}
                        title={`Plug In`}
                        message={`Are you sure you want to plug in simulated device?`}
                        acceptBtnTitle="Yes"
                        denyBtnTitle="Cancel"
                      />
                    </>
                  )}
                {status === 'ACTIVE' && simulatorType !== 'NONE' && (
                  <CreateDeviceEvent
                    deviceId={id}
                    isUnPlugEvent
                    onEventCreated={onPlugInDevice}
                  />
                )}
              </Feature>
              <Feature
                isUserDetails
                Products={[
                  APP_MOBILE.PETS,
                  APP_MOBILE.TRACKER,
                  APP_MOBILE.WATCH,
                  APP_MOBILE.FAMILYLOCATION
                ]}
              >
                <IssueCommands deviceId={id} hardwareId={hardwareId} />
              </Feature>
            </div>

            <div>
              <Feature
                isUserDetails
                Products={[
                  APP_MOBILE.PETS,
                  APP_MOBILE.TRACKER,
                  APP_MOBILE.WATCH,
                  APP_MOBILE.FAMILYLOCATION
                ]}
              >
                {status === DEVICE_STATUS.ACTIVE && availableThings.length > 0 && (
                  <TmoButton
                    onClick={() => history.push(`devices/${id}/health`)}
                    className={style.action_button}
                    type="magenta_link"
                    icon={<ReactSVG src={heartIcon} />}
                  >
                    Health
                  </TmoButton>
                )}
                {status === 'DELETED' && (
                  <AddDeviceForThing
                    thingType={productType}
                    availableThings={availableThings}
                    hardwareId={hardwareId}
                    onAddClicked={handleAddClicked}
                  />
                )}
              </Feature>
            </div>
          </div>
        </div>
      </div>
    </TmoCard>
  );
}

export default withModal(withRouter(Device));

import React from 'react';
import { TmoSelect } from '@tmobile/tmo-bps-syncup-web-component-lib';
import classNames from 'classnames';

import { capitalCase, removeDashes } from '../../utils/helper_functions';

import style from './device/DeviceSearchFormOptions.module.css';

function SearchFormSelect({ onFilter, data, name, className }) {
  const selectSpecs = {
    options: data
      .filter(d => d)
      .map(v => ({
        value: v,
        disabled: v === capitalCase(removeDashes(name))
      }))
  };

  return (
    <TmoSelect
      className={classNames(style.filter_select, className)}
      selectSpecs={selectSpecs}
      onChange={value => onFilter(value, name)}
      value={capitalCase(removeDashes(name))}
    />
  );
}

export default SearchFormSelect;

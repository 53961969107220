import React, { useCallback, useMemo, useState } from 'react';
import * as _ from 'underscore';
import {
  TmoCard,
  PaginationCounts,
  FilterableList,
  PaginationV2
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import Fetcher from '../../Fetcher';
import firmwareService from '../../../services/firmwareService';
import FirmwareDetails from '../../things/firmware/FirmwareDetails';
import queryString from 'query-string';
import StatusCard from '../../textHelpers/StatusCard';
import { capitalCase, removeDashes } from '../../../utils/helper_functions';

import style from './StatusSearchResults.module.css';

const RESULTS_PER_PAGE = 30;

function StatusSearchResults({ location }) {
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredListLength, setFilteredListLength] = useState();

  const searchQuery = useMemo(() => queryString.parse(location.search), [
    location.search
  ]);

  const actionCallback = useCallback(() => {
    return firmwareService.getFirmwareDownloadStatus({
      query: location.search.split('?')[1],
      page: currentPage,
      resultsSize: RESULTS_PER_PAGE
    });
  }, [location.search, currentPage]);

  const showPagination =
    filteredListLength && results?.page?.totalItems >= RESULTS_PER_PAGE;

  return (
    !_.isEmpty(searchQuery) && (
      <TmoCard className={style.results}>
        <Fetcher
          action={actionCallback}
          onLoad={setResults}
          render={() =>
            !!results.data.length ? (
              <>
                {results.page.totalItems !== 0 && (
                  <div className={style.counts}>
                    <PaginationCounts
                      showPagination={showPagination}
                      data={results?.data || results}
                      totalItems={
                        results.page.totalItems || results.data.length
                      }
                      resultsToShow={RESULTS_PER_PAGE}
                      currentPage={results.page.currentPage}
                      pageSize={results.page.pageSize}
                      from={results.page.from}
                      to={results.page.to}
                      dataType="Result"
                    />
                  </div>
                )}
                <FilterableList
                  listClass={style.items}
                  filteredListLength={setFilteredListLength}
                  label="Filter By:"
                  list={results.data}
                  filterByArr={[
                    'status',
                    'version',
                    'firmwareId',
                    'packageName',
                    'hardwareId'
                  ]}
                  placeholder={'Filter firmware download status'}
                  renderItem={item => (
                    <StatusCard
                      key={item.id}
                      className={style.item}
                      status={item.status}
                    >
                      <FirmwareDetails details={item} />
                    </StatusCard>
                  )}
                />
                {showPagination && (
                  <PaginationV2
                    totalPages={results.page.lastPage}
                    onPageChanged={setCurrentPage}
                    selectedPageNumber={currentPage}
                  />
                )}
              </>
            ) : (
              <div className={style.no_results}>
                <span>No results found for</span>
                {Object.entries(searchQuery).map(([key, value]) => (
                  <span className={style.no_results_filter} key={key}>
                    {capitalCase(removeDashes(key))}: <span>{value}</span>
                  </span>
                ))}
              </div>
            )
          }
        />
      </TmoCard>
    )
  );
}

export default StatusSearchResults;

import React, { useState, useContext, useCallback } from 'react';
import {
  TmoToggle,
  DynamicList,
  RawData
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import userService from '../../services/userService';
import Fetcher from '../Fetcher';
import ContactCard from './ContactCard';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';
import { hasValue } from 'utils/helper_functions';
import { formatPhoneNumber, capitalize } from '../../utils/stringUtils';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import { shuffleObjectKeysAndValues } from '../../utils/helper_functions';

import style from './EmergencyContactsList.module.css';
import sort_icon from '../../static/assets/icons/sort_icon.svg';

function EmergencyContactsList() {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);

  const [contacts, setContacts] = useState();
  const [listView, setlistView] = useState(false);

  const headerNameListItemMap = [
    {
      headerDisplayName: 'Name',
      listItemKey: 'firstName',
      render: ({ firstName, lastName }) => (
        <>
          {firstName && capitalize(firstName)}{' '}
          {lastName && capitalize(lastName)}
        </>
      ),
      columnClass: style.list_table,
      sortable: true
    },
    {
      listItemKey: 'email',
      headerDisplayName: 'Email',
      sortable: true
    },
    {
      listItemKey: 'id',
      headerDisplayName: 'Id'
    },
    {
      headerDisplayName: 'Msisdn',
      render: ({ msisdn }) => (msisdn ? formatPhoneNumber(msisdn) : null),
      columnClass: style.list_table
    },
    {
      listItemKey: 'countryCode',
      headerDisplayName: 'Country Code'
    },
    {
      listItemKey: 'type',
      headerDisplayName: 'Type',
      sortable: true
    },
    {
      headerDisplayName: 'Things',
      render: ({ things }) =>
        Object.keys(things).length !== 0 ? (
          <ItemDetailList
            hideLabel
            rowSet={[{ Things: shuffleObjectKeysAndValues(things) }]}
          />
        ) : (
          ' '
        ),
      columnClass: style.list_table
    },
    {
      headerDisplayName: 'Entity Display Name',
      render: ({ entityDisplayNames }) =>
        Object.keys(entityDisplayNames).length !== 0 ? (
          <ItemDetailList
            hideLabel
            rowSet={[
              {
                'Entity Display Name': shuffleObjectKeysAndValues(
                  entityDisplayNames
                )
              }
            ]}
          />
        ) : (
          ' '
        ),
      columnClass: style.list_table
    },
    {
      headerDisplayName: 'Full Object Details',
      render: ({ ...item }) => (
        <RawData
          rawObject={item}
          title={`Full Contact object for ${item.firstName}`}
          usePortal={false}
          modalType={'slide'}
          buttonType="magenta_link"
          type=" "
          className={style.button}
        />
      )
    }
  ];

  const actionCallback = useCallback(
    () =>
      userService.getContacts({
        userId,
        syncUpAppName: product
      }),
    [userId, product]
  );

  return (
    <>
      <PageHeader title="Contacts" tabHeader={true} className={style.header}>
        <TmoToggle
          label={'List View'}
          disabled={!hasValue(contacts)}
          isChecked={listView}
          onChange={() => setlistView(!listView)}
        />
      </PageHeader>
      <Fetcher
        action={actionCallback}
        onLoad={setContacts}
        render={() =>
          hasValue(contacts) ? (
            <div className={style.items}>
              {listView ? (
                <DynamicList
                  headerNameListItemMap={headerNameListItemMap}
                  listItems={contacts}
                  type="primary"
                  sortIcon={sort_icon}
                />
              ) : (
                contacts.map(contact => <ContactCard contact={contact} />)
              )}
            </div>
          ) : (
            <div className={style.message}>User does not have any Contacts</div>
          )
        }
      />
    </>
  );
}

export default EmergencyContactsList;

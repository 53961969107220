import React from 'react';
import { ContentContainer } from '@tmobile/tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import DistinctSoftwareVersions from '../../components/softwareVersions/DistinctSoftwareVersions';
import { SUA_ROLES } from '../../utils/app_constants';
import style from './SoftwareVersions.module.css';

function SoftwareVersions() {
  return (
    <ContentContainer className={style.container}>
      <Authorized
        roles={[SUA_ROLES.PO, SUA_ROLES.ADMIN, SUA_ROLES.MOBILE_APP_DEVS]}
        willShowErrorModal
      >
        <DistinctSoftwareVersions />
      </Authorized>
    </ContentContainer>
  );
}
export default SoftwareVersions;

import React from 'react';
import { withRouter } from 'react-router-dom';
import { withModal } from '@tmobile/tmo-bps-syncup-web-component-lib';
import { FaCaretDown } from 'react-icons/all';
import { ExpandableText } from '@tmobile/tmo-bps-syncup-web-component-lib';

import TimeDisplay from '../../uiHelpers/TimeDisplay';
import ThingTrip from '../../drive/ThingTrip';
import ItemDetailList from '../../ItemDetailList/ItemDetailList';
import ItemDetailsModal from 'components/ItemDetailsModal';

import style from './VehicleDetails.module.css';
function VehicleDetails({
  vehicle: {
    id,
    vehicle: {
      vin,
      vinReceivedAt,
      licensePlateState,
      licensePlateNumber,
      year,
      make,
      model,
      engine,
      horsepower,
      fuelGrade,
      colorName,
      odometerReading,
      odometerReadingAt,
      fuelLevel,
      fuelStatus,
      fuelStatusAt,
      batteryLevel,
      batteryStatus,
      batteryStatusAt,
      engineHealthDtc,
      engineHealthStatus,
      engineHealthStatusAt,
      currentTrip
    },
    createdDateTime,
    modifiedDateTime,
    onboardStatus,
    onboardAt,
    latitude,
    longitude,
    lastKnownLatitude,
    lastKnownLongitude,
    lastKnownRadius,
    lastKnownAddress,
    lastKnownAt,
    lastKnownAtText
  }
}) {
  return (
    <div className={style.container}>
      <ItemDetailList
        items={{
          id,
          onboardAt,
          vin,
          onboardStatus,
          vinReceivedAt,
          licensePlateState,
          licensePlateNumber,
          locationDetails: {
            latitude,
            longitude,
            lastKnownLatitude,
            lastKnownLongitude,
            lastKnownRadius,
            lastKnownAddress,
            lastKnownAt,
            lastKnownAtText
          },
          specifications: {
            year,
            make,
            model,
            engine,
            horsepower,
            fuelGrade,
            colorName
          },
          diagnostic: {
            odometerReading,
            odometerReadingAt,
            fuelLevel,
            fuelStatus,
            fuelStatusAt,
            batteryLevel,
            batteryStatus,
            batteryStatusAt,
            engineHealthDtc,
            engineHealthStatus,
            engineHealthStatusAt
          }
        }}
      />
      <ItemDetailsModal
        buttonText={'View Dates'}
        label="Created/Modified Dates"
      >
        <TimeDisplay
          title="Created Date"
          timestamp={createdDateTime}
          type="primary_time_display"
        />
        <div className={style.separator}></div>
        <TimeDisplay
          title="Modified Date"
          timestamp={modifiedDateTime}
          type="primary_time_display"
        />
      </ItemDetailsModal>
      <div className={style.trip_items}>
        <label>Current Trip</label>
        <ExpandableText
          triggerText="View"
          closeText="Close"
          triggerIcon={<FaCaretDown />}
        >
          {currentTrip && <ThingTrip trip={currentTrip} />}
        </ExpandableText>
      </div>
    </div>
  );
}

export default withRouter(withModal(VehicleDetails));

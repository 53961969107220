import pocRepository from '../repositories/pocRepository';

const pocService = {
  getDevices: async ({ productId }) => {
    return await pocRepository.getDevices({ productId });
  },
  getDeviceHistory: async ({ productId, deviceId }) => {
    return await pocRepository.getDeviceHistory({ productId, deviceId });
  },
  sendSms: async ({ productId, deviceId }) => {
    return await pocRepository.sendSms({ productId, deviceId });
  },
  setDesiredState: async ({ productId, deviceId, desiredState }) => {
    return await pocRepository.setDesiredState({
      productId,
      deviceId,
      desiredState
    });
  },
  connectToDeviceUpdates: async ({ productId, methodHandler }) => {
    return await pocRepository.connectToDeviceUpdates({
      productId,
      methodHandler
    });
  }
};

export default pocService;

import React, { useState } from 'react';
import { withModal, TmoSvg } from '@tmobile/tmo-bps-syncup-web-component-lib';

import SingleAssetMedia from './SingleAssetMedia';
import useVisible from '../../hooks/useVisible';
import Group from './groups/Group';
import { IOT_ASSET_TYPES } from '../../utils/app_constants';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import ConfigDetails from './configurations/ConfigDetails';

import style from './SingleAsset.module.css';

function SingleAsset({
  details,
  group = null,
  editFieldSpecs = null,
  onRemove,
  mediaType,
  openModal,
  onEdit
}) {
  const [uploadedAsset, setUploadedAsset] = useState();
  const { visible, setVisible } = useVisible(false);

  let mediaTypeAssetId =
    mediaType === IOT_ASSET_TYPES.FIRMWARE
      ? details?.id || group.id
      : details?.assetId;
  let mediaTypeAssetName =
    mediaType === IOT_ASSET_TYPES.FIRMWARE
      ? details?.id || group.groupName
      : details?.assetName;

  const handleRemoveAsset = async () => {
    const { isConfirmed } = await openModal();
    if (isConfirmed) {
      trackCustomEvents(`Delete ${mediaType} Config`, {
        assetId: mediaTypeAssetId,
        assetName: details.assetName
      });
      await onRemove({
        assetId: mediaTypeAssetId,
        assetName: details.assetName,
        product:
          mediaType === IOT_ASSET_TYPES.FIRMWARE
            ? details.product
            : details.productId
      });
    }
  };

  return (
    <>
      {mediaType === IOT_ASSET_TYPES.FIRMWARE ? (
        details ? (
          <>
            <ConfigDetails
              mediaType={mediaType}
              setVisible={setVisible}
              details={details}
              editFieldSpecs={editFieldSpecs}
              onDelete={handleRemoveAsset}
              assetName={mediaTypeAssetName}
              onAssetUpload={setUploadedAsset}
              onEdit={onEdit}
              assetId={mediaTypeAssetId}
            />
          </>
        ) : (
          <Group details={group} />
        )
      ) : (
        <SingleAssetMedia
          asset={uploadedAsset ? uploadedAsset : details}
          setVisible={setVisible}
          mediaType={mediaType}
          details={details}
          group={group}
          editFieldSpecs={editFieldSpecs}
          onDelete={handleRemoveAsset}
          assetName={mediaTypeAssetName}
          assetId={mediaTypeAssetId}
          onAssetUpload={setUploadedAsset}
          onEdit={onEdit}
        />
      )}
      {visible && <TmoSvg className={style.spinner} svgId="loading-spinner" />}
    </>
  );
}

export default withModal(SingleAsset);

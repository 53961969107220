import React from 'react';

import { mapStyle } from '../../utils/app_constants';
import StaticMapImage from '../map/StaticImage/StaticMapImage';

function ThingTripMap({ thingTrip }) {
  const zoom = 7;
  const {
    locations,
    startedLatitude,
    startedLongitude,
    startedAt,
    thingId,
    id
  } = thingTrip;

  const singleThingAsCenter = {
    latitude: startedLatitude,
    longitude: startedLongitude,
    updateDate: startedAt,
    deviceId: thingId,
    id: id
  };

  const places = locations.map(obj => ({
    latitude: obj.latitude,
    longitude: obj.longitude
  }));

  return (
    <StaticMapImage
      center={
        singleThingAsCenter
          ? `${singleThingAsCenter.latitude},${singleThingAsCenter.longitude}`
          : '98006'
      }
      zoom={zoom}
      places={places}
      mapStyles={mapStyle}
      marker
    />
  );
}

export default ThingTripMap;

import React, { useContext } from 'react';
import StatusContext from '../../../context/StatusContext';
import TmoButtonAsync from '../../forms/TmoButtonAsync';
import TmoToggle from '../../forms/TmoToggle';
import LabelWithInput from '../../forms/LabelWithInput';
import { getRandomString } from '../../../utils/stringUtils';
import { trackCustomEvents } from '../../../utils/appInsight_analytics';

import style from './Tracker2Controls.module.css';

function Tracker2Controls({ className, device, patchDesiredState }) {
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);

  const handleLocationRequestClick = async () => {
    try {
      trackCustomEvents(`Request new location`, {
        desiredState: {
          locationUpdateNonce: getRandomString()
        }
      });
      await patchDesiredState({
        desiredState: {
          locationUpdateNonce: getRandomString()
        }
      });
      addSuccessMessage({ message: `Successfully requested new location` });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const handleLightSensorToggle = async isEnabled => {
    await patchDesiredState({
      desiredState: {
        hwEnab: {
          litSen: isEnabled ? 0 : 1
        }
      }
    });
    addSuccessMessage({
      message: `Light sensor set to ${isEnabled ? `enabled` : `disabled`}`
    });
  };

  return (
    <>
      <TmoButtonAsync onClick={handleLocationRequestClick}>
        Request new location
      </TmoButtonAsync>
      <LabelWithInput
        className={style.control}
        formComponent={TmoToggle}
        onChange={handleLightSensorToggle}
        value={!device?.desiredState?.hwEnab?.litSen}
        labelText="Light Sensor"
      />
    </>
  );
}

export default Tracker2Controls;

import React from 'react';

import StaticMapImage from '../map/StaticImage/StaticMapImage';

function ThingEventMap({ thingEvent }) {
  const zoom = 10;

  const { eventLatitude, eventLongitude, eventAt, thingId, id } = thingEvent;

  const singleThingAsCenter = {
    latitude: eventLatitude,
    longitude: eventLongitude,
    updateDate: eventAt,
    deviceId: thingId,
    id: id
  };

  return (
    <StaticMapImage
      center={
        singleThingAsCenter
          ? `${singleThingAsCenter.latitude},${singleThingAsCenter.longitude}`
          : '98006'
      }
      zoom={zoom}
      marker
    />
  );
}

export default ThingEventMap;

import React from 'react';
import classNames from 'classnames';
import { StatusText } from '@tmobile/tmo-bps-syncup-web-component-lib';

import TimeDisplay from '../uiHelpers/TimeDisplay';
import { prettifyCamelCase, formatPhoneNumber } from '../../utils/stringUtils';
import ItemDetailsModal from 'components/ItemDetailsModal';
import { hasValue } from 'utils/helper_functions';

import rowStyle from './ItemDetailRow.module.css';
import style from './ItemDetailList.module.css';

const phoneFormat = ['msisdn'];

const ItemDetailList = ({
  items,
  rowSet,
  className,
  hideLabel,
  buttonText
}) => {
  const isObject = value => {
    return !!(
      value &&
      typeof value === 'object' &&
      Object.keys(value).length !== 0 &&
      value[0] !== 'NO_ERROR'
    );
  };
  const isDate = value => {
    const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}.*$/i;
    return !!(
      value &&
      typeof value === 'string' &&
      isNaN(value) &&
      new Date(value) !== 'Invalid Date' &&
      !isNaN(new Date(value)) &&
      regex.test(value)
    );
  };

  const isStatusText = key => {
    const str = key.toLowerCase();
    return str.includes('status');
  };
  const checkStatus = value => {
    return value === 'ACTIVE' ||
      value === 'ONBOARDED' ||
      value === 'enabled' ||
      value === 'Online'
      ? 'success'
      : 'alert';
  };

  const MapRecursive = ({ value }) => {
    return (
      <>
        {value ? (
          Object.entries(value).map(([k, v], index) =>
            isObject(v) ? (
              <div key={index}>
                <label className={style.label}>
                  {`${prettifyCamelCase(k)}`}
                </label>
                <div className={style.subItem}>
                  <MapRecursive value={v} />
                </div>
              </div>
            ) : (
              <div className={style.sub_row} key={k}>
                <label className={style.label}>
                  {k.toLocaleLowerCase() === 'msisdn'
                    ? 'MSISDN'
                    : k.toLocaleLowerCase().includes('imei')
                    ? k
                    : prettifyCamelCase(k)}
                </label>
                <span className={style.value}>
                  {phoneFormat.includes(k.toLocaleLowerCase())
                    ? formatPhoneNumber(v)
                    : v}
                </span>
              </div>
            )
          )
        ) : (
          <>-</>
        )}
      </>
    );
  };

  const MapItems = ({ data, isAutoGrid }) => {
    const assignClass = isAutoGrid
      ? `column_${Object.entries(data).length}`
      : className;
    return (
      <>
        {data &&
          Object.entries(data).map(([key, value], index) => (
            <div
              className={classNames(
                assignClass && style[assignClass],
                rowStyle.row
              )}
              key={`${key}-${index}`}
            >
              {isObject(value) || isDate(value) ? (
                <ItemDetailsModal
                  hideLabel={hideLabel}
                  label={`${prettifyCamelCase(key)}`}
                  buttonText={buttonText ? buttonText : 'View Details'}
                  key={`modal${key}`}
                >
                  {isDate(value) ? (
                    <div
                      className={classNames(style.sub_row, style.time_display)}
                    >
                      <TimeDisplay
                        timestamp={value}
                        type="primary_time_display"
                      />
                    </div>
                  ) : (
                    <MapRecursive value={value} />
                  )}
                </ItemDetailsModal>
              ) : (
                <>
                  <label className={rowStyle.label}>
                    {key.toLocaleLowerCase() === 'msisdn'
                      ? 'MSISDN'
                      : key.toLocaleLowerCase().includes('imei')
                      ? key
                      : prettifyCamelCase(key)}
                  </label>
                  <span className={rowStyle.value}>
                    {value === null || value === undefined || value === '' ? (
                      <>-</>
                    ) : (
                      <>
                        {(typeof value === 'string' ||
                          typeof value === 'number') &&
                          !isStatusText(key) && (
                            <>
                              {phoneFormat.includes(key.toLocaleLowerCase())
                                ? formatPhoneNumber(value)
                                : value}
                            </>
                          )}
                        {typeof value === 'string' && isStatusText(key) && (
                          <StatusText status={checkStatus(value)}>
                            <span className={style.statusText}>{value}</span>
                          </StatusText>
                        )}
                        {typeof value === 'boolean' && (
                          <StatusText status={value ? 'success' : 'alert'}>
                            <span className={style.statusText}>
                              {value ? 'Yes' : 'No'}
                            </span>
                          </StatusText>
                        )}
                      </>
                    )}
                  </span>
                </>
              )}
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      {rowSet && hasValue(rowSet) ? (
        <>
          {rowSet.map((row, index) => (
            <MapItems key={`item${index}`} data={row} isAutoGrid />
          ))}
        </>
      ) : (
        <MapItems data={items} />
      )}
    </>
  );
};

export default ItemDetailList;

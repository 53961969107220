import React, { useState } from 'react';
import { TmoInput } from '@tmobile/tmo-bps-syncup-web-component-lib';

import Fetcher from '../../Fetcher';
import firmwareService from '../../../services/firmwareService';
import SearchFormSelect from '../../search/SearchFormSelect';
import { FIRMWARE_DOWNLOAD_STATUS } from '../../../utils/app_constants';

import style from './StatusSearchForm.module.css';

function StatusSearchForm({ onFilter, filter }) {
  const [versions, setVersions] = useState();

  return (
    <Fetcher
      action={firmwareService.getFirmwareVersionsFromPackages}
      onLoad={setVersions}
      render={() =>
        versions ? (
          <div className={style.form}>
            <div className={style.row}>
              <label className={style.filter_label}>Device ID</label>
              <TmoInput
                className={style.form_input}
                type="text"
                value={filter['imeival']}
                placeholder="IMEI - e.g. 015422000091818"
                onChange={value => onFilter(value, 'imeival')}
              />
            </div>
            <div className={style.row}>
              <div className={style.column}>
                <label className={style.filter_label}>Firmware Version</label>
                <SearchFormSelect
                  className={style.form_select}
                  onFilter={value => onFilter(value, 'firmware-version')}
                  filter={filter}
                  name="firmware-version"
                  data={['Firmware Version', ...versions]}
                />
              </div>
              <div className={style.column}>
                <label className={style.filter_label}>Download Status</label>
                <SearchFormSelect
                  className={style.form_select}
                  onFilter={value => onFilter(value, 'status')}
                  filter={filter}
                  name="status"
                  data={[
                    'Status',
                    ...Object.values(FIRMWARE_DOWNLOAD_STATUS).map(d => d)
                  ]}
                />
              </div>
            </div>
          </div>
        ) : (
          'Unable to get firmware/hardware/software versions'
        )
      }
    />
  );
}

export default StatusSearchForm;

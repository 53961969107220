import React from 'react';
import { prettifyCamelCase } from 'utils/stringUtils';
import { capitalCase } from '../../utils/helper_functions';

import style from './SafeZoneInfoWindow.module.css';

function SafeZoneInfoWindow({ safeZoneClicked }) {
  const {
    heading,
    circle: { radius },
    info: { objects, keyValuePairs },
    id
  } = safeZoneClicked;

  return (
    <div key={id}>
      <div className={style.info_header}>
        <h3 className={style.info_header_heading}>{heading}</h3>
      </div>
      <div className={style.container}>
        <div className={style.item}>
          <div className={style.label}>SafeZone ID</div>
          <span className={style.value}>{id}</span>
        </div>
        <div className={style.item}>
          <div className={style.label}>Radius</div>
          <span className={style.value}>{radius}</span>
        </div>
        {objects &&
          objects.map(
            (item, index) =>
              item &&
              Object.keys(item).map((keyName, keyIndex) => (
                <div
                  className={style.item_container}
                  key={`${index}-${keyIndex}`}
                >
                  <div className={style.item}>
                    <div className={style.label}>Device ID</div>
                    <span className={style.value}>{keyName}</span>
                  </div>
                  <div className={style.item}>
                    <div className={style.label}>Device Status</div>
                    <span className={style.value}>
                      {capitalCase(item[keyName])}
                    </span>
                  </div>
                </div>
              ))
          )}
        <div className={style.item_container}>
          {Object.keys(keyValuePairs).map((keyName, keyIndex) => (
            <div className={style.item} key={keyIndex}>
              <div className={style.label}>{prettifyCamelCase(keyName)}</div>
              <span className={style.value}>
                {capitalCase(keyValuePairs[keyName])}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default SafeZoneInfoWindow;

import React from 'react';
import { ReactSVG } from 'react-svg';

import { TmoH2 } from '@tmobile/tmo-bps-syncup-web-component-lib';

import style from './Error.module.css';

import no_data from '../../static/assets/icons/no_data.svg';

function Error({ heading, message, icon }) {
  return (
    <div className={style.container}>
      <div className={style.no_data_wrapper}>
        <ReactSVG src={icon ? icon : no_data} className={style.no_data_img} />
      </div>
      <TmoH2 className={style.heading}>{heading}</TmoH2>
      <p className={style.message}>{message}</p>
    </div>
  );
}

export default Error;

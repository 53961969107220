import React, { useContext } from 'react';

import { TmoH1 } from '@tmobile/tmo-bps-syncup-web-component-lib';
import LoggedInUserContext from '../../context/LoggedInUserContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { getOktaGroups } from '../../utils/helper_functions';

function EnvRoleFeatureAuthorizer({ roles, products, children, ...rest }) {
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { userInfo = {} } = useContext(LoggedInUserContext);
  const oktaGroups = userInfo ? getOktaGroups(userInfo) : [];
  const showFeature =
    roles &&
    roles.length > 0 &&
    products &&
    products.length > 0 &&
    roles.some(r => oktaGroups?.includes(r)) &&
    products.some(r => r === syncUpProductContext.syncUpAppName);

  return showFeature ? (
    <>{children}</>
  ) : (
    <TmoH1 flush>
      This feature is not available in the current environment
    </TmoH1>
  );
}

export default EnvRoleFeatureAuthorizer;

import React from 'react';

import classNames from 'classnames';

import style from './TabGroup.module.css';

function TabGroups({ className, sticky, children }) {
  return (
    <div
      className={classNames(style.container, className, sticky && style.sticky)}
    >
      {children}
    </div>
  );
}

export default TabGroups;

import React, { useState, useCallback, useRef } from 'react';
import ModalContext from '../../context/ModalContext';

function withModal(BaseComponent, isInitiallyOpen = false) {
  return props => {
    const [isModalOpen, setIsOpen] = useState(isInitiallyOpen);
    const closeModalResolver = useRef();

    const openModal = useCallback(() => {
      setIsOpen(true);
      return new Promise(resolve => {
        closeModalResolver.current = resolve;
      });
    }, []);

    const closeModal = useCallback((closeResult = {}) => {
      setIsOpen(false);
      if (closeModalResolver.current) {
        closeModalResolver.current(closeResult);
      }
    }, []);

    return (
      <ModalContext.Provider
        value={{
          isModalOpen,
          openModal,
          closeModal
        }}
      >
        <BaseComponent
          {...props}
          openModal={openModal}
          closeModal={closeModal}
        />
      </ModalContext.Provider>
    );
  };
}

export default withModal;

import React from 'react';

import {
  TmoCard,
  RawData,
  withModal,
  StatusPill
} from '@tmobile/tmo-bps-syncup-web-component-lib';
import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import ItemDetailsModal from 'components/ItemDetailsModal';
import TimeDisplay from '../uiHelpers/TimeDisplay';
import { removeDashes } from '../../utils/helper_functions';

import style from './PrivacyStatusItem.module.css';

function PrivacyStatusItem({ privacyStatus, openModal, forceRefresh }) {
  const {
    id,
    type,
    attributes: {
      imei,
      status,
      campaign_id,
      account,
      started_at,
      created_at,
      ended_at
    }
  } = privacyStatus;
  return (
    <TmoCard shadow={false} Id={id}>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <div className={style.status}>
            <StatusPill
              className={style.status_pill}
              inline={false}
              status={removeDashes(status)}
            >
              {status}
            </StatusPill>
          </div>
          <label className={style.display_name}>{id}</label>
          <span className={style.role_name}>{type}</span>
        </div>
        <div className={style.action_controls}>
          <RawData
            rawObject={privacyStatus}
            tooltip="Full Status Object"
            title={`Full Status Object `}
            className={style.object}
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_primary_icon"
            type=" "
          />
        </div>
      </div>
      <div className={style.content} key={imei}>
        <ItemDetailList
          rowSet={[
            { IMEI: imei, Account: account },
            { 'Campaign Id': campaign_id }
          ]}
        />
        <ItemDetailsModal
          buttonText={'View Dates'}
          label="Created/Started/Ended Dates"
        >
          <TimeDisplay
            title="Created At"
            timestamp={created_at}
            type="primary_time_display"
          />
          <div className={style.separator}></div>
          <TimeDisplay
            title="Started At"
            timestamp={started_at}
            type="primary_time_display"
          />
          <div className={style.separator}></div>
          <TimeDisplay
            title="Ended At"
            timestamp={ended_at}
            type="primary_time_display"
          />
        </ItemDetailsModal>
      </div>
      <div className={style.content} key={created_at}></div>
    </TmoCard>
  );
}

export default withModal(PrivacyStatusItem);

import React from 'react';
import classNames from 'classnames';

import style from './TmoH3.module.css';

function TmoH3({ className, flush, children, ariaLabel, ariaRole, tabIndex }) {
  return (
    <h3
      className={classNames(style.container, flush && style.flush, className)}
      aria-label={ariaLabel}
      role={ariaRole}
      tabIndex={tabIndex}
    >
      {children}
    </h3>
  );
}

export default TmoH3;

import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  NavigationTab as Tab,
  NavigationTabGroup as TabGroup,
  ContentContainer
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import Authorized from '../../auth/Authorized';
import { SUA_ROLES } from '../../utils/app_constants';
import { getRandomString } from '../../utils/stringUtils';
import SupportGroupsGrid from '../../components/platform/SupportGroupsGrid';
import OnCallSupport from '../../components/platform/OnCallSupport';
import GoBack from '../../components/uiHelpers/goBack/GoBack';

import style from './ProductMetrics.module.css';

function PlatformSupport() {
  const [lastRefresh] = useState(getRandomString()); // TODO: Add refresh button

  const onCallGroupUrl = '/platform-support';
  const supportGroupUrl = '/platform-support/support-groups';

  return (
    <Authorized
      roles={[
        SUA_ROLES.ADMIN,
        SUA_ROLES.PO,
        SUA_ROLES.PETS_ADMIN,
        SUA_ROLES.PETS_DEV,
        SUA_ROLES.PETS_PO,
        SUA_ROLES.TRACKER_ADMIN,
        SUA_ROLES.TRACKER_DEV,
        SUA_ROLES.TRACKER_PO,
        SUA_ROLES.WATCH_ADMIN,
        SUA_ROLES.WATCH_DEV,
        SUA_ROLES.WATCH_PO
      ]}
      willShowErrorModal
    >
      <ContentContainer className={style.overview}>
        <GoBack padded />
        <TabGroup sticky>
          <Tab to={onCallGroupUrl} text="OnCall Support" />
          <Tab to={supportGroupUrl} text="Support Groups" />
        </TabGroup>
        <div className={style.content}>
          <Switch key={lastRefresh}>
            <Route exact path={onCallGroupUrl} component={OnCallSupport} />
            <Route exact path={supportGroupUrl} component={SupportGroupsGrid} />
          </Switch>
        </div>
      </ContentContainer>
    </Authorized>
  );
}

export default PlatformSupport;

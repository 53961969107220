import React, { useContext, useState } from 'react';
import TmoFieldset from '../forms/TmoFieldset';
import TmoButton from '../forms/TmoButton';
import FormModal from '../modal/FormModal';
import withModal from '../modal/withModal';
import TmoInput from '../forms/TmoInput';
import StatusContext from '../../context/StatusContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './CommonTestHarnessControls.module.css';

const fwInfoFieldSpecs = [
  {
    labelText: 'Version',
    fieldName: 'fwVer',
    formComponent: TmoInput,
    required: true
  },
  {
    labelText: 'Url',
    fieldName: 'fwUrl',
    formComponent: TmoInput,
    required: true
  },
  {
    labelText: 'Hash',
    fieldName: 'fwHash',
    formComponent: TmoInput,
    required: true
  }
];

function CommonTestHarnessControls({
  className,
  device,
  openModal,
  patchDesiredState
}) {
  const [modalTitle, setModalTitle] = useState();
  const [modalFieldSpecs, setModalFieldSpecs] = useState();
  const { addSuccessMessage } = useContext(StatusContext);

  const openUpdateFirmwareModal = async () => {
    setModalTitle('Update Firmware');
    setModalFieldSpecs(fwInfoFieldSpecs);
    const { isConfirmed, formValues } = await openModal();
    if (isConfirmed) {
      const fwInfo = {
        ...formValues
      };
      trackCustomEvents(`Test Harness - Update Firmware`, {
        desiredState: { fwInfo }
      });
      await patchDesiredState({
        desiredState: { fwInfo }
      });
      addSuccessMessage({ message: `Successfully added ${formValues.fwVer}` });
    }
  };

  const openRemoveFirmwareModal = async () => {
    setModalTitle('Remove Firmware');
    setModalFieldSpecs({
      labelText: 'fwHash',
      fieldName: 'fwHash',
      formComponent: TmoInput,
      selectSpecs: {
        defaultValue: device.desiredState?.fwInfo?.fwHash
      }
    });

    const fwInfo = null;
    trackCustomEvents(`Test Harness - Remove Firmware`, {
      desiredState: { fwInfo }
    });
    await patchDesiredState({
      desiredState: { fwInfo }
    });
    addSuccessMessage({ message: `Successfully removed firmware` });
  };

  return (
    <>
      <TmoFieldset className={style.fieldset} legendText="Firmware">
        <TmoButton onClick={openUpdateFirmwareModal}>Add</TmoButton>
        <TmoButton
          onClick={openRemoveFirmwareModal}
          disabled={!device.desiredState?.fwInfo}
          className={style.fieldset_button}
        >
          Remove
        </TmoButton>
      </TmoFieldset>
      <FormModal title={modalTitle} fieldSpecs={modalFieldSpecs} />
    </>
  );
}

export default withModal(CommonTestHarnessControls);

import {
  SEARCH_USERS_REQUEST,
  SEARCH_USERS_SUCCESS,
  SEARCH_USERS_ERROR
} from '../utils/app_constants';

const USERS_INITIAL_STATE = {
  data: [],
  isLoading: false,
  isError: false
};

export const usersReducer = (state = USERS_INITIAL_STATE, action) => {
  switch (action.type) {
    case SEARCH_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case SEARCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload
      };
    case SEARCH_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        data: action.payload
      };
    default:
      return state;
  }
};

import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { ContentContainer } from '@tmobile/tmo-bps-syncup-web-component-lib';

import MediaManager from '../components/media/MediaManager';
import SyncUpProductContext from '../context/SyncUpProductContext';
import { APP_MOBILE, SUA_PAGES } from '../utils/app_constants';

import style from './MediaPage.module.css';

function MediaPage() {
  const syncUpProductContext = useContext(SyncUpProductContext);

  const productTabs = () => {
    switch (syncUpProductContext.syncUpAppName) {
      case APP_MOBILE.PETS:
      case APP_MOBILE.TRACKER:
        return <Redirect to={SUA_PAGES.SEARCH.path} />;
      default:
        return <MediaManager />;
    }
  };

  return (
    <ContentContainer className={style.container}>
      {productTabs()}
    </ContentContainer>
  );
}

export default MediaPage;

import React, { useContext, useState } from 'react';
import { TmoButtons as TmoButton } from '@tmobile/tmo-bps-syncup-web-component-lib';

import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import faqContentService from '../../services/faqContentService';
import { trackCustomEvents } from '../../utils/appInsight_analytics';

import style from './ReOrderFAQ.module.css';

import { FaSpinner } from 'react-icons/all';

function ReOrderFAQ({
  data,
  cancelReordering,
  reOrderingList,
  onDataChange,
  language
}) {
  const [loading, setLoading] = useState(false);
  const syncUpProductContext = useContext(SyncUpProductContext);
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const handleReordering = async () => {
    let categoryOrdering = data.filter(e => e.order !== e.newCategoryOrder);
    if (
      (categoryOrdering && categoryOrdering.length > 0) ||
      (reOrderingList && reOrderingList.length > 0)
    ) {
      setLoading(true);
      let count = 0;
      let errorMsg = '';
      trackCustomEvents(`FAQ ordering`);
      for await (const category of categoryOrdering) {
        try {
          await faqContentService.updateFAQCategory({
            body: {
              id: category.id,
              categoryOrder: category.newCategoryOrder,
              language: language
            },
            product: syncUpProductContext.syncUpAppName
          });
        } catch (error) {
          count = count + 1;
          errorMsg = error;
        }
      }
      for await (const result of reOrderingList) {
        try {
          await faqContentService.updateFAQ({
            product: syncUpProductContext.syncUpAppName,
            body: [
              {
                id: result.id,
                faqOrder: result.newIndex,
                language: language
              }
            ]
          });
        } catch (error) {
          count = count + 1;
          errorMsg = error;
        }
      }
      setLoading(false);
      cancelReordering();
      if (count > 0) {
        addErrorMessage({ errorMsg });
      } else {
        addSuccessMessage({
          message: `Successfully changed FAQ order`
        });
      }
      onDataChange();
    } else {
      cancelReordering();
      onDataChange();
    }
  };
  return (
    <>
      <div className={style.ordering_row_wrapper}>
        {loading ? (
          <TmoButton className={style.action_button}>
            <FaSpinner className={style.ordering_spinner} /> Loading
          </TmoButton>
        ) : (
          <div className={style.action_wrapper}>
            <TmoButton
              onClick={cancelReordering}
              className={style.action_button}
              type="magenta_primary"
            >
              Cancel
            </TmoButton>
            <TmoButton
              onClick={handleReordering}
              className={style.action_button}
              type="magenta_primary"
            >
              Save Order
            </TmoButton>
          </div>
        )}
      </div>
    </>
  );
}

export default ReOrderFAQ;

import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import TmoH3 from '../../../components/controlWrappers/TmoH3';
import Accordion from '../../../components/uiHelpers/Accordion';
import { appInsights } from '../../../utils/appInsight_analytics';
import { APP_MOBILE, APP_LANGUAGE } from '../../../utils/app_constants';
import Details from '../../../components/faq/Details';
import Search from '../../../components/faq/Search';
import faqContentService from '../../../services/faqContentService';
import config from '../../../configs/config';
import { SUA_ENVIRONMENTS } from '../../../utils/app_constants';
import Fetcher from '../../../components/Fetcher';
import { capitalCase } from '../../../utils/helper_functions';

import style from './FAQPage.module.css';

function FAQPage() {
  const [faqContent, setFaqContent] = useState();
  const [language, setLanguage] = useState(null);
  const currentEnvironment = config.apiSettings.environment;
  const location = useLocation();
  const userId = location.search
    ? new URLSearchParams(location.search).get('userId')
    : null;
  const showSubmitQuestion = location.search
    ? new URLSearchParams(location.search).get('showSubmitQuestion')
    : null;
  const features = location.search
    ? new URLSearchParams(location.search).get('features')
    : null;

  let featureJson;
  try {
    featureJson = features ? JSON.parse(features) : null;
  } catch (er) {
    featureJson = null;
  }

  const accordionTitle = title => (
    <div className={style.row}>
      <div className={style.title}>{title}</div>
    </div>
  );
  const actionCallback = useCallback(() => {
    return (
      language &&
      faqContentService.getFAQbyCategories({
        product: APP_MOBILE.WATCH,
        isProdEnv:
          currentEnvironment === SUA_ENVIRONMENTS.PRODUCTION ? true : false,
        language:
          language === APP_LANGUAGE.ENGLISH || language === APP_LANGUAGE.SPANISH
            ? APP_LANGUAGE.ENGLISH
            : APP_LANGUAGE.ENGLISH
      })
    );
  }, [currentEnvironment, language]);
  useEffect(() => {
    const currentPath = location.pathname + location.search;
    appInsights &&
      appInsights.trackPageView({
        name: `${APP_MOBILE.WATCH}-faq`,
        uri: currentPath,
        properties: {
          userId
        }
      });
  }, [location, userId]);

  useEffect(() => {
    const getLanguage = () => {
      var language = navigator.language;
      if (language.length > 2) {
        language = language.slice(0, 2);
      }
      return language;
    };
    if (!language) {
      setLanguage(getLanguage());
    }
  }, [language, setLanguage]);
  return (
    <>
      <Fetcher
        action={actionCallback}
        onLoad={setFaqContent}
        spinner={false}
        render={() =>
          faqContent && faqContent.length > 0 ? (
            <Search
              list={faqContent}
              userId={userId}
              showSubmitQuestion={showSubmitQuestion}
              features={featureJson}
              renderItem={(item, index) => (
                <div key={`kids-faq-fragment-${index}`}>
                  <TmoH3
                    className={style.sticky_tiles}
                    flush
                    ariaLabel={capitalCase(item.category)}
                    ariaRole="heading"
                    tabIndex={10000 * index}
                  >
                    <span>{capitalCase(item.category)}</span>
                  </TmoH3>
                  {item.details &&
                    item.details.map((data, i) => (
                      <Accordion
                        key={`${data.id}-${i}`}
                        className={style.accordion_row}
                        title={accordionTitle(
                          data.title,
                          10000 * index + (i * 5 + 1)
                        )}
                        titleClassName={style.accordion_column}
                        contentClassName={style.accordion_content}
                        customIcon
                        ariaLabel={data.title}
                        ariaRole="button"
                        insights={{ id: data.id, title: data.title }}
                        tabIndex={10000 * index + (i * 5 + 1)}
                        userId={userId}
                      >
                        <Details
                          details={data}
                          className={style.content_description}
                          userId={userId}
                          tabIndex={10000 * index + (i * 5 + 1)}
                          language={language}
                        />
                      </Accordion>
                    ))}
                </div>
              )}
            />
          ) : (
            'No results found'
          )
        }
      />
    </>
  );
}

export default FAQPage;

import config from '../configs/config';
import syncUpPlatformRequest from '../repositories/syncupPlatformRequest';

const faqContentRepository = {
  getAllFAQ: async ({ product, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.productFaq}`,
      httpMethod: httpMethods.GET,
      basePath: basePath.coreConsumer,
      headers: {
        [headers.SyncUpProduct.key]: product
      },
      ignoreDefaultHeaders: true
    });
  },
  deleteFAQ: async ({ product, faqId, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.adminSingleFAQ}`.replace(':id', faqId),
      httpMethod: httpMethods.DELETE,
      basePath: basePath.coreAdminV1,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  deleteBulkFAQ: async ({ product, faqIds, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.adminBulkFAQ}`.replace(':ids', faqIds),
      httpMethod: httpMethods.DELETE,
      basePath: basePath.coreAdminV1,
      headers: {
        [headers.SyncUpProduct.key]: product
      }
    });
  },
  createFAQ: async ({ product, body, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.adminFAQ,
      body: body,
      httpMethod: httpMethods.POST,
      basePath: basePath.coreAdminV1,
      headers: {
        [headers.SyncUpProduct.key]: product,
        [headers.subscriptionKey.key]: headers.subscriptionKey.value
      }
    });
  },
  updateFAQ: async ({ product, body, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.adminFAQ,
      body: body,
      httpMethod: httpMethods.PUT,
      basePath: basePath.coreAdminV1,
      headers: {
        [headers.SyncUpProduct.key]: product,
        [headers.subscriptionKey.key]: headers.subscriptionKey.value
      }
    });
  },
  updateFAQCategory: async ({ product, body, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.adminCategory,
      body: body,
      httpMethod: httpMethods.PUT,
      basePath: basePath.coreAdminV1,
      headers: {
        [headers.SyncUpProduct.key]: product,
        [headers.subscriptionKey.key]: headers.subscriptionKey.value
      }
    });
  },
  faqVote: async ({ product, body, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: resources.faqVote,
      body: body,
      httpMethod: httpMethods.POST,
      basePath: basePath.coreConsumer,
      headers: {
        [headers.SyncUpProduct.key]: product
      },
      ignoreDefaultHeaders: true
    });
  },
  getFAQbyCategories: async ({ product, language, responseStatus = false }) => {
    const {
      resources,
      httpMethods,
      headers,
      basePath
    } = config.apiSettings.syncUpPlatform;

    return await syncUpPlatformRequest({
      responseStatus: responseStatus,
      urlSuffix: `${resources.faqCategories}`.replace(':language', language),
      httpMethod: httpMethods.GET,
      basePath: basePath.coreConsumer,
      headers: {
        [headers.SyncUpProduct.key]: product
      },
      ignoreDefaultHeaders: true
    });
  }
};

export default faqContentRepository;

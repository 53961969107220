import React, { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import {
  StatusPill,
  DynamicList,
  TmoSearch,
  DynamicSort,
  FilterModal,
  TmoDropDownSelect,
  PaginationV2,
  TmoToggle
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import APIActions from './APIActions';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';
import { API_STATUS_PAGE_SIZE_OPTIONS } from 'utils/app_constants';
import { hasValue } from 'utils/helper_functions';
import APICard from './APICard';

import style from './StatusTable.module.css';
import paginationStyle from '../../static/assets/css/pagination.module.css';
import { FaSpinner } from 'react-icons/all';
import arrowLeft from '../../static/assets/icons/left_arrow.svg';
import filterIcon from '../../static/assets/icons/filter.svg';
import sortIcon from '../../static/assets/icons/sort__new_icon.svg';
import searchIcon from '../../static/assets/icons/search_new.svg';

import arrowRight from '../../static/assets/icons/right_arrow.svg';
import { IoIosArrowDown } from 'react-icons/all';
import classNames from 'classnames';

const StatusTable = ({ item, name }) => {
  const [category, setCategory] = useState(item);
  const sortRef = useRef();
  const searchtRef = useRef();
  const filtertRef = useRef();
  const [filteredListLength, setFilteredListLength] = useState(0);
  const [filteredList, setFilteredList] = useState();
  const [listView, setlistView] = useState(true);
  const [sortOptions] = useState([
    { key: 'name', value: 'Name' },
    { key: 'description', value: 'Description' },
    { key: 'methodType', value: 'Method' }
  ]);
  const [APIPerPage, setAPIPerPage] = useState(API_STATUS_PAGE_SIZE_OPTIONS[0]);
  const [filterOptions] = useState([
    { key: 'methodType', value: 'Method' },
    { key: 'serviceName', value: 'Service' }
  ]);
  const selectOptions = API_STATUS_PAGE_SIZE_OPTIONS.map(o => ({
    key: o,
    value: `${o} APIs`
  }));
  selectOptions.push({
    key: 'All',
    value: 'All APIs'
  });
  const showPagination = filteredList && filteredList?.length > APIPerPage;
  const [currentPage, setCurrentPage] = useState(1);
  const handleOnExecute = updatedItem => {
    let updatedItems = [...filteredList];
    updatedItems.forEach(i => {
      if (i.id === updatedItem.id) {
        i.status = updatedItem.status;
      }
    });
    setFilteredList(updatedItems);
  };

  const headerNameListItemMap = [
    {
      listItemKey: 'serviceName',
      headerDisplayName: 'Service',
      columnClass: style.name
    },
    {
      listItemKey: 'name',
      headerDisplayName: 'API Name',
      columnClass: style.name
    },
    {
      listItemKey: 'description',
      headerDisplayName: 'Description',
      columnClass: style.description
    },
    {
      listItemKey: 'methodType',
      headerDisplayName: 'Method'
    },
    {
      listItemKey: 'version',
      headerDisplayName: 'Version'
    },
    {
      listItemKey: 'APIRoute',
      headerDisplayName: 'API Route',
      columnClass: style.api_route
    },
    {
      listItemKey: 'status',
      headerDisplayName: 'Status',
      columnClass: style.status,
      render: ({ status }) =>
        status === null ? (
          <FaSpinner className={style.spinner} />
        ) : status ? (
          <StatusPill
            className={style.status_text}
            status={
              status >= 200 && status < 300
                ? 'succeeded'
                : status === 404
                ? 'inactive'
                : 'failed'
            }
          >
            {status}
          </StatusPill>
        ) : (
          '-'
        )
    },
    {
      listItemKey: 'action',
      headerDisplayName: 'Action',
      columnClass: style.action,
      render: ({ ...item }) =>
        item.apiDetails && (
          <APIActions item={item} onExecuteApi={handleOnExecute} />
        )
    }
  ];

  const handleSearchFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (filtertRef?.current?.ApplyFilters) {
        filtertRef.current.ApplyFilters(data.list);
      }
    }
  };
  const handleOnFilter = data => {
    if (data && data.parentEvent) {
      setFilteredList(data.list);
      setFilteredListLength(data.list.length);
      if (sortRef?.current?.getSortedList) {
        sortRef.current.getSortedList(data.list);
      }
    } else {
      if (searchtRef?.current?.ApplyFilters) {
        searchtRef.current.ApplyFilters(data.list);
      }
    }
  };

  const handleOnSort = list => {
    setFilteredList(list);
    setFilteredListLength(list.length);
  };

  const getCurrentPageAssetList = () => {
    if (filteredList && filteredList.length) {
      const offset = (currentPage - 1) * APIPerPage;
      return filteredList.slice(offset, offset + APIPerPage);
    }
    return [];
  };

  useEffect(() => {
    if (item) {
      setCategory([...item]);
      setFilteredList([...item]);
      setFilteredListLength(item.length);
    }
  }, [item]);

  return (
    <>
      <PageHeader
        title={name}
        secondary
        subtitle={
          filteredListLength > 1 && `${filteredListLength} APIs available`
        }
      >
        <TmoSearch
          placeholder="Search by Name, Description, API Route"
          originalList={category}
          options={['name', 'description', 'APIRoute']}
          onFilter={handleSearchFilter}
          ref={searchtRef}
          isActionIconBtn
          renderIcon={<ReactSVG src={searchIcon} />}
        />
        <DynamicSort
          ref={sortRef}
          listItems={filteredList}
          optionsList={sortOptions}
          onSort={e => {
            handleOnSort(e);
          }}
          isActionIconBtn
          renderIcon={<ReactSVG src={sortIcon} />}
        />
        <FilterModal
          ref={filtertRef}
          options={filterOptions}
          originalList={category}
          listItems={filteredList}
          onFilter={handleOnFilter}
          isActionIconBtn
          renderIcon={
            <ReactSVG className={style.close_icon} src={filterIcon} />
          }
        />
      </PageHeader>

      <div
        className={classNames(
          paginationStyle.pagination_wrapper,
          style.control_wrapper
        )}
      >
        <div className={paginationStyle.page_size}>
          <TmoDropDownSelect
            optionsList={selectOptions}
            onChange={e => {
              if (e === 'All') {
                setAPIPerPage(
                  filteredList && filteredList.length
                    ? filteredList.length + 1
                    : 0
                );
              } else {
                setAPIPerPage(Number(e));
              }
              setCurrentPage(1);
            }}
            renderIcon={<IoIosArrowDown />}
            defaultSelectedValue={
              selectOptions.filter(e => e.key === APIPerPage)[0]
            }
          />
        </div>
        {showPagination && (
          <div>
            <PaginationV2
              className={paginationStyle.custom_pagination}
              totalPages={Math.ceil(filteredList.length / APIPerPage)}
              selectedPageNumber={currentPage}
              onPageChanged={currentPage => setCurrentPage(currentPage)}
              type="primary"
              icons={{
                left: <ReactSVG src={arrowLeft} />,
                right: <ReactSVG src={arrowRight} />
              }}
            />
          </div>
        )}
        <TmoToggle
          label={'List View'}
          isChecked={listView}
          onChange={() => setlistView(!listView)}
        />
      </div>

      {hasValue(filteredList) && (
        <>
          {listView ? (
            <DynamicList
              type="primary"
              headerNameListItemMap={headerNameListItemMap}
              listItems={getCurrentPageAssetList()}
              className={style.dynamic_list}
            />
          ) : (
            <div className={style.items}>
              {getCurrentPageAssetList().map((a, index) => (
                <APICard
                  onExecuteApi={handleOnExecute}
                  key={`api-${index}`}
                  api={a}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default StatusTable;

import React from 'react';

import TimeDisplay from '../uiHelpers/TimeDisplay';
import { getTimestampList } from '../../utils/dateUtils';
import style from './HealthCheckTimeStamps.module.css';

function HealthCheckTimeStamps({
  latestSensorTimestamps,
  sensorHistoryTimestamps,
  sensor
}) {
  return (
    <div className={style.container}>
      {latestSensorTimestamps && (
        <div className={style.label_container}>
          <label className={style.label_header}>Latest Sensor timestamp:</label>
          {latestSensorTimestamps[sensor] ? (
            <TimeDisplay timestamp={latestSensorTimestamps[sensor]} />
          ) : (
            <label>Not Available</label>
          )}
        </div>
      )}
      {sensorHistoryTimestamps && (
        <div className={style.label_container}>
          <label className={style.label_header}>
            Sensor history timestamp:
          </label>
          {sensorHistoryTimestamps[sensor] &&
          sensorHistoryTimestamps[sensor].length > 0 ? (
            <ul className={style.list}>
              {sensorHistoryTimestamps[sensor].map((object, index) => (
                <li>
                  {getTimestampList(
                    sensorHistoryTimestamps[sensor][index],
                    null,
                    'USER'
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <label>Not Available</label>
          )}
        </div>
      )}
    </div>
  );
}

export default HealthCheckTimeStamps;

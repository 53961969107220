import React, { useState } from 'react';
import ActiveUserTabContext from 'context/ActiveUserTabContext';

function WithAtiveUserTab(BaseComponent) {
  return function WithAtiveUserTab(props) {
    const [selectedTab, setSelectedTab] = useState(null);
    const value = { selectedTab, setSelectedTab };
    return (
      <ActiveUserTabContext.Provider value={value}>
        <BaseComponent {...props} />
      </ActiveUserTabContext.Provider>
    );
  };
}

export default WithAtiveUserTab;

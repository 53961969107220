import React from 'react';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import style from './TmoCard.module.css';

function TmoCard({
  className,
  shadow = true,
  children,
  customId,
  mainHeading,
  subPage
}) {
  return (
    <div
      id={customId}
      className={classNames(style.container, className, shadow && style.shadow)}
    >
      {subPage?.headerIcon && subPage?.heading && (
        <div className={style.page_head}>
          <ReactSVG src={subPage.headerIcon} />
          <p className={style.page_heading}>{subPage.heading}</p>
        </div>
      )}
      {mainHeading && <h1 className={style.heading}>{mainHeading}</h1>}
      {children}
    </div>
  );
}

export default TmoCard;

import React, { useState, useMemo } from 'react';

import LabelWithInput from './LabelWithInput';
import TmoButton from './TmoButton';
import { getRandomString } from '../../utils/stringUtils';

import style from './TmoForm.module.css';

function TmoForm({ className, fieldSpecs = [], onSubmit, submitText }) {
  const [fieldValues, setFieldValues] = useState(
    fieldSpecs
      .filter(f => f.defaultValue)
      .reduce(
        (acc, cur) => ({
          ...acc,
          [cur.fieldName]: cur.defaultValue
        }),
        {}
      )
  );

  const keys = useMemo(() => fieldSpecs.map(f => getRandomString()), [
    fieldSpecs
  ]);

  const handleChange = ({ fieldName, newValue }) => {
    setFieldValues({
      ...fieldValues,
      [fieldName]: newValue
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(fieldValues);
  };

  return (
    <form className={className} onSubmit={handleSubmit}>
      {fieldSpecs.map(({ fieldName, defaultValue, ...rest }, index) => (
        <LabelWithInput
          {...rest}
          key={rest.key || keys[index]}
          value={fieldValues[fieldName]}
          onChange={newValue => handleChange({ fieldName, newValue })}
        />
      ))}
      <div className={style.submit}>
        <TmoButton buttonType="submit">{submitText || 'Submit'}</TmoButton>
      </div>
    </form>
  );
}

export default TmoForm;

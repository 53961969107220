import { useState, useEffect, useRef } from 'react';

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);
  const timer = useRef();

  const handleWindowResize = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      setWidth(window.innerWidth);
    }, 200);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return width;
}

export default useWindowWidth;

import config from '../configs/config';
import { authClient, getMSALToken } from '../auth/authClient';
import { IS_AZURE_AUTH } from 'utils/app_constants';

const {
  schema,
  hostName,
  basePath,
  services,
  resources,
  httpMethods,
  headers
} = config.apiSettings.syncUpPlatform;

const getHostUrl = () => `${schema}://${hostName}`;

const getDefaultHeaders = async () => {
  const idToken = IS_AZURE_AUTH
    ? await getMSALToken()
    : await authClient.getIdToken();
  return {
    [headers.partnerId.key]: headers.partnerId.value,
    [headers.subscriptionKey.key]: headers.subscriptionKey.value,
    [headers.authorization.key]: idToken
  };
};

const initiateAPICall = async (url, httpMethod, headers, body) => {
  const response = await fetch(url, {
    method: httpMethod,
    headers: {
      ...(await getDefaultHeaders()),
      ...headers
    },
    body: JSON.stringify(body)
  });

  if (response.ok) {
    if (httpMethod !== httpMethods.DELETE) {
      return response.json();
    }
    return null;
  }
  throw Error(response.status);
};

export const userSearch = query => {
  const url = `${getHostUrl()}${basePath.admin}${services.orchestration}${
    resources.search
  }q=${query}`;
  return initiateAPICall(url, httpMethods.GET);
};

export const fetchUserThings = userID => {
  const url = `${getHostUrl()}${basePath.admin}${services.orchestration}${
    resources.userThings
  }`.replace(':userId', userID);
  return initiateAPICall(url, httpMethods.GET);
};

export const fetchSensorHistory = (
  userID,
  deviceID,
  sensorType,
  queryString
) => {
  const url = `${getHostUrl()}${basePath.admin}${services.device}${
    resources.sensorHistory
  }${queryString}`
    .replace(':userId', userID)
    .replace(':deviceId', deviceID)
    .replace(':sensorType', sensorType);
  return initiateAPICall(url, httpMethods.GET);
};

export const fetchUserNotifications = (userID, queryString) => {
  const url = `${getHostUrl()}${basePath.admin}${services.user}${
    resources.notification
  }${queryString}`.replace(':userId', userID);
  return initiateAPICall(url, httpMethods.GET);
};

export const fetchUserDevices = userId => {
  const url = `${getHostUrl()}${basePath.admin}${services.device}${
    resources.userDevices
  }`.replace(':userId', userId);
  return initiateAPICall(url, httpMethods.GET);
};

export const thingLinkDevice = (thingId, hardwareId, userId) => {
  const url = `${getHostUrl()}${basePath.platform}${
    resources.thingLinkDevice
  }`.replace(':thingId', thingId);
  let body = { identifier: hardwareId, kind: 'REGISTER' };
  return initiateAPICall(
    url,
    httpMethods.POST,
    {
      [headers.userImpersonation.key]: userId,
      [headers.contentTypeJSON.key]: headers.contentTypeJSON.value
    },
    body
  );
};

export const deleteDevice = (userId, deviceId) => {
  const url = `${getHostUrl()}${basePath.platform}${resources.device}`.replace(
    ':deviceId',
    deviceId
  );
  return initiateAPICall(url, httpMethods.DELETE, {
    [headers.userImpersonation.key]: userId
  });
};

export const getDeviceHealthCheckStatus = (userId, deviceId) => {
  const url = `${getHostUrl()}${basePath.platform}${
    resources.healthCheck
  }`.replace(':deviceId', deviceId);
  return initiateAPICall(url, httpMethods.GET, {
    [headers.userImpersonation.key]: userId
  });
};

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getHealthCheck } from '../actions/device';
import {
  parseUserIdFromURL,
  parseDeviceIdFromURL
} from '../utils/helper_functions';
import HealthCheck from '../components/healthCheck/HealthCheck';

const mapStateToProps = (state, ownProps) => {
  const { healthCheck } = state;
  return {
    healthCheck,
    isError: healthCheck.isError,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch, { match: { url } }) => {
  const userId = parseUserIdFromURL(url, 'devices');
  const deviceId = parseDeviceIdFromURL(url, 'health');

  return {
    onHealthCheck: () => dispatch(getHealthCheck(userId, deviceId))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HealthCheck)
);

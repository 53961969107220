import React, { useState, useContext, useCallback, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useParams } from 'react-router-dom';
import { TmoButtons as TmoButton } from '@tmobile/tmo-bps-syncup-web-component-lib';

import deviceService from 'services/deviceService';
import PrivacyStatusItem from './PrivacyStatusItem';
import { getUserThingBackUrl } from 'utils/helper_functions';
import Fetcher from '../Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import { getRandomString } from '../../utils/stringUtils';
import PageHeader from '../uiHelpers/pageHeader/PageHeader';

import style from './PrivacyStatus.module.css';
import refreshIcon from '../../static/assets/icons/refresh.svg';

function PrivacyStatus({ match, history, hideTab }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [privacyStatus, setPrivacyStatus] = useState();
  const [refreshKey, setRefreshKey] = useState(getRandomString());
  const forceRefresh = () => setRefreshKey(getRandomString());
  let { deviceId } = useParams();

  const actionCallback = useCallback(
    () =>
      deviceService.getPrivacyStatus({
        userId,
        deviceId: deviceId,
        syncUpAppName: product
      }),
    [userId, deviceId, product]
  );

  useEffect(() => {
    hideTab && hideTab(true);
  }, [hideTab]);

  return (
    <>
      <PageHeader
        title={'Privacy Status'}
        tabHeader={true}
        backUrl={getUserThingBackUrl(userId, 'things')}
        hideTab={hideTab}
      >
        <TmoButton
          className={style.button}
          onClick={forceRefresh}
          type="magenta_secondary"
          tooltip="Refresh"
          icon={<ReactSVG src={refreshIcon} />}
        />
      </PageHeader>
      <div className={style.items}>
        <Fetcher
          key={refreshKey}
          action={actionCallback}
          onLoad={setPrivacyStatus}
          render={() =>
            privacyStatus?.data ? (
              <PrivacyStatusItem
                privacyStatus={privacyStatus.data}
                forceRefresh={forceRefresh}
              />
            ) : (
              <span className={style.message}>
                Privacy Status data is not available
              </span>
            )
          }
        />
      </div>
    </>
  );
}

export default PrivacyStatus;

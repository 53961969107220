import React from 'react';

import { TmoCard, RawData, StatusPill } from '@tmobile/tmo-bps-syncup-web-component-lib';

import ItemDetailList from 'components/ItemDetailList/ItemDetailList';
import { removeDashes } from 'utils/helper_functions';
import { GRID } from 'utils/app_constants';
import RecurringSchedule from './RecurrringSchedule';
import DeleteTask from './DeleteTask';

import style from './TaskDetails.module.css';

function TaskDetails({ userTask, forceRefresh }) {
  const {
    id,
    thingId,
    title,
    message,
    rewardId,
    scheduleSingle,
    scheduleRecurring,
    silentMode,
    reminders,
    status,
    active
  } = userTask;

  return (
    <TmoCard shadow={false}>
      <div className={style.card_header}>
        <div className={style.card_title}>
          <div className={style.status}>
            <StatusPill
              className={style.status_pill}
              inline={false}
              status={removeDashes(status)}
            >
              {status}
            </StatusPill>
          </div>
          <div className={style.task_name} title={title}>
            {title}
          </div>
        </div>
        <div className={style.action_controls}>
          <RawData
            rawObject={userTask}
            title={`Full User Object for ${title}`}
            tooltip="Full User Object"
            type=" "
            usePortal={false}
            modalType={'slide'}
            buttonType="magenta_primary"
          />
          <DeleteTask
            id={id}
            isCardView
            title={title}
            forceRefresh={forceRefresh}
          />
        </div>
      </div>
      <div className={style.content}>
        <ItemDetailList rowSet={[{ thingId }, { message }, { rewardId }]} />
        <ItemDetailList
          className={GRID.COLUMN_HALF}
          items={{
            scheduleType: scheduleSingle ? 'Single' : 'Recurring',
            ...(scheduleSingle && { scheduleSingle: scheduleSingle })
          }}
        />
        {scheduleRecurring != null && (
          <RecurringSchedule
            buttonText={'View Details'}
            scheduleRecurring={scheduleRecurring}
          />
        )}
        <ItemDetailList
          rowSet={[
            {
              silentMode: silentMode ? 'On' : 'Off',
              reminders: reminders ? 'On' : 'Off',
              active: active ? 'On' : 'Off'
            }
          ]}
        />
      </div>
    </TmoCard>
  );
}

export default TaskDetails;

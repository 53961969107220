import React, { useState, useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import thingService from '../../services/thingService';
import Fetcher from '../../components/Fetcher';
import ActiveUserTabContext from '../../context/ActiveUserTabContext';
import ThingTrip from './ThingTrip';
import { getUserThingBackUrl } from 'utils/helper_functions';
import PageHeader from 'components/uiHelpers/pageHeader/PageHeader';

import style from './ThingEventsGrid.module.css';

function ThingTripsGrid({ className, hideTab }) {
  const {
    selectedTab: { userId, product }
  } = useContext(ActiveUserTabContext);
  const [thingTrips, setThingTrips] = useState();
  let { thingId } = useParams();

  const getThingTrips = useCallback(() => {
    return thingService.getThingTrips({
      userId: userId,
      thingId: thingId,
      syncUpAppName: product
    });
  }, [thingId, product, userId]);

  useEffect(() => {
    hideTab && hideTab(true);
  }, [hideTab]);

  return (
    <>
      <PageHeader
        backUrl={getUserThingBackUrl(userId, 'things')}
        title={'Trips'}
        tabHeader={true}
        hideTab={hideTab}
      />
      <Fetcher
        action={getThingTrips}
        onLoad={setThingTrips}
        data={thingTrips}
        render={() => (
          <div className={classNames(style.results, className)}>
            {thingTrips && thingTrips.length ? (
              <>
                {thingTrips.map(s => (
                  <ThingTrip key={s.mapKey || s.id} trip={s} />
                ))}
              </>
            ) : (
              'No trips found'
            )}
          </div>
        )}
      />
    </>
  );
}

export default ThingTripsGrid;

import React, { useState, useContext } from 'react';
import {
  LabelWithInput,
  TmoInput,
  TmoTextarea,
  TmoFieldset,
  TmoButtons as TmoButton
} from '@tmobile/tmo-bps-syncup-web-component-lib';

import UserService from '../../services/userService';
import StatusContext from '../../context/StatusContext';
import SyncUpProductContext from '../../context/SyncUpProductContext';
import { trackCustomEvents } from '../../utils/appInsight_analytics';
import PageHeader from '../../components/uiHelpers/pageHeader/PageHeader';

import style from './BulkNotificationFormOpenForm.module.css';

function BulkNotificationFormOpenForm() {
  const [userIds, setUserIds] = useState();
  const [prettyUserIds, setPrettyUserIds] = useState();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const { addSuccessMessage, addErrorMessage } = useContext(StatusContext);
  const syncUpProductContext = useContext(SyncUpProductContext);

  const handleSubmit = async event => {
    event.preventDefault();
    const data = {
      userIds,
      title,
      body
    };
    try {
      trackCustomEvents(`Bulk Notification Open`, {
        userIds,
        title
      });
      await UserService.postBulkNotification({
        data,
        syncUpAppName: syncUpProductContext.syncUpAppName
      });
      setTitle();
      setBody();
      setUserIds([]);
      addSuccessMessage({ message: 'Notifications sent successfully' });
    } catch (error) {
      addErrorMessage({ error });
    }
  };

  const formatUserIds = userIds => {
    setPrettyUserIds(userIds);
    let trimmedUserIds = userIds.split(',').map(item => item.trim());
    setUserIds(trimmedUserIds);
  };

  //TODO: move the required check from the button to the select component
  const isSendEnabled = userIds && userIds.length && title && body;

  const handleReset = () => {
    setTitle();
    setBody();
    setUserIds([]);
    setPrettyUserIds([]);
  };

  return (
    <>
      <PageHeader title={'BULK NOTIFICATION OPEN FORM'} tabHeader />
      <form onSubmit={handleSubmit}>
        <TmoFieldset className={style.form_container}>
          <div className={style.user_container}>
            <LabelWithInput
              labelText="UserIds separated by ','"
              formComponent={TmoInput}
              value={prettyUserIds}
              onChange={formatUserIds}
              inputType="primary"
              placeholder="User IDs"
              className={style.user}
            />

            <LabelWithInput
              labelText="Title"
              formComponent={TmoInput}
              name="name"
              value={title}
              onChange={setTitle}
              inputType="primary"
              className={style.user}
              placeholder="Title"
            />
          </div>
          <div>
            <LabelWithInput
              labelText="Body"
              formComponent={TmoTextarea}
              className={style.text_area}
              value={body}
              onChange={setBody}
              type="primary"
              placeholder="Enter here..."
            />
          </div>
          <div className={style.action}>
            <TmoButton type="magenta_secondary" onClick={handleReset}>
              Clear
            </TmoButton>
            <TmoButton
              buttonType="submit"
              className={style.send}
              disabled={!isSendEnabled}
              type="magenta_primary"
            >
              Send
            </TmoButton>
          </div>
        </TmoFieldset>
      </form>
    </>
  );
}
export default BulkNotificationFormOpenForm;

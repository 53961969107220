import React, { useContext } from 'react';
import { Route, useHistory } from 'react-router-dom';
import _ from 'underscore';

import ErrorMessageModal from '../components/errorHandler/ErrorMessageModal';
import LoggedInUserContext from '../context/LoggedInUserContext';
import { ERROR_SCREEN_MESSAGE } from '../utils/app_constants';
import { getOktaGroups } from 'utils/helper_functions';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { userInfo, homePageUrl } = useContext(LoggedInUserContext);
  const history = useHistory();
  const groups = getOktaGroups(userInfo);
  return (
    <Route
      {...rest}
      render={props => {
        if (userInfo && _.intersection(groups, roles).length === 0) {
          return (
            <ErrorMessageModal
              message={ERROR_SCREEN_MESSAGE.UNAUTHORIZED.message}
              onClose={() => history.push(homePageUrl)}
            />
          );
        }
        return <Component {...props} />;
      }}
    />
  );
};
